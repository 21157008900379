import React from "react";
import styled from "styled-components";

import { _, $ } from "js/vendor";
import { app } from "js/namespaces";
import getLogger, { LogGroup } from "js/core/logger";
import { CANVAS_HEIGHT, CANVAS_WIDTH } from "common/constants";
import { appVersion } from "js/config";
import { getCanvasBundle } from "js/canvas";

const SLIDE_TRANSITION_MS = 300;

const logger = getLogger(LogGroup.PLAYER);

const CanvasContainer = styled.div.attrs(({ visible, transition }) => ({
    style: {
        opacity: visible ? 1 : 0,
        zIndex: visible ? 10 : 0,
        transition: transition ? `opacity ease-in-out ${SLIDE_TRANSITION_MS}ms ${visible ? 0 : SLIDE_TRANSITION_MS}ms` : "none"
    }
}))`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

class CanvasView extends React.Component {
    constructor() {
        super();

        this.containerRef = React.createRef();

        this.setCanvasPromise = null;
    }

    componentDidMount() {
        this.setCanvasPromise = this.setCanvas();
    }

    componentWillUnmount() {
        const { isCurrent } = this.props;

        if (this.canvas) {
            if (isCurrent) {
                this.canvas.removeAsCurrentCanvas();
            }

            this.canvas.stopAnimation();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            slide,
            isCurrent,
            shouldAnimate,
            playbackStage,
            ignorePlaybackStage,
            shouldJumpToNextWaitForClick,
            showBranding,
            isCreatorOldBasicUser,
            canvasScale,
            onPlaybackStageChanged,
            playTransition
        } = this.props;

        if (slide.id !== prevProps.slide.id) {
            logger.warn("[CanvasView] slide changed, this should never happen, initialize new CanvasView instead!", { slideId: slide.id, prevSlideId: prevProps.slide?.id });
        }

        // Make sure the canvas is rendered before applying the changes
        this.setCanvasPromise.then(async () => {
            if (isCurrent && !prevProps.isCurrent) {
                app.currentCanvas = this.canvas;
                this.canvas.setAsCurrentCanvas();
                this.canvas.prepareToShowElements();
            } else if (!isCurrent && prevProps.isCurrent) {
                this.canvas.removeAsCurrentCanvas();
            }

            if (shouldAnimate !== prevProps.shouldAnimate) {
                if (shouldAnimate) {
                    // We need to run the prepareToShow to trigger any autoplaying videos
                    await this.canvas.prepareToShowElements();
                    // Note: we'll wait for the transition to finish
                    //   before kicking off the actual animation
                    this.animateCanvas(isCurrent && !prevProps.isCurrent && playTransition);
                } else {
                    this.canvas.stopAnimation();
                }
            }

            if (shouldAnimate && shouldJumpToNextWaitForClick && !prevProps.shouldJumpToNextWaitForClick) {
                this.canvas.jumpToNextWaitForClick();
            }

            if (!ignorePlaybackStage) {
                if (ignorePlaybackStage !== prevProps.ignorePlaybackStage && playbackStage !== this.canvas.currentPlaybackStage) {
                    this.canvas.goToStage(playbackStage, false);
                } else if (playbackStage !== prevProps.playbackStage) {
                    // onPlaybackStageChanged is triggered only if there was an explicit request to change
                    // playback stage, it won't be triggered if ignorePlaybackStage got enabled
                    this.canvas.goToStage(playbackStage, true)
                        .then(() => onPlaybackStageChanged(playbackStage, prevProps.playbackStage));
                }
            }

            if (showBranding !== prevProps.showBranding) {
                this.canvas.options.showBranding = showBranding;
            }

            if (isCreatorOldBasicUser !== prevProps.isCreatorOldBasicUser) {
                this.canvas.options.isCreatorOldBasicUser = isCreatorOldBasicUser;
            }

            if (canvasScale !== prevProps.canvasScale) {
                this.setCanvasScale();
            }
        });
    }

    async animateCanvas(waitForTransition = false) {
        const {
            onAnimationStarted,
            onAnimationProgress,
            onBuildAnimationFinished,
            onAnimationFinished,
            onWaitForClick,
            renderClickShieldWhenAnimating,
            slide
        } = this.props;

        try {
            // Prepare canvas for animation (render the initial animation state)
            await this.canvas.prepareForAnimation();
            // Wait for slide to fade in (transition) if requested
            if (waitForTransition) {
                await new Promise(resolve => setTimeout(resolve, SLIDE_TRANSITION_MS));
            }
            // Animate and report
            onAnimationStarted();
            const hasBeenCancelled = await this.canvas.animate(onAnimationProgress, renderClickShieldWhenAnimating, onBuildAnimationFinished, onWaitForClick);
            onAnimationFinished(hasBeenCancelled);
        } catch (err) {
            logger.error(err, "[CanvasView] animateCanvas() failed", { slideId: slide?.id });
        }
    }

    async setCanvas() {
        const {
            slide,
            slideIndex,
            isCurrent,
            shouldAnimate,
            onRendered,
            advanceToSlide,
            playbackStage,
            showBranding,
            isCreatorOldBasicUser,
            playerView,
        } = this.props;

        try {
            // Make sure slide model loaded
            await slide.load();

            // Load slide canvas bundle
            const { SlideCanvas } = await getCanvasBundle(slide.get("version") ?? appVersion);

            this.canvas = new SlideCanvas({
                dataModel: slide,
                canvasWidth: CANVAS_WIDTH,
                canvasHeight: CANVAS_HEIGHT,
                editable: false,
                isPlayback: true,
                slideIndex,
                advanceToSlide,
                showBranding,
                isCreatorOldBasicUser,
                playerView,
            });

            this.canvas.$el.css({
                position: "absolute",
                top: "50%",
                left: "50%"
            });

            this.setCanvasScale();

            $(this.containerRef.current).empty();
            $(this.containerRef.current).append(this.canvas.render().$el);

            if (isCurrent) {
                app.currentCanvas = this.canvas;
                this.canvas.setAsCurrentCanvas();
            }

            await this.canvas.renderSlide();
            await this.canvas.prepareToShowElements();

            if (playbackStage !== 0) {
                await this.canvas.goToStage(playbackStage, false);
            }

            onRendered(this.canvas.playbackStages.length);

            if (shouldAnimate) {
                // Won't await
                this.animateCanvas();
            }
        } catch (err) {
            logger.error(err, "[CanvasView] setCanvas() failed", { slideId: slide?.id });
        }
    }

    setCanvasScale() {
        const { canvasScale } = this.props;

        // This may happen when canvasScale prop is changed before the slide model has loaded
        if (!this.canvas) {
            return;
        }

        this.canvas.canvasScale = canvasScale;
        this.canvas.$el.css({ transform: `scale(${canvasScale}) translate(-50%, -50%)` });
    }

    render() {
        const { isCurrent, playTransition } = this.props;

        return <CanvasContainer ref={this.containerRef} visible={isCurrent} transition={playTransition} />;
    }
}

export default CanvasView;
