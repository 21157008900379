import { _ } from "js/vendor";
import moment from "moment";

function date(val) {
    if (!(this instanceof date)) {
        return new date(val);
    }
    this.val = this.parse(val);
}

date.prototype = {
    parse: function(val) {
        if (_.isNumber(val)) {
            return new Date(val);
        } else {
            return Date.parse(val);
        }
    },

    toMoment: function() {
        return moment(this.val).utc();
    },

    format: function(format) {
        return this.toMoment().format(format);
    },

    isValid: function() {
        return this.toMoment().isValid();
    },

    valueOf: function() {
        return this.toMoment().valueOf();
    }
};

export default date;
