import { SUGGEST_TEMPLATE_FORM } from "common/constants";
import { app } from "js/namespaces";
import { FeatureType } from "../models/planPermissions";
import { getPricingPageUrl } from "./pricing";
import { trackActivity } from "./utilities";
import AppController from "js/core/AppController";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { isPPTAddin } from "js/config";
import { PPTAddinChoosePlan } from "js/react/views/PPTAddin/PPTAddinChoosePlan";

export const openSalesCalendar = (analytics = {}) => {
    const userIsBasicInWorkspace = app.user.features.isFeatureEnabled(FeatureType.UPGRADE, AppController.workspaceId);
    analytics.plan = userIsBasicInWorkspace ? "basic" : app.user.analyticsPersonalPlan;
    trackActivity("Sales", "ShowCalendar", null, null, analytics);
    window.open("https://calendly.com/beautifulteam/demo-call-with-beautiful-ai-upgrade-to-team", "_blank");
};

export const openPricingPage = (personalPlan = "pro", analytics = null) => {
    const userIsBasicInWorkspace = AppController.user.features.isFeatureEnabled(FeatureType.UPGRADE, AppController.workspaceId);
    const currentPlan = userIsBasicInWorkspace ? "basic" : personalPlan;

    if (analytics) {
        if (!analytics.cta) {
            throw new Error("analytics.cta is required");
        }

        trackActivity("Upgrade", "ShowPricingPage", null, null, analytics);
    }

    // if the user is in the PPT addin, we need to show a dialog instead of opening a new tab
    if (isPPTAddin) {
        ShowDialog(PPTAddinChoosePlan, {
            isPopup: true,
            hideBasic: true,
            removeEmphasized: true,
            emphasizePlan: "pro",
            hasTakenTrial: AppController.user.hasTakenTrial || AppController.user.hasTakenTeamTrial
        });
    } else {
        window.open(getPricingPageUrl(AppController.user.hasTakenTrial, AppController.user.has("hasTakenTeamTrial"), currentPlan), "_blank");
    }
};

export const openTeamUpgrade = (analytics = {}, callback) => {
    const userIsBasicInWorkspace = AppController.user.features.isFeatureEnabled(FeatureType.UPGRADE, AppController.workspaceId);
    analytics.plan = userIsBasicInWorkspace ? "basic" : AppController.user.analyticsPersonalPlan;
    trackActivity("Upgrade", "ShowTeamPricingPage", null, null, analytics);

    // if the user is in the PPT addin, we need to show a dialog instead of opening a new tab
    if (isPPTAddin) {
        if (userIsBasicInWorkspace) {
            ShowDialog(PPTAddinChoosePlan, {
                isPopup: true,
                hideBasic: true,
                emphasizePlan: "team",
                removeEmphasized: true,
                hasTakenTrial: AppController.user.hasTakenTrial || AppController.user.hasTakenTeamTrial
            });
        } else {
            callback && callback();
        }
    } else {
        window.open("/team-upgrade", "_blank");
    }
};

export const openSuggestTemplateForm = () => {
    window.open(SUGGEST_TEMPLATE_FORM, "_blank");
};
