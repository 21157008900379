import firebase from "js/firebase";
import DbRouter from "common/dbRouter";
const baiFirebase = window.baiFirebase || {};

const dbRouter = new DbRouter(firebase, baiFirebase.routes, baiFirebase.config);

function db(ref) {
    return dbRouter.db(ref);
}

db.isDefaultDb = function(path) {
    return dbRouter.isDefaultDb(path);
};

db.getDbInstance = function(path) {
    return dbRouter.getDbInstance(path);
};

db.authenticate = function(token) {
    return dbRouter.authenticate(token);
};

db.updateCurrentUser = function(user) {
    return dbRouter.updateCurrentUser(user);
};

db.logoutAllFirebaseInstances = function() {
    return dbRouter.logoutAllFirebaseInstances();
};

export default db;
