import { _, Backbone } from "js/vendor";
import { app } from "js/namespaces";
import StorageModel from "../storage/storageModel";
import { ds } from "js/core/models/dataService";
import ReferenceCollection from "js/core/storage/referenceCollection";
import { isOfflinePlayer } from "../../config";
import Api from "../api";

const UserFolder = StorageModel.extend({
    root: "userFolders",
    readonly: isOfflinePlayer,

    initialize: function(models, options = {}) {
    },

    addPresentationToFolder: function(inputPresentationId) {
        //We need to make a new copy of this.get("presentations") array for the update to be saved to the database
        //When update is updated to handle arrays then slice will no longer be needed.
        let presentationIds = this.get("presentations") ? this.get("presentations").slice() : [];
        presentationIds.push(inputPresentationId);
        this.update({ presentations: presentationIds });
    },

    removePresentationFromFolder: function(inputPresentationId) {
        let presentationIds = this.get("presentations") ? this.get("presentations").filter(presentationId => presentationId !== inputPresentationId) : [];
        ds.presentations.trigger("change", ds.presentations);
        this.update({ presentations: presentationIds });
    },

    update: function(attrs, options) {
        // add `modifiedAt` on every update (if the update actually changes anything)
        let changeSet = StorageModel.prototype.update.call(this, attrs, Object.assign({ computeChangeSet: true }, options));
        if (changeSet.hasUpdates) {
            attrs = Object.assign({ modifiedAt: new Date().getTime() }, attrs);
        }
        return StorageModel.prototype.update.call(this, attrs, options);
    },

    destroy: function(options) {
        //Call Backbone's destroy instead of the StorageModel's destroy so we can call our delete api instead.
        Backbone.Model.prototype.destroy.call(this, options);
        this.adapter.disconnect();
        if (!options || !options.remoteChange) {
            return Api.folders.delete({ id: this.id });
        } else {
            return Promise.resolve({});
        }
    }
});

const UserFolders = ReferenceCollection.extend({
    model: UserFolder,
    ignoreErrors: true,
    referenceRoot: "users",
    getReferenceId: function() {
        return `${this.userId}/userFolders`;
    },

    initialize: function() {
        this.type = "UserFolders";
        this.userId = app.user.id;
    },

    createFolder: function(model) {
        const folder = new UserFolder(_.extend(model, {
            userId: this.userId,
            createdAt: new Date().getTime(),
            modifiedAt: new Date().getTime(),
            presentations: []
        }), {
            userId: this.userId
        });

        return this.add(folder);
    },

    setSort: function(sort = "name") {
        this.comparator = function(item) {
            if (sort == "name") {
                return item.get("name").toLowerCase();
            } else {
                return -item.get(sort);
            }
        };
        this.sort();
    }

});

export { UserFolder, UserFolders };
