import React, { Component } from "reactn";
import { app } from "js/namespaces";
import { PopupMenu } from "js/react/views/Editor/PopupMenu";
import { FlexBox, RowGrid } from "js/react/components/LayoutGrid";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { Fragment } from "react";
import { ColorChit, ColorPalette } from "js/react/views/Editor/Components/ColorPalette";
import { Button, Checkbox, Icon } from "@material-ui/core";
import { BackgroundStyleType } from "common/constants";
import styled from "styled-components";
import { ColorPicker } from "js/react/components/ColorPicker";

function StylePresetOption(props) {
    let color;
    if (props.color == "auto" || props.color == null) {
        color = app.currentTheme.palette.getColor(app.currentCanvas.getSlideColor()).toRgbString();
    } else {
        color = app.currentTheme.palette.getColor(props.color).toRgbString();
    }

    switch (props.type) {
        case "outlined":
            return (
                <div className="preset-option" onClick={props.onClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="22" height="22" stroke={color} strokeWidth="2" />
                    </svg>
                </div>
            );
        case "filled":
            return (
                <div className="preset-option" onClick={props.onClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="24" fill={color} />
                    </svg>
                </div>
            );
        case "muted":
            return (
                <div className="preset-option" onClick={props.onClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="24" fill={color} fillOpacity="0.35" />
                    </svg>
                </div>
            );
        case "fillAndStroke":
            return (
                <div className="preset-option" onClick={props.onClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="22" height="22" stroke={color} fill={color}
                            fillOpacity="0.35" strokeWidth="2" />
                    </svg>
                </div>
            );
    }
}

const NoColorNotice = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  .MuiIcon-root {
    margin-right: 10px;
  }
`;

export class StylePopupMenu extends Component {
    state = {
        showPresets: true,
        selectedColor: this.props.element.model.color,
        selectedTextColor: this.props.element.model.textColor,
        showShadow: this.props.element.model.showShadow
    }

    handleChangeTextColor = color => {
        this.setState({ selectedTextColor: color });

        this.props.element.model.textColor = color;
        this.props.element.canvas.markStylesAsDirty();
        this.props.element.canvas.updateCanvasModel(true);
    }

    handleChangePaletteColor = color => {
        this.setState({ selectedColor: color });

        this.props.element.model.color = color;
        this.props.element.canvas.markStylesAsDirty();
        this.props.element.canvas.updateCanvasModel(true);
        this.props.callback(color);
    }

    handleChangePresetOption = value => {
        this.props.element.model.decorationStyle = value;
        this.props.element.canvas.markStylesAsDirty();
        this.props.element.canvas.updateCanvasModel(true);
    }

    handleChangeShowShadow = () => {
        this.props.element.model.showShadow = !this.state.showShadow;
        this.setState({ showShadow: !this.state.showShadow });
    }

    render() {
        let { selectedColor, selectedTextColor, selectedAccentColor, showShadow } = this.state;
        let { element, showAuto = true, showBackgroundColors, showTextColor, position } = this.props;

        let presets = (
            <Fragment>
                {showTextColor &&
                <Fragment>
                    <label>Text Color</label>
                    <Gap10/>
                    <ColorPalette
                        selectedColor={selectedTextColor}
                        onSelect={value => this.handleChangeTextColor(value)}
                        showTextColors
                        showAccentColors={false}
                    />
                    <Gap10/>
                    <label>Accent Color</label>
                    <Gap10/>
                </Fragment>
                }

                <ColorPalette
                    selectedColor={selectedColor}
                    onSelect={value => this.handleChangePaletteColor(value)}
                    showAuto={showAuto}
                    showPrimary
                    showSecondary
                    showBackgroundColors={showBackgroundColors}
                    autoColor={element.getSlideColor({ ignoreUserDefinedColor: true })}
                />

                {element.allowDecorationStyles &&
                    <Fragment>
                        <Gap20/>
                        <FlexBox left>
                            <label>Shape Style</label>
                            <StylePresetOption type="outlined" color={selectedColor}
                                onClick={() => this.handleChangePresetOption("outlined")} />
                            <Gap10/>
                            <StylePresetOption type="muted" color={selectedColor}
                                onClick={() => this.handleChangePresetOption("muted")} />
                            <Gap10/>
                            <StylePresetOption type="filled" color={selectedColor}
                                onClick={() => this.handleChangePresetOption("filled")} />
                            <Gap10/>
                            <StylePresetOption type="fillAndStroke" color={selectedColor}
                                onClick={() => this.handleChangePresetOption("fillAndStroke")} />
                        </FlexBox>
                    </Fragment>
                }

                {/*<Button onClick={this.handleChangeShowShadow}>Toggle Shadow</Button>*/}
                {/*<Gap20/>*/}
                {/*<div className="toggle-button" onClick={() => this.setState({ showPresets: false })}>*/}
                {/*    Show Advanced*/}
                {/*</div>*/}
            </Fragment>
        );

        let advanced = (
            <Fragment>
                <FlexBox>
                    <label>Fill</label>
                    <ColorChit color="red" />
                    <Gap10 />
                    <label>Stroke</label>
                    <ColorChit color="blue" />
                    <input type="number" />
                </FlexBox>
                <Gap20 />
                <div className="toggle-button" onClick={() => this.setState({ showPresets: true })}>
                    Show Presets
                </div>
            </Fragment>
        );

        return (
            <PopupMenu {...this.props} position={position} className="style-popup-menu beautiful-ui">
                {this.state.showPresets ? presets : advanced}
            </PopupMenu>
        );
    }
}

