import React, { PureComponent } from "reactn";
import moment from "moment";

import { TextField } from "@material-ui/core";
import { FlexBox } from "js/react/components/LayoutGrid";
import { ShowUpgradeDialog } from "js/react/components/Dialogs/BaseDialog";
import { BlueSwitch } from "js/react/components/Switch";
import ProBadge from "js/react/components/ProBadge";
import shouldShowProBadge from "js/core/utilities/shouldShowProBadge";
import { FeatureType } from "common/features";
import { app } from "js/namespaces";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";

import styled from "styled-components";
import { themeColors } from "js/react/sharedStyles";

const DefaultLinkExpirationContainer = styled.div`
    padding: 0 0 15px 0;
    display: flex;
    gap: 15px;

    align-items: center;
`;

const StackedLinkExpirationContainer = styled.div`
    display: grid;
    gap: 15px;

    grid-template-columns: auto auto;
    align-items: center;

    .link-expiration-activation {
        grid-column: 1 / 3;
    }
`;

const ExpiredLinkWarning = styled.div`
    color: ${themeColors.warning};
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
`;

export default class LinkExpiration extends PureComponent {
    static getDefaultExpirationDate() {
        return moment().add(30, "day").endOf("day").format("YYYY-MM-DD");
    }

    // updates the link
    updateLink = expiresAt => {
        this.props.onUpdate({
            linkExpiresAt: expiresAt,
            linkShouldExpire: !!expiresAt,
            linkHasExpired: !!expiresAt && checkIfExpired(expiresAt)
        });
    }

    onSelectExpirationDate = event => {
        this.updateLink(getExpirationDate(event.target.value));
    }

    // handle toggling the feature on and off
    onToggleExpireLink = () => {
        const { presentation } = this.props;

        // check if they can use this feature
        if (!app.user.features.isFeatureEnabled(FeatureType.DISABLE_LINKS, presentation.getWorkspaceId())) {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: { cta: "EnableLink", ...presentation.getAnalytics() },
                workspaceId: presentation.getWorkspaceId()
            });
            return;
        }

        // toggle the state
        if (this.props.expiresAt) {
            this.updateLink(null);
        } else {
            this.updateLink(LinkExpiration.getDefaultExpirationDate());
        }
    }

    render() {
        const { presentation, expiresAt, errorBelowInput } = this.props;
        const display = getExpirationDate(expiresAt);
        const expireLink = !!expiresAt;
        const isExpired = expireLink && checkIfExpired(expiresAt);
        const Container = "stacked" in this.props ? StackedLinkExpirationContainer : DefaultLinkExpirationContainer;

        return (
            <Container>
                <FlexBox left middle className="link-expiration-activation">
                    <BlueSwitch label="Expire This Link" checked={expireLink} onChange={this.onToggleExpireLink} />
                    <ProBadge
                        show={shouldShowProBadge(FeatureType.DISABLE_LINKS, presentation.getWorkspaceId())}
                        analytics={{
                            cta: "ExpireLink",
                            ...presentation.getAnalytics()
                        }}
                        workspaceId={presentation.getWorkspaceId()}
                    />
                </FlexBox>

                {expireLink && (
                    <>
                        <TextField
                            label="Expires At"
                            type="date"
                            value={display}
                            InputLabelProps={{ shrink: true }}
                            onChange={this.onSelectExpirationDate}
                        />
                        {isExpired && <ExpiredLinkWarning>Please select a date in the future</ExpiredLinkWarning>}
                    </>

                )}
            </Container>
        );
    }
}

function checkIfExpired(date) {
    return moment(date).isBefore(Date.now());
}

function getExpirationDate(val) {
    return moment(val).format("YYYY-MM-DD");
}
