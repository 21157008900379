import React, { Component } from "react";

import { DialogActions, DialogTitle } from "@material-ui/core";
import {
    DocumentType,
    PresentationFilters,
} from "common/constants";
import AppController from "js/core/AppController";
import { ds } from "js/core/models/dataService";
import ErrorHandler from "js/core/utilities/errorHandler";
import { trackActivity } from "js/core/utilities/utilities";
import { PresentationLibraryController } from "js/editor/PresentationLibrary/PresentationLibraryController";
import { app } from "js/namespaces";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import GeneratingSlidesView from "js/react/views/AIGeneration/panes/GeneratePresentationPane/components/GeneratingSlidesView";
import { PromptPresentationInput, RefPromptPresentationInput } from "js/react/views/AIGeneration/panes/GeneratePresentationPane/components/PromptView";
import GeneratePresentationController, { GeneratePresentationState, PresentationGenerationStep } from "js/react/views/AIGeneration/panes/GeneratePresentationPane/controllers/GeneratePresentationController";
import styled from "styled-components";
import Button from "../Button2";
import { FlexSpacer, Gap30, Gap5 } from "../Gap";
import { FlexBox } from "../LayoutGrid";
import { LoadingBot } from "../LoadingBot";
import { Breadcrumbs } from "../Breadcrumbs";
import { BlueButton } from "../UiComponents";

const SubTitle = styled.span`
font-size: 18px;
line-height: 18px;
font-weight: 400;
`;

type Props = {
    onSuccess?: (email: string) => void;
    closeDialog: () => void;
} & GeneratePresentationState;

export const GeneratePresentationDialog = GeneratePresentationController.withState(class GeneratePresentationDialog extends Component<Props> {
    refInput = React.createRef<RefPromptPresentationInput>();

    async componentDidMount() {
        await GeneratePresentationController.reset(AppController.workspaceId, this.handleCreatePresentation);
    }

    handleCreatePresentation = async options => {
        const {
            closeDialog,
        } = this.props;

        const workspaceId = AppController.workspaceId;

        try {
            const createdPresentation = await PresentationLibraryController.createPresentation({
                name: options.name,
                documentType: DocumentType.PRESENTATION,
                libraryFilter: {
                    type: PresentationFilters.RECENT,
                    folderId: null,
                    subFolderId: null,
                },
                theme: options.theme || ds.builtInThemes.sample(),
                defaultSlides: options.defaultSlides
            });
            await createdPresentation.prepareSlides();
            createdPresentation.slides.map(async slide => {
                await slide.getThumbnailUrl(createdPresentation.id, "small", 0, true);
            });

            app.user.update({ promptForGeneratePresentationTour: false });

            trackActivity("Presentation", "Generate", null, null, {
                presentationId: createdPresentation.id,
                numSlides: options.defaultSlides.length,
                fromTour: true,
            });

            PresentationLibraryController.editPresentation({
                presentation: createdPresentation,
                withAppcue: "1c65534f-fa6e-498c-b02a-fce139a94c85",
            });

            closeDialog();
        } catch (err) {
            ErrorHandler.handleSlideLimitReached(err, { workspaceId });
        }
    }

    skip() {
        const {
            closeDialog,
        } = this.props;

        app.user.update({ promptForGeneratePresentationTour: false });

        trackActivity("GeneratePresentationTour", "Skipped");

        closeDialog();
    }

    render() {
        const {
            closeDialog,
            startGenerationParams,
            step,
        } = this.props;

        let maxWidth: any = "md";
        if (
            step === PresentationGenerationStep.GENERATING_SLIDES ||
            step === PresentationGenerationStep.DONE
        ) {
            maxWidth = "lg";
        }

        const breadCrumbIndex = (
            step === PresentationGenerationStep.PROMPT ? 0
                : step === PresentationGenerationStep.GENERATING_OUTLINE ? 1
                    : 2
        );

        return (
            <BeautifulDialog
                preventClose
                closeDialog={closeDialog}
                closeButton={false}
                maxWidth={maxWidth}
                fullWidth={maxWidth !== "lg"}
                style={{ zIndex: 10000 }}
            >
                <Gap30 />
                <Breadcrumbs
                    activeIndex={breadCrumbIndex}
                    crumbs={[
                        {
                            content: <span>1</span>,
                            label: "Provide a prompt",
                        },
                        {
                            content: <span>2</span>,
                            label: "Drafting a story",
                        },
                        {
                            content: <span>3</span>,
                            label: "Generating slides",
                        },
                    ]}
                />
                {step === PresentationGenerationStep.PROMPT &&
                    <>
                        <DialogTitle> {/* @ts-ignore() */}
                            <FlexBox column>
                                <span>Try generating a presentation with AI!</span>
                                <Gap5 />
                                <SubTitle></SubTitle>
                            </FlexBox>
                        </DialogTitle>
                        <DialogContent>
                            <PromptPresentationInput
                                ref={this.refInput}
                                startGenerationParams={startGenerationParams}
                                hideActionButton
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => this.skip()}
                                label="Skip"
                                color="secondary"
                                variant="text"
                            />
                            <FlexSpacer /> {/* @ts-ignore() */}
                            <BlueButton onClick={() => this.refInput.current.handleStartGeneration()}>Generate Presentation</BlueButton>
                        </DialogActions>
                    </>
                }
                {step === PresentationGenerationStep.GENERATING_OUTLINE &&
                    <>
                        <DialogTitle> {/* @ts-ignore() */}
                            <FlexBox column>
                                <span>Sit back and relax while we create a draft for you.</span>
                                <Gap5 />
                                <SubTitle>Once your draft is ready, we'll automatically advance you to the next step.</SubTitle>
                            </FlexBox>
                        </DialogTitle>
                        <DialogContent>
                            <LoadingBot
                                labels={[
                                    "Gathering data...",
                                    "Plotting narrative...",
                                    "Curating supporting visuals...",
                                ]}
                            />
                        </DialogContent>
                    </>
                }
                {(
                    step === PresentationGenerationStep.GENERATING_SLIDES ||
                    step === PresentationGenerationStep.DONE
                ) &&
                    <>
                        <DialogTitle> {/* @ts-ignore() */}
                            <FlexBox column>
                                <span>Drumroll please... Your presentation is almost ready!</span>
                                <Gap5 />
                                <SubTitle>Update your presentation's theme and fine-tune its content once your slides are ready.</SubTitle>
                            </FlexBox>
                        </DialogTitle>
                        <DialogContent>
                            <GeneratingSlidesView
                                excludeStatusLabel
                            />
                        </DialogContent>
                    </>
                }
            </BeautifulDialog>
        );
    }
});
