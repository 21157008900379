import React, { Component } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { Button, IconButton, Icon, Menu } from "@material-ui/core";

import { themeColors } from "js/react/sharedStyles";
import * as geom from "js/core/utilities/geom";
import getObjectHash from "common/utils/getObjectHash";
import { _ } from "js/vendor";
import { Fragment } from "react";
import Spinner from "./Spinner";
import { Gap20 } from "js/react/components/Gap";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import { SearchBar } from "../views/AddSlide/Panes/Components/SearchBox";

const SearchContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export class List extends Component {
    constructor(props) {
        super(props);

        this.searchBarRef = React.createRef();

        this.state = {
            searchTerm: "",
            data: [],
            sort: props.sort ?? null,
            sortAscending: true,
            showSearchBar: false
        };
    }

    get trackVisibility() {
        return !!this.props.scrollContainerEl;
    }

    componentDidMount() {
        this.syncData()
            .then(() => {
                if (this.trackVisibility) {
                    const { scrollContainerEl } = this.props;
                    scrollContainerEl.addEventListener("scroll", this.setRowsVisibility);
                    this.setRowsVisibility();
                    (async () => await this.sortData())();
                }
            });
    }

    getExternalDataHash(externalData) {
        // row overrides contain react fragments, we don't want to compare those
        return getObjectHash(externalData.map(record => ({ ...record, rowOverrides: null })), true);
    }

    componentDidUpdate(prevProps, prevState) {
        const { data: externalData } = this.props;
        const { sort, sortAscending } = this.state;

        (async () => {
            if (
                externalData?.length !== prevProps.data?.length ||
                this.getExternalDataHash(externalData) !== this.getExternalDataHash(prevProps.data)
            ) {
                await this.syncData();
                if (this.trackVisibility) {
                    await this.setRowsVisibility();
                }
            }

            if (sort && prevState.sort !== sort || prevState.sortAscending !== sortAscending) {
                await this.sortData();
            }
        })();
    }

    async sortData() {
        const { data, sort, sortAscending } = this.state;

        const sortedData = _.orderBy(data,
            row => typeof row[sort] === "string" ? row[sort].toLowerCase() : row[sort],
            sortAscending ? "asc" : "desc");

        await this.setStateAsync({ data: sortedData });
        if (this.trackVisibility) {
            await this.setRowsVisibility();
        }
    }

    componentWillUnmount() {
        if (this.trackVisibility) {
            const { scrollContainerEl } = this.props;
            scrollContainerEl.removeEventListener("scroll", this.setRowsVisibility);
        }
    }

    setStateAsync(stateUpdates) {
        return new Promise(resolve => this.setState(stateUpdates, resolve));
    }

    syncData() {
        const { data: externalData } = this.props;
        const { data } = this.state;

        if (data.length !== externalData.length) {
            while (data.length < externalData.length) {
                data.push(null);
            }
            while (data.length > externalData.length) {
                data.pop();
            }
        }

        externalData.forEach((dataRecord, idx) => {
            const hash = this.getExternalDataHash([dataRecord]);
            if (!data[idx] || data[idx].__renderProps.hash !== hash) {
                data[idx] = {
                    ...dataRecord,
                    __renderProps: {
                        id: data[idx]?.__renderProps.id ?? uuid(),
                        ref: data[idx]?.__renderProps.ref ?? React.createRef(),
                        hash,
                        isVisible: data[idx]?.__renderProps.isVisible ?? (this.trackVisibility ? false : true),
                        fetching: dataRecord.fetching ?? false
                    }
                };
            }
        });

        return this.setStateAsync({ data });
    }

    setRowsVisibility = async () => {
        const { scrollContainerEl } = this.props;
        const { data } = this.state;

        let hasVisibilityChanged = false;
        const containerBounds = geom.Rect.FromBBox(scrollContainerEl.getBoundingClientRect());
        const paddedContainerBounds = containerBounds.inflate({ top: containerBounds.height, bottom: containerBounds.height });
        data.forEach(dataRecord => {
            const rowBounds = geom.Rect.FromBBox(dataRecord.__renderProps.ref.current.getBoundingClientRect());
            const isVisible = rowBounds.intersects(paddedContainerBounds);
            if (dataRecord.__renderProps.isVisible !== isVisible) {
                dataRecord.__renderProps.isVisible = isVisible;
                hasVisibilityChanged = true;
            }
        });

        if (hasVisibilityChanged) {
            await this.setStateAsync({ data });
        }
    }

    handleUpdateSort = sort => {
        this.setState({
            sort: sort,
            sortAscending: !this.state.sortAscending
        });
    };

    handleChange = value => this.setState({ searchTerm: value });

    filteredData() {
        const { data, searchTerm } = this.state;
        const { searchFields = [] } = this.props;

        if (!searchTerm) return data;

        return data.filter(row => {
            return searchFields.some(field => row[field]?.toLowerCase().includes(searchTerm.toLowerCase()));
        }).map(row => ({
            ...row,
            __renderProps: {
                ...row.__renderProps,
                isVisible: true
            }
        }));
    }

    handleShowSearchBar = () => {
        this.setState({ showSearchBar: true });
        _.defer(() => {
            this.searchBarRef.current.focusInput();
        });
    }

    handleHideSearchBar = () => {
        this.setState({ showSearchBar: false });
    }

    render() {
        const { data, sort, sortAscending, showSearchBar } = this.state;

        const { showHeader, isLoading, onClickRow, onDoubleClickRow, children, showSearch, exportToCSV, searchBarPlaceHolder = "Search...", isOwner } = this.props;
        const fields = Array.isArray(children) ? children : [children];

        const gridTemplateColumns = fields
            .filter(field => !!field)
            .map(field => {
                if (field.props.width == null) {
                    if (field.type.name == "ListContextMenu") {
                        return "50px";
                    } else {
                        return "1fr";
                    }
                }
                if (isNaN(field.props.width)) {
                    return field.props.width;
                } else {
                    return field.props.width + "px";
                }
            })
            .join(" ");

        const renderList = () => {
            if (isLoading) {
                return <Spinner />;
            }

            if (data) {
                return this.filteredData().map(row => {
                    const renderProps = row.__renderProps;
                    const rowOverrides = row.rowOverrides ?? {};

                    const row_fields = rowOverrides.fields ?? fields;
                    const row_gridTemplateColumns = rowOverrides.gridTemplateColumns ?? gridTemplateColumns;
                    const row_onClickRow = rowOverrides.onClickRow ?? onClickRow;
                    const row_onDoubleClickRow = rowOverrides.onDoubleClickRow ?? onDoubleClickRow;

                    return (
                        <ListRow
                            key={renderProps.id}
                            containerRef={renderProps.ref}
                            isVisible={renderProps.isVisible}
                            data={_.omit(row, "__renderProps", "rowOverrides")}
                            fields={row_fields}
                            columns={row_gridTemplateColumns}
                            onClick={() => row_onClickRow && row_onClickRow(row)}
                            onDoubleClick={() => row_onDoubleClickRow && row_onDoubleClickRow(row)}
                            fetching={renderProps.fetching}
                        />
                    );
                });
            }

            return "No items";
        };

        return (
            <div className="beautiful-list">
                <SearchContainer>
                    {showSearch && <IconButton size="medium" onClick={this.handleShowSearchBar}
                        style={{ color: themeColors.ui_blue }}>
                        <Icon>search</Icon>
                    </IconButton>}
                    {showSearchBar && <SearchBar
                        showIcon={false}
                        ref={this.searchBarRef}
                        placeholder={searchBarPlaceHolder}
                        onBlurOnEmpty={this.handleHideSearchBar}
                        onSearch={this.handleChange}
                    />}
                    {exportToCSV && isOwner && <IconButton size="medium"
                        color="secondary"
                        onClick={() => exportToCSV(this.filteredData())}
                    >
                        <Icon>download</Icon>
                    </IconButton>}

                </SearchContainer>
                {showHeader ? (
                    <ListHeader
                        onSort={this.handleUpdateSort}
                        sortField={sort}
                        sortAscending={sortAscending}
                        columns={gridTemplateColumns}
                        fields={this.props.children}
                    />
                ) : null}
                {renderList()}
            </div>
        );
    }
}

export class ListHeader extends Component {
    render() {
        const { fields, sortField, columns, sortAscending, onSort } = this.props;

        const dataFields = fields.map(field => {
            if (!field) return;
            return React.createElement(ListFieldHeader, {
                key: field.props.field || _.uniqueId(),
                ...field.props,
                onSort,
                isSorted: sortField == field.props.field,
                sortAscending: sortAscending ?? true,
            });
        });

        return (
            <div className="list-header" style={{ gridTemplateColumns: columns }}>
                {dataFields}
            </div>
        );
    }
}

class ListFieldHeader extends Component {
    render() {
        const { name, field, sortable, onSort, isSorted, sortAscending, isSelectField } = this.props;

        const arrowDirection = sortable ? (sortAscending ? "arrow_downwards" : "arrow_upwards") : null;

        let sortIndicator;
        if (sortable && isSorted) {
            sortIndicator = <i className="sort-indicator micon">{arrowDirection}</i>;
        }

        return (
            <div
                onClick={sortable ? () => onSort(field) : null}
                className="list-field-header"
            >
                {isSelectField && <Gap20 />}
                {name}
                {sortIndicator}
            </div>
        );
    }
}

export class ListRow extends Component {
    shouldComponentUpdate(nextProps) {
        const {
            containerRef,
            isVisible,
            data,
            fields,
            columns
        } = this.props;

        if (containerRef !== nextProps.containerRef ||
            isVisible !== nextProps.isVisible ||
            columns !== nextProps.columns
        ) {
            return true;
        }

        const sanitizeFields = fields => fields.map(({ props }) => _.omit(props, "children"));
        if (getObjectHash(sanitizeFields(nextProps.fields), true) !== getObjectHash(sanitizeFields(fields), true)) {
            return true;
        }

        if (getObjectHash(nextProps.data, true) !== getObjectHash(data, true)) {
            return true;
        }

        return false;
    }

    render() {
        const {
            containerRef,
            isVisible,
            fetching,
            data,
            fields,
            columns,
            onClick,
            onDoubleClick
        } = this.props;

        return (<div className="list-item" style={{ gridTemplateColumns: columns }} onClick={onClick} onDoubleClick={onDoubleClick} ref={containerRef}>
            <FetchingClickShield visible={fetching} backgroundColor={"rgba(255,255,255,0.8)"} />
            {isVisible && (Array.isArray(fields) ? fields : [fields])
                .filter(Boolean)
                .map((field, idx) => React.cloneElement(field, { key: field.props.field ?? idx, data }))
            }
        </div>);
    }
}

export class ListField extends Component {
    render() {
        const { field, type, data, renderer } = this.props;

        let fieldClass = `${field} list-field`;
        switch (type) {
            case "bold":
                fieldClass += " bold";
                break;
            case "tag":
                fieldClass += " label";
                break;
        }

        return <div className={fieldClass}>{renderer ? renderer(data) : data[field] ? data[field] : "--"}</div>;
    }
}

export class ListAction extends Component {
    render() {
        const { data, callback, renderer } = this.props;

        if (renderer) {
            return renderer(data);
        }

        return (
            <Button color="primary" size="small" variant="contained" onClick={() => callback(data)} rowid={data.id}>
                {this.props.children}
            </Button>
        );
    }
}

export class ListIcon extends Component {
    render() {
        const { data, renderer, onClick, iconName } = this.props;

        if (renderer) {
            return renderer(data);
        }

        return <Icon onClick={onClick}>{iconName}</Icon>;
    }
}

export class ListIconButton extends Component {
    render() {
        const {
            data,
            renderer,
            onClick,
            iconName,
            showOnHover,
        } = this.props;

        if (renderer) {
            return renderer(data);
        }

        return (
            <IconButton
                disableRipple
                className={`list-item-icon-button ${showOnHover ? "show-on-hover" : ""}`}
                onClick={onClick}
            >
                <Icon>{iconName}</Icon>
            </IconButton>
        );
    }
}

export class ListImage extends Component {
    render() {
        const { data } = this.props;
        return <img src={data.imageURL} />;
    }
}

export class ListContextMenu extends Component {
    state = {
        menuAnchorEl: null
    };

    render() {
        const { menuAnchorEl } = this.state;
        const { data, renderer, showOnHover } = this.props;

        let menuItemsDef;
        if (renderer) {
            menuItemsDef = renderer(data);
        } else {
            menuItemsDef = this.props.children;
        }

        if (!menuItemsDef) {
            return null;
        }

        const menuItems = menuItemsDef.map(menuItem => {
            return React.cloneElement(menuItem, {
                rowid: data.id,
                onClick: () => {
                    this.setState({ menuAnchorEl: null });
                    menuItem.props.onClick(data);
                }
            });
        });

        return (
            <Fragment>
                <IconButton
                    disableRipple
                    className={`list-item-icon-button ${showOnHover ? "show-on-hover" : ""}`}
                    onClick={event => this.setState({ menuAnchorEl: event.currentTarget })}
                >
                    <Icon>more_vert</Icon>
                </IconButton>
                <Menu
                    id="list-context-menu"
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={() => this.setState({ menuAnchorEl: null })}
                >
                    {menuItems}
                </Menu>
            </Fragment>
        );
    }
}
