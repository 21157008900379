import React from "react";
import { _, tinycolor } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { HorizontalAlignType, VerticalAlignType } from "common/constants";
import Logos from "js/core/models/logos";
import { uploadBlobToSignedUrl } from "js/core/utilities/uploadBlobToSignedUrl";
import { Gradient } from "./components/Gradient";
import { reactToSvg } from "js/react/renderReactRoot";

const COLORS_TO_SHOW = 5;

export async function GenerateThemeThumbnail(theme) {
    const canvas = document.createElement("canvas");
    canvas.width = 480;
    canvas.height = 270;
    const ctx = canvas.getContext("2d");

    const backgroundColor = tinycolor(theme.colors[theme.defaultBackgroundColor || "background_light"]);

    let colorStyle = "color";

    const bgGradient = theme.backgroundGradients?.find(bg => bg.id === theme.defaultBackgroundColor);
    if (bgGradient) {
        await new Promise(async resolve => {
            const svgString = await reactToSvg(
                canvas.width,
                canvas.height,
                <Gradient gradient={bgGradient} />
            );

            const base64 = btoa(svgString);
            const svgBase64 = `data:image/svg+xml;base64,${base64}`;

            const img = new Image();
            img.crossOrigin = "anonymous";

            // Make sure the image is loaded first otherwise nothing will draw.
            img.onload = () => {
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                resolve();
            };
            img.src = svgBase64;
        });
        colorStyle = bgGradient.colorStyle || "color";
    } else {
        // When we set a default image we would like to use it as the background
        const bgImage = theme.backgroundImages?.find(bg => bg.id === theme.defaultBackgroundColor);
        if (bgImage) {
            await new Promise(async resolve => {
                const img = new Image();
                img.crossOrigin = "anonymous";

                // Make sure the image is loaded first otherwise nothing will draw.
                img.onload = () => {
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    resolve();
                };
                img.src = await Logos.getSignedUrlAndLoad(bgImage.asset, true);
            });
            colorStyle = bgImage.colorStyle || "light";
        } else {
            ctx.fillStyle = backgroundColor.toHexString();
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        }
    }

    // decoration
    ctx.fillStyle = theme.colors.theme;
    const styleWeightToLineSize = {
        light: 7.5,
        medium: 12,
        heavy: 18,
    };
    const lineSize = styleWeightToLineSize[theme.styleWeight];

    // Determine if the background color is in dark mode
    const colorStyleToDarkMode = {
        dark: true,
        color: backgroundColor.isDark(),
        light: false,
    };
    const isDarkMode = colorStyleToDarkMode[colorStyle];

    const primaryColor = isDarkMode ? theme.colors.primary_light : theme.colors.primary_dark;
    const secondaryColor = isDarkMode ? theme.colors.secondary_light : theme.colors.secondary_dark;

    let offsetX = 0;
    let offsetY = 0;
    switch (theme.styleDecoration) {
        case "bar_left":
            ctx.fillRect(0, 0, lineSize, canvas.height);
            offsetX = 15;
            break;
        case "bar_top_left":
        case "bar_top_center":
            ctx.fillRect(0, 0, canvas.width, lineSize);
            break;
        case "underline_left":
            ctx.fillRect(30, 112.5, 45, lineSize / 2);
            offsetY = -15;
            break;
        case "underline_center":
            ctx.fillRect(canvas.width / 2 - 60, 112.5, 120, lineSize / 2);
            offsetY = -15;
            break;
        case "block_left":
        case "block_center":
            ctx.fillRect(0, 0, canvas.width, 115.5);
            offsetY = -22.5;
            break;
    }

    ctx.fillStyle = primaryColor;
    ctx.font = `${theme.fontHeaderWeight} 75px ${theme.fontHeaderFontId}`;
    ctx.textAlign = theme.styleDecoration.contains("center") ? "center" : "left";
    let textX;
    if (theme.styleDecoration.contains("center")) {
        textX = canvas.width / 2;
    } else {
        textX = 30 + offsetX;
    }
    ctx.textBaseline = "middle";
    ctx.fillText("Header", textX, 90 + offsetY);

    ctx.fillStyle = secondaryColor;
    ctx.font = `${theme.fontBodyWeight} 30px ${theme.fontBodyFontId}`;
    ctx.fillText("This is the body text", textX, 150);

    const maxColorsToShow = theme.showLogo ? COLORS_TO_SHOW : 10;
    for (let i = 0; i < maxColorsToShow; i++) {
        let color;
        if (i == 0) {
            color = theme.colors.theme;
        } else if (Object.keys(theme.colors).contains(`accent${i}`)) {
            color = theme.colors[`accent${i}`];
        }
        if (color) {
            ctx.beginPath();
            ctx.fillStyle = color;
            ctx.strokeStyle = "#ccc";
            ctx.arc(Math.round(45 + i * 37.5 + offsetX), 233, 15, 0, 2 * Math.PI);
            ctx.fill();
            ctx.stroke();
        }
    }

    if (theme.showLogo && Object.keys(theme.colors).filter(key => key.startsWith("accent")).length > COLORS_TO_SHOW) {
        // 30 + 4 * 25 + offsetX + 15
        const offsetColor = 217.5 + offsetX;

        for (let i = 0; i < 3; i++) {
            ctx.beginPath();
            ctx.arc(Math.round(offsetColor + i * 7.5), 245, 3, 0, Math.PI * 2);
            ctx.fillStyle = isDarkMode ? "#fff" : "#000";
            ctx.closePath();
            ctx.fill();
        }
    }

    // If show logo is enabled and we have a logo, then add it to the thumbnail
    // Otherwise, we don't need to do anything
    if (theme.showLogo && (theme.logo || theme.logo_dark)) {
        await new Promise(async resolve => {
            let logo = new Image();
            logo.crossOrigin = "anonymous";
            logo.onload = () => {
                let bounds = new geom.Rect(0, 0, 195, 75).alignInContainer(new geom.Rect(0, 0, canvas.width, canvas.height), HorizontalAlignType.RIGHT, VerticalAlignType.BOTTOM).offset(-15, -15);
                let imageRect = new geom.Rect(0, 0, logo.naturalWidth, logo.naturalHeight)
                    .fitToSize(bounds).alignInContainer(bounds, HorizontalAlignType.CENTER, VerticalAlignType.CENTER);

                ctx.drawImage(logo, imageRect.x, imageRect.y, imageRect.width, imageRect.height);
                resolve();
            };

            // Use the or (||) operator to select the appropriate logo based on the dark mode condition.
            // We might have a case where the logo was added and then removed the then this prop is considered as an empty string.
            let themeLogo = isDarkMode ? theme.logo_dark || theme.logo : theme.logo || theme.logo_dark;
            // If the themeLogo is a url, extract the id from it
            if (/^https?:\/\//.test(themeLogo)) {
                themeLogo = /logos%2F(.*?)(?=\?)/.exec(themeLogo)?.[1];
                themeLogo = themeLogo.replaceAll("%20", " ");
            }

            logo.src = await Logos.getSignedUrlAndLoad(themeLogo, true);
        });
    }

    return new Promise(resolve => {
        canvas.toBlob(async blob => {
            // upload the image
            const id = `${theme.id}-${new Date().getTime().toString()}.png`;
            const writeUrl = await Logos.getWriteSignedUrl(id);
            await uploadBlobToSignedUrl(blob, writeUrl);
            resolve(id);
        });
    });
}
