import React, { Component, Fragment } from "react";
import { $ } from "js/vendor";
import { UIController } from "js/editor/dialogs/UIController";
import { app } from "js/namespaces";
import Avatar from "js/react/components/Avatar";
import { Menu, MenuItem, Icon } from "@material-ui/core";
import { NestedMenuItem } from "js/react/components/NestedMenuItem";
import { ds } from "js/core/models/dataService";
import styled from "styled-components";
import { trackActivity } from "js/core/utilities/utilities";
import renderReactRoot from "js/react/renderReactRoot";
import { Gap10, Gap30 } from "js/react/components/Gap";
import { themeColors } from "js/react/sharedStyles";
import AppController from "js/core/AppController";
import { openPricingPage, openTeamUpgrade } from "js/core/utilities/externalLinks";
import { BlueButton } from "js/react/components/UiComponents";

const MenuSection = styled.div`
    max-width: 250px;
    padding: 10px 20px 14px 14px;
    font-size: 11px;
    line-height: 13px;
    color: #666;

    .title {
        font-size: 13px;
        line-height: 21px;
        font-weight: 600;
        text-transform: uppercase;
        color: #333;
    }
`;

export class UserMenu extends Component {
    state = {
        anchorEl: null
    }

    showMenu = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    }

    closeMenu = event => {
        this.setState({
            anchorEl: null
        });
    }

    getWorkspaceName = workspace => {
        if (workspace.id == "personal") {
            return "Personal";
        } else {
            const defaultTeam = ds.teams.find(team => team.get("isDefault") && team.get("orgId") === workspace.id);
            return defaultTeam.get("name");
        }
    }

    handleEditProfile = () => {
        AppController.showAccountPane({ pane: "me" });
    }

    handleSwitchWorkspace = workspaceId => {
        this.closeMenu();

        AppController.switchWorkspace(workspaceId);

        if (AppController.view !== "library" || AppController.libraryPath?.includes("folder")) {
            AppController.showLibrary();
        }
    }

    handleLogout = () => {
        trackActivity("Auth", "logout", null, null, { workspace_id: "all" }, { audit: true });
        ds.shutdown();
        window.location = "/logout";
    }

    render() {
        let { canSwitchWorkspace } = this.props;
        let { anchorEl } = this.state;

        let workspace = app.user.workspaces[UIController.getWorkspaceId()];

        return (
            <Fragment>
                <Avatar id="userMenu" onClick={this.showMenu} url={AppController.userAvatarUrl} />
                <Menu
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.closeMenu}
                >
                    <MenuItem id="menu-item-profile" onClick={this.handleEditProfile}><Icon>account_circle</Icon>Manage Account</MenuItem>
                    <hr />
                    <NestedMenuItem
                        id="menu-item-workspace"
                        showNestedOnClick
                        disabled={!canSwitchWorkspace}
                        contents={
                            <Fragment><Icon>{workspace.icon}</Icon>Switch Workspace</Fragment>
                        }
                    >
                        {
                            Object.values(app.user.workspaces).map(ws => {
                                return (
                                    <MenuItem
                                        key={ws.id}
                                        value={ws.id}
                                        onClick={() => this.handleSwitchWorkspace(ws.id)}
                                    >
                                        {ws == workspace && <Icon style={{ color: themeColors.ui_blue }}>check</Icon>}
                                        {ws != workspace && <Gap30 />}
                                        <Icon>{ws.icon}</Icon>
                                        {this.getWorkspaceName(ws)}
                                        <Gap10 />
                                    </MenuItem>
                                );
                            })
                        }
                        {
                            !app.integrator &&
                            <>
                                <hr />
                                <MenuItem key="create" onClick={() => openTeamUpgrade()}>Create Workspace...</MenuItem>
                            </>
                        }
                    </NestedMenuItem>
                    <hr />
                    <MenuItem id="menu-item-logout" onClick={this.handleLogout}><Icon>logout</Icon>Log out</MenuItem>
                    {
                        workspace.id === "personal" && // Duplicate conditional because Menu doesn't accept fragments
                        <hr />
                    }
                    {
                        workspace.id === "personal" &&
                        <MenuSection>
                            <div className="title">Invite your team</div>
                            <div>Keep your team on brand and on track with shared resources and advanced collaboration.</div>
                            <Gap10 />
                            <BlueButton
                                fullWidth
                                onClick={() => openTeamUpgrade({ cta: "UserMenu" })}
                            >Create a workspace</BlueButton>
                        </MenuSection>
                    }
                </Menu>
            </Fragment>
        );
    }
}

export function renderUserMenu($el, canSwitchWorkspace) {
    let $div = $el.addEl($.div());
    renderReactRoot(UserMenu, { canSwitchWorkspace }, $div[0]);
}
