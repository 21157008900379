import React from "react";

import {
    Button,
    Typography,
    Paper,
    Link,
    LinearProgress,
    TextField,
    Grid,
    Hidden
} from "@material-ui/core";
import { withStyles, ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import { _ } from "js/vendor";
import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import { getStaticUrl, isPPTAddin } from "js/config";
import BeautifulLogo from "js/react/components/BeautifulLogo";
import LabelledDivider from "js/react/components/LabelledDivider";
import { dialogTheme, darkTheme } from "js/react/materialThemeOverrides";
import { app } from "js/namespaces";

const logger = getLogger(LogGroup.AUTH);

const googleIcon = getStaticUrl("/images/auth/google.svg");
const emailIcon = getStaticUrl("/images/auth/email.svg");
const genericImage = getStaticUrl("/images/auth/generic.png");
const teamImage = getStaticUrl("/images/auth/team.png");

const spacing = 16;

const styles = theme => {
    let style = {
        // sso
        ssoNotice: {
            borderRadius: 4,
            padding: 6,
            textAlign: "center",
            color: "#46555a",
            fontSize: 13
        },
        ssoIcon: {
            fontFamily: "Material Icons",
            fontSize: 20,
            fontStyle: "normal",
            verticalAlign: "bottom"
        },
        // google sign-in
        googleButton: {
            display: "flex",
            justifyContent: "start",
            borderRadius: 2,
            height: 40,
            background: "#FFFFFF",
            border: "none",
            padding: "11px",
            boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.17)",
            "& .MuiButton-root:hover": {
                background: "#EEEEEE"
            },
            "& .MuiButton-label": {
                display: "flex",
                columnGap: 24,
                lineHeight: "normal",

                "& :hover": {
                    cursor: "pointer"
                }
            },
            "& label": {
                textTransform: "capitalize",
                color: "rgba(0, 0, 0, 0.54)",
                fontFamily: "Roboto",
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: "normal",
            },
            "& img": {
                width: 18,
                height: 18
            }
        },
        // rows
        smallRow: {
            marginTop: spacing,
            marginBottom: spacing,
            "&:first-child": {
                marginTop: 0
            }
        },
        largeRow: {
            marginTop: spacing * 2,
            marginBottom: spacing * 2,
            "&:first-child": {
                marginTop: spacing
            }
        },
        topPaddedRow: {
            marginTop: spacing * 2,
        },
        // error message
        error: {
            background: "rgb(247, 181, 174)",
            color: "rgb(97, 26, 21)",
            paddingTop: spacing * 1,
            paddingBottom: spacing * 1,
            paddingLeft: spacing * 3,
            paddingRight: spacing * 3,
        },
        // info message
        info: {
            background: "#d1eeff",
            fontSize: 14,
            paddingTop: spacing * 1,
            paddingBottom: spacing * 1,
            paddingLeft: spacing * 2,
            paddingRight: spacing * 2,
        },
        // AuthPage
        logoContainer: {
            textAlign: "center",
            padding: spacing * 4,
        },
        authContainer: {
            margin: "auto",
            maxWidth: 450,
            [theme.breakpoints.up("md")]: {
                width: 450,
            },
        },
        // AuthForm
        authFormContent: {
            padding: spacing * 2
        },
        // text
        muted: {
            color: "#333"
        },
        wideSpace: {
            paddingLeft: ".2em"
        },
        // text field
        disabledField: {
            "& input": {
                background: "#f7f7f7",
                color: "#525252 !important"
            }
        },
        headerText: {
            marginBottom: spacing * 2
        },
        subheadingText: {
            marginTop: 11,
            fontSize: 19,
            opacity: 0.75,
            maxWidth: 450
        },
        captionText: {
            marginTop: 15,
            fontSize: 19,
            lineHeight: "1.5em",
            opacity: 0.75,
        },
        // sign-in/up form toggle
        toggleHoisted: {
            marginTop: 16
        },
        toggleBelow: {
            marginTop: 24,
            marginBottom: -64
        }
    };

    if (app.isDarkMode) {
        style = _.merge(
            style,
            {
                // text
                muted: {
                    color: "#ddd",
                },
                subheadingText: {
                    color: "white",
                },
            },
        );
    }

    return style;
};

function blockHotkeys(event) {
    // Prevent window.keydown events from firing.
    event.stopPropagation();
}

function makeHero(hero) {
    if (hero) {
        if (hero.image) {
            switch (hero.image) {
                case "generic": return <ImageWithCaption imageSrc={genericImage} imageWidth={500} />;
                case "team-invite": return <ImageWithCaption imageSrc={teamImage} imageWidth={500} />;
                case "presentation-invite": return <ImageWithCaption imageSrc={hero.thumbnailUrl} imageWidth={500} imageHeight={281} caption={hero.caption} isSlide />;
            }
        } else if (hero.imageUrl) {
            return <ImageWithCaption imageSrc={hero.imageUrl} imageWidth={500} caption={hero.caption} isSlide={hero.isSlide} />;
        }
    }
    return <ImageWithCaption imageSrc={genericImage} imageWidth={500} />;
}

export const AuthPage = withStyles(styles)(
    ({ classes, subheading, hero = null, noLogo = false, children }) => {
        const content = (
            <div data-cy="auth-page" style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ display: "flex", alignItems: "center", flexGrow: 1, maxWidth: 1280 }}>
                        <Grid container spacing={3}>
                            {hero && (
                                <Grid item container xs={12} lg={6}>
                                    <Hidden mdDown>
                                        <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                                            {makeHero(hero)}
                                        </div>
                                    </Hidden>
                                </Grid>
                            )}
                            <Grid item xs={12} lg={hero ? 6 : 12}>
                                <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                                    <div style={{ textAlign: "center", marginBottom: 20 }}>
                                        {!noLogo && (
                                            <BeautifulLogo darkOnLight={!app.isDarkMode} forceMarketing />
                                        )}
                                        {subheading && (
                                            <Typography variant="h5" component="h2" align="center" className={classes.subheadingText}>
                                                {subheading}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.authContainer}>
                                    {children}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
        return (
            <MuiThemeProvider theme={dialogTheme}>
                {
                    app.isDarkMode &&
                    <MuiThemeProvider theme={darkTheme}>
                        {content}
                    </MuiThemeProvider>
                }
                {
                    !app.isDarkMode &&
                    content
                }
            </MuiThemeProvider>
        );
    }
);

export const ImageWithCaption = withStyles(styles)(({ classes, caption, imageSrc, imageWidth, imageHeight, isSlide }) => {
    return (
        <div style={{ textAlign: "center", maxWidth: imageWidth, isSlide }}>
            <img
                width={imageWidth}
                height={imageHeight}
                src={imageSrc}
                style={{
                    background: isSlide ? "#f1f1f1" : undefined,
                    boxShadow: isSlide ? "2px 2px 8px rgba(0,0,0,0.3)" : undefined
                }}
            ></img>
            {caption && (
                <Typography variant="h5" component="h2" align="center" className={classes.captionText}>
                    {caption}
                </Typography>
            )}
        </div>
    );
});

export const AuthForm = withStyles(styles)(
    ({ classes, title, children, error, loading, info }) => (
        <Paper style={{ overflow: "hidden" }}>
            {loading && <LinearProgress />}
            {error && <div data-cy="error" className={classes.error}>{error}</div>}
            {info && <div data-cy="info" className={classes.info}>{info}</div>}
            <div className={classes.authFormContent}>
                {/* TODO: what should noValidate and autoComplete be set to? */}
                <form noValidate autoComplete="off">
                    {title && (
                        <Typography variant="h6" component="h1" align="center" className={classes.headerText}>
                            {title}
                        </Typography>
                    )}
                    <div>
                        {React.Children.map(children, child => (
                            <div className={classes.smallRow}>
                                {child}
                            </div>
                        ))}
                    </div>
                </form>
            </div>
        </Paper>
    )
);

export const GoogleButton = withStyles(styles)(
    ({ classes, children, label, disabled, onClick, fullWidth = true }) => (
        <Button
            id="google"
            className={classes.googleButton}
            onClick={onClick}
            disabled={disabled}
            fullWidth={fullWidth}
        >
            <img src={googleIcon} />
            {children}
            <label>{label}</label>
        </Button>
    )
);

const SignInOrCreateAccount = withStyles(styles)(
    class SignInOrCreateAccount extends React.Component {
        handleClickSubmit = e => {
            e.preventDefault();
            this.props.onClickSubmit();
        }

        handleClickSwitchLink = e => {
            e.preventDefault();
            this.props.onClickSwitchLink({ signIn: !!this.props.createAccount });
        }

        handleClickForgot = e => {
            e.preventDefault();
            this.props.onClickForgot();
        }

        handleClickSSOLink = e => {
            e.preventDefault();
            this.props.onClickSSOLink();
        }

        render = () => {
            const {
                classes,
                email, defaultEmail, password, error, loading, createAccount, emailError, passwordError,
                onClickSSOLink,
                showOnlyGoogle,
                noToggle,
                ssoState,
                children,
                info,
                withCompany,
                hero,
                subheading,
                noLogo,
                hoistTerms,
                noTitle
            } = this.props;

            const isEmailReadonly = !!defaultEmail;
            const sso = ssoState || "none";
            const signInLabel = (sso == "strict" || sso == "optional") ? "Continue" : "Sign in";

            const onEmailChange = event => {
                if (this.props.onEmailChange) {
                    this.props.onEmailChange(event.target.value);
                }
            };

            const onPasswordChange = event => {
                if (this.props.onPasswordChange) {
                    this.props.onPasswordChange(event.target.value);
                }
            };

            const onFirstNameChange = event => {
                if (this.props.onFirstNameChange) {
                    this.props.onFirstNameChange(event.target.value);
                }
            };

            const onLastNameChange = event => {
                if (this.props.onLastNameChange) {
                    this.props.onLastNameChange(event.target.value);
                }
            };

            const onCompanyChange = event => {
                if (this.props.onCompanyChange) {
                    this.props.onCompanyChange(event.target.value);
                }
            };

            let title;
            if (!noTitle) {
                title = createAccount
                    ? "Create an account"
                    : "Sign in to your account";
            }

            const isSafari = (
                navigator.userAgent.indexOf("Safari") > -1 &&
                navigator.userAgent.indexOf("Chrome") < 0
            );
            const hideGoogleSso = app.integrator && isSafari;

            return (
                <AuthPage
                    subheading={subheading ?? (createAccount ? "Start creating beautiful presentations, faster." : "")}
                    hero={hero}
                    noLogo={noLogo}
                >
                    <AuthForm
                        title={title}
                        error={error}
                        info={info}
                        loading={loading}
                    >
                        {sso !== "strict" && !hideGoogleSso && (
                            <GoogleButton
                                label="Continue with Google"
                                onClick={this.props.onClickGoogleButton}
                                disabled={loading}
                            />
                        )}
                        {children}
                        {!showOnlyGoogle && (
                            <>
                                {sso !== "strict" && !hideGoogleSso && (
                                    <div style={{ marginBottom: -spacing }}>
                                        <LabelledDivider label="OR" />
                                    </div>
                                )}
                                {createAccount && (
                                    <>
                                        {withCompany && (
                                            <div className={classes.smallRow}>
                                                <TextField
                                                    id="company"
                                                    variant="outlined"
                                                    type="text"
                                                    placeholder="(Company Name)"
                                                    fullWidth
                                                    onChange={onCompanyChange}
                                                    onKeyDown={blockHotkeys}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                                <div className={classes.smallRow}>
                                    <TextField
                                        id="email"
                                        className={isEmailReadonly ? classes.disabledField : ""}
                                        variant="outlined"
                                        type="email"
                                        placeholder={createAccount ? "Work Email" : "Email"}
                                        value={email || defaultEmail || ""}
                                        autoFocus={!createAccount && !isEmailReadonly}
                                        disabled={isEmailReadonly}
                                        fullWidth
                                        helperText={emailError}
                                        error={!!emailError}
                                        onChange={onEmailChange}
                                        onKeyDown={blockHotkeys}
                                    />
                                </div>
                                {sso !== "strict" && (
                                    <div className={classes.smallRow}>
                                        <TextField
                                            variant="outlined"
                                            type="password"
                                            placeholder={createAccount
                                                ? "Create Password"
                                                : "Password"
                                            }
                                            autoFocus={!createAccount && isEmailReadonly}
                                            value={password || ""}
                                            fullWidth
                                            helperText={passwordError}
                                            error={!!passwordError}
                                            onChange={onPasswordChange}
                                            onKeyDown={blockHotkeys}
                                        />
                                    </div>
                                )}
                                {(sso === "strict" || sso === "optional") && (
                                    <div className={classes.smallRow}>
                                        <div className={classes.ssoNotice}>
                                            <i className={classes.ssoIcon}>lock</i>
                                            Single sign-on enabled
                                        </div>
                                    </div>
                                )}
                                <div className={classes.smallRow}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        onClick={this.handleClickSubmit}
                                        // TODO: handle enter key press -> submit
                                        disabled={loading}
                                        fullWidth
                                    >{createAccount ? "Create account" : signInLabel}
                                    </Button>
                                </div>
                                {!createAccount && sso !== "strict" && (
                                    <Typography variant="body2">
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={this.handleClickForgot}
                                        >Forgot your password?</Link>
                                    </Typography>
                                )}
                                {hoistTerms && (
                                    <Toggle
                                        createAccount={createAccount}
                                        noToggle={noToggle}
                                        onClickSSOLink={onClickSSOLink}
                                        handleClickSwitchLink={this.handleClickSwitchLink}
                                        handleClickSSOLink={this.handleClickSSOLink}
                                        hoistTerms={true}
                                    />
                                )}
                                {createAccount && hoistTerms && (
                                    <Terms align="left" />
                                )}
                            </>
                        )}
                    </AuthForm>
                    {!hoistTerms && (
                        <Toggle
                            createAccount={createAccount}
                            noToggle={noToggle}
                            onClickSSOLink={onClickSSOLink}
                            handleClickSwitchLink={this.handleClickSwitchLink}
                            handleClickSSOLink={this.handleClickSSOLink}
                            hoistTerms={false}
                        />
                    )}
                    {createAccount && !hoistTerms && (
                        <Terms align="center" />
                    )}
                </AuthPage>
            );
        }
    }
);

const Toggle = withStyles(styles)(
    ({ classes, createAccount, noToggle, onClickSSOLink, handleClickSwitchLink, handleClickSSOLink, hoistTerms }) => (
        <div className={classes.smallRow + " " + hoistTerms ? classes.toggleHoisted : classes.toggleBelow}>
            <Typography
                variant="body1"
                align="center"
                className={classes.muted}
                style={{
                    marginBottom: hoistTerms
                        ? (createAccount ? 0 : -16)
                        : (createAccount ? 0 : -48)
                }}>
                {!noToggle && <>
                    {createAccount
                        ? "Already have an account?"
                        : "New to Beautiful.ai?"}
                    <Link
                        id="toggle"
                        href="#"
                        className={classes.wideSpace}
                        onClick={handleClickSwitchLink}
                    >{createAccount ? "Sign in" : "Create an account"}
                    </Link>
                </>}
                {!createAccount && onClickSSOLink && (
                    <span style={{ float: "right" }}>
                        <Link
                            className={classes.wideSpace}
                            onClick={handleClickSSOLink}
                        >Sign in with SSO
                        </Link>
                    </span>
                )}
            </Typography>
        </div>
    )
);

const Terms = withStyles(styles)(
    ({ classes, align }) => (
        <div className={classes.smallRow}>
            <Typography
                variant="body2"
                align={align}
                className={classes.muted}
                style={{
                    marginBottom: -spacing
                }}
            >
                By creating an account, you agree to the { }
                <Link
                    href="/terms-of-service"
                    target="_blank"
                >Terms of Service
                </Link> and { }
                <Link
                    href="/privacy-policy"
                    target="_blank"
                >Privacy Policy
                </Link>.
            </Typography>
        </div>
    )
);

export const SignIn = props => (
    <SignInOrCreateAccount {...props} />
);

export const CreateAccount = props => (
    <SignInOrCreateAccount {...props} createAccount />
);

export class ResetPassword extends React.Component {
    render = () => {
        const { loading, error, email, emailError, hero } = this.props;

        const onEmailChange = event => {
            if (this.props.onEmailChange) {
                this.props.onEmailChange(event.target.value);
            }
        };

        return (
            <AuthPage
                hero={hero}
                noLogo={this.props.noLogo}
            >
                <AuthForm
                    title="Forgot your password?"
                    loading={loading}
                    error={error}
                >
                    <Typography variant="body1">
                        Enter your email address to reset your password.
                    </Typography>
                    <TextField
                        variant="outlined"
                        type="email"
                        placeholder="Email"
                        value={email || ""}
                        fullWidth
                        autoFocus
                        helperText={emailError}
                        error={!!emailError}
                        onChange={onEmailChange}
                        onKeyDown={blockHotkeys}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.props.onClickReset}
                        fullWidth
                    >Reset Password
                    </Button>
                </AuthForm>
            </AuthPage>
        );
    }
}

export const ResetDone = withStyles(styles)(class ResetDone extends React.Component {
    render = () => {
        return (
            <AuthPage
                hero={this.props.hero}
                noLogo={this.props.noLogo}
            >
                <AuthForm>
                    <div style={{ textAlign: "center" }}>
                        <img src={emailIcon} />
                    </div>
                    <Typography variant="h6" component="h1" align="center" className={this.props.classes.headerText}>
                        Check your email
                    </Typography>
                    <Typography variant="body1" align="center">
                        We've sent you instructions to reset your password.
                    </Typography>
                </AuthForm>
            </AuthPage>
        );
    }
});

export class WaitForGoogle extends React.Component {
    render = () => {
        return (
            <AuthPage
                hero={this.props.hero}
                noLogo={this.props.noLogo}
            >
                <AuthForm
                    loading={false}
                    error={false}
                >
                    <div style={{ textAlign: "center" }}>
                        <img width="40" src={googleIcon} />
                    </div>
                    <Typography variant="h6" component="h1" align="center">
                        Waiting for Google...
                    </Typography>
                </AuthForm>
            </AuthPage>
        );
    }
}

export const VerifyEmail = withStyles(styles)(class VerifyEmail extends React.Component {
    state = {
        loading: false,
        sent: false,
        errorMessage: null
    };

    handleClickResend = async event => {
        event.preventDefault();
        this.setState({ loading: true });
        try {
            await Api.verifyEmail.post({ isPPT: isPPTAddin });
            this.setState({ sent: true, errorMessage: null });
        } catch (err) {
            logger.error(err, "[VerifyEmail] Api.verifyEmail.post() failed");
            this.setState({ errorMessage: err.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    render = () => {
        const { sent, loading, errorMessage } = this.state;

        return (
            <AuthPage noLogo={this.props.noLogo}>
                <AuthForm
                    loading={false}
                    error={false}
                    hero={this.props.hero}
                >
                    <div style={{ textAlign: "center" }}>
                        <img src={emailIcon} />
                    </div>
                    <Typography variant="h6" component="h1" align="center" className={this.props.classes.headerText}>
                        Verify your email address
                    </Typography>
                    <Typography variant="body1" align="center">
                        <span>To verify your account, click on the link sent to:</span>
                        <br />
                        <b>{this.props.email}</b>
                    </Typography>
                    <br />
                    <Typography variant="body1" align="center">
                        {sent && !errorMessage && <div>Sent! Check your inbox.</div>}
                        {!sent && errorMessage && <div>Error: {errorMessage}</div>}
                        {!sent && <Link
                            component="button"
                            variant="body1"
                            onClick={this.handleClickResend}
                            disabled={loading}
                        >
                            Resend Email
                        </Link>}
                    </Typography>
                    <Typography variant="body2" align="center">
                        Having trouble? <Link
                            href="mailto:support@beautiful.ai"
                            variant="body2"
                        >Contact support</Link> or <Link
                            href="/logout?continue=/signup"
                            variant="body2"
                        >return to sign-up</Link>
                    </Typography>
                </AuthForm>
            </AuthPage>
        );
    }
});
