import React, { Component } from "reactn";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { LinearProgress, Icon, Button } from "@material-ui/core";

import { ds } from "js/core/models/dataService";
import getLogger, { LogGroup } from "js/core/logger";
import { TaskState, TaskType, PPTImportWarningType } from "common/constants";
import { themeColors } from "js/react/sharedStyles";
import { FlexBox, ScrollBox } from "js/react/components/LayoutGrid";
import { FlexSpacer, Gap10, Gap30 } from "js/react/components/Gap";
import {
    BlueButton,
    UIPane,
    UIPaneContents
} from "js/react/components/UiComponents";
import {
    MultiSelectThumbnailGrid,
    Thumbnail,
    ThumbnailContainer
} from "js/react/views/AddSlide/Panes/Components/ThumbnailGrid";
import { ShowErrorDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { uploadFileAndCreateTask, createTask } from "js/core/services/tasks";
import { trackActivity } from "js/core/utilities/utilities";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

const logger = getLogger(LogGroup.PPT_IMPORT);

const InnerPane = styled.div`
    padding: 30px 50px;
    background: white;
    border-radius: 4px;
    width: 820px;

    h1 {
        text-transform: none !important;
        font-size: 22px !important;
        margin: 0 !important;
    }

    p {
        font-size: 15px;
        opacity: 0.8;
    }
`;

const UploadFileInput = styled.input`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
`;

const Instructions = styled.div`
    width: 100%;
    height: 93px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    color: white;
    pointer-events: auto;
    flex-shrink: 0;

    div {
        color: ${themeColors.ui_blue};
    }
`;

const SlidesScrollBox = styled(ScrollBox)`
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    pointer-events: auto;
`;

const ImportSlidesButton = styled(Button)`
    &&& {
        background: #2EB4E5;
        color: white;
        min-width: 150px;
        padding: 10px;

        &:disabled {
            background: white;
            color: #A9A9A9;
        }
    }
`;

const ImportAnotherFileButton = styled(Button)`
    &&& {
        height: 53px;
        background: white;
        color: #2EB4E5;
        white-space: nowrap;
        min-width: fit-content;
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 25px;
        padding-right: 25px;

        &:hover {
            background: #2EB4E5;
            color: white;
        }
    }
`;

const ImportSlidesButtonContainer = styled.div`
    width: 100%;
    background: #f5f5f9;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CancelButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const PPTImportStep = {
    SELECTING_FILE: "selecting-file",
    LOADING_PREVIEW: "loading-preview",
    SELECTING_SLIDES: "selecting-slides",
    IMPORTING_SLIDES: "importing-slides"
};

const initialState = {
    currentStep: PPTImportStep.SELECTING_FILE,
    currentStepProgress: 0,
    currentStepMessage: null,
    pptSlides: [],
    pptFileName: null,
    selectedPptSlides: [],
};

export class ImportSlidesFromPPTPane extends Component {
    constructor(props) {
        super(props);

        this.state = { ...initialState };

        this.isCurrentTaskCancelled = false;
        this.cancelCurrentTask = null;
    }

    componentWillUnmount() {
        if (this.cancelCurrentTask) {
            this.cancelCurrentTask();
        }
    }

    handleError(err) {
        logger.error(err, "[ImportSlidesFromPPTPane] handleError()");
        ShowErrorDialog({ title: "Error", message: "Sorry, we could not process your file, try another one or contact support at support@beautiful.ai" });
        this.setState({ ...initialState });
    }

    handleSelectFile(file) {
        if (!file) {
            return;
        }

        const maxFileSizeMB = 100;
        if (file.size > maxFileSizeMB * 1024 * 1024) {
            ShowErrorDialog({ title: `File is bigger than ${maxFileSizeMB}MB`, message: "Please select another presentation or split this one into separate files of smaller sizes" });
            return;
        }

        this.setState({ currentStep: PPTImportStep.LOADING_PREVIEW, currentStepProgress: 0 });

        this.isCurrentTaskCancelled = false;
        uploadFileAndCreateTask(
            file,
            TaskType.PPT_PREVIEW,
            task => {
                if (this.isCurrentTaskCancelled) {
                    // Ignoring the change because we requested the task to stop
                    return;
                }

                switch (task.state) {
                    case TaskState.ERROR:
                        this.cancelCurrentTask = null;
                        return this.handleError(task.errorMessage);
                    case TaskState.PREPARING:
                        return this.setState({ currentStepProgress: task.stateProgressPercents / 2 });
                    case TaskState.PROCESSING:
                        return this.setState({ currentStepProgress: task.stateProgressPercents / 2 + 50 });
                    case TaskState.FINISHED:
                        this.cancelCurrentTask = null;
                        const pptSlides = task.slides.map(slide => ({ ...slide, id: uuid() }));

                        trackActivity("PPTImport", "FileSelected", null, null, { file_name: file.name, total_slides: pptSlides.length }, { audit: true });

                        return this.setState({
                            currentStep: PPTImportStep.SELECTING_SLIDES,
                            currentStepProgress: 0,
                            pptSlides,
                            pptFileName: task.pptFileName,
                            svgFileName: task.svgFileName,
                            notesFileName: task.notesFileName
                        });
                }
            })
            .then(task => this.cancelCurrentTask = () => {
                task.cancel().catch(err => logger.error(err, "ImportSlidesFromPPTPane task.cancel() failed"));
                this.cancelCurrentTask = null;
                this.isCurrentTaskCancelled = true;
            })
            .catch(err => this.handleError(err));
    }

    handleImportSlides = () => {
        const { onImportFinished, forTeamSlide } = this.props;
        const { selectedPptSlides, pptFileName, svgFileName, notesFileName } = this.state;

        // When we open the shared slides or the slide templates pane, we create a new dummy presentation
        // which we then use to import the slides into. in order to kepe the same reference we are using the ds.selection.presentation
        // to ensure that the slides are imported into the same presentation and the reference is kept
        const presentation = forTeamSlide ? ds.selection.presentation : PresentationEditorController.presentation;

        this.setState({ currentStep: PPTImportStep.IMPORTING_SLIDES, currentStepProgress: 0 });

        const isPresentationDummy = presentation.get("isDummy") === true;

        this.isCurrentTaskCancelled = false;
        this.isSubscribedForSlidesUpdate = false;

        createTask(
            TaskType.PPT_IMPORT,
            task => {
                if (this.isCurrentTaskCancelled) {
                    // Ignoring the change because we requested the task to stop
                    return;
                }

                switch (task.state) {
                    case TaskState.ERROR:
                        this.cancelCurrentTask = null;
                        if (this.isSubscribedForSlidesUpdate) {
                            presentation.off("slidesUpdated", this.handleUpdatedSlides);
                        }

                        return this.handleError(task.errorMessage);
                    case TaskState.PREPARING:
                        return this.setState({ currentStepProgress: 10 });
                    case TaskState.PROCESSING:
                        if (!this.isSubscribedForSlidesUpdate) {
                            presentation.once("slidesUpdated", this.handleUpdatedSlides);
                            this.isSubscribedForSlidesUpdate = true;
                        }

                        return this.setState({ currentStepProgress: task.stateProgressPercents / 10 * 9 + 10 });
                    case TaskState.FINISHED:
                        this.cancelCurrentTask = null;
                        trackActivity("PPTImport", "Completed", null, null, { slides_imported: selectedPptSlides.length }, { audit: true });
                        if (task.warnings && task.warnings.length > 0) {
                            const messageElements = [];
                            if (task.warnings.some(warning => warning.type === PPTImportWarningType.ELEMENTS_TRUNCATED)) {
                                messageElements.push(
                                    <p>
                                        Some of your original slides have more elements than we support.
                                        We've imported as much detail as possible.
                                    </p>
                                );

                                const slideIndexes = task.warnings
                                    .filter(warning => warning.type === PPTImportWarningType.ELEMENTS_TRUNCATED)
                                    .map(warning => warning.slideIndex + 1);

                                messageElements.push(
                                    <p className="bold">Imported slide{slideIndexes.length > 1 ? "s" : ""} {slideIndexes.join(" and ")} may be incomplete.</p>
                                );
                            }

                            ShowWarningDialog({
                                title: "Oops! There was an issue with your slides.",
                                message: messageElements
                            });
                        }

                        if (isPresentationDummy) {
                            return presentation.addExistingSlides(task.slideIds.map(id => ({ id })), 0, { skipUndo: true })
                                .then(() => onImportFinished());
                        }
                }
            },
            {
                pptFileName: pptFileName ?? null,
                svgFileName: svgFileName ?? null,
                notesFileName: notesFileName ?? null,
                slideImportIndexes: selectedPptSlides.map(({ index }) => index),
                slideImageFileNames: selectedPptSlides.map(({ imageFileName }) => imageFileName),
                slidesInsertIndex: forTeamSlide ? 0 : PresentationEditorController.getCurrentSlideIndex() + 1,
                presentationId: presentation.id,
                isPresentationDummy
            })
            .then(task => this.cancelCurrentTask = () => {
                task.cancel().catch(err => logger.error(err, "ImportSlidesFromPPTPane task.cancel() failed"));
                this.cancelCurrentTask = null;
                this.isCurrentTaskCancelled = true;
            })
            .catch(err => this.handleError(err));
    }

    handleUpdatedSlides = async (updatedSlides, options) => {
        const { forTeamSlide, onImportFinished } = this.props;
        if (!forTeamSlide) {
            // since editor is not mounted when AddSlide view is open, we need to call PresentationEditorController's listener directly
            await PresentationEditorController.syncSlidesWithPresentation(updatedSlides, options);
        }
        onImportFinished();
    }

    handleCancelButtonClick = () => {
        const { currentStep } = this.state;

        if (!this.cancelCurrentTask) {
            return;
        }

        this.cancelCurrentTask();

        if (currentStep === PPTImportStep.LOADING_PREVIEW) {
            this.setState({ ...this.initialState });
        } else if (currentStep === PPTImportStep.IMPORTING_SLIDES) {
            this.setState({ currentStep: PPTImportStep.MAPPING_FONTS });
        }
    }

    renderPaneContents() {
        const {
            forTeamSlide,
        } = this.props;
        const {
            currentStep,
            currentStepProgress,
            pptSlides,
            selectedPptSlides
        } = this.state;

        const allowMultiSelect = !forTeamSlide;

        switch (currentStep) {
            case PPTImportStep.SELECTING_FILE:
                return (
                    <FlexBox center fill>
                        <InnerPane>
                            <div>
                                <h1>Select PowerPoint Presentation</h1>
                                <Gap10 />
                                <p>
                                    We'll show you the slides from the PowerPoint file you selected so
                                    you can choose which ones you want to import into the presentation.
                                </p>
                                <Gap30 />
                                <BlueButton>
                                    <UploadFileInput
                                        onChange={event => this.handleSelectFile(event.currentTarget.files[0])}
                                        type="file"
                                        accept=".pptx"
                                    />
                                    <Icon>cloud_upload</Icon>
                                    Select File
                                </BlueButton>
                            </div>
                        </InnerPane>
                    </FlexBox>
                );
            case PPTImportStep.SELECTING_SLIDES:
                return (
                    <FlexBox column fill top>
                        <Instructions>
                            <ImportAnotherFileButton>
                                <UploadFileInput
                                    onChange={event => {
                                        this.setState({ ...this.initialState });
                                        this.handleSelectFile(event.currentTarget.files[0]);
                                    }}
                                    type="file"
                                    accept=".pptx"
                                />
                                Import another file
                            </ImportAnotherFileButton>
                            <div>
                                Select slides to copy into your presentation
                            </div>
                            <FlexSpacer />
                        </Instructions>
                        <SlidesScrollBox fillWidth>
                            <MultiSelectThumbnailGrid
                                columns={4}
                                colGap={30}
                                items={pptSlides}
                                allowDragging={false}
                                allowMultiSelect={allowMultiSelect}
                                thumbnailClass={PPTSlideThumbnail}
                                onItemSelected={selectedPptSlides => this.setState({ selectedPptSlides })}
                                onDoubleClick={pptSlide => {
                                    this.setState({ selectedPptSlides: [pptSlide] }, () => {
                                        this.handleImportSlides();
                                    });
                                }}
                            />
                        </SlidesScrollBox>
                        <ImportSlidesButtonContainer>
                            <ImportSlidesButton
                                disabled={selectedPptSlides.length == 0}
                                onClick={this.handleImportSlides}
                            >
                                Import Slides
                            </ImportSlidesButton>
                        </ImportSlidesButtonContainer>
                    </FlexBox>
                );
            case PPTImportStep.LOADING_PREVIEW:
            case PPTImportStep.IMPORTING_SLIDES:
                return (
                    <FlexBox center fill>
                        <InnerPane>
                            <div>
                                {currentStep === PPTImportStep.LOADING_PREVIEW && <h1>Generating slide previews</h1>}
                                {currentStep === PPTImportStep.IMPORTING_SLIDES && <h1>Importing selected slides</h1>}
                                <Gap10 />
                                <p>
                                    Hang tight. This might take a minute or so. Please do not close or refresh the browser.
                                </p>
                                <Gap30 />
                                <LinearProgress
                                    value={currentStepProgress}
                                    variant="determinate"
                                />
                                <Gap30 />
                                <CancelButtonContainer>
                                    <BlueButton onClick={this.handleCancelButtonClick}>
                                        Cancel
                                    </BlueButton>
                                </CancelButtonContainer>
                            </div>
                        </InnerPane>
                    </FlexBox>
                );
        }
    }

    render() {
        return (
            <UIPane style={{ padding: 0 }}>
                <UIPaneContents style={{ padding: 0 }}>
                    {this.renderPaneContents()}
                </UIPaneContents>
            </UIPane >
        );
    }
}

class PPTSlideThumbnail extends Component {
    render() {
        const { item: { index, imageUrl }, onMouseDown, onDoubleClick, selected } = this.props;

        return (
            <ThumbnailContainer>
                <Thumbnail
                    onMouseDown={onMouseDown}
                    onDoubleClick={onDoubleClick}
                    url={imageUrl}
                    selected={selected}
                    showSpinner={true}
                    style={{ border: "solid 1px #ccc", boxShadow: "0px 0px 20px rgba(0,0,0,0.15)" }}
                    imageStyle={{ width: "auto" }}
                />
                <label>Slide {index + 1}</label>
            </ThumbnailContainer>
        );
    }
}
