import React, { Component, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Button, DialogTitle, Icon, IconButton, MenuItem, Select } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import CalendarRange from "mdi-material-ui/CalendarRange";
import CloudSearch from "mdi-material-ui/CloudSearch";
import SortAlphabeticalVariant from "mdi-material-ui/SortAlphabeticalVariant";
import Update from "mdi-material-ui/Update";
import { Transition } from "react-transition-group";

import { _ } from "js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import { getStaticUrl } from "js/config";
import { app } from "js/namespaces";
import AppController from "js/core/AppController";
import { ds } from "js/core/models/dataService";
import { Presentation } from "js/core/models/presentation";
import * as appSearch from "js/core/services/appSearch";
import { delay } from "js/core/utilities/promiseHelper";
import { trackActivity } from "js/core/utilities/utilities";
import {
    LibraryView,
    PresentationFilters,
    SearchThrottleWait
} from "common/constants";
import { FeatureType } from "common/features";
import { TeamFoldersController, TeamFoldersDataService } from "js/core/dataServices/TeamFoldersDataService";
import {
    PresentationLibraryController,
    PresentationLibraryDataService
} from "js/editor/PresentationLibrary/PresentationLibraryController";
import { PresentationLibraryGrid } from "js/editor/PresentationLibrary/PresentationLibraryGrid";
import { PresentationLibraryList } from "js/editor/PresentationLibrary/PresentationLibraryList";
import { PresentationLibrarySideBar } from "js/editor/PresentationLibrary/PresentationLibrarySideBar";
import { PresentationListHeader } from "js/editor/PresentationLibrary/PresentationListHeader";
import { UserMenu } from "js/editor/components/UserMenu";
import { HelpButton } from "js/editor/components/helpButton";
import { UIController } from "js/editor/dialogs/UIController";
import DesignerBotIcon from "js/react/components/DesignerBotIcon";
import {
    BeautifulDialog,
    DialogContent,
    ShowDialog,
    ShowDialogAsync,
    ShowErrorDialog
} from "js/react/components/Dialogs/BaseDialog";
import { FlexSpacer, Gap10, Gap20, Gap30, Gap5 } from "js/react/components/Gap";
import { FlexBox, ScrollBox } from "js/react/components/LayoutGrid";
import { NoMatchNotice, Notice } from "js/react/components/Notice";
import ProBadge from "js/react/components/ProBadge";
import Spinner from "js/react/components/Spinner";
import {
    BlueButton,
    Divider,
    StyledWarningIcon,
    UIPane,
    UIPaneContents,
    UIPaneSideBar,
    UIPaneWithSideBar,
    WarningBox,
    WarningButton,
    WhiteIconButton
} from "js/react/components/UiComponents";
import UpgradeButton from "js/react/components/UpgradeButton";
import { dialogTheme } from "js/react/materialThemeOverrides";
import { themeColors } from "js/react/sharedStyles";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import TeamResources from "js/react/views/TeamResources/TeamResources";
import MyResources from "js/react/views/MyResources/MyResources";
import { CreatePresentationDialogPaneType } from "js/editor/CreatePresentation/CreatePresentationDialogTypes";
import { AddPresentationToFolderDialog } from "js/react/views/TeamResources/dialogs/TeamFolderDialogs";
import { RequestAccessDialog, ProvideAccessDialog } from "js/react/components/Dialogs/FolderAccessDialogs";
import ShortcutPanel from "js/editor/PresentationEditor/Components/ShortCutPanel";
import { PresentationFilterContext } from "js/editor/PresentationLibrary/PresentationFilterContext";

import "css/presentations.scss";

const logger = getLogger(LogGroup.LIBRARY);

const CloseButtonContainer = styled.div`
    text-align: right;
    margin-bottom: -15px;
    margin-top: 10px;
`;

const PresentationLibraryContainer = styled.div`
    width: 100vw;
    height: 100vh;
`;

const NewDocumentButtonContainer = styled.div`
    height: 100%;
`;

const LibraryHeaderContainer = styled.div`
    width: 100%;
    height: 50px;
    background: #333;
    padding: 0px;
    display: flex;
    align-items: center;
    color: white;

    .logo-desktop {
        height: 24px;
        cursor: pointer;
    }

    .logo-mobile {
        height: 20px;
        cursor: pointer;
    }

    .avatar {
        width: 30px;
        height: 30px;
    }

    input {
        color: white;
    }

    .MuiIcon-root {
        color: white;
    }

    .MuiSlider-rail {
        background: #aaa;
    }

    .MuiSlider-track {
        background: #aaa;
    }

    .empty-trash-header {
        display: flex;
        align-items: center;
        height: 100%;

        .MuiIcon-root {
            color: orangered;
        }

        label {
            height: 100%;
            background: orangered;
            color: white;
            display: block;
            display: inline-flex;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            padding: 0px 20px;
        }
    }
`;

const LogoBox = styled.div`
    background: #222;
    width: 260px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const EmptyFilterMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    & > div {
        padding: 30px;
        font-weight: 600;
        font-size: 24px;
        border: solid 1px orangered;
        color: #333;
        background: white;
        max-width: 700px;
        letter-spacing: 0px;
        text-align: center;

        div {
            font-size: 15px;
            font-weight: 500;
            margin-top: 10px;
            color: #555;
        }
    }
`;

const RemoteControlBanner = styled.div`
    padding: 10px;
    text-align: center;
    background: orangered;
    color: white;
    font-size: 18px;
    width: 100%;
    font-weight: 600;
`;

const CreatePresentationButton = styled(Button)`
    flex-shrink: 0;
    height: 50px;
    background: ${themeColors.ui_blue} !important;
    padding: 0px 20px 0px 10px !important;

    .MuiButton-label {
        color: white;
        font-family: "Source Sans Pro";
        font-size: 16px;
        font-weight: 600;
        margin-left: 10px;
    }

    .MuiIcon-root {
        background: white;
        color: black;
        border-radius: 50%;
        padding: 3px;
        font-size: 17px;
        margin-right: 6px;
    }
`;

const CreatePresentationButtonIntegrator = styled(CreatePresentationButton)`
    border: 1px solid ${themeColors.ui_blue} !important;
    padding: 0 !important;
    margin-right: 14px !important;

    .MuiButton-label {
        color: #444;
        background: transparent;
        padding: 10px 20px;
    }

    .dark-mode & {
        .MuiButton-label {
            color: #ccc;
        }
    }
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
const PopupMenuMask = styled.div`
    position: absolute;
    z-index: 2000;
`;

const CreatePopupMenu = styled.div`
    top: 5px;
    position: absolute;
    padding: 10px;
    background: white;
    border-radius: 2px;
    color: black;
    flex-direction: column;
    z-index: 1000;
    display: flex;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.2);
    
    align-items: flex-start;
    align-items: center;
    opacity: ${({ state }) => (["entering", "entered"].includes(state) ? 1 : 0)};
    display: ${({ state }) => (["entering", "entered"].includes(state) ? "block" : "none")};;
    transition: 300ms;
`;

const CreatePopupMenuItem = styled.div`
    font-size: 14px;
    font-weight: 600;
    gap: 10px;
    white-space: nowrap;
    color: #222;
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    
    &:hover {
        background: #f1f1f1;
    }

    .MuiIcon-root {
        font-size: 18px;
        color: ${themeColors.mediumGray};
    }
`;

const StyledBlueButton = styled(BlueButton)`
    &&& {
        height: 100%;
        padding: 6px 20px;
        border-radius: 0;
    }
`;

export const Breadcrumb = styled.div`
    font-size: 18px;
    text-transform: uppercase;
    margin-right: 10px;
    color: #444;
    flex-shrink: 0;

    .dark-mode & {
        color: #ccc;
    }
`;

const NotificationsLabelButton = styled(WhiteIconButton)`
    .MuiButtonBase-root {
        position: relative;
    }

    span.countLabel {
        position: absolute;
        color: white;
        background-color: ${themeColors.ui_blue};
        border-radius: 80px;
        min-width: 8px;
        height: 14px;
        font-weight: 600;
        top: 8px;
        left: 23px;
        padding: 1px 4px;
        letter-spacing: 0px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.small {
            font-size: 9px;
            line-height: 15px;
        }
    }
`;

const IntegratorMessage = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    color: white;
`;

class NotificationsLabel extends Component {
    constructor() {
        super();

        this.state = {
            unreadCount: 0
        };
    }

    setUnreadCount = () => {
        const workspaceId = UIController.getWorkspaceId();
        const unreadCount = AppController.notificationsService.unread.filter(notification => notification.event.workspaceId === workspaceId).length;
        this.setState({ unreadCount });
    }

    componentDidMount() {
        this.setUnreadCount();
        AppController.notificationsService.onUnreadChanged(this.setUnreadCount);
    }

    componentWillUnmount() {
        AppController.notificationsService.offUnreadChanged(this.setUnreadCount);
    }

    render() {
        const { onClick } = this.props;
        const { unreadCount } = this.state;

        return (
            <NotificationsLabelButton onClick={onClick}>
                <Icon>notifications</Icon>
                {unreadCount > 0 && <span
                    className={`countLabel ${unreadCount > 99 ? "small" : ""}`}>{unreadCount > 999 ? ">1k" : unreadCount}</span>}
            </NotificationsLabelButton>
        );
    }
}

const IframeContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: calc(56.25% + 40px);
`;

const StyledIframe = styled.iframe`
    position: absolute;
    width: 100%;
    height: 100%;
    border: solid 1px #333;
`;

const StyledDialogTitle = styled(DialogTitle)`
    text-align: right;
    padding: 5px !important;

    span {
        cursor: pointer;
    }
`;

class IntroToWorkspaceDialog extends Component {
    handleClose = () => {
        this.props.callback();
        this.props.closeDialog();
    }

    render() {
        return (
            <BeautifulDialog closeDialog={this.handleClose} maxWidth="lg">
                <StyledDialogTitle>
                    <Icon onClick={this.handleClose}>
                        close
                    </Icon>
                </StyledDialogTitle>
                <DialogContent>
                    <IframeContainer>
                        <StyledIframe
                            allowFullScreen
                            src="https://www.beautiful.ai/embed/-MO3t5X1C-tKv-GW1KGf?utm_source=beautiful_player&utm_medium=embed&utm_campaign=-MNPpk-zS039hjt8SWVz"
                        />
                    </IframeContainer>
                </DialogContent>
            </BeautifulDialog>
        );
    }
}

class PresentationLibrary extends Component {
    constructor(props) {
        super(props);

        const librarySettings = app.user.getLibrarySettings();

        this.state = {
            type: props.view ?? LibraryView.LIBRARY,
            showSidebar: false,
            sideBarWidth: librarySettings.sideBarWidth || 260,
        };

        this.ref = React.createRef();

        if (!props.workspaceId) {
            throw new Error("workspaceId must be present");
        }

        this.debouncedSaveSideBarWidth = _.debounce(sideBarWidth => {
            app.user.update({
                librarySettings: { sideBarWidth }
            });
        }, 1000);
    }

    handleClickLogo = () => {
        UIController.gotoMarketingPage();
    }

    handleShowNotifications = () => {
        AppController.showAccountPane({ pane: "notifications" });
    }

    componentDidMount() {
        const { pane, subFolderId, folderId } = this.props;
        // If there's gDriveFolderId specified, then we've been redirected from GDrive
        // so we need to create a new presentation in the specified folder
        const gDriveFolderId = new URL(window.location).searchParams.get("gDriveFolderId");
        if (gDriveFolderId) {
            PresentationLibraryController.handleCreateNewPresentation({
                pane: CreatePresentationDialogPaneType.BLANK_PRESENTATION,
                libraryFilter: {
                    type: pane,
                    folderId,
                    subFolderId
                }
            });
            return;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.view != prevProps.view) {
            this.setState({ type: this.props.view });
        }
    }

    handleContextMenu = event => {
        event.preventDefault();
    }

    handleClickEarnCredit() {
        AppController.showAccountPane({ pane: "referrals" });
    }

    handleSwitchType = type => {
        switch (type) {
            case LibraryView.LIBRARY:
                AppController.showLibrary();
                break;
            case LibraryView.TEAM_RESOURCES:
                AppController.showTeamResources();
                trackActivity("TeamResources", "Viewed");
                break;
            case LibraryView.MY_RESOURCES:
                AppController.showMyResources();
                trackActivity("MyResources", "Viewed");
                break;
        }
    }

    handleResizeSideBar = width => {
        width = Math.clamp(width, 200, 400);
        this.setState({ sideBarWidth: width });
        this.debouncedSaveSideBarWidth(width);
    }

    handleMouseEnterNewDocumentButton = () => {
        this.setState({
            showCreateMenu: "on",
        });
    }

    handleMouseLeaveNewDocumentButton = async () => {
        this.setState({ showCreateMenu: "timeout" });
        await delay(600);
        if (this.state.showCreateMenu == "timeout") {
            this.setState({ showCreateMenu: null });
        }
    }

    handleCreateNewPresentation = pane => {
        //const { filter } = this.state;
        const { pane: sideBarPane, subFolderId, folderId } = this.props;

        // If we are trying to create a presentation from the team folder
        if (sideBarPane === PresentationFilters.TEAM_FOLDER) {
            const defaultTeam = ds.teams.defaultTeamForOrg(AppController.orgId);
            const teamTheme = ds.sharedThemes.findWhere({ id: defaultTeam.get("sharedThemeId") });

            // Create a dummy presentation to pass to the dialog
            this.dummyPresentation = new Presentation({
                name: "Team Slide",
                isSlideEditor: true,
                userId: app.user.id,
                orgId: AppController.orgId,
                themeId: teamTheme.id,
                sharedThemeId: teamTheme.id,
                isDummy: true
            }, { disconnected: true, userId: app.user.id });
            this.dummyPresentation.permissions.write = true;
            this.dummyPresentation.permissions.read = true;

            let folder = ds.teams.get(folderId);

            // hide the menu
            this.setState({
                showCreateMenu: null,
            });

            ShowDialog(AddPresentationToFolderDialog, {
                isDummy: true,
                folder,
                presentations: [{
                    presentationModel: this.dummyPresentation
                }], callback: permission => {
                    PresentationLibraryController.handleCreateNewPresentation({
                        pane,
                        libraryFilter: {
                            type: sideBarPane,
                            subFolderId,
                            folderId,
                            // make sure we remember the folder permissions we just set
                            permission
                        }
                    });
                }
            });
        } else {
            PresentationLibraryController.handleCreateNewPresentation({
                pane,
                libraryFilter: {
                    type: sideBarPane,
                    subFolderId,
                    folderId,
                }
            });
        }
    }

    render() {
        const { type, showSidebar, sideBarWidth, showCreateMenu } = this.state;
        const { workspaceId, pane, subFolderId, folderId, collection, view } = this.props;

        const designerBotDisabled = app.user.features.isFeatureEnabled(FeatureType.PROHIBIT_GENERATIVE_AI, AppController.workspaceId) ||
            !app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_INSPIRATION_SLIDES, AppController.workspaceId);
        const designerBotAccessible = app.user.features.isFeatureEnabled(FeatureType.DESIGNER_BOT, AppController.workspaceId);
        const pptImportAccessible = app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_IMPORT_PPT, AppController.workspaceId);
        const starterTemplateAccessible = app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_INSPIRATION_SLIDES, AppController.workspaceId);

        const headerDesktop = (
            <LibraryHeaderContainer className="library-header">
                <LogoBox className="logo-box" onClick={this.handleClickLogo} style={{ width: sideBarWidth }}>
                    <img className="logo-desktop"
                        src={getStaticUrl("/images/beautifulai-logos/beautifulai-mark-reverse.svg")} />
                </LogoBox>

                <FlexBox fillWidth left fillHeight>
                    <NewDocumentButtonContainer
                        onMouseEnter={this.handleMouseEnterNewDocumentButton}
                        onMouseLeave={this.handleMouseLeaveNewDocumentButton}>
                        {view === LibraryView.LIBRARY && <StyledBlueButton
                            id="new-presentation-button"
                            onClick={() => this.handleCreateNewPresentation(CreatePresentationDialogPaneType.BLANK_PRESENTATION)}
                        >
                            <Icon>add_circle</Icon>Create presentation...
                        </StyledBlueButton>}
                        <Transition
                            in={!!showCreateMenu}
                            timeout={300}
                        >
                            {state => (
                                <PopupMenuMask state={state}>
                                    <CreatePopupMenu state={state}>
                                        <CreatePopupMenuItem
                                            onClick={() => this.handleCreateNewPresentation(CreatePresentationDialogPaneType.BLANK_PRESENTATION)}>
                                            <Icon>add_circle</Icon>Blank Presentation
                                        </CreatePopupMenuItem>
                                        {!designerBotDisabled &&
                                            <CreatePopupMenuItem onClick={() => this.handleCreateNewPresentation(CreatePresentationDialogPaneType.GENERATE_WITH_AI)} >
                                                <div>
                                                    <DesignerBotIcon style={{
                                                        width: 18,
                                                        height: 18,
                                                        fill: themeColors.mediumGray,
                                                    }} width={18} height={18} color={themeColors.mediumGray} />
                                                </div>
                                                Generate with AI
                                                <ProBadge
                                                    upgradeType={UpgradePlanDialogType.UPGRADE_PLAN}
                                                    show={!designerBotAccessible}
                                                    analytics={{ cta: "PresentationBot", ...ds.selection?.presentation?.getAnalytics() }}
                                                    workspaceId={AppController.workspaceId}
                                                />
                                            </CreatePopupMenuItem>
                                        }
                                        <CreatePopupMenuItem onClick={() => this.handleCreateNewPresentation(CreatePresentationDialogPaneType.TEAM_TEMPLATES)}>
                                            <Icon>video_library</Icon>From Team Template
                                            <ProBadge
                                                upgradeType={UpgradePlanDialogType.TEAM_NOOP}
                                                show={!app.user.features.isFeatureEnabled(FeatureType.VIEW_LIBRARY_ITEMS, AppController.workspaceId)}
                                                analytics={{ cta: "TeamTemplates" }}
                                                workspaceId={AppController.workspaceId}
                                            />
                                        </CreatePopupMenuItem>
                                        {starterTemplateAccessible &&
                                            <CreatePopupMenuItem
                                                onClick={() => this.handleCreateNewPresentation(CreatePresentationDialogPaneType.STARTER_TEMPLATES)}>
                                                <Icon>lightbulb</Icon>From Starter Template
                                            </CreatePopupMenuItem>
                                        }
                                        {pptImportAccessible && (
                                            <CreatePopupMenuItem
                                                onClick={() => this.handleCreateNewPresentation(CreatePresentationDialogPaneType.IMPORT_PPT)}>
                                                <Icon>upload</Icon>Import PPT
                                            </CreatePopupMenuItem>
                                        )}
                                    </CreatePopupMenu>
                                </PopupMenuMask>
                            )}
                        </Transition>
                    </NewDocumentButtonContainer>
                    <FlexSpacer />
                    <FlexBox fillHeight middle right style={{ paddingRight: 20 }}>
                        {/* {workspaceId == "personal" && (
                            <EarnCreditButton onClick={this.handleClickEarnCredit}/>
                        )} */}
                        <Gap20 />
                        {app.user.features.isFeatureEnabled(FeatureType.UPGRADE, workspaceId) && (
                            <UpgradeButton workspaceId={workspaceId} />
                        )}
                        <NotificationsLabel onClick={this.handleShowNotifications} />
                        <HelpButton source="library" />
                        <Gap10 />
                        <UserMenu canSwitchWorkspace />
                    </FlexBox>
                </FlexBox>
            </LibraryHeaderContainer>
        );
        const menuIcon = (showSidebar
            ? "navigate_before"
            : "menu"
        );
        const headerMobile = (
            <LibraryHeaderContainer className="library-header">
                <FlexBox
                    fillHeight
                    fillWidth
                    horizontalAlign="between"
                    style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                    }}
                >
                    <FlexBox fillHeight middle onClick={() => {
                        this.setState({ showSidebar: !showSidebar });
                    }}>
                        <i className="micon">{menuIcon}</i>
                    </FlexBox>

                    {
                        !app.integrator &&
                        <LogoBox
                            className="logo-box"
                            onClick={this.handleClickLogo}
                        >
                            <img className="logo-mobile"
                                src={getStaticUrl("/images/beautifulai-logos/beautifulai-logo-reverse.svg")} />
                        </LogoBox>
                    }
                    {
                        app.integrator &&
                        <IntegratorMessage>Select a presentation to embed or create a new one.</IntegratorMessage>
                    }

                    <UserMenu canSwitchWorkspace />
                </FlexBox>
            </LibraryHeaderContainer>
        );
        const header = app.isConstrained ? headerMobile : headerDesktop;
        return (
            <ThemeProvider theme={dialogTheme}>
                <PresentationLibraryContainer onContextMenu={this.handleContextMenu}>
                    {header}
                    {type == LibraryView.LIBRARY &&
                        <PresentationLibraryPane
                            {...this.props}
                            ref={this.ref}
                            subFolderId={subFolderId}
                            pane={pane}
                            folderId={folderId}
                            workspaceId={workspaceId}
                            collection={collection}
                            showWorkspaceChooser
                            onSwitchType={this.handleSwitchType}
                            showSidebar={showSidebar}
                            sideBarWidth={sideBarWidth}
                            onResizeSidebar={this.handleResizeSideBar}
                            createNewPresentation={() => PresentationLibraryController
                                .handleCreateNewPresentation({
                                    pane: CreatePresentationDialogPaneType.BLANK_PRESENTATION, libraryFilter: {
                                        type: pane,
                                        folderId,
                                        subFolderId
                                    }
                                })}
                            onHideSidebar={() => this.setState({ showSidebar: false })}
                        />
                    }
                    {type == LibraryView.TEAM_RESOURCES &&
                        <TeamResources
                            sideBarWidth={sideBarWidth}
                            pane={this.props.pane}
                            onSwitchType={this.handleSwitchType}
                            onResizeSidebar={this.handleResizeSideBar}
                        />
                    }
                    {type == LibraryView.MY_RESOURCES &&
                        <MyResources
                            sideBarWidth={sideBarWidth}
                            pane={this.props.pane}
                            onSwitchType={this.handleSwitchType}
                            onResizeSidebar={this.handleResizeSideBar}
                        />
                    }

                </PresentationLibraryContainer>
            </ThemeProvider>
        );
    }
}

export class LibraryViewSelect extends Component {
    render() {
        let { libraryView, onSwitchType } = this.props;

        let canEditLibraryItems = !app.integrator && app.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, AppController.orgId);// && !readOnly;

        return (
            <>
                <Select id="libraryType" value={libraryView} onChange={event => onSwitchType(event.target.value)}
                    disableUnderline>
                    <MenuItem value={LibraryView.LIBRARY}>My Presentations</MenuItem>
                    <MenuItem value={LibraryView.MY_RESOURCES}>My Resources</MenuItem>
                    {canEditLibraryItems && <MenuItem value={LibraryView.TEAM_RESOURCES}>Team Resources</MenuItem>}
                </Select>
                <Divider margin={10} />
            </>
        );
    }
}

const SubFolderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: 108px;
    overflow-y: auto;
    margin-bottom: 10px;
    gap: 5px;
    row-gap: 5px;
`;

const SubFolder = styled.div`
    background: ${props => props.isSelected ? "white" : "#e1e1e1"};
    padding: 10px 15px 10px 10px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
        font-weight: 400;
    }

    .MuiIcon-root {
        opacity: 0.5;
    }

    &:hover {
        background: ${themeColors.selection};
    }
`;

export class PresentationLibraryPane extends Component {
    constructor(props) {
        super(props);

        this.sideBarRef = React.createRef();
        this.scrollRef = React.createRef();

        const librarySettings = app.user.getLibrarySettings();

        this.fetchSearchResultsThrottled = _.throttle(this.fetchSearchResults, SearchThrottleWait, { leading: false });

        this.state = {
            hadFirstRender: false,
            presentations: [],
            filteredPresentations: [],
            draggedItems: [],
            userFolders: [],
            teamFolders: [],
            isEveryTeamFolderLoaded: false,
            filter: {
                type: props.pane ?? PresentationFilters.RECENT,
                folderId: props.folderId,
                subFolderId: props.subFolderId
            },
            viewType: librarySettings.viewType,
            sort: {
                field: librarySettings.sortField,
                reverse: librarySettings.sortReverse
            },
            searchResults: null,
            // prevWorkspaceId: props.workspaceId,
        };
    }

    onPresentationsChanged = async presentations => {
        if (presentations.collection) {
            presentations = presentations.collection;
        }
        await this.filterPresentations({ presentations });
    }

    onPresentationsUpdated = _.debounce(presentations => {
        if (!this.state.hadFirstRender) {
            const current = presentations.length;
            const total = Object.keys(presentations.references.attributes).length - 1;
            if (current != total) {
                this.setState({ isLoadingText: `${(current / total * 100).toFixed()}%` });
            }
        } else {
            this.onPresentationsChanged(presentations);
        }
    }, 50)

    onUserFoldersChanged = userFolders => {
        if (userFolders.collection) {
            userFolders = userFolders.collection;
        }
        this.setState({ userFolders });
    }

    componentDidMount() {
        this.loadWorkspace();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.workspaceId !== this.props.workspaceId) {
            this.presentationLibraryDS.unsubscribe();
            this.teamFolderDS.unsubscribe();
            this.loadWorkspace();

            this.setState({
                type: "presentations"
            });
        }

        if (this.props.pane !== prevProps.pane || this.props.folderId !== prevProps.folderId || this.props.subFolderId !== prevProps.subFolderId) {
            this.filterPresentations({
                filter: {
                    type: this.props.pane,
                    folderId: this.props.folderId,
                    subFolderId: this.props.subFolderId
                }
            });
        }
    }

    loadWorkspace() {
        this.presentationLibraryDS = new PresentationLibraryDataService({
            onPresentationsUpdated: this.onPresentationsUpdated,
            onPresentationsChanged: this.onPresentationsChanged,
            onUserFoldersChanged: this.onUserFoldersChanged,
            onTeamFoldersChanged: this.onTeamFoldersChanged
        });

        this.presentationLibraryDS.loadPresentationModels().then(() => {
            this.setState({ hadFirstRender: true, isLoadingText: "" });
        });

        this.teamFolderDS = new TeamFoldersDataService({
            onCollectionChanged: teamFolders => {
                // Remove the default folder if we aren't flagged to have access
                if (!app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_ALL_TEAM_MEMBERS_FOLDER, AppController.workspaceId)) {
                    teamFolders = teamFolders.filter(folder => !folder.isDefault);
                }
                this.setState({ teamFolders, isEveryTeamFolderLoaded: true });
            }
        });
    }

    componentWillUnmount() {
        this.presentationLibraryDS.unsubscribe();
        this.teamFolderDS.unsubscribe();
    }

    saveUserSettings() {
        let { viewType, sort } = this.state;
        // do not persist the sort setting "relevance" - this is set in the UI when the user is searching only.
        if (sort.field !== "relevance") {
            app.user.update({
                librarySettings: { viewType, sortField: sort.field, sortReverse: sort.reverse }
            });
        }
    }

    get sortOptions() {
        const { search } = this.state;
        const options = [{
            value: "name", label: "Name", reverse: false, icon: <SortAlphabeticalVariant />
        }, {
            value: "createdAt", label: "Recently Created", reverse: true, icon: <CalendarRange />
        }, {
            value: "modifiedAt", label: "Recently Edited", reverse: true, icon: <Update />
        }];
        if (search) {
            options.push({
                value: "relevance", label: "Relevance", reverse: false, icon: <CloudSearch />
            });
        }
        return options;
    }

    handleSetViewType = viewType => {
        this.setState({ viewType });
        _.defer(() => this.saveUserSettings());
    }

    fetchSearchResults = async query => {
        const { workspaceId } = this.props;

        if (!query || !query.length) {
            // if there was a query, the sort may have been set to "relevance"
            // here we revert back to the persisted setting
            const librarySettings = app.user.getLibrarySettings();
            const sort = {
                field: librarySettings.sortField,
                reverse: librarySettings.sortReverse
            };
            this.filterPresentations({ searchResults: null, sort });
            return;
        }

        const { results: searchResults } = await appSearch.search({
            workspaceId,
            searchEngine: appSearch.SearchEngine.USER_SLIDES,
            query,
            page: { size: 500 },
            resultFields: { presentation_id: { raw: {} } }
        });

        // when searching, initially we should set the sort to "relevance" but allow the user to override
        const sort = {
            field: "relevance",
            reverse: false
        };
        this.filterPresentations({ searchResults, sort, filter: { type: PresentationFilters.ALL_PRESENTATIONS } });
    }

    handleSearch = search => {
        this.setState({ search });
        this.fetchSearchResultsThrottled(search);
    }

    filterPresentations = async (stateDelta = {}) => {
        const state = {
            ...this.state,
            ...stateDelta,
        };

        const {
            searchResults,
            sort,
            filter,
            presentations,
        } = state;

        const { isNewWorkspace } = this.props;

        const filteredPresentations = await PresentationLibraryController.getFilteredPresentations(presentations, {
            filter,
            searchResults,
            sort,
            isNewWorkspace,
        });

        this.setState({
            filteredPresentations,
            ...stateDelta,
        });
    }

    handleSort = async sort => {
        await this.filterPresentations({ sort });
        _.defer(() => this.saveUserSettings());
    }

    handleSetFilter = filter => {
        const { readOnly } = this.props;

        // if we are in read-only (We're adding a slide from My Slide sectio ) mode
        // we should just update the filter state because we
        // don't want to navigate away from the current page
        if (readOnly) {
            this.filterPresentations({ filter });
        } else {
            AppController.showLibrary({ filter });
        }
    }

    handleSelectedPresentation = async selection => {
        if (this.props.onSelectedPresentation) {
            this.props.onSelectedPresentation(selection[0]);
        } else if (app.isConstrained) {
            await this.handleDoubleClickedPresentation(selection[0]);
        }
    }

    handleDoubleClickedPresentation = async ({ id }) => {
        // take the id from this view's representation of a presentation and fetch backbone model with updated permissions
        const presentation = await ds.presentations.getPresentation(id, "write");
        if (presentation.get("softDeletedAt") != null) {
            return ShowErrorDialog({
                title: "Can't edit presentations in the trash",
                message: "Please restore this presentation back into your library before editing."
            });
        }

        if (!presentation.permissions.write || app.isConstrained || window.roomID) {
            PresentationLibraryController.playPresentation(presentation);
        } else {
            PresentationLibraryController.editPresentation({ presentation });
        }
    }

    handleStartDragItems = selection => {
        this.setState({ draggedItems: selection });
    }

    handleDropDragItems = (selection, event) => {
        this.setState({ draggedItems: [] });
    }

    handleSubFolderMouseUp = subFolderId => {
        if (this.state.draggedItems?.length) {
            TeamFoldersController.addPresentationsToTeamSubFolder(
                this.state.draggedItems,
                this.state.filter.folderId,
                subFolderId,
                () => {
                    this.handleSetFilter({
                        type: PresentationFilters.TEAM_FOLDER,
                        folderId: this.state.filter.folderId,
                        subFolderId
                    });
                }
            );
        } else {
            this.handleSetFilter({
                type: PresentationFilters.TEAM_FOLDER,
                folderId: this.state.filter.folderId,
                subFolderId
            });
        }
    }

    render() {
        const {
            workspaceId,
            readOnly,
            onCloseDialog,
            showCloseIcon,
            showSidebar,
            createNewPresentation,
            onHideSidebar,
            onSwitchType,
            sideBarWidth,
            onResizeSidebar,
            className,
            showLibraryViewSelect = true
        } = this.props;
        const {
            hadFirstRender,
            isLoadingText,
            viewType,
            userFolders,
            teamFolders,
            isEveryTeamFolderLoaded,
            filter,
            draggedItems,
            sort
        } = this.state;

        if (!hadFirstRender) {
            return (<UIPane>
                <Spinner label={isLoadingText} />
            </UIPane>);
        }

        let subFolders = [];
        let breadcrumb;
        switch (filter.type) {
            case PresentationFilters.ALL_PRESENTATIONS:
                breadcrumb = "All Presentations";
                break;
            case PresentationFilters.RECENT:
                breadcrumb = "Recent Presentations";
                break;
            case PresentationFilters.OWNED_BY_ME:
                breadcrumb = "Created by me";
                break;
            case PresentationFilters.SHARED_WITH_ME:
                breadcrumb = "Shared With Me";
                break;
            case PresentationFilters.FOLDER:
                breadcrumb = userFolders.find(f => f.id == filter.folderId)?.get("name");
                break;
            case PresentationFilters.TEAM_FOLDER:
                let team = ds.teams.find(t => t.id == filter.folderId);
                if (team) {
                    breadcrumb = team.get("name");
                    subFolders = _.sortBy(team.get("subFolders") || [], subFolder => subFolder.name);
                    const subFolder = subFolders.findById(filter.subFolderId);
                    if (subFolder) {
                        breadcrumb += " > " + subFolder.name;
                    }
                }
                break;
            case PresentationFilters.TRASH:
                breadcrumb = "Trash";
                break;
        }

        let canEditLibraryItems = !app.integrator && app.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, AppController.orgId) && !readOnly;

        const trashHeader = () => (
            filter.type == PresentationFilters.TRASH &&
            <FlexBox middle right fillWidth style={{ paddingRight: 40 }}>
                <StyledWarningIcon>warning</StyledWarningIcon>
                <Gap10 />
                <WarningBox>
                    Items in your trash will be permanently deleted after 30 days
                </WarningBox>
                <Gap20 />
                <WarningButton
                    onClick={PresentationLibraryController.emptyTrashNow}>
                    Empty Trash
                </WarningButton>
            </FlexBox>
        );

        return (
            <UIPaneWithSideBar className={className ?? ""}>
                <UIPaneSideBar
                    show={showSidebar}
                    onHide={onHideSidebar}
                    style={{ width: sideBarWidth }}
                    onResize={onResizeSidebar}
                >
                    {showLibraryViewSelect && <LibraryViewSelect libraryView={LibraryView.LIBRARY} onSwitchType={onSwitchType} />}
                    <PresentationLibrarySideBar
                        workspaceId={workspaceId}
                        filter={filter}
                        onSetFilter={this.handleSetFilter}
                        userFolders={userFolders}
                        teamFolders={teamFolders}
                        isEveryTeamFolderLoaded={isEveryTeamFolderLoaded}
                        readOnly={readOnly}
                        draggedItems={draggedItems}
                        canEditLibraryItems={canEditLibraryItems}
                    />
                </UIPaneSideBar>

                {
                    !app.isMobileOrTablet &&
                    <UIPaneContents style={{
                        paddingLeft: 30,
                        paddingRight: 10,
                        paddingBottom: 0
                    }}>
                        {
                            showCloseIcon &&
                            <CloseButtonContainer>
                                <Icon onClick={onCloseDialog}>
                                    close
                                </Icon>
                            </CloseButtonContainer>
                        }

                        <PresentationFilterContext.Provider value={filter}>
                            {
                                app.integrator &&
                                <>
                                    <Gap20 />
                                    <CreatePresentationButtonIntegrator
                                        id="new-presentation-button"
                                        variant="text"
                                        onClick={createNewPresentation}
                                    >
                                        <Icon>add</Icon>Create New Presentation
                                    </CreatePresentationButtonIntegrator>
                                </>
                            }
                            {window.roomID &&
                                <RemoteControlBanner>Select Presentation to Remote Play</RemoteControlBanner>
                            }
                            <FlexBox left gap={20} middle style={{ height: 65 }}>
                                <Breadcrumb className="breadcrumb">{breadcrumb}</Breadcrumb>
                                {
                                    filter.type != PresentationFilters.TRASH &&
                                    <>
                                        <FlexSpacer />
                                        <PresentationListHeader
                                            onSearch={this.handleSearch}
                                            viewType={viewType}
                                            onSetViewType={this.handleSetViewType}
                                            sortOptions={this.sortOptions}
                                            onSetSort={this.handleSort}
                                            sort={sort}
                                            searchPlaceholder=""
                                        />
                                    </>
                                }
                                {trashHeader()}
                            </FlexBox>
                            {subFolders.length > 0 &&
                                <SubFolderContainer>
                                    {filter.subFolderId &&
                                        <IconButton size="small"
                                            onMouseUp={() => this.handleSubFolderMouseUp(null)}><Icon>arrow_upward</Icon></IconButton>
                                    }
                                    {subFolders.map(subFolder => (
                                        <SubFolder
                                            key={subFolder.id}
                                            onMouseUp={() => this.handleSubFolderMouseUp(subFolder.id)}
                                            isSelected={filter.subFolderId === subFolder.id}
                                        >
                                            <Icon>folder_open</Icon>
                                            <Gap10 />
                                            {subFolder.name}
                                            <Gap5 />
                                            <span>({subFolder.presentations?.length ?? 0})</span>
                                        </SubFolder>)
                                    )}
                                </SubFolderContainer>
                            }
                            <ScrollBox id="presentation_list" ref={this.scrollRef}
                                fillWidth
                                style={{ height: 0, flexGrow: 2, marginTop: 5, paddingRight: 10 }}
                            >
                                {this.renderItems()}
                            </ScrollBox>
                        </PresentationFilterContext.Provider>
                    </UIPaneContents>
                }
                {
                    app.isMobileOrTablet &&
                    <UIPaneContents style={{ padding: 0 }}>
                        {
                            showCloseIcon &&
                            <CloseButtonContainer>
                                <Icon onClick={onCloseDialog}>
                                    close
                                </Icon>
                            </CloseButtonContainer>
                        }

                        {window.roomID &&
                            <RemoteControlBanner>Select Presentation to Remote Play</RemoteControlBanner>
                        }

                        <PresentationFilterContext.Provider value={filter}>
                            <FlexBox center middle>
                                {
                                    filter.type != PresentationFilters.TRASH &&
                                    <>
                                        <PresentationListHeader
                                            onSearch={this.handleSearch}
                                            viewType={viewType}
                                            onSetViewType={this.handleSetViewType}
                                            sortOptions={this.sortOptions}
                                            onSetSort={this.handleSort}
                                            sort={sort}
                                            searchPlaceholder=""
                                        />
                                    </>
                                }
                                {trashHeader()}
                            </FlexBox>
                            <FlexBox middle style={{
                                margin: 20,
                                marginTop: 0,
                                marginBottom: 0,
                            }}>
                                <Breadcrumb className="breadcrumb" style={{
                                    width: "100%",
                                    marginRight: 0,
                                }}>{breadcrumb}</Breadcrumb>
                            </FlexBox>
                            <ScrollBox id="presentation_list" ref={this.scrollRef}
                                fillWidth
                                style={{ height: 0, flexGrow: 2, marginTop: 0, paddingRight: 10, paddingLeft: 10 }}
                            >
                                {this.renderItems()}
                                <Gap30 />
                            </ScrollBox>
                        </PresentationFilterContext.Provider>
                    </UIPaneContents>
                }
                <ShortcutPanel />
            </UIPaneWithSideBar>
        );
    }

    renderItems() {
        const { readOnly } = this.props;
        const {
            search,
            sort,
            filter,
            viewType,
            filteredPresentations,
        } = this.state;

        if (filteredPresentations.length == 0) {
            switch (filter.type) {
                case PresentationFilters.ALL_PRESENTATIONS:
                case PresentationFilters.OWNED_BY_ME:
                case PresentationFilters.RECENT:
                    //currently readOnly is set to true when viewing presentation library from CopySlidePane.
                    if (readOnly || search) {
                        return <NoMatchNotice />;
                    } else {
                        if (app.isConstrained) {
                            return (
                                <Notice
                                    title="You have no presentations in this workspace."
                                    message="Open Beautiful.ai in your desktop browser to create one."
                                />
                            );
                        } else {
                            return (
                                <Notice
                                    title="Click Create Presentation to get started."
                                    message={
                                        <>
                                            <span>New to Beautiful.ai? </span>
                                            <a
                                                onClick={() => AppController.showTour()}
                                            >
                                                Take the Tour
                                            </a>
                                        </>

                                    }
                                />
                            );
                        }
                    }
                case PresentationFilters.SHARED_WITH_ME:
                    return (
                        <Notice
                            title="No one has shared a presentation with you."
                            message={(
                                <>
                                    <span>Maybe you should share one of yours? </span>
                                    <br />
                                    <span>
                                        <a target="_blank"
                                            href="https://support.beautiful.ai/hc/en-us/articles/360000771231-How-can-I-collaborate-with-someone-">Learn more</a> about sharing and collaboration.
                                    </span>
                                </>
                            )}
                        />
                    );
                case PresentationFilters.TRASH:
                    return (
                        <Notice
                            title="Trash is empty"
                        />
                    );
                case PresentationFilters.FOLDER:
                    return (
                        <Notice
                            title="This folder is empty."
                            message={!app.isConstrained && "You can drag presentations into this folder to add them."}
                        />
                    );
                case PresentationFilters.TEAM_FOLDER:
                    return (
                        <Notice
                            image={getStaticUrl(`/images/notice-banner/team-folders.png`)}
                            title="This shared Team Folder is empty."
                            message="Facilitate collaboration by creating shared Team Folders for teams or projects. Add presentations to collaborate with folder members instantly."
                        />
                    );
                default:
                    return (
                        <Notice
                            title="No presentations found"
                        />
                    );
            }
        }

        if (viewType == "grid") {
            return (
                <PresentationLibraryGrid
                    items={filteredPresentations}
                    readOnly={readOnly}
                    onSelectedPresentation={this.handleSelectedPresentation}
                    onDoubleClick={this.handleDoubleClickedPresentation}
                    onStartDragItems={this.handleStartDragItems}
                    onDropDragItems={this.handleDropDragItems}
                />
            );
        } else {
            return (
                <PresentationLibraryList
                    items={filteredPresentations}
                    readOnly={readOnly}
                    sort={sort}
                    onSort={this.handleSort}
                    onSelectedPresentation={this.handleSelectedPresentation}
                    onDoubleClick={this.handleDoubleClickedPresentation}
                    onStartDragItems={this.handleStartDragItems}
                    onDropDragItems={this.handleDropDragItems}
                />
            );
        }
    }
}

export default AppController.withState(
    function PresentationLibraryInitializer(props) {
        const { pane, folderId, teamMemberId, provideAccess } = props;

        useEffect(() => {
            if (pane === PresentationFilters.TEAM_FOLDER) {
                const defaultTeam = ds.teams.defaultTeamForOrg(AppController.orgId);
                const teamFolder = ds.teams.get(folderId);

                // check if the user is member of the team but doesn't have access to the teamFolder
                if (defaultTeam.get("members")[app.user.id] && !teamFolder) {
                    ShowDialog(RequestAccessDialog, { folderId });
                }
            }
        }, [folderId]);

        useEffect(() => {
            if (provideAccess && teamMemberId) {
                ShowDialog(ProvideAccessDialog, { folderId, teamMemberId });
            }
        }, [teamMemberId, provideAccess]);

        return <PresentationLibrary {...props} />;
    }
);
