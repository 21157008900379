import React from "react";
import { v4 as uuid } from "uuid";

import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import Api from "js/core/api";
import { trackActivity } from "js/core/utilities/utilities";
import { ShowDialog, ShowSnackBar } from "js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import { PERMISSION_RESOURCE_TYPE } from "common/constants";
import { FeatureType } from "common/features";
import getLogger from "js/core/logger";
import { PresentationLibraryController } from "js/editor/PresentationLibrary/PresentationLibraryController";
import { Team } from "js/core/models/team";
import { _ } from "js/vendor";
import { UIController } from "js/editor/dialogs/UIController";
import permissionsDS from "js/react/views/PresentationSettings/dataservices/PermissionsDataService";
import AppController from "js/core/AppController";

const logger = getLogger();

const folderCache = {};

export class TeamFoldersDataService {
    constructor(config) {
        this.config = config;
        ds.teams.on("add update change", this.onChange);
        this.onChange();
    }

    onChange = async model => {
        const canManageTeam = app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM, AppController.orgId);
        let teamFolders;
        if (canManageTeam) {
            const orgResponse = await Api.organizations.get({ id: AppController.orgId });
            const teams = Object.keys(orgResponse.teams ?? {});
            teams.push(orgResponse.defaultTeamId);
            teamFolders = await Promise.all(teams.map(async teamId => {
                let cachedFolder = folderCache[teamId];

                if (model?.id === teamId) {
                    // delete from cache and re-fetch below
                    delete folderCache[teamId];
                } else if (cachedFolder) {
                    // if the folder wasn't updated, cached version should still be good
                    return cachedFolder;
                }

                let folder = await Api.teams.get({ id: teamId }).then(response => response.body);
                folder.presentationCount = (folder.sharedResources && folder.sharedResources[0]) ? Object.keys(folder.sharedResources[0]).length : 0;
                folder.memberCount = folder.members.length;
                folderCache[teamId] = folder;
                return folder;
            }));
        } else {
            teamFolders = ds.teams.filter(folder => folder.get("orgId") === AppController.orgId).map(folder => folder.attributes).map(folder => {
                return {
                    ...folder,
                    presentationCount: (folder.sharedResources && folder.sharedResources[0]) ? Object.keys(folder.sharedResources[0]).length : 0,
                    memberCount: Object.keys(folder.members || {}).length
                };
            });
        }
        this.config.onCollectionChanged(teamFolders);
    }

    unsubscribe() {
        ds.teams.off("add update change", this.onChange);
    }
}

export class TeamFoldersController {
    /**
     * Triggers a change event on the ds.teams collection specifying the changed folder,
     * suposed to be used when the change was made to an api-served folder via the api.
     */
    static _triggerFolderChange = folderId => {
        ds.teams.trigger("change", { id: folderId });
    };

    static getAllTeamMembersFolder = () => {
        return ds.teams.find(teamFolder => teamFolder.get("isDefault") && teamFolder.get("orgId") == UIController.getOrganizationId());
    }

    static createTeamFolder = async (name, addCurrentUser) => {
        const { body: folder } = await Api.teams.post({ teamName: name, orgId: AppController.orgId });

        const props = {
            workspace_id: AppController.orgId,
            team_id: folder.id,
            team_name: name
        };
        trackActivity("OrgTeam", "Created", null, null, props);

        if (addCurrentUser) {
            await Api.teams.put({
                teamId: folder.id,
                orgId: AppController.orgId,
                type: "add_existing_members",
                userIds: [app.user.id]
            });
        }

        let folderModel = new Team({ id: folder.id });
        await folderModel.load();

        return folderModel.toJSON();
    }

    static getPresentationPermissionsForFolder = async (folderId, presentationIds) => {
        let presentationPermissions = await Promise
            .all(presentationIds.map(presentationId => permissionsDS
                .getPermissions(presentationId, [folderId])
                .then(perms => {
                    let perm = perms.find(x => (
                        x.level === "team" &&
                        x.id === folderId
                    ));
                    return {
                        id: presentationId,
                        type: perm?.type,
                    };
                })
            ));
        // Convert the array to a key:value map
        let results = presentationPermissions
            .reduce((res, { id, type }) => ({
                [id]: type,
                ...res,
            }), {});
        return results;
    }

    static getTeamMembersForFolder = async folderId => {
        const response = await Api.teams.get({ id: folderId });
        const teamInvitesResponse = await Api.teamInvites.get({
            type: "team",
            id: folderId
        });

        let allMembers = response.body.members.concat(teamInvitesResponse.body);
        allMembers = _.sortBy(allMembers, "email");

        return allMembers;
    }

    static deleteTeamFolder = async folder => {
        await Api.teams.delete({
            teamId: folder.id,
            orgId: AppController.orgId
        });
        this._triggerFolderChange(folder.id);

        const props = {
            workspace_id: AppController.orgId,
            team_id: folder.id,
            team_name: folder.name
        };
        trackActivity("OrgFolder", "Deleted", null, null, props);
    }

    static renameTeamFolder = async (folder, name) => {
        if (folder.isDefault) {
            throw new Error("Can't edit default team name");
        }

        // optimally update our in memory model if we have one, otherwise use the API
        const teamFolder = ds.teams.get(folder.id);
        if (teamFolder) {
            teamFolder.update({ name });
        } else {
            await Api.teams.put({ type: "change_team_name", teamId: folder.id, teamName: name });
            this._triggerFolderChange(folder.id);
        }
    }

    static setOnlyOwnerCanEdit = async (folder, onlyOwnerCanEdit) => {
        await Api.teams.put({ type: "set_only_owner_can_edit", teamId: folder.id, onlyOwnerCanEdit });
        this._triggerFolderChange(folder.id);
    }

    static createSubFolder = (folder, name) => {
        const teamFolder = ds.teams.get(folder.id);
        if (teamFolder) {
            let subFolders = _.cloneDeep(teamFolder.get("subFolders")) || [];
            let newFolder = {
                id: uuid(),
                name: name
            };
            subFolders.push(newFolder);
            teamFolder.update({ subFolders });
        }
    }

    static deleteSubFolder = (folderId, subFolderId) => {
        const teamFolder = ds.teams.get(folderId);
        if (teamFolder) {
            let subFolders = _.cloneDeep(teamFolder.get("subFolders")) || [];
            subFolders.remove(subFolders.findById(subFolderId));
            teamFolder.update({ subFolders });
        }
    }

    static renameSubFolder = (folderId, subFolderId, name) => {
        const teamFolder = ds.teams.get(folderId);
        if (teamFolder) {
            let subFolders = _.cloneDeep(teamFolder.get("subFolders")) || [];
            let folder = subFolders.findById(subFolderId);
            folder.name = name;
            teamFolder.update({ subFolders });
        }
    }

    static addPresentationsToTeamSubFolder = async (presentations, teamFolderId, subFolderId, callback) => {
        const teamFolder = ds.teams.get(teamFolderId);
        if (teamFolder) {
            let subFolders = _.cloneDeep(teamFolder.get("subFolders")) || [];
            let folder = subFolders.findById(subFolderId);
            if (folder) {
                if (!folder.presentations) {
                    folder.presentations = [];
                }
                for (let presentation of presentations) {
                    // remove from any previous subfolders
                    for (let subFolder of subFolders) {
                        if (subFolder.presentations) {
                            subFolder.presentations.remove(presentation.id);
                        }
                    }
                    // add to the target subfolder
                    folder.presentations.push(presentation.id);
                    folder.presentations = _.uniq(folder.presentations);
                }
                teamFolder.update({ subFolders });

                let snackbar;
                const action = () => {
                    callback && callback();
                    snackbar.close();
                };

                let message = (<p>
                    <span>{presentations.length}  {(presentations.length > 1) ? " presentations were added to " : " presentation was added to "}</span>
                    <span className="action_item" onClick={action}>{teamFolder.get("name")} {">"} {folder.name}</span>
                </p>);

                snackbar = ShowSnackBar({ message });
            }
        }
    }

    static removePresentationsFromTeamSubFolder = async (presentations, teamFolderId, subFolderId) => {
        const teamFolder = ds.teams.get(teamFolderId);
        if (teamFolder) {
            const subFolders = _.cloneDeep(teamFolder.get("subFolders")) || [];
            for (const subFolder of subFolders) {
                if (subFolder.presentations && subFolder.id === subFolderId) {
                    for (let presentation of presentations) {
                        subFolder.presentations.remove(presentation.id);
                    }
                }
                teamFolder.update({ subFolders });
            }
        }
    }

    static removePresentationsFromAllTeamSubFolders = async (presentations, teamFolderId) => {
        const teamFolder = ds.teams.get(teamFolderId);
        if (teamFolder) {
            let subFolders = _.cloneDeep(teamFolder.get("subFolders")) || [];
            for (let subFolder of subFolders) {
                if (subFolder.presentations) {
                    for (let presentation of presentations) {
                        subFolder.presentations.remove(presentation.id);
                    }
                }
                teamFolder.update({ subFolders });
            }
        }
    }

    static setTemplatePermissions = async (templates, teamFolderId) => {
        const presentationIds = templates.map(selectedPresentation => selectedPresentation.id);

        const progressDialog = ShowDialog(ProgressDialog, {
            title: `Creating template...`,
        });
        const payload = {
            teamId: teamFolderId,
            resourceIds: presentationIds,
            resourceType: "presentations",
            permission: "write",
            type: "create-template"
        };
        try {
            await Api.teamPermissions.put(payload);
        } catch (err) {
            logger.error(err, "Api.teamPermissions.put() failed", payload);
        }
        progressDialog.props.closeDialog();
    }

    static deleteTemplatePermissions = async (templates, teamFolderId) => {
        const presentationIds = templates.map(selectedPresentation => selectedPresentation.id);

        const progressDialog = ShowDialog(ProgressDialog, {
            title: `Deleting template...`,
        });
        const payload = {
            teamId: teamFolderId,
            resourceIds: presentationIds,
            resourceType: "presentations",
            permission: "write",
            type: "delete-template"
        };
        try {
            await Api.teamPermissions.put(payload);
        } catch (err) {
            logger.error(err, "Api.teamPermissions.put() failed", payload);
        }
        progressDialog.props.closeDialog();
    }

    static addPresentationsToTeamFolder = async (presentations, teamFolderId, permission) => {
        //Recover any presentations that are in the trash before sharing them with a team.
        PresentationLibraryController.recoverPresentations(presentations);

        let team = ds.teams.get(teamFolderId);

        const progressDialog = ShowDialog(ProgressDialog, {
            title: `Sharing presentations with ${team.get("name")}...`,
        });
        const presentationIds = presentations.map(selectedPresentation => selectedPresentation.id);
        const payload = {
            teamId: teamFolderId,
            resourceIds: presentationIds,
            permission,
            resourceType: "presentations",
            type: "access"
        };
        try {
            await Api.teamPermissions.put(payload);
            const permissionObj = (permission === "write") ? { write: true, read: true } : { read: true };
            presentationIds.forEach(id => {
                if (!team.has("sharedResources")) {
                    team.update({ "sharedResources": [{ [id]: permissionObj }] });
                } else {
                    team.get("sharedResources")[PERMISSION_RESOURCE_TYPE.PRESENTATION][id] = permissionObj;
                }
            });
            trackActivity("Folder", "PresentationsAdded", null, null, { ids: presentationIds }, { audit: true });
        } catch (err) {
            logger.error(err, "Api.teamPermissions.put() failed", payload);
        }
        progressDialog.props.closeDialog();
    }

    static removePresentationFromTeamFolder = async (presentation, teamFolderId, subFolderId) => {
        const payload = {
            teamId: teamFolderId,
            resourceIds: [presentation.id],
            resourceType: "presentations"
        };
        try {
            await Api.teamPermissions.delete(payload);
            if (subFolderId) {
                await this.removePresentationsFromTeamSubFolder([presentation], teamFolderId, subFolderId);
            }

            // this is a hack so the presentation library updates because we don't have a team folder dataservice
            ds.presentations.trigger("change", ds.presentations);
            trackActivity("Folder", "PresentationRemoved", null, null, { presentation_id: presentation.id }, { audit: true });
        } catch (err) {
            logger.error(err, "Api.teamPermissions.delete() failed", payload);
        }
    }

    static addMembersToTeamFolder = async (folder, userIds) => {
        await Api.teams.put({
            teamId: folder.id,
            orgId: AppController.orgId,
            type: "add_existing_members",
            userIds
        });

        const props = {
            workspace_id: AppController.orgId,
            team_id: folder.id,
            team_name: folder.name,
            userid_added: userIds,
            userid_removed: null,
            num_team_members: (folder.members ? folder.members.length : 0) + userIds.length,
            num_changed: userIds.length,
            change_mechanism: "admin initiated"
        };
        trackActivity("OrgTeam", "MembershipChanged", null, null, props, { audit: true });
    }

    static removeMembersFromTeamFolder = async (folder, userIds, isRemovingSelf) => {
        const teamFolder = ds.teams.get(folder.id);
        if (teamFolder && isRemovingSelf) {
            teamFolder.disconnect();
        }
        await Api.teams.put({
            orgId: AppController.orgId,
            teamId: folder.id,
            type: "remove_users",
            userIds: userIds
        });

        const props = {
            workspace_id: AppController.orgId,
            team_id: folder.id,
            team_name: folder.name,
            userid_added: null,
            userid_removed: userIds,
            num_team_members: folder.members.length - 1,
            num_changed: -1,
            change_mechanism: isRemovingSelf ? "user initiated" : "admin initiated"
        };
        trackActivity("OrgTeam", "MembershipChanged", null, null, props, { audit: true });
    }
}
