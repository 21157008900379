import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import { FeatureType } from "js/core/models/features";

class Quotas {
    static async get(workspaceId) {
        const slideLimit = app.user.features.getFeatureProps(FeatureType.CREATE_SLIDES, workspaceId).limit;
        const slideCount = ds.presentations.getWorkspaceSlideCount(workspaceId);

        return {
            slides: {
                limit: slideLimit,
                count: slideCount
            }
        };
    }
}

export default Quotas;
