import React from "react";
import { GlobalStateController } from "bai-react-global-state";

import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import { _, $ } from "js/vendor";
import Api from "js/core/api";
import { presentations as presentationsApi } from "apis/callables";
import * as geom from "js/core/utilities/geom";
import getLogger, { LogGroup } from "js/core/logger";
import permissionsDS from "js/react/views/PresentationSettings/dataservices/PermissionsDataService";
import { appVersion } from "js/config";
import { getCanvasBundle } from "js/canvas";
import { trackActivity } from "js/core/utilities/utilities";
import ErrorHandler from "js/core/utilities/errorHandler";
import { trackState } from "js/analytics";
import { browserHistory } from "js/react/history";
import { UpdateTemplateVersionDialog } from "js/editor/dialogs/UpdateTemplateVersionDialog";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowDialogAsync,
    ShowInputDialog,
    ShowWarningDialog
} from "js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import { LIBRARY_RECENT_MAX } from "common/constants";
import { CollaborationSlidesLockService } from "js/core/services/collaborationSlidesLock";

const logger = getLogger(LogGroup.EDITOR);

export const PanelType = {
    COLOR: "color",
    LAYOUT: "layout",
    ADD_ELEMENT: "add-element",
    NOTES: "notes",
    VARIATIONS: "variations",
    COMMENTS: "comments",
    VERSION_HISTORY: "version-history",
    ELEMENT: "element",
    ANIMATION: "animation",
    RECORD: "record",
    REWRITE: "rewrite",
    SHORTCUTS: "shortcuts",
    ADVANCE_ON: "advance-on",
    MORE_ACTIONS: "more-actions"
};

const PRE_RENDER_SLIDES_RANGE = 5;

const initialState = {
    isInitialized: false,
    currentSlide: null,
    presentation: null,
    activePanel: null,
    showCanvasControls: true,
    showCanvasControlsLockedState: false,
    showSelectionLayer: true,
    isSingleSlideEditor: false,
    currentCanvasController: null,
    canvasControllers: {},
    currentSlideLockOwner: null,
    allowThemeChange: true,
    popupOpenState: false,
    hidePanels: [],
    slides: [],
    currentTheme: null,
    currentSelectionLayer: null
};

class PresentationEditorController extends GlobalStateController {
    constructor(initialState) {
        super(initialState);

        this.syncSlidesWithPresentationPromiseChain = Promise.resolve();
        this.setCurrentSlidePromiseChain = Promise.resolve();
        this.collaborationSlidesLockService = null;
        this.collaborationSlidesLockServiceSubscription = null;
    }

    get presentation() {
        return this._state.presentation;
    }

    get currentSlide() {
        return this._state.currentSlide;
    }

    get isInitialized() {
        return this._state.isInitialized;
    }

    get isSingleSlideEditor() {
        return this._state.isSingleSlideEditor;
    }

    get activePanel() {
        return this._state.activePanel;
    }

    get elementPanelView() {
        return this._state.elementPanelView;
    }

    canShowSelectionLayer(
        slide = this._state.currentSlide,
        canvasController = this._state.currentCanvasController,
        isSingleSlideEditor = this._state.isSingleSlideEditor
    ) {
        return (!slide?.isLibrarySlide() || isSingleSlideEditor) && !canvasController.isTemplateObsolete;
    }

    _stateDidUpdate(prevState) {
        const { currentSlideLockOwner, currentSlide } = this._state;

        if (prevState.currentSlideLockOwner !== currentSlideLockOwner) {
            if (currentSlideLockOwner) {
                this.closeAllPanels(false);
                this.hideSelectionLayer();
            } else {
                this.showSelectionLayer();
            }
        }

        if (prevState.currentSlide !== currentSlide && currentSlide) {
            this.renderCurrentAndSurroundingSlides();
        }
    }

    async renderCurrentAndSurroundingSlides() {
        const { slides, canvasControllers } = this._state;

        const currentSlideIndex = this.getCurrentSlideIndex();

        // Giving priority to the closest slides to the current one, i.e. current->next->prev->next+1->prev-1->next+2->prev-2->...
        const offsets = _.sortBy(_.range(-PRE_RENDER_SLIDES_RANGE, PRE_RENDER_SLIDES_RANGE).reverse(), i => Math.abs(i));
        const slideIndexes = offsets.map(offset => currentSlideIndex + offset).filter(slideIndex => slideIndex >= 0 && slideIndex < slides.length);

        for (const slideIndex of slideIndexes) {
            const slide = slides[slideIndex];
            const canvasController = canvasControllers[slide.id];
            if (canvasController) {
                await canvasController.renderCanvas()
                    .catch(err => logger.error(err, "[PresentationEditorController] renderCurrentAndSurroundingSlides() error rendering slide", { slideId: slide.id }));
            }

            if (currentSlideIndex !== this.getCurrentSlideIndex()) {
                // Current slide has changed, stop rendering (the new renderCurrentAndSurroundingSlides() call will take over)
                return;
            }
        }
    }

    getCurrentCanvasController() {
        return this._state.currentCanvasController;
    }

    getCanvasControllerForSlide(slide) {
        return this._state.canvasControllers[slide.id];
    }

    getCanvasControllers() {
        return this._state.canvasControllers;
    }

    getPopupOpenState() {
        return this._state.popupOpenState;
    }

    getCanvases() {
        return Object.values(this._state.canvasControllers)
            .filter(c => c.canvas)
            .map(c => c.canvas);
    }

    getCurrentSlideIndex() {
        const { presentation, currentSlide } = this._state;
        return presentation.getSlideIndex(currentSlide.id);
    }

    getCurrentSelectionLayer() {
        return this._state.currentSelectionLayer;
    }

    async reset() {
        logger.info("[PresentationEditorController] reset()");

        const { presentation, currentCanvasController, currentSlide } = this._state;

        if (currentCanvasController) {
            currentCanvasController.removeAsCurrentCanvas();
        }
        if (presentation) {
            presentation.off("change", this._onPresentationChange);
            presentation.off("slidesUpdated", this._onPresentationSlidesUpdated);
        }
        if (currentSlide) {
            currentSlide.off("change", this.onCurrentSlideChange);
        }

        ds.selection.presentation = null;

        app.undoManager.reset();

        if (this.collaborationSlidesLockServiceSubscription) {
            this.collaborationSlidesLockServiceSubscription.unsubscribe();
        }

        if (this.collaborationSlidesLockService) {
            await this.collaborationSlidesLockService.dispose();
            this.collaborationSlidesLockService = null;
        }

        await this._updateState(() => _.cloneDeep(initialState));
    }

    async instantiateCanvasController(presentation, slide) {
        const { CanvasController } = await import(/* webpackMode: "eager" */ "js/editor/PresentationEditor/CanvasController");

        return new CanvasController({
            presentation,
            slide,
            canvasWidth: 1280,
            canvasHeight: 720,
            onCanvasRendered: this.onCanvasRendered
        }, this.collaborationSlidesLockService);
    }

    onCanvasRendered = async canvasController => {
        const { currentCanvasController, showSelectionLayer } = this._state;
        if (showSelectionLayer && currentCanvasController === canvasController) {
            // Reload selection layer
            await this.hideSelectionLayer();
            await this.showSelectionLayer();
        }
    }

    async loadPresentation(presentation, slideIndex = 0, isSingleSlideEditor = false, allowThemeChange = true, hidePanels = []) {
        logger.info("[PresentationEditorController] loadPresentation()", { presentationId: presentation.id, slideIndex, isSingleSlideEditor, allowThemeChange, hidePanels });

        // Force reset state
        await this.reset();

        await this._updateState({
            isSingleSlideEditor,
            allowThemeChange,
            hidePanels
        });

        await presentation.load();

        if (presentation.get("isDummy") !== true && !this.isSingleSlideEditor) {
            this.collaborationSlidesLockService = new CollaborationSlidesLockService(presentation.id);
            await this.collaborationSlidesLockService.initialize();
            this.collaborationSlidesLockServiceSubscription = this.collaborationSlidesLockService.observable.subscribe(({ prev, curr }) => {
                const currentSlideId = this.currentSlide?.id;
                if (!currentSlideId) {
                    return;
                }
                const prevLock = prev[currentSlideId];
                const currLock = curr[currentSlideId];

                if (!prevLock?.isLockedForMe && currLock?.isLockedForMe) {
                    this._updateState({ currentSlideLockOwner: currLock.lockedBy });
                } else if (prevLock?.isLockedForMe && !currLock?.isLockedForMe) {
                    this._updateState({ currentSlideLockOwner: null });
                }
            });
        }

        trackState({ presentationId: presentation.id });

        let recentPresentations = _.clone(app.user.getLibrarySettings().recentPresentations);
        if (recentPresentations.contains(presentation.id)) {
            recentPresentations.remove(presentation.id);
        }
        recentPresentations.push(presentation.id);
        if (recentPresentations.length > LIBRARY_RECENT_MAX) {
            recentPresentations.shift();
        }
        app.user.update({
            librarySettings: { recentPresentations }
        });

        // Immediately start loading slides metadata (if presentation is not dummy)
        this.slidesMetadataLoadPromise =
            presentation.get("isDummy") === true
                ? Promise.resolve({})
                : presentationsApi.getSlidesMetadata({ id: presentation.id });

        ds.selection.presentation = presentation;

        presentation.loadPresentationLinks();

        // legacy - needed to load the PermissionDataService which still uses reactn
        permissionsDS.initializeStore();

        await presentation.prepareSlides(false);

        const theme = await app.themeManager.loadTheme(presentation);

        // create canvas controllers for all the slides
        const canvasControllers = {};
        for (const slide of presentation.slides.models) {
            canvasControllers[slide.id] = await this.instantiateCanvasController(presentation, slide);

            if (presentation.slides.models.indexOf(slide) === slideIndex) {
                // Rendering current slide (so the editor is ready to be used)
                const canvasController = canvasControllers[slide.id];
                try {
                    await canvasController.renderCanvas();
                } catch (err) {
                    logger.error(err, "[PresentationEditorController] error rendering current slide, will retry..", { slideId: slide.id });
                    await new Promise(resolve => setTimeout(resolve, 100)); // Just in case
                    canvasController.renderCanvasPromise = null;
                    await canvasController.renderCanvas();
                }
            }
        }

        await this._updateState({
            presentation,
            // Keeping references to the slides but busting the reference to the array,
            // see below for more details
            slides: [...presentation.slides.models],
            currentTheme: theme,
            canvasControllers
        });

        await this.setCurrentSlideByIndex(slideIndex);

        // legacy - needed to prompt tour to start
        ds.selection.presentation.openPresentation();

        presentation.on("change", this._onPresentationChange);
        presentation.on("slidesUpdated", this._onPresentationSlidesUpdated);

        await this._updateState({ isInitialized: true });
    }

    _onPresentationChange = presentation => {
        this._updateState({ presentation });
    }

    _onPresentationSlidesUpdated = (updatedSlides, options) => {
        // Keeping references to the slides but busting the reference to the array
        // itself so we're mutation safe in cases when slides are added/removed to/from
        // the initial array (e.g. presentation slides)
        this.syncSlidesWithPresentation([...updatedSlides], options);
    }

    async syncSlidesWithPresentation(updatedSlides, { isSlideCreatedFromUserAction } = {}) {
        await new Promise((resolve, reject) => {
            this.syncSlidesWithPresentationPromiseChain = this.syncSlidesWithPresentationPromiseChain
                .then(async () => {
                    const { presentation, slides, canvasControllers } = this._state;

                    const newSlides = [];

                    // Find deleted slides
                    if (_.intersection(slides, updatedSlides).length === 0) {
                        await this.setCurrentSlide(updatedSlides[0], false);
                    } else {
                        let currentSlide = this._state.currentSlide;
                        let reversed = false;
                        // First go forward to find the next slide that exists, if not found, then go backwards
                        while (!updatedSlides.contains(currentSlide)) {
                            const slideIndex = slides.findIndex(slide => slide.id === currentSlide.id) + (reversed ? -1 : 1);
                            if (slideIndex === slides.length) {
                                reversed = true;
                                continue;
                            }
                            currentSlide = slides[slideIndex];
                        }

                        await this.setCurrentSlide(currentSlide, false);
                    }

                    const newControllers = {};
                    for (const slide of updatedSlides) {
                        if (canvasControllers[slide.id]) {
                            // Existing slide
                            newControllers[slide.id] = canvasControllers[slide.id];
                            const canvas = newControllers[slide.id].canvas;
                            const footer = canvas?.layouter?.elements.footer;
                            if (footer) {
                                // Force-refresh footer so the page numbers are updated
                                canvas.refreshElement(footer, false, true, false);
                            }
                        } else {
                            // New slide
                            const canvasController = await this.instantiateCanvasController(presentation, slide);
                            newControllers[slide.id] = canvasController;
                            newSlides.push(slide);
                        }
                    }

                    await this._updateState({
                        slides: updatedSlides,
                        canvasControllers: newControllers,
                    });

                    if (newSlides.length > 0 && isSlideCreatedFromUserAction) {
                        // If the slide was created from a user action, then we need to
                        // update the current slide to the new slide
                        await this.setCurrentSlide(newSlides[0], false);
                    }
                })
                .then(resolve)
                .catch(reject);
        });
    }

    getCurrentSlide() {
        return this._state.currentSlide;
    }

    goNextSlide() {
        return this.setCurrentSlideByIndex(this.getCurrentSlideIndex() + 1);
    }

    goPrevSlide() {
        return this.setCurrentSlideByIndex(this.getCurrentSlideIndex() - 1);
    }

    onCurrentSlideChange = slide => {
        this._updateState({ currentSlide: slide });
    }

    setCurrentSlide(slide, waitForSync = true, isTriggeredByPopState = false) {
        return new Promise((resolve, reject) => {
            this.setCurrentSlidePromiseChain = this.setCurrentSlidePromiseChain
                .then(async () => {
                    const { activePanel, showCanvasControls } = this._state;

                    // if active panel is open, don't allow changing slides
                    // unless we are showing the canvas controls
                    if (activePanel && !showCanvasControls) {
                        return;
                    }

                    if (waitForSync) {
                        // Wait for current sync to finish (if there is any)
                        await this.syncSlidesWithPresentationPromiseChain;
                    }

                    const { slides, presentation, canvasControllers, currentCanvasController, currentSlide, isSingleSlideEditor } = this._state;

                    if (typeof slide === "string") {
                        slide = slides.find(s => s.id === slide);
                        if (!slide) {
                            throw new Error(`Slide with id ${slide} not found`);
                        }
                    }

                    if (currentSlide) {
                        currentSlide.off("change", this.onCurrentSlideChange);
                    }
                    if (currentCanvasController) {
                        currentCanvasController.removeAsCurrentCanvas();
                    }

                    // Get the canvas controller for the slide
                    const canvasController = canvasControllers[slide.id];
                    await canvasController.setAsCurrentCanvas(isSingleSlideEditor);

                    let currentSlideLockOwner = null;
                    if (this.collaborationSlidesLockService) {
                        const lockState = this.collaborationSlidesLockService.getLockState(slide.id);
                        if (lockState?.isLockedForMe) {
                            currentSlideLockOwner = lockState.lockedBy;
                        }
                    }

                    await this._updateState({
                        currentSlide: slide,
                        currentCanvasController: canvasController,
                        currentSlideLockOwner,
                        showSelectionLayer: this.canShowSelectionLayer(slide, canvasController, isSingleSlideEditor)
                    });

                    // Push the slide index to the url
                    const slideIndex = slide.getIndex();

                    if (!isSingleSlideEditor && window.history.pushState && !isTriggeredByPopState) {
                        browserHistory.push(`/${presentation.id}/${slideIndex + 1}`);
                    }

                    // Legacy
                    ds.selection.slide = slide;

                    // Listen for changes to the slide
                    slide.on("change", this.onCurrentSlideChange);
                })
                .then(resolve)
                .catch(reject);
        });
    }

    async setCurrentSlideByIndex(index, waitForSync = true, isTriggeredByPopState = false) {
        const { slides } = this._state;
        index = Math.clamp(index, 0, slides.length - 1);
        await this.setCurrentSlide(slides[index], waitForSync, isTriggeredByPopState);
    }

    async setCurrentSlideById(id) {
        const { slides } = this._state;
        const targetSlide = slides.find(slide => slide.id === id);
        if (!targetSlide) {
            throw new Error(`Slide with id ${id} not found`);
        }
        await this.setCurrentSlide(targetSlide);
    }

    async setCurrentSelectionLayer(selectionLayer) {
        await this._updateState({ currentSelectionLayer: selectionLayer });

        app.mainView.editorView.selectionLayer = selectionLayer;

        // Legacy: refresh the selectionlayer so it shows controls for the primary element
        if (selectionLayer) {
            selectionLayer.refreshSelectionLayer();
        }
    }

    clearElementSelection() {
        ds.selection.element = null;
    }

    async showAddSlideDialog() {
        const { AddSlideContainer } = await import(/* webpackMode: "eager" */ "js/react/views/AddSlide");
        await ShowDialogAsync(AddSlideContainer);
    }

    async duplicateSlide() {
        const { presentation, currentSlide } = this._state;

        const workspaceId = presentation.getWorkspaceId();
        const insertIndex = this.getCurrentSlideIndex() + 1;

        try {
            const [newSlide] = await presentation.duplicateSlide(currentSlide, { insertIndex, isSlideCreatedFromUserAction: true });

            const { slideTemplates: { slideTemplates } } = await getCanvasBundle(newSlide.get("version") ?? appVersion);
            const duplicateSlideCount = 1;
            const eventProps = {
                "slide_ids": [newSlide.get("id")],
                "source_slides": [currentSlide.get("id")],
                "slide_template_names": [slideTemplates[currentSlide.get("template_id")].title],
                "slides_created": duplicateSlideCount,
            };
            trackActivity("Slide", "Duplicate", null, duplicateSlideCount, eventProps, { audit: true });
        } catch (err) {
            ErrorHandler.handleSlideLimitReached(err, { workspaceId });
        }
    }

    async deleteSlide() {
        const { presentation, currentSlide, currentCanvasController } = this._state;

        if (presentation.slides.length <= 1) {
            ShowWarningDialog({
                title: "Can't delete slide",
                message: "Sorry, you can't delete the only slide in a presentation"
            });
            return;
        }

        await this.closeAllPanels();

        await this.hideCanvasControls();
        await this.hideSelectionLayer();

        const slideIndex = this.getCurrentSlideIndex();
        if (slideIndex === presentation.slides.length - 1) {
            await this.setCurrentSlideByIndex(slideIndex - 1);
        } else {
            await this.setCurrentSlideByIndex(slideIndex + 1);
        }

        await currentCanvasController.setOpacity(0);

        await new Promise(resolve => setTimeout(resolve, 300));

        await presentation.destroySlides(currentSlide.id);

        await this.showCanvasControls();
    }

    async renamePresentation() {
        const { presentation } = this._state;

        this.clearElementSelection();

        const presentationName = await ShowInputDialog({
            title: "Enter a name for your presentation",
            value: presentation.get("name"),
            trimInput: true
        });

        if (!presentationName) {
            return;
        }

        const currentPresentationName = presentation.get("name");
        if (presentationName !== currentPresentationName) {
            const eventProps = {
                from_name: currentPresentationName,
                to_name: presentationName,
                presentation_id: presentation.get("id"),
                workspace_id: presentation.getWorkspaceId()
            };
            trackActivity("Presentation", "Rename", null, null, eventProps, { audit: true });
        }

        presentation.update({ name: presentationName });
        await presentation.updatePromise;
    }

    undo() {
        app.undoManager.undo();
    }

    redo() {
        app.undoManager.redo();
    }

    isSlideGridVisible() {
        return this._state.showSlideGrid;
    }

    toggleSlideGrid() {
        if (this.isSingleSlideEditor) {
            return;
        }

        if (this._state.showSlideGrid) {
            return this.hideSlideGrid();
        }

        return this.showSlideGrid();
    }

    showSlideGrid() {
        return this._updateState({
            showSlideGrid: true,
            showSelectionLayer: false,
            showCanvasControls: false
        });
    }

    hideSlideGrid() {
        return this._updateState({
            showSlideGrid: false,
            showSelectionLayer: this.canShowSelectionLayer(),
            showCanvasControls: true
        });
    }

    setPopupState(popupOpenState) {
        return this._updateState({
            popupOpenState
        });
    }

    showCanvasControls() {
        return this._updateState({ showCanvasControls: true });
    }

    hideCanvasControls() {
        return this._updateState({ showCanvasControls: false });
    }

    async togglePanel(type, lock = true) {
        if (this._state.activePanel === type) {
            await this.closeAllPanels(lock);
        } else {
            await this.showPanel(type, lock);
        }
    }

    async showPanel(type, lock) {
        const { currentCanvasController } = this._state;

        await this._updateState({
            activePanel: type,
        });

        if (!lock) return;
        currentCanvasController.lockSlideForCollaborators();
    }

    async closeAllPanels(keepLock = true) {
        const { currentCanvasController, showCanvasControlsLockedState } = this._state;

        // This is a special case where
        // we want to keep the canvas controls showed
        // when user A is editing the slide
        if (!showCanvasControlsLockedState) {
            await this._updateState({
                activePanel: null,
                showSelectionLayer: this.canShowSelectionLayer(),
            });
            ds.selection.element = null;
        }

        if (keepLock) currentCanvasController.unlockSlideForCollaborators();
    }

    showEditorPopup(value) {
        return this._updateState({
            showEditorPopup: value,
        });
    }

    async showElementPanel(panelView) {
        const { currentCanvasController } = this._state;

        await this._updateState({
            activePanel: PanelType.ELEMENT,
            showCanvasControls: true,
            elementPanelView: panelView,
            showSelectionLayer: false,
        });

        currentCanvasController.lockSlideForCollaborators();
    }

    allowAuthoringEvents() {
        const { activePanel } = this._state;

        // If there is an active panel, don't allow any mouse events to propagate. Allow if activePanel is SpeakerNotes or comments
        return activePanel && activePanel !== "notes" && activePanel !== "comments";
    }

    toggleComments() {
        const { currentSlideLockOwner } = this._state;

        return this._updateState({
            showComments: !this._state.showComments,
            showCanvasControlsLockedState: currentSlideLockOwner !== null ? !this._state.showComments : false,
            activePanel: this._state.activePanel === PanelType.COMMENTS ? null : PanelType.COMMENTS,
        });
    }

    toggleVersionHistory() {
        return this.togglePanel(PanelType.VERSION_HISTORY);
    }

    showSelectionLayer() {
        return this._updateState({
            showSelectionLayer: this.canShowSelectionLayer()
        });
    }

    hideSelectionLayer() {
        return this._updateState({
            showSelectionLayer: false
        });
    }

    setSkipSlide(slide, value) {
        const { presentation } = this._state;
        presentation.skipSlide(slide, value);
    }

    async playAnimation() {
        await this.hideSelectionLayer();

        await this.getCurrentCanvasController().canvas.animate();

        await this.showSelectionLayer();
    }

    async exportPresentation() {
        const { default: ExportDialog } = await import(/* webpackMode: "eager" */ "js/react/views/PresentationSettings/dialogs/ExportDialog");

        await ShowDialogAsync(ExportDialog, { presentation: this._state.presentation });
    }

    async updateTheme() {
        const { presentation } = this._state;

        const progressDialog = ShowDialog(ProgressDialog, {
            title: "Applying theme...",
            progress: 0
        });

        const theme = await app.themeManager.loadTheme(presentation);
        await this._updateState({ currentTheme: theme });
        app.currentTheme = theme;

        await this.refreshAllCanvases(progressDialog);

        progressDialog.props.closeDialog();
    }

    async refreshAllCanvases(progressDialog) {
        const canvases = this.getCanvases();
        const slideCount = canvases.length;

        let slidesRefreshed = 1;
        for (const canvas of canvases) {
            await canvas.loadStyles(true);
            canvas.layouter.canvasElement.markStylesAsDirty();
            await canvas.refreshCanvas({ forceRender: false });
            if (progressDialog) {
                progressDialog.setProgress(slidesRefreshed / slideCount * 100);
            }
            slidesRefreshed++;
        }
    }

    async updateSlideVersion(slide, slideVersion = appVersion) {
        const { activePanel } = this._state;

        if (activePanel !== PanelType.VERSION_HISTORY) {
            this.closeAllPanels();
        }

        ds.selection.element = null;

        const confirmed = await ShowConfirmationDialog({
            title: "Hey, this Smart Slide got a bit smarter!",
            showRobot: true,
            optOutProperty: "updateSlideConfirmationDialog",
            message: (
                <>
                    <p>Occasionally, we make improvements or add new features to our Smart Slides.</p>
                    <p>Updating your slide is completely <strong>optional</strong> but if you'd like to take
                        advantage of the improvements on this existing slide, you can update it using the <strong>Update
                            Slide</strong> button.</p>
                    <blockquote>
                        <p><strong>NOTE</strong> In some cases, features, styles, or appearance may be different
                            from the previous version.</p>
                        <p>If you are unhappy with any changes, you can undo or revert to a previous version of your
                            slide using the version history.</p>
                    </blockquote>
                </>
            ),
            okButtonLabel: "Update Slide"
        });

        if (!confirmed) {
            return;
        }

        slide.commit({ undo: false }); // Preserve changes (if any)

        const canvasController = this.getCanvasControllerForSlide(slide);

        // Reload the canvas forcing to the current slide version
        await canvasController.reloadCanvas(slideVersion);

        // Save the migration immediately
        await canvasController.canvas.saveCanvasModel();

        await this.setCurrentSlide(slide);
    }

    async updateCurrentSlideTemplate() {
        const { currentCanvasController } = this._state;

        const shouldUpdate = await ShowDialogAsync(UpdateTemplateVersionDialog, { canvas: currentCanvasController.canvas });
        if (!shouldUpdate) {
            return;
        }

        const canvas = currentCanvasController.canvas;

        if (canvas.slideTemplate.constructor.updateMigration) {
            // Migrate model
            canvas.slideTemplate.constructor.updateMigration(canvas);
        }

        // New template id
        canvas.model.template_id = canvas.slideTemplate.constructor.updateTemplateId;

        // Reload the canvas forcing to the current slide version
        await currentCanvasController.reloadCanvas();

        // Save the migration immediately
        await currentCanvasController.canvas.saveCanvasModel();

        // Force update
        await this.setCurrentSlide(currentCanvasController.slide);
    }
}

const presentationEditorController = new PresentationEditorController(_.cloneDeep(initialState));
export default presentationEditorController;

if (!app.mainView) {
    app.mainView = {};
}

app.mainView.editorView = {
    lockModel: {
        setLockState: () => {
        }
    },
    getContainerBounds: () => {
        return {
            canvasBounds: new geom.Rect(0, 0, 1280, 1656)
        };
    }
};
