import React, { Component } from "react";
import styled from "styled-components";
import { Button, Icon } from "@material-ui/core";
import { ds } from "js/core/models/dataService";
import { _, $ } from "js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import { PREDEFINED_PALETTES } from "common/constants";
import { themeColors } from "js/react/sharedStyles";
import { Theme } from "js/core/models/theme";
import { ShowConfirmationDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { UIController } from "js/editor/dialogs/UIController";
import { BlueButton, UIPane, UIPaneContents } from "js/react/components/UiComponents";
import { FlexBox, ScrollBox } from "js/react/components/LayoutGrid";
import { FlexSpacer, Gap30 } from "js/react/components/Gap";
import { trackActivity } from "js/core/utilities/utilities";
import AppController from "js/core/AppController";
import { ThemePreview } from "js/editor/ThemeEditor/themePreview/ThemePreview";
import { Transition } from "react-transition-group";
import { ThemeThumbnailList } from "js/editor/ThemeEditor/components/ThemeThumbnailList";

const logger = getLogger(LogGroup.THEMES);

const TRANSITION = 500;

const Header = styled.div`
  width: 100%;
  padding: 0px 30px 0 40px;

  h2 {
    font-size: 24px;
    line-height: 24px;
  }

  display: flex;
`;

const ThumbnailScrollBox = styled(ScrollBox)`
  width: 100%;
  height: 100%;
  padding: 0 20px 40px 40px;
`;

const PalettePopup = styled.div`
  label {
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  padding: 30px;

  & > div {
    padding: 10px;
  }

  & > div:hover {
    background: ${themeColors.lightBlue};
  }
`;

const Palette = styled.div`
  display: flex;

  & > div {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  width: 100%;
  height: 100%;
`;

const LeftFrame = styled.div`
  width: 100%;
  transition: ${TRANSITION}ms;
  width: ${props => {
        switch (props.state) {
            case "entering":
                return "100%";
            case "entered":
                return "50%";
            default:
                return "100%";
        }
    }};
  height: calc(100% - 85px);
  overflow: hidden;
  flex-shrink: 0;
`;

const ThemePreviewContainer = styled.div`
  width: 50%;
  flex-shrink: 0;
  background: #ddd;
`;
const BottomBar = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 40px;
  position: absolute;
  bottom: 0px;
  background: white;
`;

export class CreateBlankPresentationPane extends Component {
    _isMounted = false;

    static defaultProps = {
        createButtonText: "Create Presentation"
    };

    state = {
        selectedTheme: null,
        selectedThemeType: null,
        isCreating: false,
        teamThemes: [],
        userThemes: [],
        frameWidth: 1000
    };

    constructor(props) {
        super(props);
        this.frameRef = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ frameWidth: $(this.frameRef.current).innerWidth() - 60 });

        this.loadTeamThemes();
        this.loadUserThemes();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setDefaultTheme() {
        const { teamThemes, userThemes } = this.state;
        if (teamThemes?.length > 0) {
            this.setCurrentTheme(teamThemes[0], "team-theme");
        } else if (userThemes?.length > 0) {
            this.setCurrentTheme(userThemes[0], "existing-user-theme");
        } else {
            this.setCurrentTheme(ds.builtInThemes.at(0), "built-in-theme");
        }
    }

    loadTeamThemes() {
        const teamThemes = ds.sharedThemes.getThemesInWorkspace(UIController.getOrganizationId()).sort((a, b) => a.id.localeCompare(b.id)); // lexicographic sort

        this.setState({ teamThemes });
    }

    loadUserThemes() {
        const userThemes = ds.userThemes.getThemesInWorkspace(UIController.getOrganizationId());
        this.setState({ userThemes });
    }

    setCurrentTheme = async (theme, themeType) => {
        const predefinedPalette = _.find(PREDEFINED_PALETTES, { id: theme.get("palette_name") || "colorful" });
        if (predefinedPalette) {
            theme.set("colors", predefinedPalette.colors);
        }

        this.setState({
            selectedTheme: theme,
            selectedThemeType: themeType,
        });

        theme.loadTheme()
            .catch(err => logger.error(err, "ChooseThemePane theme.loadTheme() failed", { themeId: theme?.id }));
    }

    handlePreviewRendered = () => {
        if (!this._isMounted) {
            return;
        }
        this.setState({ showThemePreview: true });
    }

    handleChooseTheme = (theme, themeType) => {
        const { onCreate } = this.props;
        this.setState({
            showPreview: false
        });
        onCreate(theme);
        trackActivity("Presentation", "Start", null, null, { source: themeType, theme_id: theme.id }, { audit: false });
    }

    handleDeleteTheme = theme => {
        ShowConfirmationDialog({
            title: "Are you sure you want to delete this theme?",
            message: "No existing presentations will be affected by this change but you won't be able to use this theme in any future presentations."
        }).then(confirmed => {
            if (confirmed) {
                theme.collection.remove([theme]);
                theme.destroy();
                this.loadUserThemes();
            }
        });
    };

    handleClickPalette = event => {
        if (!this.props.requireSharedTheme) {
            this.setState({ anchorEl: event.currentTarget });
        }
    };

    handleSetPalette = palette => {
        this.setState({ anchorEl: null });
        const theme = new Theme(this.state.selectedTheme.attributes, { disconnected: true, autoLoad: false });
        theme.update({ palette_name: palette.id }, { replaceKeys: true });
        this.setCurrentTheme(theme, this.state.selectedThemeType);
    };

    handleNewTheme = async () => {
        AppController.goToRoute(`/theme/new-presentation`);
    };

    handleClickCreatePresentation = () => {
        const { onCreate } = this.props;
        const { selectedTheme } = this.state;

        if (selectedTheme) {
            this.setState({ isCreating: true });
            onCreate(selectedTheme);
        } else {
            ShowWarningDialog({ title: "Please select a theme to use for your presentation." });
        }
    }

    render() {
        const { requireTeamTheme, createButtonText } = this.props;
        const { isCreating } = this.state;
        const { selectedTheme, selectedThemeType, showThemePreview, anchorEl, teamThemes, userThemes, frameWidth } = this.state;

        const palette = {};
        if (selectedTheme) {
            _.map(selectedTheme.get("colors"), (color, key) => {
                if (key == "theme" || key.startsWith("accent")) {
                    palette[key] = color;
                }
            });
        }

        return (
            <UIPane>
                <UIPaneContents style={{ padding: 0 }}>
                    <Container>
                        <Transition in={showThemePreview} timeout={0}>
                            {state => (
                                <LeftFrame state={state} ref={this.frameRef}>
                                    <Header>
                                        <h2>Select a Theme</h2>
                                        {!requireTeamTheme && (<>
                                            <Gap30/>
                                            <Button onClick={this.handleNewTheme}><Icon color="primary">add_circle</Icon>New Theme...</Button>
                                        </>)}

                                    </Header>
                                    <ThemeThumbnailList
                                        requireTeamTheme={requireTeamTheme}
                                        selectedTheme={selectedTheme}
                                        width={showThemePreview ? frameWidth / 2 - 60 : frameWidth}
                                        onClick={this.setCurrentTheme}
                                        onDoubleClick={this.handleChooseTheme}
                                        styles={{ padding: "0 20px 70px 40px" }}
                                    />
                                </LeftFrame>
                            )}
                        </Transition>
                        <ThemePreviewContainer>
                            {selectedTheme && (
                                <ThemePreview
                                    theme={selectedTheme.attributes}
                                    onRendered={this.handlePreviewRendered}
                                />
                            )}
                        </ThemePreviewContainer>
                    </Container>
                    <FlexSpacer />
                    <BottomBar id="create-presentation-btn">
                        <FlexSpacer />
                        <BlueButton onClick={this.handleClickCreatePresentation} disabled={isCreating}>Create Presentation</BlueButton>
                    </BottomBar>
                </UIPaneContents>
            </UIPane>
        );
    }

    renderPalettes = () => {
        return PREDEFINED_PALETTES.map((palette, index) => {
            const colors = Object.keys(palette.colors);
            return (
                <FlexBox vertical left key={index} onClick={() => this.handleSetPalette(palette)}>
                    <label>{palette.name}</label>
                    <Palette>
                        {colors.map((color, index) => {
                            if (color == "theme" || color.startsWith("accent")) {
                                return <div key={index} style={{ background: palette.colors[color] }} />;
                            }
                        })}
                    </Palette>
                </FlexBox>
            );
        });
    };
}

