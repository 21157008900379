import Cookies from "js-cookie";
import { _ } from "js/vendor";
import firebase from "js/firebase";
import { app } from "js/namespaces";
import StorageModel from "js/core/storage/storageModel";
import Api from "js/core/api";
import { Features } from "js/core/models/features";
import { Workspace } from "js/core/models/workspace";
import isConnected from "js/core/utilities/isConnected";
import { FeatureType } from "common/features";
import { OLD_BASIC_CUTOFF } from "common/constants";
import { ShowMessageDialog } from "js/react/components/Dialogs/BaseDialog";
import { ds } from "js/core/models/dataService";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.USER);

const User = StorageModel.extend({
    root: "users",

    initialize() {
        if (this.id) {
            // cookie for analytics
            Cookies.set("userId", this.id);
        }
    },

    async generateLoadPromise(...args) {
        // Awaiting the base generate promise first
        await StorageModel.prototype.generateLoadPromise.apply(this, ...args);

        const user = firebase.auth().currentUser;
        if (this.get("email") !== user.email) {
            // we found an issue where there are some users (who signed up
            // through google sign in) in our user table that do not have an
            // email even though the auth table does have an email, so this
            // is to backfill the user table with emails from the auth table.
            this.update({ email: user.email });
        }

        // fetch plan permissions from Firebase
        this.features = new Features();
        await this.features.initializePromise;

        // fetch trial detail from API
        if (isConnected.connected) {
            try {
                // note: these details apply to the personal workspace only
                const detail = await Api.trialDetail.get({ t: new Date().getTime() });
                this.trialStatus = detail.status;
                this.hasTakenTrial = detail.hasTakenTrial;
                this.analyticsPersonalPlan = detail.analyticsCurrentPlan;
                this.teamPlans = detail.teamPlans;
                this.personalPlan = detail.personalPlan;
            } catch (err) {
                logger.error(err, "[user] load() Api.trialDetail.get() failed", { id: this.id });
            }
        }

        this.workspaces = Object.freeze({
            ..._.mapValues(this.get("orgs"), (value, orgId) => {
                return new Workspace(orgId);
            }),
            "personal": new Workspace("personal")
        });
    },

    loadMinimum() {
        return StorageModel.prototype.load.apply(this, arguments);
    },

    getEmail() {
        return this.getAuthUser().email;
    },

    getAuthUser() {
        return firebase.auth().currentUser;
    },

    getIsTeamOwner(workspaceId) {
        return !!workspaceId && app.user.getRole(workspaceId) === "owner";
    },

    getAvatarUrl() {
        let { photoURL } = this.getAuthUser() || {};
        return this.get("avatarUrl") || photoURL;
    },

    getDisplayName() {
        return this.getAuthUser().displayName || this.getAuthUser().email;
    },

    isOldBasicUser() {
        return new Date(firebase.auth().currentUser.metadata.creationTime).getTime() < OLD_BASIC_CUTOFF;
    },

    checkIfUserAndPresentationBelongToSameOrg(presentationOrgId) {
        return this.has("orgs") && this.get("orgs")[presentationOrgId];
    },

    isEligibleForEduPlan(email) {
        // allow new address to be passed in, or use current address
        email = email || this.get("email");
        const domain = email.split("@").pop();
        // simple check for whether the user's email matches know school emails
        return !!(domain.includes(".edu") || domain.includes(".org") || domain.includes(".ac"));
    },

    async updateEmailAddress(email) {
        let user = this.getAuthUser();
        await Api.customers.put({ email });
        await user.updateEmail(email);
        this.update({ email });
        if (this.analyticsPersonalPlan === "edu" && !this.isEligibleForEduPlan(email)) {
            ShowMessageDialog({
                title: "You are currently on the Edu plan. Changing your email will downgrade you to the Basic plan.",
                message: "If you are still a student, you can reapply for the education plan after validating your new email address."
            });
            await Api.eduPlan.delete();
        }
        return user.sendEmailVerification();
    },

    waitForVerifiedEmail() {
        let user = this.getAuthUser();
        let checkVerifiedInterval;
        return new Promise(resolve => {
            clearInterval(checkVerifiedInterval);
            checkVerifiedInterval = setInterval(async () => {
                if (user) {
                    await user.reload();
                    if (user.emailVerified) {
                        await firebase.auth().currentUser.getIdToken(true);
                        clearInterval(checkVerifiedInterval);
                        resolve();
                    }
                } else {
                    clearInterval(checkVerifiedInterval);
                }
            }, 5000);
        });
    },

    getAnalyticsPlan(workspaceId) {
        if (!workspaceId) {
            throw new Error("workspaceId is required");
        }
        if (workspaceId == "personal") {
            const currentPlan = this.analyticsPersonalPlan;
            if (currentPlan) {
                return currentPlan;
            }
        } else {
            if (this.features.isFeatureEnabled(FeatureType.UPGRADE, workspaceId)) {
                return "organization-basic";
            } else {
                return "organization-pro";
            }
        }
    },

    getRole(workspaceId) {
        if (!workspaceId) {
            throw new Error("workspaceId is required");
        }
        if (workspaceId == "personal") {
            return "none";
        } else {
            return ds.teams?.defaultTeamForOrg(workspaceId).getUserRole() || "N/A";
        }
    },

    getWorkspaceName(workspaceId) {
        return ds.teams?.defaultTeamForOrg(workspaceId).get("name");
    },

    getLibrarySettings() {
        return _.defaults(_.cloneDeep(this.get("librarySettings")), {
            viewType: "grid",
            sortField: "modifiedAt",
            sortReverse: true,
            thumbnailSize: 300,
            recentPresentations: [],
            scaleInterp: 0.5,
        });
    },

    async isEduUser() {
        return await Api.eduPlan.get({
            action: "isEduUser"
        });
    },

    async toObject() {
        const attrs = await StorageModel.prototype.toObject.call(this);
        //base64 encode images
        const photoURL = this.getAuthUser().photoURL;
        if (photoURL) {
            try {
                await fetch(photoURL);
            } catch {
                // noop
            }
        }
        return attrs;
    }
});

export {
    User,
};
