import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import theme from "../materialThemeOverrides";
import PropTypes from "prop-types";
import InfoToolTip from "js/react/components/InfoToolTip";
import { FlexBox } from "js/react/components/LayoutGrid";

const { primary, grey } = theme.palette;

const blueStyles = {
    switchContainer: {
        padding: 10,
        paddingLeft: 0,
        display: "flex",
        alignItems: "center"
    },
    disabled: {
        pointerEvents: "none",
        opacity: 0.5
    },
    switchWrapper: {
        width: 50,
        height: 24,
        padding: 2,
        position: "relative",
        backgroundColor: grey[700],
        borderRadius: 3,
        marginRight: 14,
        display: "inline-block",
        "&.checked": {
            backgroundColor: primary[500]
        },
        "& .switchFlag": {
            transition: "all 0.2s ease",
            position: "absolute",
            fontSize: 10,
            padding: "3px 4px",
            opacity: 0,
            textTransform: "uppercase",
            fontWeight: 600
        },
        "&.checked .icon": {
            transition: "all 0.3s ease",
            left: 28,
            opacity: 0.8
        },
        "&.unchecked .icon": {
            transition: "all 0.3s ease",
            left: 3,
            opacity: 0.6
        },

        "&.checked $on, &.unchecked $off": {
            opacity: 1
        }
    },
    toggle: {
        transition: "all 0.4s ease",
        height: 20,
        width: 20,
        background: "#fff",
        opacity: 0.4,
        position: "absolute",
        borderRadius: 3
    },
    on: {
        left: 3,
        color: "#fff"
    },
    off: {
        right: 0,
        color: grey[100]
    },
    switchTextLabel: {
        "&:not(.inheritFont)": {
            fontSize: "12px !important",
            textTransform: "uppercase",
            letterSpacing: 1,
        },
        fontWeight: 600,
        color: "#333",
        position: "relative",
        top: -1
    }
};

const redStyles = {
    switchContainer: {
        padding: 10,
        paddingLeft: 0,
        display: "flex",
        alignItems: "center"
    },
    disabled: {
        pointerEvents: "none",
        opacity: 0.5
    },
    switchWrapper: {
        width: 50,
        height: 24,
        padding: 2,
        position: "relative",
        backgroundColor: grey[700],
        borderRadius: 3,
        marginRight: 14,
        display: "inline-block",
        "&.checked": {
            backgroundColor: "#cb3d0c"
        },
        "& .switchFlag": {
            transition: "all 0.2s ease",
            position: "absolute",
            fontSize: 10,
            padding: "3px 4px",
            opacity: 0,
            textTransform: "uppercase",
            fontWeight: 600
        },
        "&.checked .icon": {
            transition: "all 0.3s ease",
            left: 28,
            opacity: 0.8
        },
        "&.unchecked .icon": {
            transition: "all 0.3s ease",
            left: 3,
            opacity: 0.6
        },

        "&.checked $on, &.unchecked $off": {
            opacity: 1
        }
    },
    toggle: {
        transition: "all 0.4s ease",
        height: 20,
        width: 20,
        background: "#fff",
        opacity: 0.4,
        position: "absolute",
        borderRadius: 3
    },
    on: {
        left: 3,
        color: "#fff"
    },
    off: {
        right: 0,
        color: grey[100]
    },
    switchTextLabel: {
        "&:not(.inheritFont)": {
            fontSize: "12px !important",
            textTransform: "uppercase",
            letterSpacing: 1,
        },
        fontWeight: 600,
        color: "#333",
        position: "relative",
        top: -1
    }
};

const Switch = ({
    id,
    classes,
    checked,
    onChange,
    label,
    disabled,
    parentSwitchContainer,
    tooltipTitle,
    inheritFont = false,
}) => {
    return (
        <FlexBox left middle>
            <div
                onClick={e => onChange(e)}
                className={classNames(
                    "switch",
                    classes.switchContainer,
                    parentSwitchContainer,
                    disabled && classes.disabled
                )}
            >
                <div
                    id={id}
                    className={classNames(classes.switchWrapper, {
                        checked: checked,
                        unchecked: !checked
                    })}
                >
                    <label className={classNames("switchFlag", classes.on)}>ON</label>
                    <div className={classNames(classes.toggle, "icon")} />
                    <label className={classNames("switchFlag", classes.off)}>Off</label>
                </div>
                <label
                    className={classNames(
                        classes.switchTextLabel,
                        {
                            inheritFont
                        }
                    )}
                >{label}</label>
            </div>
            {tooltipTitle &&
            <InfoToolTip
                title={tooltipTitle}
            />
            }
        </FlexBox>
    );
};
Switch.propTypes = {
    checked: PropTypes.bool,
    classes: PropTypes.object,
    onChange: PropTypes.func,
    label: PropTypes.string
};

export const BlueSwitch = withStyles(blueStyles)(Switch);
export const RedSwitch = withStyles(redStyles)(Switch);
