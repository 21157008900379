import React, { useImperativeHandle, useState } from "react";
import { FlexBox } from "js/react/components/LayoutGrid";
import { Gap20 } from "js/react/components/Gap";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";

import { PaneContent, PaneFooter } from "../../../components/PaneComponents";
import PromptInput from "../../../components/PromptInput";
import GenerationHeader from "../../../components/GenerationHeader";
import { ContextSources } from "../../../components/ContextSources";

import GeneratePresentationController, {
    StartGenerationParams,
    GeneratePresentationState,
    ContextSource, ContextSourceType
} from "../controllers/GeneratePresentationController";

const promptExamples = [
    "Investor pitch deck for a Series A SaaS company in the B2C FinTech space",
    "Board deck for Q1 revenue, growth, and new customers",
    "What is Google's business model?",
    "How the Grinch stole Christmas",
    "A pitch deck for a mobile app that sells train tickets called 'Ticket to Ride'",
    "Presentation on the workplace transformation and the future of work",
];

export type RefPromptPresentationInput = {
    handleStartGeneration: () => void,
}

type Props = {
    startGenerationParams?: StartGenerationParams,
    contextSources?: ContextSource[],
    hideActionButton?: boolean,
}

export const PromptPresentationInput = React.forwardRef<RefPromptPresentationInput, Props>(function PromptPresentationInput({
    startGenerationParams,
    contextSources = [],
    hideActionButton = false,
}, ref) {
    const [prompt, setPrompt] = useState<string>(startGenerationParams?.prompt ?? "");

    const handleStartGeneration = () => {
        const params: StartGenerationParams = {
            prompt,
            files: [],
            contextUrls: [],
            documentText: "",
        };

        for (const contextSource of contextSources) {
            switch (contextSource.type) {
                case ContextSourceType.FILE:
                    if (contextSource.file) params.files.push(contextSource.file);
                    break;
                case ContextSourceType.WEBPAGE:
                    if (contextSource.url) params.contextUrls.push(contextSource.url);
                    break;
                case ContextSourceType.TEXT:
                    if (contextSource.text) params.documentText += contextSource.text;
                    break;
            }
        }

        if (params.prompt.trim().length + params.files.length + params.contextUrls.length + params.documentText.trim().length == 0) {
            // @ts-ignore
            return ShowWarningDialog({
                title: "Please enter a prompt or context before generating a presentation",
                zIndex: 11000,
            });
        }

        GeneratePresentationController.generatePresentationOutline(params);
    };

    const canStartGeneration = () => {
        return true;
    };

    const handleEnterKeyPress = () => {
        if (canStartGeneration()) {
            handleStartGeneration();
        }
    };

    useImperativeHandle(ref, () => ({
        handleStartGeneration,
    }));

    let promptPlaceholder = "Describe the topic of your presentation...";
    if (contextSources.length > 0) {
        promptPlaceholder += " (Note: You can leave this blank to create a presentation just based on the additional context you add)";
    }

    return (
        <PromptInput
            label="Create a presentation about..."
            prompt={prompt}
            onPromptChange={setPrompt}
            promptPlaceholder={promptPlaceholder}
            promptExamples={promptExamples}
            onEnterKeyPress={handleEnterKeyPress}
            actionButtonText="Generate Presentation"
            onActionButtonClick={hideActionButton ? null : handleStartGeneration}
        />
    );
})

const PromptView = GeneratePresentationController.withState(({
    startGenerationParams,
    aiCreditsBalance,
}: GeneratePresentationState) => {
    const [contextSources, setContextSources] = useState<ContextSource[]>([]);

    const handleAddContextSource = contextSourceType => {
        setContextSources([...contextSources, { type: contextSourceType }]);
    };

    const handleDeleteContextSource = contextSource => {
        setContextSources(contextSources.filter(c => c != contextSource));
    };

    return (
        <>
            <GenerationHeader
                header="Generate a presentation with ai."
            />
            <PaneContent>
                <FlexBox vertical fillWidth fillHeight left top>
                    <PromptPresentationInput
                        startGenerationParams={startGenerationParams}
                        contextSources={contextSources}
                    />
                    <Gap20 />
                    <ContextSources
                        contextSources={contextSources}
                        aiCreditsBalance={aiCreditsBalance}
                        onAdd={handleAddContextSource}
                        onDelete={handleDeleteContextSource}
                        requiredCredits={15}
                        reloadAiCreditsBalance={() => GeneratePresentationController.loadAiCreditsBalance(true)}
                    />
                </FlexBox>
            </PaneContent>
            <PaneFooter
                showBackButton={false}
                onBackButtonClick={() => GeneratePresentationController.showChooseFlow()}
            />
        </>
    );
});

export default PromptView;
