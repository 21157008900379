import React, { Component, setGlobal, getGlobal } from "reactn";
import { SearchAssets } from "js/core/utilities/searchAssets";
import { SearchInput } from "js/react/components";
import { app } from "js/namespaces.js";
import { _ } from "js/vendor";
import { loadImage } from "js/core/utilities/promiseHelper";
import {
    UIContainerContext,
    UIPane,
    UIPaneContents,
    UIPaneHeader
} from "js/react/components/UiComponents";
import Loadable from "../../../components/Loadable";
import LoadingOverlay from "../../../components/LoadingOverlay";
import ImageThumbnailGrid from "js/react/views/AddAssets/Components/ImageThumbnailGrid";
import StockPhotoPreview from "js/react/views/AddAssets/Components/StockPhotoPreview";
import StockMediaCategoryImage from "js/react/views/AddAssets/Components/StockMediaCategoryImage";
import { NoMatchNotice } from "js/react/components/Notice";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.ASSETS);

export class StockPhotosPane extends Component {
    static contextType = UIContainerContext;

    constructor(props) {
        super(props);
        this.state = {
            isSearching: false,
            searchResults: null,
            searchAssetsUtil: new SearchAssets(),
            stockCategory: null
        };
        this.searchInputRef = React.createRef();

        if (app.tourFuncs) {
            app.tourFuncs.doTourImageSearch = async () => {
                this.setState({
                    stockCategory: "dandelion"
                });
                await this.doSearch("dandelion", true);
            };
        }
    }

    componentDidMount = async () => {
        const { prefilledSearchQuery, onPrefilledSearchQueryComplete } = this.props;

        const global = getGlobal();
        const { lastPhotoSearchTerm } = global;

        const prefill = prefilledSearchQuery;
        this.setState({ autoSearch: prefill });
        if (prefill) {
            await this.doSearch(prefill);
        } else if (lastPhotoSearchTerm) {
            await this.doSearch(lastPhotoSearchTerm);
        }
        onPrefilledSearchQueryComplete();
    };

    componentDidUpdate = async prevProps => {
        if (
            this.props.prefilledSearchQuery &&
            prevProps.prefilledSearchQuery !== this.props.prefilledSearchQuery
        ) {
            this.setState({ autoSearch: this.props.prefilledSearchQuery });
            await this.doSearch(this.props.prefilledSearchQuery);
        }
    };

    renderPopularSearches = () => {
        const stockInfo = [
            { title: "Black & White", urlFragment: "picture-categories/blackandwhite.jpeg" },
            { title: "Business", urlFragment: "picture-categories/business.jpeg" },
            { title: "Crowd", urlFragment: "picture-categories/crowd.jpeg" },
            { title: "Data", urlFragment: "picture-categories/data.jpeg" },
            { title: "Food", urlFragment: "picture-categories/food.jpeg" },
            { title: "Health", urlFragment: "picture-categories/health.jpeg" },
            { title: "Kids", urlFragment: "picture-categories/kids.jpeg" },
            { title: "Meeting", urlFragment: "picture-categories/meeting.jpeg" },
            { title: "Nature", urlFragment: "picture-categories/nature.jpeg" },
            { title: "People", urlFragment: "picture-categories/people.jpeg" },
            { title: "Sky", urlFragment: "picture-categories/sky.jpeg" },
            { title: "Speed", urlFragment: "picture-categories/speed.jpeg" },
            { title: "Technology", urlFragment: "picture-categories/technology.jpeg" },
            { title: "Tools", urlFragment: "picture-categories/tools.jpeg" },
            { title: "Water", urlFragment: "picture-categories/water.jpeg" },
            { title: "Work", urlFragment: "picture-categories/work.jpeg" },
        ];
        return (
            <div className="stock-media-popular-search-container">
                {stockInfo.map(photo => (
                    <StockMediaCategoryImage
                        key={photo.title}
                        title={photo.title}
                        urlFragment={photo.urlFragment}
                        handleClick={async () => {
                            this.setState({
                                stockCategory: photo.title.toLowerCase()
                            });
                            await this.doSearch(photo.title.toLowerCase());
                        }}
                    />
                ))}
            </div>
        );
    };

    doSearch = async searchTerm => {
        setGlobal({ lastPhotoSearchTerm: searchTerm });
        if (searchTerm === "") {
            this.setState({ searchResults: null });
            return;
        }
        this.setState({
            isLoading: true,
            isSearching: true,
            searchResults: []
        });
        const response = await this.state.searchAssetsUtil.searchStockImages(searchTerm.toLowerCase());
        if (response.complete && response.results.length) {
            await Promise.all(
                response.results.map(result =>
                    loadImage(result.thumbnailUrl, 4000)
                        .catch(err => logger.error(err, "loadImage() failed", { url: result.thumbnailUrl }))
                )
            );
            this.setState({
                searchResults: response.results,
                isLoading: false,
                isSearching: false
            });
        } else {
            this.setState({
                searchResults: [],
                isLoading: false,
                isSearching: false
            });
        }
    };

    getNextPage = async () => {
        this.setState({ isLoading: true });
        const response = await this.state.searchAssetsUtil.getNextPage();
        if (response.complete && response.results.length) {
            await Promise.all(
                response.results.map(result =>
                    loadImage(result.thumbnailUrl, 4000)
                        .catch(err => logger.error(err, "loadImage() failed", { url: result.thumbnailUrl }))
                )
            );
            this.setState(prevState => {
                if (
                    prevState.searchResults &&
                    prevState.searchResults.length > 1
                ) {
                    const searchResults = [
                        ...prevState.searchResults,
                        ...response.results
                    ];
                    const uniqueResults = _.uniqBy(
                        searchResults,
                        e => e.previewUrl
                    );
                    return { searchResults: uniqueResults, isLoading: false };
                }
            });
        }
    };

    checkScroll = e => {
        const scrollDistance =
            e.currentTarget.scrollHeight - e.currentTarget.scrollTop;
        if (scrollDistance <= e.currentTarget.clientHeight * 2) {
            if (!this.state.isLoading) {
                const debouncedLoadPage = _.debounce(
                    () => {
                        this.getNextPage();
                    },
                    3000,
                    { leading: true }
                );
                debouncedLoadPage();
            }
        }
    };

    renderSearchResults = () => {
        const { searchResults, isSearching } = this.state;
        const { addAssetCallback, handleConfirm } = this.props;

        const results = searchResults.map((imgData, idx) => (
            <StockPhotoPreview
                addAssetCallback={addAssetCallback}
                key={`${imgData.previewUrl}`}
                handleConfirm={handleConfirm}
                imgProps={imgData}
                {...(idx === 0 && { tourImage: true })}
            />
        ));

        return (
            <>
                {isSearching && <LoadingOverlay />}
                <Loadable isLoading={isSearching}>
                    {
                        !!results.length &&
                        <ImageThumbnailGrid>
                            {results}
                        </ImageThumbnailGrid>
                    }
                    {
                        !results.length &&
                        <NoMatchNotice />
                    }
                </Loadable>
            </>
        );
    };

    handleClearSearch() {
        this.searchInputRef.current.clearSearch();
    }

    render() {
        const { searchResults, stockCategory, autoSearch } = this.state;
        const inputFocused = !this.context;

        const global = getGlobal();
        const { lastPhotoSearchTerm } = global;

        return (
            <UIPane>
                <UIPaneHeader>
                    <SearchInput
                        ref={this.searchInputRef}
                        handleSubmit={searchTerm =>
                            this.doSearch(searchTerm)
                        }
                        placeholder="Search for images..."
                        handleClearSearch={() => {
                            setGlobal({ lastPhotoSearchTerm: null });
                            this.setState({
                                searchResults: null,
                                stockCategory: null,
                                isSearching: false
                            });
                        }}
                        focus={inputFocused}
                        prefilledSearch={stockCategory || autoSearch || lastPhotoSearchTerm}
                    />
                </UIPaneHeader>
                <UIPaneContents
                    style={{ paddingTop: 20, position: "relative" }}
                    onScroll={e => {
                        searchResults && this.checkScroll(e);
                    }}
                >
                    {searchResults
                        ? this.renderSearchResults()
                        : this.renderPopularSearches()}
                </UIPaneContents>
            </UIPane>
        );
    }
}
