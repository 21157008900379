import { Grid, DialogTitle, Fade } from "@material-ui/core";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import db from "js/db";
import { isPPTAddin } from "js/config";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { StandaloneDialogContent, StandaloneForm, StandalonePage } from "../Onboarding/Standalone";
import Api from "js/core/api";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import BeautifulDialog from "js/react/components/Dialogs/BeautifulDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import PlanCard from "./Components/PlanCard";
import AppController from "js/core/AppController";

const Container = styled.div`
    background: #F8FAFC;
`;

const StyledStandaloneForm = styled.div`
&&& {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
}`;

const StyledGridContainer = styled(Grid)`
    gap:20px;
`;

const StyledStandaloneDialogContent = styled(StandaloneDialogContent)`
    padding: 20px 20px !important;
    `;

class PPTAddinChoosePlanBase extends Component {
    state = {
        fadeOut: false,
        fadeOutBackground: false,
        loading: false,
        planName: ""
    };

    saveConfirm = async () => await db.getDbInstance("users").ref(`users/${this.props.firebaseUser.uid}`).update({ didConfirmPlan: true });

    handlePro = async () => {
        const { isPopup } = this.props;
        await this.saveConfirm();
        this.setState({ fadeOut: true });
        if (isPopup && isPPTAddin) {
            Office.context.ui.messageParent(JSON.stringify({
                eventType: "showCheckout",
                plan: "pro"
            }));
            this.props.closeDialog();
        } else {
            setTimeout(() => {
                window.location = "/ppt-addin/checkout?plan=pro&billingTerm=year";
            }, 750);
        }
    }

    handleTeam = async () => {
        const { isPopup } = this.props;
        await this.saveConfirm();
        this.setState({ fadeOut: true });

        if (isPopup && isPPTAddin) {
            Office.context.ui.messageParent(JSON.stringify({
                eventType: "showCheckout",
                plan: "team"
            }));
            this.props.closeDialog();
        } else {
            setTimeout(() => {
                window.location = "/ppt-addin/checkout?plan=team&billingTerm=year";
            }, 750);
        }
    }

    handleBasic = async () => {
        // In the upgrade flow we do not show the basic plan, so we don't need to handle this case
        await this.saveConfirm();
        this.setState({ fadeOut: true });
        setTimeout(() => {
            this.setState({ fadeOutBackground: true });
            setTimeout(() => {
                Api.customToken.get().then(customTokenRes => {
                    Office.context.ui.messageParent(JSON.stringify({
                        eventType: "close",
                        token: customTokenRes.token,
                        reload: true
                    }));
                }).catch(() => {
                    ShowWarningDialog({
                        message: "Failed to authenticate. Please try again.",
                        acceptCallback: () => Office.context.ui.messageParent(JSON.stringify({ eventType: "error" }))
                    });
                });
            }, 750);
        }, 750);
    }

    renderForm = () => {
        const { hideBasic = false, hasTakenTrial, emphasizePlan } = this.props;
        const { partOfTeam } = this.state;

        const planCardProps = [
            {
                hide: hideBasic,
                icon: "person",
                title: "Starter Plan",
                planName: "Basic",
                planPrice: "Access limited features for free",
                buttonId: "choose-basic",
                buttonOnClick: this.handleBasic,
                buttonText: "Get started",
                planMore: "The Beautiful.ai basics:",
                features: ["Smart Slide templates", "Gallery of pre-designed slides"]
            },
            {
                emphasize: emphasizePlan === "pro",
                icon: "stars",
                title: "For Professionals",
                planName: "Pro",
                planPrice: "From $12/mo",
                buttonId: "choose-pro",
                buttonOnClick: this.handlePro,
                buttonText: hasTakenTrial ? "Upgrade" : "Try it free",
                planMore: "Everything in Basic, plus:",
                features: ["Save editable PPT slides", "AI slide and image generation", "AI text assistant"]
            },
            {
                emphasize: emphasizePlan === "team",
                icon: "apartment",
                title: "For companies",
                planName: "Team",
                planPrice: "From $40/mo per user",
                buttonId: "choose-team",
                buttonOnClick: this.handleTeam,
                buttonText: hasTakenTrial ? "Upgrade" : "Try it free",
                planMore: "Everything in Pro, plus:",
                features: ["Custom Company Theme", "Centralized slide library", "Slide asset library"]
            }
        ];

        return (<StandaloneForm
            style={{ height: "530px",
                display: "flex",
                flexDirection: "column",
                gap: "25px" }}
            open={true}
            fullWidth >
            <StyledStandaloneForm>
                <DialogTitle align="center" style={{ paddingBottom: 0 }}>
                    Choose the plan that's right for you
                </DialogTitle>
                <StyledStandaloneDialogContent>
                    <StyledGridContainer container >
                        {planCardProps.map((props, index) => <PlanCard key={index} {...props} />)}
                    </StyledGridContainer>
                </StyledStandaloneDialogContent>
            </StyledStandaloneForm>
        </StandaloneForm>);
    }

    render() {
        const { isPopup } = this.props;
        const { loading } = this.state;

        return isPopup
            ? <BeautifulDialog closeDialog={this.props.closeDialog}>
                <FetchingClickShield visible={loading} />
                {this.renderForm()}
            </BeautifulDialog> : (
                <Container>
                    <Fade in={!this.state.fadeOutBackground} timeout={this.state.fadeOutBackground ? 750 : 0}>
                        <StandalonePage maxWidth={800}>
                            <Fade in={!this.state.fadeOut} timeout={750}>
                                {this.renderForm()}
                            </Fade>
                        </StandalonePage>
                    </Fade>
                </Container>
            );
    }
}

export const PPTAddinChoosePlan = withFirebaseUser(PPTAddinChoosePlanBase);

export const PPTAddinChoosePlanWithRouter = withRouter(withFirebaseUser(PPTAddinChoosePlanBase));
