import React, { Component } from "react";
import styled from "styled-components";
import { Icon, Chip } from "@material-ui/core";

import { SearchShortCuts } from "js/react/views/AddSlide/Panes/Components/SearchShortCuts";
import { FlexBox } from "js/react/components/LayoutGrid";

const SearchBarBox = styled.div`
    display: flex;
    position: relative;
    pointer-events: auto;
    padding: 20px 30px 20px 0px;
    margin-top: -20px;
    margin-bottom: -20px;
    border-radius: 0;
    border: 0;
    align-items: center;
    z-index: 100;
    
    .micon {
        vertical-align: -12px;
        font-size: 30px;
        color: #ccc;
        cursor: default;
        margin: 0px 4px;
    }

    .clear-search {
        position: absolute;
        right: 0;
        margin-right: 8px;
        color: #999;
    }

    input[type="text"] {
        vertical-align: middle;
        font-size: 18px;
        font-weight: 600;
        border: none;
        padding: 0px;
        background: none;
        outline: none;
        padding-right: 32px;

        margin-top: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;

    }
`;

export class SearchBar extends Component {
    state = {
        value: ""
    }

    constructor() {
        super();

        this.inputRef = React.createRef();
    }

    handleSearch = event => {
        const { onSearch } = this.props;
        this.setState({ value: event.target.value });
        onSearch(event.target.value);
    }

    handleClearSearch = () => {
        const { onSearch } = this.props;
        this.setState({ value: "" });
        onSearch("");
    }

    focusInput = () => {
        this.inputRef.current.focus();
    }

    handleBlurInput = event => {
        const { onBlurOnEmpty } = this.props;

        if (event.target.value == "") {
            onBlurOnEmpty && onBlurOnEmpty();
        }
    }

    render() {
        const { style, placeholder = "Search...", showIcon = true, className } = this.props;
        const { value } = this.state;

        return (
            <SearchBarBox style={style} className={`search-bar-box ${className ?? ""}`}>
                {showIcon && <Icon>search</Icon>}
                <input
                    ref={this.inputRef}
                    onChange={this.handleSearch}
                    spellCheck="false"
                    type="text"
                    placeholder={placeholder}
                    value={value || ""}
                    onBlur={this.handleBlurInput}
                />
                {
                    value &&
                    <Icon className="clear-search" onClick={this.handleClearSearch}>clear</Icon>
                }
            </SearchBarBox>

        );
    }
}

const StyledChip = styled(Chip)`
  background: #DBE9F0 !important;
  border-radius: 3px !important;
  max-width: 310px !important;
  margin-right: 10px !important;
  .MuiIcon-root {
    font-size: 1.25rem !important;
  }
  .MuiChip-label {
    padding: 0 0 0 10px !important;
    font-size: .875rem !important;
    color: #666666 !important;
    letter-spacing: .5px;
  }
`;

export const SearchBarContainer = styled.div`
  position: absolute;
  top: 0px;
  padding: 20px 24px 20px 20px;
  display: flex;
  height: 90px;
  width: 100%;     
  align-items: center;
  justify-content: flex-start;
`;

export const SearchBarInnerContainer = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 2;
  padding: 0px 10px;
  height: 50px;
`;

const SearchInput = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    
    span {
        font-size: 30px;
        margin-right: 10px;
        margin-left: 5px;
    }
    
    input {
        width: 100%;
        font-size: 22px;
        border: none;
        outline: none;
        background: none;
        
        &::placeholder {
          font-weight: 500;
        }
    }
`;

export const UIPaneResultsContainer = styled.div`
  margin-top: 90px;
  padding: 0px 0px 20px 20px;
  overflow-y: scroll;
  flex-grow: 2;
  position: relative;
  overflow-x: hidden;
`;

export const UIPaneResultsInnerContainer = styled.div`
  position: absolute;
  left: 20px;
  right: 0;
`;

export const InnerWhiteFrameContainer = styled.div`
  background: white;
  padding: 20px;
`;

export const StackableSearchBarContainer = styled(SearchBarContainer)`
  position: static;
`;

export const StackableUIPaneResultsContainer = styled(UIPaneResultsContainer)`
  margin-top: 0;
  flex-grow: 0;
  overflow-y: hidden;
`;

const StyledIcon = styled(Icon)`
    &&&{
        color: "#666666";
    }
`;

export class SlideSearchInput extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.state = {
            query: props.query || ""
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.ref.current.focus();
            this.ref.current.select();
        }, 0);
    }

    onChange = event => {
        const { searchOnEnter } = this.props;
        this.updateQuery(event.target.value, searchOnEnter);
    };

    updateQuery = (query, skipSearch = false) => {
        const { onSearch } = this.props;
        this.setState({
            query
        });
        if (!skipSearch) {
            onSearch(query);
        }
    }

    handleKeyDown = event => {
        const { query } = this.state;
        if (
            this.props.searchOnEnter &&
            event.key === "Enter"
        ) {
            this.props.onSearch(query);
        }
    }

    render() {
        const { chip, placeholder, onRemoveChip, showShortCuts } = this.props;
        const { query } = this.state;

        return (
            <FlexBox fillWidth>
                <SearchInput>
                    <Icon color="primary">search</Icon>
                    {chip && (
                        <StyledChip
                            clickable={true}
                            color="default"
                            label={chip}
                            onDelete={() => onRemoveChip(chip)}
                            deleteIcon={<StyledIcon fontSize="small">close</StyledIcon>}
                        />
                    )
                    }
                    <input
                        ref={this.ref}
                        autoFocus
                        value={query}
                        type="text"
                        spellCheck="false"
                        placeholder={placeholder}
                        onChange={this.onChange}
                        onKeyDown={this.handleKeyDown}
                    />
                    {query != "" &&
                    <Icon className="clear-search" onClick={() => this.updateQuery("")}>close</Icon>
                    }

                </SearchInput>
                {(showShortCuts && query == "") && <SearchShortCuts handleShortCut={this.updateQuery} /> }
            </FlexBox>
        );
    }
}

