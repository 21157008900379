import React, {useEffect} from "react";
import styled from "styled-components";

import {Icon, TextField} from "@material-ui/core";
import {themeColors} from "js/react/sharedStyles";
import {
    ContextSource
} from "js/react/views/AIGeneration/panes/GeneratePresentationPane/controllers/GeneratePresentationController";
import {SimpleCaption} from "js/react/components/SimpleCaption";
import {Gap10} from "js/react/components/Gap";
import {SimpleLabel} from "js/react/components/SimpleLabel";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 10px;

`;

const URLInput = styled.div<{ isUrlValid: boolean }>`
    width: 100%;
    height: 50px;

    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 0 10px;
    font-size: 16px;

    border: 1px solid ${themeColors.lightGray};

    > div.MuiTextField-root {
        width: 100%;
    }

    > span.MuiIcon-root {
        color: ${themeColors.mediumGray};
    }

    input.MuiInputBase-input::-webkit-input-placeholder {
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: ${themeColors.darkGray};
    }

    input.MuiInputBase-input {
        color: ${({isUrlValid}) => isUrlValid ? themeColors.darkGray : themeColors.warning};
    }
`;

const WebPreview = styled.iframe`
    width: 100%;
    flex-grow: 1;
    border: solid 1px #ccc;
    outline: none;
`;

interface WebpageUrlInputProps {
    contextSource: ContextSource;
}

function checkUrl(url: string) {
    if (!url) {
        return true;
    }

    try {
        const parsedUrl = new URL("https://" + url);
        return parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
    } catch {
        return false;
    }
}

function WebpageUrlInput({contextSource}: WebpageUrlInputProps) {
    const webpageUrl = contextSource.url;

    // const webPreviewRef = React.useRef(null);
    const [rawUrl, setRawUrl] = React.useState(webpageUrl);
    const [isUrlValid, setIsUrlValid] = React.useState(checkUrl(webpageUrl));
    const [isExcludedDomain, setIsExcludedDomain] = React.useState(false);

    useEffect(() => {
        // Prevent iframe from using credentials
        // webPreviewRef.current.setAttribute("credentialless", true);
    });

    const handleRawUrlChange = event => {
        let url = event.target.value;

        url = url.replaceAll("https://", "");

        let isExcluded = false;
        const excludedDomains = ["linkedin.com"];
        for (let domain of excludedDomains) {
            if (url.contains(domain)) {
                isExcluded = true;
            }
        }
        setIsExcludedDomain(isExcluded);

        const isValid = checkUrl(url) && !isExcluded;

        setRawUrl(url);
        setIsUrlValid(isValid);

        contextSource.url = (isValid ?  `https://${url}` : "");
    };

    return (
        <Container>
            <URLInput isUrlValid={isUrlValid}>
                <Icon>language</Icon>
                <Gap10/>
                https://
                <TextField
                    value={rawUrl}
                    onChange={handleRawUrlChange}
                    placeholder={"Type URL..."}
                    InputProps={{disableUnderline: true}}
                />
            </URLInput>
            {isExcludedDomain &&
                <label style={{color: "orangered"}}>This domain or url is not available to use for AI context. You can
                    copy the text from the
                    page and paste into the text content instead.</label>
            }
            <SimpleCaption>NOTE: The webpage must be publicly available and not require authentication or
                cookies. At this time, only text from the webpage will be used as context. Images and theming from the webpage will not be utilized in the generated presentation.</SimpleCaption>
            {/*<Gap10 />*/}
            {/*<WebPreview ref={webPreviewRef} src={webpageUrl} />*/}
        </Container>
    );
}

export default WebpageUrlInput;
