import { app } from "js/namespaces";
import { PERMISSION_RESOURCE_TYPE } from "common/constants";
import StorageModel from "js/core/storage/storageModel";
import ReferenceCollection from "js/core/storage/referenceCollection";
import { isOfflinePlayer } from "js/config";
import { LibraryItems } from "./libraryItem";
import { Backbone } from "js/vendor";
import Api from "js/core/api";
import AppController from "js/core/AppController";

const Team = StorageModel.extend({
    root: "teams",
    readonly: isOfflinePlayer,

    loadLibraryItems: function() {
        this.libraryItems = new LibraryItems(null, { teamId: this.id });
        return this.libraryItems.loadModels();
    },

    checkIfSharedThemeBelongsToOrg: function(presentationSharedThemeId) {
        if (this.get("isDefault")) {
            if (this.get("sharedThemeId") === presentationSharedThemeId) {
                return true;
            }
            const sharedThemes = this.get("sharedResources")?.[PERMISSION_RESOURCE_TYPE.SHARED_THEME];
            if (sharedThemes) {
                if (sharedThemes[presentationSharedThemeId]) {
                    return true;
                }
            }
        }
        return false;
    },

    getUserRole: function() {
        return this.get("members")[app.user.id].role;
    },

    checkIfUserHasSeat: function(member) {
        if (!this.get("members")[member.id]) {
            return false;
        }
        return this.get("members")[member.id].hasSeat;
    },

    destroy(options) {
        //Call Backbone's destroy instead of the StorageModel's destroy so we can call our delete api instead.
        Backbone.Model.prototype.destroy.call(this, options);
        this.destroyed = true;
        this.adapter.disconnect();
        //delete the team via an endpoint.
        if (!options || !options.remoteChange) {
            return Api.teams.delete({
                teamId: this.id,
                orgId: this.get("orgId")
            });
        } else {
            return Promise.resolve({});
        }
    },
});

const Teams = ReferenceCollection.extend({
    model: Team,
    ignoreErrors: true,
    referenceRoot: "users",

    getReferenceId: function() {
        return `${this.userId}/teams`;
    },

    initialize: function() {
        this.type = "Teams";
        this.userId = app.user.id;
    },

    defaultTeamForOrg(orgId) {
        if (this.length < 1) {
            return null;
        }

        if (orgId === undefined) {
            throw new Error("orgId required");
        }

        const defaultTeam = this.models.find(model => {
            return model.get("orgId") === orgId && model.get("isDefault");
        });

        if (!defaultTeam) {
            throw new Error("Cannot get default team: user is not a member of the given org");
        }

        return defaultTeam;
    },

    isUserLibrarianOrOwner(orgId = null) {
        if (!orgId) {
            orgId = AppController.orgId;
        }
        let role = null;
        try {
            let team = this.defaultTeamForOrg(orgId);
            role = team.getUserRole();
        } catch {
            // Do nothing.
        }
        return role === "librarian" || role === "owner";
    },
});

export { Team, Teams };
