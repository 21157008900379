import { _ } from "js/vendor";
import { ds } from "js/core/models/dataService";
import { Slide } from "js/core/models/slide";
import * as appSearch from "js/core/services/appSearch";
import * as Thumbnails from "js/core/models/thumbnails";

import DataService from "./DataService";
import { THUMBNAIL_SIZES } from "common/constants";

interface UserSlidesDataServiceSearchQuery {
    fullTextSearchQuery?: string,
    fullTextSearchByFields?: string[],
    size?: number,
    minScore?: number,
    presentationId?: string
    organizationId?: string
}

interface UserSlidesDataServiceDataItem {
    id: string,
    index: number,
    presentationId: string,
    presentationName: string,
    organizationId: string,
    getThumbnailUrl: () => Promise<string>,
    score: number
}

class UserSlidesDataService extends DataService {
    public async search(query: UserSlidesDataServiceSearchQuery): Promise<UserSlidesDataServiceDataItem[]> {
        const {
            fullTextSearchQuery,
            fullTextSearchByFields = [],
            size = UserSlidesDataService.SEARCH_QUERY_SIZE,
            minScore = UserSlidesDataService.SEARCH_QUERY_MIN_SCORE,
            presentationId,
            organizationId
        } = query;

        let searchFields = undefined;
        if (fullTextSearchByFields.length) {
            searchFields = fullTextSearchByFields.reduce((searchFields, fieldName) => {
                return {
                    ...searchFields,
                    [fieldName]: {},
                };
            }, {});
        }

        let { results: appsearchResults } = await appSearch.search({
            workspaceId: organizationId ?? "personal",
            searchEngine: appSearch.SearchEngine.USER_SLIDES,
            query: fullTextSearchQuery ?? "",
            // x3 is a rough estimation to mitigate the subsequent filtering by slides not being soft deleted
            // (we don't want to always use UserSlidesDataService.SEARCH_QUERY_SIZE because we'd have to load
            // too many presentations which is expensive)
            page: { size: Math.min(UserSlidesDataService.SEARCH_QUERY_SIZE, size * 3) },
            filters: {
                presentation_id: presentationId ? [presentationId] : undefined,
                org_id: (!presentationId && organizationId) ? [organizationId] : undefined
            },
            searchFields,
        });

        appsearchResults = appsearchResults
            // Filter by score to avoid excessive presentations loading
            .filter(result => result._meta.score >= minScore);

        let searchResults: UserSlidesDataServiceDataItem[] = await Promise.all(appsearchResults.map(async ({
            slide_id: { raw: slideId },
            presentation_id: { raw: presentationId },
            modified_at,
            _meta: { score }
        }): Promise<UserSlidesDataServiceDataItem | null> => {
            const presentation = await ds.presentations.getPresentation(presentationId)
                .catch(err => {
                    if ([404, 403].includes(err.status ?? err.statusCode)) {
                        // Ignore presentation not found and permission denied errors
                        return;
                    }

                    throw err;
                });
            if (!presentation) {
                return null;
            }

            const slideIndex = presentation.getSips().indexOf(slideId);
            if (slideIndex < 0) {
                return null;
            }

            const modifiedAt = modified_at?.raw;
            return {
                id: slideId,
                index: presentation.getSips().indexOf(slideId),
                presentationId: presentation.id,
                presentationName: presentation.get("name"),
                organizationId: presentation.get("orgId"),
                getThumbnailUrl: (showThemeThumbnails = false) => {
                    if (showThemeThumbnails && ds.selection.presentation && !ds.selection.presentation.get("isDummy")) {
                        const presentationId = ds.selection.presentation.id;
                        const firstSlideModifiedAt = ds.selection.presentation.get("firstSlideModifiedAt");
                        return Thumbnails.getSignedUrlAndLoad(slideId, firstSlideModifiedAt, presentationId, THUMBNAIL_SIZES["small"].suffix);
                    }

                    const slide = new Slide({ id: slideId }, { autoLoad: false });

                    if (modifiedAt) {
                        // No need to load slide model
                        return slide.getThumbnailUrl(presentation.id, "small", 0, true, modifiedAt);
                    } else {
                        return slide.load().then(() => slide.getThumbnailUrl(presentation.id));
                    }
                },
                score
            };
        }));

        searchResults = searchResults
            // Remove non existing slides
            .filter(slide => !!slide)
            // Respect size
            .slice(0, size);

        if (presentationId) {
            // Sort by slide index
            searchResults = searchResults
                .sort((a, b) => a.index - b.index);
        } else {
            // Sort by score desc
            searchResults = searchResults
                .sort((a, b) => b.score - a.score);
        }

        return searchResults;
    }
}

export default UserSlidesDataService;
