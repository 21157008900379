import { Grid, Icon, DialogTitle, Fade, Button } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import db from "js/db";
import { getStaticUrl } from "js/config";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { StandalonePage, StandaloneForm, StandaloneDialogContent } from "./Standalone";

const blueCheckIcon = getStaticUrl("/images/onboarding/check.png");

const PlanBox = styled.div`
    color: #485159;
    flex-grow: 1;
    border: 1px solid #a9a9a9;
    border-radius: 2px;
    min-height: 100%;
`;

const PlanIntent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background: #DBE9F0;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-size: 13px;

    .MuiIcon-root {
        margin-right: 10px;
        color: #11a9e2;
    }
`;

const PlanName = styled.h2`
    color: black;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    text-align: center;
    margin-bottom: 5px;
`;

const PlanInfo = styled.div`
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
`;

const PlanPrice = styled.div`
    text-align: center;
    padding-bottom: 20px;
`;

const PlanMore = styled.div`
    font-weight: bold;
    padding-left: 30px;
    padding-top: 15px;
`;

const FeatureList = styled.ul`
    list-style-type: none;
    font-size: 15px;
    padding-left: 32px;
    margin-block-end: 30px;

    li {
        margin-bottom: .5em;
    }

    li::before {
        content: "";
        background-image: url(${blueCheckIcon});
        background-position: 0 3px;
        background-size: auto 11px;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        padding-right: 3px;
    }
`;

const PaddedButton = styled(Button)`
    padding: 6px 30px;

    .MuiButton-label {
        letter-spacing: 0.8px;
    }
`;

const HiddenButton = styled.div`
  display: block;
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 3px;
  height: 3px;
  opacity: 0;
`;

export const ChoosePlan = withRouter(withFirebaseUser(class ChoosePlanImpl extends React.Component {
    state = {
        fadeOut: false,
        fadeOutBackground: false,
    };

    saveConfirm = async () => {
        await db.getDbInstance("users").ref(`users/${this.props.firebaseUser.uid}`).update({
            didConfirmPlan: true
        });
    }

    handlePro = async () => {
        this.saveConfirm();
        this.setState({ fadeOut: true });
        setTimeout(() => {
            this.props.history.push("/checkout?plan=pro&billingTerm=year&continue=" + encodeURIComponent(this.props.continueUrl || "/"));
        }, 750);
    }

    handleTeam = async () => {
        this.saveConfirm();
        this.setState({ fadeOut: true });
        setTimeout(() => {
            this.props.history.push("/checkout?plan=team&billingTerm=year&continue=" + encodeURIComponent(this.props.continueUrl || "/"));
        }, 750);
    }

    handleBasic = async () => {
        this.saveConfirm();
        this.setState({ fadeOut: true });
        setTimeout(() => {
            this.setState({ fadeOutBackground: true });
            setTimeout(() => {
                this.props.nextStep();
            }, 750);
        }, 750);
    }

    render() {
        return (
            <Fade in={!this.state.fadeOutBackground} timeout={this.state.fadeOutBackground ? 750 : 0}>
                <StandalonePage>
                    <Fade in={!this.state.fadeOut} timeout={750}>
                        <StandaloneForm
                            open={true}
                            hideBackdrop
                            fullWidth
                        >
                            <DialogTitle align="center" style={{ paddingBottom: 0 }}>
                                Choose the plan that's right for you
                            </DialogTitle>
                            <StandaloneDialogContent style={{ paddingTop: 30, paddingBottom: 40 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <PlanBox>
                                            <PlanIntent>
                                                <Icon>person</Icon>
                                                <span>For individuals</span>
                                            </PlanIntent>
                                            <PlanName>Pro</PlanName>
                                            <PlanInfo>
                                                <PlanPrice>From $12/mo</PlanPrice>
                                                <PaddedButton
                                                    id="choose-pro"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handlePro}
                                                >
                                                    Try it free
                                                </PaddedButton>
                                            </PlanInfo>
                                            <PlanMore>Everything for professionals:</PlanMore>
                                            <FeatureList>
                                                <li>Version History</li>
                                                <li>Powerpoint Export</li>
                                                <li>Viewer Analytics</li>
                                            </FeatureList>
                                        </PlanBox>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <PlanBox>
                                            <PlanIntent>
                                                <Icon>apartment</Icon>
                                                <span>For companies</span>
                                            </PlanIntent>
                                            <PlanName>Team</PlanName>
                                            <PlanInfo>
                                                <PlanPrice>From $40/mo per user</PlanPrice>
                                                <PaddedButton
                                                    id="choose-team"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={this.handleTeam}
                                                >
                                                    Try it free
                                                </PaddedButton>
                                            </PlanInfo>
                                            <PlanMore>Everything in Pro, plus:</PlanMore>
                                            <FeatureList>
                                                <li>Collaborative Workspace</li>
                                                <li>Custom Company Theme</li>
                                                <li>PowerPoint Import</li>
                                                <li>Slide and Template Library</li>
                                            </FeatureList>
                                        </PlanBox>
                                    </Grid>
                                </Grid>
                                <HiddenButton
                                    id="choose-basic"
                                    variant="contained"
                                    onClick={this.handleBasic}
                                />
                            </StandaloneDialogContent>
                        </StandaloneForm>
                    </Fade>
                </StandalonePage>
            </Fade>
        );
    }
}));
