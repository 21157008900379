import { GlobalStateController } from "bai-react-global-state";

import { workspaces } from "apis/callables";
import {
    ISanitizedWorkspace,
    IWorkspace,
    IWorkspaceDocumentChangedEvent,
    IWorkspacePermission,
    IWorkspaceResource
} from "common/interfaces";
import getLogger, { LogGroup } from "js/core/logger";
import pusher, { ExtendedChannel } from "js/core/services/pusher";
import { PusherEventType } from "common/constants";

const logger = getLogger(LogGroup.WORKSPACES);

export interface WorkspaceControllerState {
    workspace: IWorkspace | ISanitizedWorkspace;
    resources: (IWorkspaceResource & { permission: IWorkspacePermission })[];
    isInitialized: boolean;
    isFetchingData: boolean;
    initializeError: Error | null;
}

export class WorkspaceController extends GlobalStateController<WorkspaceControllerState> {
    private _initializePromise: Promise<void>;
    private _workspaceId: string;

    private _pusherChannel: ExtendedChannel;
    private _onPusherEvent: (event: IWorkspaceDocumentChangedEvent) => void;

    constructor(workspaceId: string) {
        super({ workspace: null, resources: [], isInitialized: false, isFetchingData: false, initializeError: null });

        this._workspaceId = workspaceId;

        this._onPusherEvent = async (event: IWorkspaceDocumentChangedEvent) => {
            try {
                if (event.documentType === "Workspace") {
                    await this._fetchWorkspace();
                    return;
                }

                if (event.documentType === "WorkspaceResource") {
                    if (event.operationType === "create" || event.operationType === "update") {
                        await this._fetchResource(event.documentId);
                    }
                    if (event.operationType === "delete") {
                        await this._removeResource(event.documentId);
                    }
                    return;
                }

                if (event.documentType === "WorkspacePermission") {
                    if (event.operationType === "create" || event.operationType === "update") {
                        await this._fetchResourcesForPermission(event.documentId);
                    }
                    if (event.operationType === "delete") {
                        await this._removeResourceForPermission(event.documentId);
                    }
                    return;
                }
            } catch (err) {
                logger.error(err, "[WorkspaceController] _onPusherEvent() failed", { workspaceId: this._workspaceId });
            }
        };
    }

    get workspaceId() {
        return this._workspaceId;
    }

    get workspace() {
        return this._state.workspace;
    }

    get isInitialized() {
        return this._state.isInitialized;
    }

    get initializePromise() {
        return this._initializePromise;
    }

    public initialize() {
        if (!this._initializePromise) {
            this._initializePromise = (async () => {
                try {
                    await Promise.all([
                        this._fetchWorkspace(),
                        this._fetchResources()
                    ]);

                    this._pusherChannel = await pusher.subscribe(`private-workspace-${this._workspaceId}`);
                    this._pusherChannel.bind(PusherEventType.DATA_RECORD_UPDATED, this._onPusherEvent);

                    await this._updateState({ isInitialized: true });
                } catch (err) {
                    logger.error(err, "[WorkspaceController] initialize() failed", { workspaceId: this._workspaceId });
                    await this._updateState({ initializeError: err });
                    throw err;
                }
            })();
        }

        return this._initializePromise;
    }

    public dispose() {
        this._pusherChannel.unbind(PusherEventType.DATA_RECORD_UPDATED, this._onPusherEvent);
        if (!this._pusherChannel.isInUse) {
            pusher.unsubscribe(this._pusherChannel.name);
        }
    }

    private async _fetchWorkspace() {
        await this._updateState({ isFetchingData: true });

        const workspace = await workspaces.getWorkspace({ id: this._workspaceId });

        await this._updateState({ workspace, isFetchingData: false });
    }

    private async _fetchResources() {
        await this._updateState({ isFetchingData: true });

        const resources = await workspaces.getWorkspaceResources({ id: this._workspaceId });

        await this._updateState({ resources, isFetchingData: false });
    }

    private async _fetchResource(resourceId: string) {
        await this._updateState({ isFetchingData: true });

        const resource = await workspaces.getWorkspaceResource({ workspaceId: this._workspaceId, resourceId });

        await this._updateState(state => ({
            ...state,
            resources: [...state.resources.filter(r => r.id !== resourceId), resource],
            isFetchingData: false
        }));
    }

    private async _fetchResourcesForPermission(permissionId: string) {
        await this._updateState({ isFetchingData: true });

        const resources = await workspaces.getWorkspaceResources({ id: this._workspaceId, filter: { permissionId } });

        await this._updateState(state => ({
            ...state,
            resources: [...state.resources.filter(stateResource => !resources.some(r => r.id === stateResource.id)), ...resources],
            isFetchingData: false
        }));
    }

    private async _removeResource(resourceId: string) {
        await this._updateState(state => ({
            ...state,
            resources: [...state.resources.filter(r => r.id !== resourceId)],
            isFetchingData: false
        }));
    }

    private async _removeResourceForPermission(permissionId: string) {
        await this._updateState(state => ({
            ...state,
            resources: [...state.resources.filter(r => r.permission.id !== permissionId)],
            isFetchingData: false
        }));
    }
}
