import firebase from "firebase";

import { workspaces as workspacesApi } from "apis/callables";
import getLogger, { LogGroup } from "js/core/logger";

import { WorkspaceController } from "./WorkspaceController";

const logger = getLogger(LogGroup.DATA_SERVICE);

export class WorkspacesDataService {
    private _workspaceControllers: { [workspaceId: string]: WorkspaceController } = {};
    private _user: firebase.User;

    private _initializePromise: Promise<void>;

    constructor(user: firebase.User) {
        this._user = user;

        this._initializePromise = this._setWorkspaceControllers();
    }

    get initializePromise() {
        return this._initializePromise;
    }

    public async getWorkspaceControllerForLegacyId(legacyWorkspaceId: string) {
        await this._initializePromise;

        for (const workspaceController of Object.values(this._workspaceControllers)) {
            await workspaceController.initializePromise;

            const workspace = workspaceController.workspace;
            if (legacyWorkspaceId === "personal"
                ? (workspace.migrationInfo.originalEntityType === "User" && workspace.migrationInfo.originalEntityId === this._user.uid)
                : (workspace.migrationInfo.originalEntityType === "Organization" && workspace.migrationInfo.originalEntityId === legacyWorkspaceId)) {
                return workspaceController;
            }
        }

        return null;
    }

    public async getWorkspaceController(workspaceId: string) {
        await this._initializePromise;

        const controller = this._workspaceControllers[workspaceId];
        if (!controller) {
            return null;
        }

        await controller.initializePromise;
        return controller;
    }

    public dispose() {
        Object.keys(this._workspaceControllers).forEach(workspaceId => {
            this._disposeWorkspaceController(workspaceId);
        });
    }

    private async _setWorkspaceControllers() {
        const workspaces = await workspacesApi.getWorkspaces({});
        const workspaceIds = workspaces.map(workspace => workspace.id);

        for (const workspaceId of workspaceIds) {
            if (!this._workspaceControllers[workspaceId]) {
                this._workspaceControllers[workspaceId] = new WorkspaceController(workspaceId);
                this._workspaceControllers[workspaceId].initialize().catch(err => {
                    logger.error(err, `[WorkspacesDataService] Error initializing workspace controller for workspace ${workspaceId}`);
                });
            }
        }
        for (const workspaceId of Object.keys(this._workspaceControllers)) {
            if (!workspaceIds.includes(workspaceId)) {
                this._disposeWorkspaceController(workspaceId);
            }
        }
    }

    private _disposeWorkspaceController(workspaceId: string) {
        if (this._workspaceControllers[workspaceId]) {
            this._workspaceControllers[workspaceId].dispose();
            delete this._workspaceControllers[workspaceId];
        }
    }
}
