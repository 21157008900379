import LogRocket from "logrocket";

import { _ } from "js/vendor";
import { app } from "js/namespaces";
import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import firebase from "js/firebase";
import { isInternalBAIUser, isPPTAddin, isCypress } from "js/config";
import { ds } from "js/core/models/dataService";
import { delay } from "js/core/utilities/promiseHelper";
import { ShowSnackBar } from "js/react/components/Dialogs/SnackBar";

const logger = getLogger(LogGroup.ANALYTICS);

// Default state
let state = {
    context: isPPTAddin ? "ppt" : "app",
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
};

// tracking window size
const setWindowSize = () => {
    state.windowWidth = window.innerWidth;
    state.windowHeight = window.innerHeight;
};

window.addEventListener("resize", setWindowSize);

async function determinePlanForAnalytics() {
    if (app.user) {
        await app.user.load();
        await delay(100);
        if (ds.teams && app.user.has("orgs")) {
            // if user is in more than one team workspace, just use the first org (this should be rare)
            const orgId = Object.keys(app.user.get("orgs"))[0];
            await ds.teams.loadModels();
            const team = ds.teams.defaultTeamForOrg(orgId);
            const role = team.getUserRole();
            return role;
        } else if (ds.userPlans) {
            await ds.userPlans.loadModels();
            const role = ds.userPlans.getPersonalPlan();
            return role;
        }
    } else {
        return "N/A";
    }
}

export async function identify(user, extraProps, isNew) {
    if (window.debugAnalytics) {
        logger.info("identify()", { uid: user.uid });
    }

    const plan = await determinePlanForAnalytics();

    if (!isCypress && isInternalBAIUser(user.email)) {
        LogRocket.init("1xdce1/beautifulai");
        LogRocket.identify(user.uid, {
            name: user.displayName || user.email,
            email: user.email,
            subscriptionType: plan
        });
    }

    const { claims } = await user.getIdTokenResult();
    if (claims.session_tracking_disabled) {
        logger.info(`identify() ${user.uid} user has the "session_tracking_disabled" custom claim set to true, won't be tracking session`, { uid: user.uid });
        return;
    }

    const userInfo = app.user?.get("userInfo") || {};

    window.dataLayer.push({ userId: user.uid });

    if (window.zE) {
        window.zE(function() {
            window.zE.identify({
                email: user.email,
                name: user.displayName || user.email,
                // TODO: this is going to be incorrect, but Zendesk doesn't update it anyway
                // https://developer.zendesk.com/embeddables/docs/widget/core#identify
                currentPlan: app.user && app.user.analyticsPersonalPlan,
                plan,
                ...userInfo
            });
        });
    }

    if (window.amplitude) {
        window.amplitude.getInstance().setUserId(user.uid);
        const userProps = Object.assign({}, extraProps);
        if (isNew) {
            userProps.initial_signup_date = new Date().toISOString();
        }
        setUserProps({
            ...userProps,
            plan,
            ...userInfo
        });
    }

    if (window.Appcues) {
        const workspaceId = app.appController?.workspaceId || "personal";
        const planDetail = (workspaceId === "personal" ? app.user?.personalPlan : app.user?.teamPlans?.[workspaceId]) ?? {};

        if (!planDetail) {
            return;
        }

        window.Appcues.identify(user.uid, {
            createdAt: user.metadata.creationTime,
            planTier: app.user && app.user.analyticsPersonalPlan,
            plan,
            stripePlanName: planDetail.planName,
            stripeSubscriptionStatus: planDetail.subscriptionStatus,
            ...(planDetail.organizationId ? {
                organizationId: planDetail.organizationId,
                organizationName: planDetail.organizationName
            } : { organizationId: null, organizationName: null }),
            firstName: user.displayName,
            email: user.email,
            ...userInfo
        });

        if (planDetail.organizationId) {
            window.Appcues.group(planDetail.organizationId, {
                organizationId: planDetail.organizationId,
                organizationName: planDetail.organizationName,
                stripePlanName: planDetail.planName,
                stripeSubscriptionStatus: planDetail.subscriptionStatus
            });
        }
    }
}

/**
 * Updates user records in analytics providers to reflect the current workspace user is on
 */
export async function trackWorkspaceChange(user) {
    const workspaceId = app.appController?.workspaceId || "personal";
    const planDetail = workspaceId === "personal" ? app.user?.personalPlan : app.user?.teamPlans[workspaceId];

    if (window.Appcues) {
        window.Appcues.identify(user.uid, {
            stripePlanName: planDetail.planName,
            stripeSubscriptionStatus: planDetail.subscriptionStatus,
            ...(planDetail.organizationId ? {
                organizationId: planDetail.organizationId,
                organizationName: planDetail.organizationName
            } : { organizationId: null, organizationName: null }),
        });

        if (planDetail.organizationId) {
            window.Appcues.group(planDetail.organizationId, {
                organizationId: planDetail.organizationId,
                organizationName: planDetail.organizationName,
                stripePlanName: planDetail.planName,
                stripeSubscriptionStatus: planDetail.subscriptionStatus
            });
        }
    }
}

/**
 * Update the state that is sent along with trackEvent().
 */
export function trackState(props) {
    const newState = _.merge({}, state, props);
    if (window.debugAnalytics && !_.isEqual(state, newState)) {
        logger.info("state", newState);
    }
    state = newState;
}

export function getState(propName) {
    return state[propName];
}

/**
 * Helper to recursively update any state variables which appear in event props.
 */
function updateState(obj, updates, path = []) {
    for (const [key, value1] of Object.entries(obj)) {
        const childPath = [...path, key];
        const value2 = updates[key];
        if (value1 === undefined || value1 === null) {
            continue;
        }
        if (typeof value1 == "object") {
            updateState(value1, value2, childPath);
        } else {
            if (value1 === value2) {
                if (window.debugAnalytics) {
                    logger.warn(`[ANALYTICS] "${childPath.join(".")}" is redundant - tracked in state`);
                }
            } else if (updates.hasOwnProperty(key)) {
                if (window.debugAnalytics) {
                    logger.info("state", { update: childPath.join(".") + "->" + value2 });
                }
                obj[key] = value2;
            }
        }
    }
}

/**
 * Track an event.
 */
export async function track(options) {
    const user = firebase.auth().currentUser;
    if (user) {
        const { claims } = await user.getIdTokenResult();
        if (claims.session_tracking_disabled) {
            logger.info(`track() ${user.uid} user has the "session_tracking_disabled" custom claim set to true, won't be tracking session`, { uid: user.uid });
            return;
        }
    }

    // merge state into props
    const props = _.merge({}, state, options.props);
    const role = props.role;

    const eventId = options.category + ":" + options.action;

    // update any state variables that are changed in props
    updateState(state, options.props);

    if (window.debugAnalytics) {
        logger.info("track", { ...options, eventId, props });

        // Delay showing the snackbar so that mounting works properly in player view.
        setTimeout(
            () => ShowSnackBar({
                message: `[TRACKING EVENT] ${eventId}`
            }),
            500
        );
    }

    if (!props.workspace_id) {
        logger.warn(`[ANALYTICS] options.props.workspace_id required in all events - ${eventId}`);
    }

    const removePrefix = (obj, prefix) => {
        Object.keys(obj).forEach(key => {
            if (key.indexOf(prefix) === 0) {
                obj[key.slice(3)] = obj[key];
                delete obj[key];
            }
        });
    };

    const sp_props = { ...props };
    removePrefix(sp_props, "sp_");
    const pg_props = { ...props };
    removePrefix(pg_props, "pg_");

    const event = {
        event: eventId,
        eventCategory: options.category,
        eventAction: options.action,
        eventLabel: options.label,
        eventProperty: sp_props,
        eventValue: options.value,
        eventUserId: user ? user.uid : null,
        eventRole: role
    };
    const flushEvent = {
        event: "Flush",
        eventCategory: undefined,
        eventAction: undefined,
        eventLabel: undefined,
        eventProperty: undefined,
        eventValue: undefined,
        eventRole: undefined
    };

    if (options.audit) {
        delete pg_props.email;
        delete pg_props.team_id;
        delete pg_props.affected_user_id;
        delete pg_props.presentationId;
        delete pg_props.workspace_id;
        pg_props.label = options.label;
        pg_props.value = options.value;

        const columns = {
            category: options.category,
            action: options.action,
            team_id: props.team_id,
            affected_user_id: props.pg_affected_user_id,
            presentation_id: props.presentationId,
            workspace_id: props.workspace_id,
            props: JSON.stringify(pg_props),
            email: user.email,
        };

        Api.audit.post({
            namespace: "analytics",
            action: null,
            props: columns
        })
            .catch(err => {
                logger.error(err, "Api.audit.post() failed");
            });
    }

    if (window.dataLayer) {
        // flush the full event to prevent GTM from re-firing previous event
        window.dataLayer.push(flushEvent);
        window.dataLayer.push(event);
    }

    if (window.amplitude && options.skipAmplitude !== true) {
        window.amplitude.getInstance().logEvent(
            eventId,
            Object.assign(
                {
                    label: options.label,
                    value: options.value
                },
                props
            )
        );
    }

    if (user && user.uid && window.Appcues) {
        window.Appcues.track(eventId, event);
    }
}

export function setUserProps(props) {
    props = props || {};
    if (window.amplitude) {
        let hasUpdates = false;
        let identify = new window.amplitude.Identify();
        for (let key in props) {
            hasUpdates = true;
            if (props.hasOwnProperty(key)) {
                if (props[key] === null) {
                    identify = identify.unset(key);
                }
                identify = identify.set(key, props[key]);
            }
        }

        if (hasUpdates) {
            window.amplitude.getInstance().identify(identify);
        }
    }

    if (window.debugAnalytics) {
        logger.info("setUserProps", props);
    }
}

export function incUserProps(props) {
    props = props || {};
    if (window.amplitude) {
        let hasUpdates = false;
        let identify = new window.amplitude.Identify();
        for (let key in props) {
            hasUpdates = true;
            if (props.hasOwnProperty(key)) {
                identify = identify.add(key, props[key]);
            }
        }
        if (hasUpdates) {
            window.amplitude.getInstance().identify(identify);
        }
    }

    if (window.debugAnalytics) {
        logger.info("incUserProps", props);
    }
}

export function getDebugAnalyticsState() {
    return state;
}

export async function audit(namespace, action, properties) {
    if (!navigator.onLine) {
        return;
    }

    await Api.audit.post({
        namespace: namespace,
        action: action,
        props: properties
    })
        .catch(err => {
            logger.error(err, "Api.audit.post() failed");
        });
}
