import React, { Component } from "react";
import { Button, Select, MenuItem, DialogTitle, DialogActions } from "@material-ui/core";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import { presentations as presentationsApi } from "apis/callables";
import { Presentation } from "js/core/models/presentation";
import { ds } from "js/core/models/dataService";
import Loadable from "../Loadable";
import { _ } from "js/vendor";
import permissionsDS from "js/react/views/PresentationSettings/dataservices/PermissionsDataService";
import { getStaticUrl } from "js/config";
import { FormIcon } from "js/react/views/Onboarding/Standalone";
import { FlexBox } from "../LayoutGrid";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.TEAMS);

const baiBotImage = getStaticUrl("/images/bai-bot/bai-bot-shake.gif");

export default class MoveWorkspacesDialog extends Component {
    constructor(props) {
        super(props);
        const {
            workspaces,
            currentWorkspaceId
        } = this.props;
        this.state = {
            isLoading: true,
            workspace: currentWorkspaceId === "personal" ? workspaces.find(w => w.id !== "personal").id : "personal",
            movedPresentations: [],
            unmovedPresentationNames: [],
            presentationPermissions: {},
            uniquePermisions: [],
        };

        this.inputRef = React.createRef();
    }

    componentWillMount() {
        const {
            presentations,
            teams,
        } = this.props;

        const movedPresentations = presentations.filter(item => item.presentationModel.permissions.owner)
            .map(item => item.presentationModel);

        const unmovedPresentationNames = presentations.filter(item => !item.presentationModel.permissions.owner)
            .map(item => item.presentationModel.get("name"));

        const teamIds = teams.models.map(x => x.attributes.id);

        const apiRequests = movedPresentations.map(presentation =>
            permissionsDS.getPermissions(presentation.id, teamIds)
                .catch(err => {
                    logger.error(err, "[MoveWorkspacesDialog] permissionsDS.getPermissions() failed");
                })
        );
        Promise.allSettled(apiRequests)
            .then(results => {
                // Map the permissions by each presentationId
                let presentationPermissions = results
                    .filter(result => result.status === "fulfilled")
                    .reduce((presentationPermissions, result, index) => {
                        const presentationId = movedPresentations[index].id;
                        presentationPermissions[presentationId] = result.value
                            .filter(permission => permission.type !== "owner");
                        return presentationPermissions;
                    }, {});

                return presentationPermissions;
            })
            .then(presentationPermissions => {
                const uniquePermisions = _.flatten(Object.values(presentationPermissions))
                    .reduce((uniquePermissions, permission) => {
                        // Only retain the permission if one isn't already present for this user
                        if (!uniquePermissions.filter(x => x.id === permission.id).length) {
                            uniquePermissions.push(permission);
                        }
                        return uniquePermissions;
                    }, []);
                this.setState({
                    isLoading: false,
                    movedPresentations,
                    unmovedPresentationNames,
                    presentationPermissions,
                    uniquePermisions,
                });
            });
    }

    render() {
        const {
            closeDialog,
            workspaces,
            teams,
            currentWorkspaceId
        } = this.props;

        const {
            isLoading,
            workspace,
            movedPresentations,
            unmovedPresentationNames,
            presentationPermissions,
            uniquePermisions,
        } = this.state;

        return (
            <BeautifulDialog closeDialog={closeDialog}>
                <DialogTitle>
                    <FormIcon src={baiBotImage} />
                    <FlexBox middle fillWidth style={{ textAlign: "center" }}>
                        <span>
                            {
                                !isLoading && uniquePermisions.length > 0
                                    ? "Are you sure you want to move these presentations?"
                                    : "Move presentations"
                            }
                        </span>
                    </FlexBox>
                </DialogTitle>
                <DialogContent>
                    <Loadable isLoading={isLoading}>
                        {
                            uniquePermisions.length > 0 &&
                            <>
                                <div style={{ textAlign: "center" }}>
                                    Any presentations currently shared in a Team Folder will be removed from<br />those folders and the collaborators will no longer have access.
                                </div>
                                {/*
                                    NOTE: Attempted to list the collaborators that would be removed. Design was too complicated tho, so the list has been removed.
                                      Leaving commented here in case we want to revisit this design in the future. List was designed to mimic PermissionsList.js.
                                */}
                                {/* <div className="permissions-list">
                                    {uniquePermisions.map(perm => (
                                        <div key={perm.id} className="permission">
                                            {
                                                perm.level &&
                                                <>
                                                    <TeamAvatar/>
                                                    <div className="name">
                                                        <span className="fullName">{perm.name}</span>
                                                    </div>
                                                </>
                                            }
                                            {
                                                !perm.level &&
                                                <>
                                                    <Avatar url={perm.photoURL} />
                                                    <div className="name">
                                                        <span className="fullName">
                                                            {perm.displayName ? perm.displayName : perm.email}
                                                        </span>
                                                        {
                                                            perm.displayName &&
                                                            <span className="email">{perm.email}</span>
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    ))}
                                </div> */}
                                <br />
                            </>
                        }
                        <div style={{ display: "flex", width: "100%" }} disableTypography={true}>
                            <h2 style={{ margin: "6px 0 0 0" }}>Move to:</h2>
                            <Select
                                style={{ marginLeft: "20px", flex: 1 }}
                                value={workspace}
                                onChange={event => {
                                    this.setState({ workspace: event.target.value });
                                }}
                            >
                                {workspaces.filter(workspace => workspace.id !== currentWorkspaceId).map(ws => {
                                    if (ws.id === "personal") {
                                        return <MenuItem key={ws.id} value={ws.id}>Personal</MenuItem>;
                                    } else {
                                        const defaultTeam = teams.find(team => team.get("isDefault") && team.get("orgId") === ws.id);
                                        return <MenuItem key={ws.id} value={ws.id}>{defaultTeam.get("name")}</MenuItem>;
                                    }
                                })}
                            </Select>
                        </div>
                        {unmovedPresentationNames.length > 0 &&
                            <div>
                                <p>The following presentations could not be moved because they were shared with you.
                                    You can only move presentations that you own.</p>
                                <p><b><i>{unmovedPresentationNames.join(", ")}</i></b></p>
                            </div>
                        }
                    </Loadable>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button disabled={isLoading} onClick={() => {
                        movedPresentations.forEach(async presentation => {
                            // Ensure real time updates are enabled
                            await presentation.setAutoSync(true);

                            const deleteCalls = _.flatten(
                                Object.entries(presentationPermissions)
                                    .map(([presentationId, permissions]) => {
                                        return permissions.map(perm => {
                                            const pendingPermToDelete = {
                                                //subject can be email or team name depending on if its a user email or team name
                                                //probably should rename id to something else
                                                subject: perm.email || perm.name,
                                                id: perm.id,
                                                pending: perm.pending || false,
                                                type: perm.type,
                                                level: perm.level === "team" ? "team" : "user"
                                            };

                                            if (pendingPermToDelete.level === "team") {
                                                return permissionsDS
                                                    .deleteTeamPermission(pendingPermToDelete.id, pendingPermToDelete, presentationId)
                                                    .catch(err => {
                                                        logger.error(err, "[MoveWorkspacesDialog] permissionsDS.deleteTeamPermission() failed");
                                                    });
                                            }
                                        });
                                    })
                            );

                            await Promise.allSettled(deleteCalls);

                            // Setup orgId change listener
                            const orgIdChangePromise = new Promise(resolve => presentation.once("change:orgId", () => resolve()));

                            await presentationsApi.moveWorkspace({ id: presentation.id, newWorkspaceId: workspace });

                            // Ensure the orgId change has propagated
                            await orgIdChangePromise;
                        });
                        closeDialog();
                    }} color="primary">Move Presentations</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
