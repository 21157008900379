import React from "react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";

import firebase from "js/firebase";
import getLogger, { LogGroup } from "js/core/logger";
import { LoadingPage } from "js/react/components/LoadingPage";
import sanitizeRelativeUrl from "common/utils/sanitizeRelativeUrl";

const logger = getLogger(LogGroup.AUTH);

export class Logout extends React.Component {
    constructor(props) {
        super(props);

        this.auth = firebase.auth();
    }

    componentDidMount() {
        this.auth.onAuthStateChanged(this.handleAuthStateChanged);
    }

    handleAuthStateChanged = user => {
        if (user) {
            logger.info("[Logout] User is logged in, logging out..");
            this.auth.signOut();
            return;
        }

        Cookies.set("logged-in", false);

        const continueUrl = sanitizeRelativeUrl(
            new URL(window.location).searchParams.get("continue") ?? "/"
        );

        logger.info(`[Logout] User is logged out, redirecting to ${continueUrl}..`);
        window.location = continueUrl;
    }

    render() {
        return <LoadingPage />;
    }
}

export default withRouter(Logout);
