import React, { Component } from "reactn";
import isConnected from "js/core/utilities/isConnected";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { ASSET_FILETYPE, AssetType } from "common/constants";
import getLogger, { LogGroup } from "js/core/logger";
import Spinner from "../../../components/Spinner";
import { ImageThumbnail } from "js/react/components/ImageThumbnail";
import { ShowOfflineDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import * as geom from "js/core/utilities/geom";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";

const logger = getLogger(LogGroup.ASSETS);

export default class StockPhotoPreview extends Component {
    state = {
        isImporting: false
    };

    componentDidMount = () => {
        if (app.tourFuncs && this.props.tourImage) {
            app.tourFuncs.selectDandelion = async () => {
                await this.downloadStockPhoto();
            };
        }
    };

    downloadStockPhoto = async () => {
        if (this.state.isAlreadyClicked) return;
        this.setState({
            isAlreadyClicked: true,
            isImporting: true
        });

        const {
            addAssetCallback,
            handleConfirm,
            imgProps
        } = this.props;

        const width = imgProps.width;
        const height = imgProps.height;

        const importStockPhoto = (name, url, previewURL, metadata, imageProperties = {}) => {
            if (!isConnected.connected) {
                ShowOfflineDialog();
                return;
            }

            return ds.assets
                .getOrCreateImage({
                    url,
                    fileType: ASSET_FILETYPE.JPG,
                    assetType: AssetType.IMAGE,
                    name,
                    metadata,
                    imagePropsOverride: imageProperties
                })
                .then(asset => {
                    return asset;
                });
        };

        imgProps.service
            .getDownloadUrl(imgProps)
            .then(url => {
                return importStockPhoto(imgProps.title, url, imgProps.previewUrl, imgProps.metadata, {
                    width: width,
                    height: height
                });
            })
            .then(asset => {
                const result = {
                    ...asset,
                    ...asset.attributes,
                    type: asset.type,
                    size: new geom.Size(asset.get("w"), asset.get("h")),
                };
                addAssetCallback(result);
                asset.addAssetToUserAssets();
                this.setState({
                    isImporting: false
                });
                handleConfirm();
            })
            .catch(err => {
                ShowWarningDialog({
                    title: "Sorry!",
                    message: "We seem to be having trouble accessing this image. Please try again.",
                });
                this.setState({
                    isImporting: false
                });
                logger.error(err, "[StockPhotoPreview] downloadStockPhoto() failed");
            });
    };

    render() {
        const { imgProps, width } = this.props;

        return (
            <div
                className="stock-photo-wrapper"
                onClick={async () => {
                    await this.downloadStockPhoto();
                }}
            >
                {this.state.isImporting && <Spinner />}
                <ImageThumbnail src={imgProps.thumbnailUrl} imgProps={imgProps} width={width} />
                <div
                    className="attribution"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtmlText(imgProps.metadata.attribution)
                    }}
                    onClick={e => e.stopPropagation()}
                />
            </div>
        );
    }
}
