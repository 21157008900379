import React, { Component } from "reactn";
import styled from "styled-components";
import { Icon, Slider } from "@material-ui/core";

import { _, tinycolor } from "js/vendor";
import { app } from "js/namespaces";
import { getStaticUrl } from "js/config";
import Logos from "js/core/models/logos";
import { themeColors } from "js/react/sharedStyles";
import Loadable from "js/react/components/Loadable";
import { ColorPicker } from "js/react/components/ColorPicker";
import { Divider, UIPane } from "js/react/components/UiComponents";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { Breadcrumb } from "js/editor/PresentationLibrary/PresentationLibrary";
import { BlueSwitch } from "js/react/components/Switch";
import UploadLogo from "js/react/components/UploadLogo";
import { StackableSearchBarContainer, StackableUIPaneResultsContainer } from "js/react/views/AddSlide/Panes/Components/SearchBox";
import PlayerSettings from "js/react/views/PresentationSettings/components/PlayerSettings";
import { PresentationPrivacyType } from "js/core/models/presentation";
import { FeatureType } from "common/features";
import AppController from "js/core/AppController";

const WhiteBG = styled.div`
    background: white;
    padding: 20px;
`;

const CustomLoaderForm = styled.div`
    display: grid;
    grid-template-columns: 40% auto;
    gap: 15px;

    label {
        font-weight: bold;
        font-size: 12px;
    }
`;

const AssetScalePicker = styled.div`
    max-width: 250px;
    text-transform: uppercase;
`;

const BackgroundColorPicker = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
    
    // removes padding from the Color Picker component
    > div {
        padding: 0;
    }
    
    button {
        border: 1px solid ${themeColors.gray};
        width: 30px;
        height: 30px;
    }

`;

const CustomLoaderPreview = styled.div`
    max-width: 650px;

    .preview {
        background: black;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
        border: 1px solid ${themeColors.gray};
    }

    .content {
        display: grid;
        grid-auto-rows: auto;
        gap: 40px;

        > * {
            justify-content: center;
            display: flex;
        }
    }
    
    .logo img {
        max-width: 150px;
        max-height: 150px;
        width: auto;
        height: auto;
    }

    img.default {
        transform: scale(0.6);
    }

    img.loading-bar {
        transform: scale(0.25);
    }
`;

export default class TeamCustomLoaderPane extends Component {
    constructor() {
        super();

        let playerSettingsDefaults = app.user.features.getFeatureProps(FeatureType.WORKSPACE_PLAYER_SETTINGS_DEFAULTS, AppController.orgId);

        let playerSettings = {
            ...playerSettingsDefaults,
            slideThumbnail: getStaticUrl("/images/thumbnails/sample-slide-large.png"),
            removeBeautifulBranding: app.user.features.isFeatureEnabled(FeatureType.PLAYER_SETTINGS, AppController.orgId),
            showBranding: app.user.features.isFeatureEnabled(FeatureType.PLAYER_SETTINGS, AppController.orgId) == false,
        };
        this.state = {
            isLoading: true,
            playerSettings,
            playerBranding: {
                active: false,
                useLoadingAnimation: true,
                logoScale: 1,
                backgroundColor: "#000000",
            }
        };
    }

    componentDidMount() {
        //  check for an existing config
        const {
            playerBranding = this.state.playerBranding
        } = (AppController.currentTeam?.attributes || {});

        // set the defaults
        this.setState({
            isLoading: false,
            playerBranding
        });

        // check for a logo url
        this.setLogoUrl(playerBranding.logoId, false);
    }

    get logoAltText() {
        return AppController.currentTeam?.attributes?.name || "Beautiful.ai";
    }

    removeLogo = () => {
        this.setState({ logoSrc: null });
        this.updateConfig({ logoId: null });
    }

    setLogoUrl = async (logoId, commitChanges = true) => {
        // there's nothing to load
        let logoSrc;
        if (logoId) {
            logoSrc = await Logos.getSignedUrlAndLoad(logoId);
        }

        // get the state update
        const update = {
            updatingLogo: false,
            logoSrc
        };

        // if saving the changes, also update the player branding
        if (commitChanges) {
            update.playerBranding = {
                ...this.state.playerBranding,
                logoId
            };
        }

        // request the url
        this.setState(update);

        // also, save if needed
        if (commitChanges) {
            this.commitConfig();
        }
    }

    commitConfig = _.debounce(() => {
        const playerBranding = { ...this.state.playerBranding };

        // clean up
        Object.keys(playerBranding).forEach(key => {
            if (playerBranding[key] === undefined) {
                delete playerBranding[key];
            }
        });

        // submit
        AppController.currentTeam.update({ playerBranding });
    }, 500)

    handleBeforeUploadLogo = () => {
        this.setState({ updatingLogo: true });
    }

    updateConfig = params => {
        const playerBranding = { ...this.state.playerBranding, ...params };
        this.setState({ playerBranding });

        // queue an update
        this.commitConfig();
    }

    handleSetLogoScale = (event, logoScale) => {
        this.updateConfig({ logoScale });
    }

    handleChangeBackgroundColor = backgroundColor => {
        this.updateConfig({ backgroundColor });
    }

    handleToggleUseCustomLoader = () => {
        const active = !this.state.playerBranding.active;
        this.updateConfig({ active });
    }

    handleToggleUseLoadingAnimation = () => {
        const useLoadingAnimation = !this.state.playerBranding.useLoadingAnimation;
        this.updateConfig({ useLoadingAnimation });
    }

    handleLogoUpdate = update => {
        if ("logoId" in update) {
            const { logoId } = update;

            // used remove
            if (!logoId) {
                this.removeLogo();

                // set a new logo
            } else {
                this.setLogoUrl(logoId);
            }
        } else if ("averageImageColor" in update) {
            const { averageImageColor } = update;
            const backgroundColor = detectBackgroundColor(averageImageColor);

            this.updateConfig({ backgroundColor });
        }
    }

    handleChangePlayerSettings = stateDelta => {
        AppController.currentTeam.update({
            workspaceSettings: {
                playerSettingsDefaults: stateDelta
            }
        });
        this.setState({
            playerSettings: {
                ...this.state.playerSettings,
                ...stateDelta,
            },
        });
    }

    renderCustomLoaderControls() {
        const {
            useLoadingAnimation,
            backgroundColor,
            logoScale = 1
        } = this.state.playerBranding;

        return (
            <>
                <Divider />
                <div>
                    <BlueSwitch
                        checked={useLoadingAnimation}
                        onChange={this.handleToggleUseLoadingAnimation}
                        label="Loading Bar"
                    />
                </div>
                <Gap10 />
                <AssetScalePicker>
                    <label>Asset Scale</label>
                    <Slider
                        value={logoScale}
                        onChange={this.handleSetLogoScale}
                        onChangeCommitted={this.handleSetLogoScale}
                        min={0.8}
                        max={1.5}
                        step={0.05}
                    />
                </AssetScalePicker>
                <Gap10 />
                <BackgroundColorPicker>
                    <ColorPicker
                        disableAlpha
                        disablePalette
                        color={backgroundColor}
                        showColorPicker
                        onChange={this.handleChangeBackgroundColor}
                    />
                    <label>Background Color</label>
                </BackgroundColorPicker>
            </>
        );
    }

    renderCustomLoaderPreview() {
        const {
            logoAltText,
            state
        } = this;

        const {
            logoSrc,
            updatingLogo,
            playerBranding
        } = state;

        const {
            active,
            backgroundColor,
            useLoadingAnimation = true,
            logoScale,
            logoId
        } = playerBranding;

        // default params
        const preview = {
            logoSrc: getStaticUrl("/images/beautifulai-logos/beautifulai-logo-reverse.svg"),
            loadingAnimationSrc: getStaticUrl("/images/splash.gif"),
            backgroundColor: "black",
            logoScale: 1
        };

        // if the custom loader is active
        if (active) {
            preview.loadingAnimationSrc = getStaticUrl("/images/splash-bar.gif");
            preview.backgroundColor = backgroundColor;
            preview.logoScale = logoScale;
            preview.logoSrc = logoSrc || preview.logoSrc;
        }

        // styling
        const logoStyle = { transform: `scale(${preview.logoScale})` };
        const previewStyle = { backgroundColor: preview.backgroundColor };
        const loadingAnimationCx = active ? "loading-bar" : "default";

        // visibility
        const showLogo = active && !updatingLogo && preview.logoSrc;
        const showLoadingAnimation = !active || (active && useLoadingAnimation);

        // show the preview window
        return (
            <CustomLoaderPreview>
                <div className="preview" style={previewStyle}>
                    <div className="content" >
                        {showLogo && (
                            <div className="logo" style={logoStyle}>
                                <img src={preview.logoSrc} alt={logoAltText} />
                            </div>
                        )}

                        {showLoadingAnimation && (
                            <img className={loadingAnimationCx} src={preview.loadingAnimationSrc} />
                        )}
                    </div>

                </div>

                {active && (
                    <div>
                        <Gap20 />
                        <UploadLogo
                            renderAsButton
                            attribute="logoId"
                            src={logoId}
                            update={this.handleLogoUpdate}
                            allowedFileTypes={["svg+xml", "png", "jpeg", "jpg", "gif"]}
                            disableImageProcessing={file => /\.(svg|gif)$/i.test(file.name)}
                            onBeforeUploadFile={this.handleBeforeUploadLogo}
                            extractAverageImageColor
                        >
                            <Icon>camera_alt</Icon>
                            Replace Asset
                        </UploadLogo>
                    </div>
                )}
            </CustomLoaderPreview>
        );
    }

    render() {
        const {
            isLoading,
            playerSettings,
            playerBranding,
        } = this.state;
        const {
            active,
        } = playerBranding;
        const {
            removeBeautifulBranding,
            showTitle,
            showAuthor,
            requireEmail,
            showSocialSharing,
            showGetDeckUI,
            slideThumbnail,
            showBranding,
            allowPdfDownload,
        } = playerSettings;

        return (
            <UIPane id="playerBranding" style={{
                width: "100%",
                overflowY: "scroll",
                display: "block"
            }}>
                <StackableSearchBarContainer className="search-container">
                    <Breadcrumb>Player Settings</Breadcrumb>
                </StackableSearchBarContainer>
                <StackableUIPaneResultsContainer>
                    <Loadable isLoading={isLoading}>
                        <WhiteBG>
                            <div className="player_settings">
                                <PlayerSettings
                                    sectionTitle={"Default Player Settings"}
                                    privacySetting={PresentationPrivacyType.PUBLIC}
                                    removeBeautifulBranding={removeBeautifulBranding}
                                    showTitle={showTitle}
                                    showAuthor={showAuthor}
                                    requireEmail={requireEmail}
                                    showSocialSharing={showSocialSharing}
                                    showGetDeckUI={showGetDeckUI}
                                    slideThumbnail={slideThumbnail}
                                    showBranding={showBranding}
                                    allowPdfDownload={allowPdfDownload}
                                    presentationName={"Presentation Title"}
                                    handleChange={this.handleChangePlayerSettings}
                                />
                            </div>
                        </WhiteBG>
                    </Loadable>
                </StackableUIPaneResultsContainer>
                <StackableUIPaneResultsContainer>
                    <Loadable isLoading={isLoading}>
                        <WhiteBG>
                            <CustomLoaderForm>
                                <div>
                                    <div className="section-title">Player Branding</div>
                                    <div style={{ display: "flex" }}>
                                        <BlueSwitch
                                            checked={active}
                                            onChange={this.handleToggleUseCustomLoader}
                                            label="Use Custom Loader"
                                        />
                                    </div>

                                    {active && this.renderCustomLoaderControls()}
                                </div>

                                {this.renderCustomLoaderPreview()}

                            </CustomLoaderForm>
                        </WhiteBG>
                    </Loadable>
                </StackableUIPaneResultsContainer>
            </UIPane>
        );
    }
}

// determines a good background color for a logo
function detectBackgroundColor(rgb) {
    let color = tinycolor(rgb);

    // adjust colors
    if (color.isLight()) {
        color = color.desaturate(50).darken(30);
    } else {
        color = color.desaturate(50).lighten(40);
    }

    return color.toHexString();
}
