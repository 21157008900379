import styled from "styled-components";
import {
    MultiSelectThumbnailGrid,
    Thumbnail,
    ThumbnailContainer
} from "js/react/views/AddSlide/Panes/Components/ThumbnailGrid";
import React, { Component, Fragment } from "reactn";
import { IconMenu } from "js/react/components/UiComponents";
import { IconButton, Icon } from "@material-ui/core";
import {
    renderPresentationContextMenu,
} from "js/editor/PresentationLibrary/PresentationContextMenu";
import { themeColors } from "js/react/sharedStyles";
import { PresentationFilterContext } from "./PresentationFilterContext";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { ShareDialog } from "js/react/views/PresentationSettings/dialogs/ShareDialog";
import { app } from "js/namespaces";
import { _ } from "js/vendor";
import LoadableAsync from "js/react/components/LoadableAsync";
import getLogger from "js/core/logger";

const logger = getLogger();

const ThumbnailGridContainer = styled.div`
  background: white;
  padding: 20px;
  margin: 0px 0px 20px;
  overflow: hidden;

  .dark-mode & {
      background: #333;
  }
`;

export class PresentationLibraryGrid extends Component {
    static contextType = PresentationFilterContext;

    state = {};

    render() {
        let { items, onSelectedPresentation, onDoubleClick, onStartDragItems, onDropDragItems, readOnly } = this.props;

        return (
            <ThumbnailGridContainer>
                <MultiSelectThumbnailGrid
                    items={items}
                    columns={3}
                    colGap={30}
                    rowGap={30}
                    readOnly={readOnly}
                    allowDragging={!readOnly}
                    allowMultiSelect={!readOnly}
                    thumbnailClass={PresentationLibraryPreviewThumbnail}
                    onItemSelected={onSelectedPresentation}
                    onDoubleClick={onDoubleClick}
                    onStartDragItems={onStartDragItems}
                    onDropDragItems={onDropDragItems}
                    renderContextMenuItems={selection => renderPresentationContextMenu(selection, this.context)}
                />
            </ThumbnailGridContainer>
        );
    }
}

const ThumbnailBar = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    label {
        font-size: 14px;
        letter-spacing: 0px;
        color: #333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        flex-grow: 2;
        text-transform: none;
        margin: 0px;
    }
    button {
        padding: 2px;
    }

    .dark-mode & {
        label {
            color: #ddd;
        }
    }
`;

const PresentationThumbnailContainer = styled(ThumbnailContainer)`
    //padding: 0px 0px 12px 0px;
    //background: white;
    &:hover {
      img {
      //  outline: solid 1px red;
      }
    }
    img {
      //  outline: ${props => props.selected ? `solid 2px ${themeColors.ui_blue}` : "none"};
        //outline: none !important;
    }
`;

const SharedPresentationIcon = styled.div`
  position:absolute;
  top: 8px;
  right: 8px;
  width: 30px;
  height: 30px;
  z-index: 100;
  border-radius: 50%;
  background: ${themeColors.ui_blue};
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: white;
    font-size: 20px;
  }
`;

const ReadOnlyPresentationIcon = styled.div`
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  span {
    color: ${themeColors.ui_blue};
    font-size: 66px;
    background: white;
    border-radius: 50%;
  }
`;

const Tag = styled.div`
  background: ${themeColors.ui_blue};
  color: white;
  padding: 5px 6px;
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 11px;
  border-radius: 2px;
  font-weight: 600;
`;

export class PresentationLibraryPreviewThumbnail extends Component {
    static contextType = PresentationFilterContext;

    state = {
        thumbnailUrl: "",
    }

    constructor() {
        super();

        this.ref = React.createRef();
    }

    fetchThumbnailUrl() {
        const { item: { presentationModel } } = this.props;
        const { thumbnailUrl: currentThumbnailUrl } = this.state;

        // Showing spinner while loading
        this.setState({ thumbnailUrl: "" });

        presentationModel.getThumbnailUrl()
            .then(thumbnailUrl => this.setState({ thumbnailUrl }))
            .catch(err => {
                logger.error(err, "[PresentationLibraryPreviewThumbnail] presentationModel.getThumbnailUrl() failed");

                if (currentThumbnailUrl) {
                    // Restore previous thumbnail
                    this.setState({ thumbnailUrl: currentThumbnailUrl });
                }
            });
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting === true) {
                if (_.isEmpty(this.state.thumbnailUrl)) {
                    this.fetchThumbnailUrl();
                }
            }
        });

        this.observer.observe(this.ref.current);
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.item.firstSlideModifiedAt !== this.props.item.firstSlideModifiedAt) {
            this.fetchThumbnailUrl();
        }
    }

    handleSharePresentation = selectedPresentation => {
        ShowDialog(ShareDialog, { presentation: selectedPresentation.presentationModel });
    }

    async loadPermission(selection) {
        if (selection.length === 1) {
            let item = selection[0];
            await item?.presentationModel?.getUserPermissions(true);
        } else {
            return;
        }
    }

    render() {
        const { item, onMouseDown, onDoubleClick, onContextMenu, selected, readOnly, allSelected } = this.props;
        const { thumbnailUrl } = this.state;

        return (
            <PresentationThumbnailContainer
                data-item-id={item.id}
                className="thumbnail-grid-item"
                onMouseDown={onMouseDown}
                onDoubleClick={onDoubleClick}
                onContextMenu={onContextMenu}
                ref={this.ref}
            >
                <Thumbnail
                    selected={selected}
                    url={thumbnailUrl}
                    showSpinner={true}
                    style={{ border: "solid 1px #ccc", boxShadow: "0px 0px 20px rgba(0,0,0,.05)" }}
                >
                    {item.isShared && <SharedPresentationIcon><Icon>people</Icon></SharedPresentationIcon>}
                </Thumbnail>

                <ThumbnailBar>
                    {item.type == "document" && <Tag>Doc</Tag>}
                    <label className="presentation_item_name">{item.name}</label>
                    {
                        !app.isConstrained &&
                        !readOnly &&
                        <LoadableAsync
                            load={() => this.loadPermission(allSelected)}
                            renderChildren={() => (
                                <IconMenu>
                                    {renderPresentationContextMenu(allSelected, this.context)}
                                </IconMenu>
                            )}
                        />
                    }
                    {
                        app.isMobileOrTablet &&
                        !readOnly &&
                        !item.readOnly &&
                        <IconButton
                            className="text-blue"
                            onMouseDown={event => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                            onMouseUp={event => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                            onDoubleClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                this.handleSharePresentation(item);
                            }}
                        >
                            <Icon>share</Icon>
                        </IconButton>
                    }
                </ThumbnailBar>
            </PresentationThumbnailContainer>
        );
    }
}
