import React from "reactn";
import slug from "slug";

import { getLogoPath } from "common/assetUtils";
import { LimitErrors, PresentationPrivacyType, PresentationFilters, THUMBNAIL_SIZES, TaskState, UndoType } from "common/constants";
import { playerSettingsDefaults } from "common/features";
import { incUserProps, setUserProps } from "js/analytics";
import { getCanvasBundle } from "js/canvas";
import { appVersion, isOfflinePlayer, serverUrl } from "js/config";
import AppController from "js/core/AppController";
import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import Profiler from "js/core/profiler";
import { downloadFromUrl, filenameForExport, trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import { Backbone, _ } from "js/vendor";
import { presentations as presentationsApi } from "apis/callables";

import { requestExportCache } from "js/core/services/exportCache";
import NotificationsService from "js/core/services/notifications";

import { ShowConfirmationDialog, ShowDialog, ShowErrorDialog, ShowMessageDialog, ShowSnackBar } from "js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";

import ReferenceCollection from "js/core/storage/referenceCollection";
import StorageModel from "js/core/storage/storageModel";
import PresentationsAdapter from "js/core/storage/presentationsAdapter";

import { ClipboardType, clipboardWrite } from "../utilities/clipboard";
import ErrorHandler from "../utilities/errorHandler";
import { ds } from "./dataService";
import { FeatureType } from "./features";
import Logos from "./logos";
import { PresentationLinks } from "./presentationLinks";
import { Slide, Slides } from "./slide";
import Thumbnails from "./thumbnails";

const logger = getLogger(LogGroup.PRESENTATION);

const UNSUPPORTED_TEMPLATES_FOR_EXPORT = ["video"];

const profiler = Profiler.create({
    name: "Pres",
    type: Profiler.AVG_LOAD_TIME,
    warnTime: 400
});

function sortSlides(slides, slideRefs, getId) {
    if (!getId) {
        getId = slide => {
            return slide;
        };
    }
    slides.sort(function(a, b) {
        const result = slideRefs[getId(a)] - slideRefs[getId(b)];
        if (result !== 0) {
            return result;
        }
        //sort by id so this is consistent for all clients.
        if (a.id < b.id) {
            return -1;
        }
        if (a.id > b.id) {
            return 1;
        }
        return 0;
    });
}

export async function checkSlideCount(presentationsReferenceCollection, additionalSlideCount, workspaceId) {
    const { limit: slideCountLimit } = app.user.features.getFeatureProps(FeatureType.CREATE_SLIDES, workspaceId);
    if (slideCountLimit && presentationsReferenceCollection) {
        const totalSlideCount = presentationsReferenceCollection.getWorkspaceSlideCount(workspaceId, additionalSlideCount);
        const finalSlideLimit = slideCountLimit;
        if (totalSlideCount > finalSlideLimit) {
            throw new Error(LimitErrors.SLIDE_COUNT_LIMIT_REACHED);
        }
    }
    return null;
}

class PresentationError extends Error {
    name = "PresentationError";

    constructor(messageOrError) {
        if (messageOrError instanceof Error) {
            super(messageOrError.message);
            this.stack = messageOrError.stack ?? this.stack;
            this.originalError = messageOrError;
            return;
        }

        super(messageOrError);
    }
}

export class PresentationPermissionDeniedError extends PresentationError {
    name = "PresentationPermissionDeniedError";
    statusCode = 403;
}

export class PresentationNotFoundError extends PresentationError {
    name = "PresentationNotFoundError";
    statusCode = 404;
}

const Presentation = StorageModel.extend({

    root: "presentations",
    profiler: profiler,
    readonly: isOfflinePlayer,
    // Has to be set to true in order to make sure
    // the initial model is always normalized by
    // the adapter before firing the load event
    ensurePersisted: true,

    defaults: {
        hideControls: false,
        autoPlay: false,
        autoPlayDuration: 5,
        autoLoop: false,
        ...playerSettingsDefaults,
    },

    createAdapter: function(options) {
        return new PresentationsAdapter({ ...options, model: this });
    },

    initialize: function(data, options = {}) {
        this.type = "Presentation";
        this.permissions = {
            owner: false,
            read: false,
            write: false,
            collaborator: false
        };
        this.disconnected = options.disconnected || false;

        this.loadPermission = options.permission || "read";

        this.slides = new Slides();
        this.slides.presentation = this;
    },

    getSlideCount: function() {
        if (this.has("slideRefs")) {
            return Object.keys(this.get("slideRefs")).length;
        } else if (this.has("slides")) {
            return this.get("slides").length;
        }
        return null;
    },

    getPrivacySetting: function() {
        if (this.get("secured")) {
            return PresentationPrivacyType.SECURED;
        } else if (this.get("public")) {
            return PresentationPrivacyType.PUBLIC;
        } else {
            return PresentationPrivacyType.PRIVATE;
        }
    },

    setPrivacySetting: async function(value) {
        switch (value) {
            case PresentationPrivacyType.PUBLIC:
                this.update({ public: true, secured: false });
                break;
            case PresentationPrivacyType.SECURED:
                this.update({ public: true, secured: true });
                break;
            case PresentationPrivacyType.PRIVATE:
                this.update({ public: false, secured: false });
                break;
        }

        await this.updatePromise;

        NotificationsService.notifyOnPresentationPrivacyChanged(this.id, value)
            .catch(err => logger.error(err, "[presentation] update() NotificationsService.notifyOnPresentationPrivacyChanged() failed", { id: this.id }));
    },

    loadPresentationLinks: function() {
        if (this.disconnected) return;
        this.links = new PresentationLinks(null, { presentationId: this.id });
    },

    update: function(attrs, options = {}) {
        if (!this.permissions.write) return;

        // add `modifiedAt` on every update (if the update actually changes anything)
        const changeSet = StorageModel.prototype.update.call(this, attrs, Object.assign({ computeChangeSet: true }, options));
        if (changeSet.hasUpdates && !options.skipModifiedAt) {
            attrs = Object.assign({ modifiedAt: new Date().getTime() }, attrs);
        }

        if (!this.disconnected) {
            const updatedKeys = Object.keys(changeSet.update);
            if (updatedKeys.includes("name")) {
                NotificationsService.notifyOnPresentationRenamed(this.id)
                    .catch(err => logger.error(err, "[presentation] update() NotificationsService.notifyOnPresentationRenamed() failed", { id: this.id }));
            }
            if (updatedKeys.some(key => key.startsWith("theme_"))) {
                NotificationsService.notifyOnPresentationThemeChanged(this.id)
                    .catch(err => logger.error(err, "[presentation] update() NotificationsService.notifyOnPresentationThemeChanged() failed", { id: this.id }));
            }
        }

        return StorageModel.prototype.update.call(this, attrs, options);
    },

    recoverPresentation: async function() {
        try {
            await checkSlideCount(this.collection, this.slides.length, this.getWorkspaceId());
        } catch (err) {
            throw err;
        }
        return this.update({ softDeletedAt: null });
    },

    sortSlides: function(slides, getId) {
        if (this.has("slideRefs")) {
            slides = slides.slice(0);
            sortSlides(slides, this.get("slideRefs"), getId);
            return slides;
        } else {
            const sips = this.getSips();
            return _.sortBy(slides, slide => {
                return sips.indexOf(getId(slide));
            });
        }
    },

    getSips: function(skippedSlideIds = []) {
        let sips;
        const slideRefs = this.get("slideRefs");
        if (slideRefs) {
            sips = Object.keys(slideRefs);
            sortSlides(sips, slideRefs, i => i);
        } else {
            sips = this.get("slides") || [];
        }
        return sips.filter(slideId => !skippedSlideIds.includes(slideId));
    },

    setSips: function(sips, deletedSlides, options) {
        const slideRefs = {};
        const currentSips = this.getSips();
        deletedSlides = deletedSlides || {};

        sips.forEach((id, index) => {
            slideRefs[id] = index;
        });

        //remove existing slides.
        currentSips.forEach(id => {
            if (!slideRefs.hasOwnProperty(id)) {
                slideRefs[id] = null;
            }
        });

        this.update({ slideRefs, deletedSlides }, options);
        // Upgrade to the latest format.
        if (this.has("slides")) {
            this.update({ slides: null });
        }

        return this.onSlideRefChange(this, this.get("slideRefs"), options);
    },

    onSlideRefChange: function(presentation, slideRefs = {}, options = {}) {
        const slidePromises = [];
        let previousRefs = presentation.previous("slideRefs") || {};

        Object.keys(slideRefs).forEach(slideId => {
            if (this.slides.has(slideId)) {
                slidePromises.push(this.slides.get(slideId));
            } else {
                slidePromises.push(this.loadSlide(slideId));
            }
        });

        Object.keys(previousRefs).forEach(slideId => {
            if (!(slideId in slideRefs)) {
                const slide = this.slides.get(slideId);
                if (slide) {
                    slide.disconnect();
                }
            }
        });

        return Promise.all(slidePromises).then(slides => {
            sortSlides(slides, slideRefs, slide => {
                return slide.id;
            });
            this.slides.set(slides);

            if (!options.silent) {
                this.trigger("slidesUpdated", slides, options);
            }
        });
    },

    setSlides: function(slides) {
        this._slidesPrepared = true;
        this.slides.set(slides, { silent: true });
    },

    prepareSlides: async function(loadSlideModels = true) {
        if (this._slidesPrepared) {
            return;
        }

        this._slidesPrepared = true;

        const slides = this.getSips()
            .map(id => new Slide({ id }, {
                autoSync: this.autoSync,
                autoLoad: false,
                userId: this.userId,
                presentation: this,
            }));

        this.slides.set(slides, { silent: true });

        if (loadSlideModels) {
            await Promise.all(slides.map(slide => slide.load()));
        }

        if (this.autoSync) {
            // listen for external changes to slides property to load and add new slide to slide collection
            this.listenTo(this, "change:slideRefs", (model, slideRefs, options) => {
                if (options.remoteChange) {
                    this.onSlideRefChange(model, slideRefs, options);
                }
            });
        }
    },

    loadSlide: function(slideId) {
        const slide = new Slide({ id: slideId }, {
            userId: this.userId,
            presentation: this,
        });
        return slide.load();
    },

    // update the presentation's SIPs list with a list of slide IDs plucked from the slides collection
    updateSIPS: function(deletedSlides, options) {
        let sips = this.slides.pluck("id");
        return this.setSips(sips, deletedSlides, options);
    },

    openPresentation: function() {
        this.trigger("presentation_opened");
    },

    closePresentation: function() {
        this.stopListening();
        this.clearSlides();
    },

    clearSlides: function() {
        this._slidesPrepared = false;
        this.slides.each(model => {
            model.disconnect();
        });
        this.slides.set([], { silent: true, remoteChange: true });
    },

    getTemplateSlideData: async function(templateId, overrides = {}) {
        const slideModel = {
            template_id: templateId,
            presentationId: this.id
        };

        const { slideTemplates: { slideTemplates } } = await getCanvasBundle(window.FORCE_NEW_SLIDES_VERSION ?? appVersion);

        const Template = _.find(slideTemplates, { id: templateId });
        if (Template) {
            const template = new Template();
            Object.assign(slideModel, template.generateSlideData(), overrides);
        } else {
            Object.assign(slideModel, overrides);
        }

        return slideModel;
    },

    addSlideFromTemplate: async function(template, index, options = {}) {
        try {
            if (!options.isTour) {
                await checkSlideCount(this.collection, 1, this.getWorkspaceId());
            }
        } catch (err) {
            throw err;
        }

        const slideData = await this.getTemplateSlideData(template.id);

        return this.createSlides(slideData, index, options).then(slides => slides[0]);
    },

    addExistingSlides: async function(data, startIndex, options = {}) {
        if (!data) return [];

        if (!_.isArray(data)) {
            data = [data];
        }
        try {
            await checkSlideCount(this.collection, data.length, this.getWorkspaceId());
        } catch (err) {
            throw err;
        }
        let index = startIndex || 0;
        let sips = this.getSips();
        let deletedSlides = Object.assign({}, this.get("deletedSlides"));
        let newSlideOptions = Object.assign({}, options, {
            userId: this.userId,
            presentation: this,
        });

        const newSlides = data.map(slideData => {
            if (slideData.id) {
                deletedSlides[slideData.id] = null;
                index = Math.max(index, sips.indexOf(slideData.id) + 1);
            }
            return new Slide({ id: slideData.id }, newSlideOptions);
        });

        data.forEach((slideData, i) => {
            !options.skipUndo && app.undoManager.set(UndoType.ADD_SLIDE, slideData.id, null, {
                slideData: slideData,
                index: index + i
            });
        });

        //load the slides and then update sips!
        return Promise.all(newSlides.map(slide => {
            return slide.load();
        })).then(() => {
            // TODO: since this is async, we're not guarenteed the index is still valid. We need to have
            // one way to add an existing slide back into the ui.
            this.slides.add(newSlides, Object.assign({ silent: true, at: index }, options));
            return this.updateSIPS(deletedSlides, options);
        })
            .then(() => !this.disconnected ? Promise.all(newSlides.map(slide => NotificationsService.notifyOnSlideAdded(this.id, slide.id))) : Promise.resolve())
            .then(() => newSlides);
    },

    /**
     * This will create new slides with an undo point. Do not call this from a remote change.
     * @param {Object|Object[]} slides slide model or array of slide models (can be either instances of the Slide model or plain objects)
     * @param {Number=} startIndex insert index, defaults to 0
     * @param {Object=} options options passed to backbone models on update and add calls
     * @returns {Slide[]} array of created Slide model instances
     */
    createSlides: async function(slides, startIndex = 0, options = {}) {
        if (!slides) return [];

        if (!_.isArray(slides)) {
            slides = [slides];
        }

        const createdSlideCount = slides.length;
        incUserProps({
            created_slides: createdSlideCount
        });

        const { slideTemplates: { slideTemplates } } = await getCanvasBundle(window.FORCE_NEW_SLIDES_VERSION ?? appVersion);
        const slideTemplateIds = slides.map(slide => slide.template_id || slide.get("template_id"));
        const slideTemplateNames = slideTemplateIds.map(id => slideTemplates[id].title);
        const eventProps = {
            slide_template_ids: slideTemplateIds,
            slide_template_names: slideTemplateNames,
            current_slide_count: this.collection.getWorkspaceSlideCount(this.getWorkspaceId(), createdSlideCount),
            slides_created: createdSlideCount,
        };
        trackActivity("Slide", "Create", this.id, createdSlideCount, eventProps, { audit: true });

        const newSlidesMap = {};

        const newSlides = await Promise.all(slides.map(async slide => {
            let slideModel;
            if (slide instanceof Slide) {
                // We have to make sure the slide has loaded in order
                // to get its attributes
                await slide.load();
                slideModel = _.cloneDeep(slide.attributes);
            } else {
                slideModel = _.cloneDeep(slide);
            }

            const oldId = slideModel.id;

            if (!options.skipSlidesCleanup) {
                delete slideModel.id;
                delete slideModel.thumbnails;
                delete slideModel.libraryItemId;
                delete slideModel.isGallerySlide;
                delete slideModel.assignedUser;
                delete slideModel.assignedPendingUser;
                delete slideModel.pendingUserAssignedBy;
            }

            slideModel.version = slideModel.version ?? appVersion;
            slideModel.presentationId = this.id;

            const disconnected = options.disconnected ?? this.disconnected;
            const newSlide = new Slide(slideModel, {
                ...options,
                userId: this.userId,
                presentation: this,
                disconnected
            });

            if (!disconnected) {
                await newSlide.load();
            }
            if (oldId) {
                newSlidesMap[oldId] = newSlide.id;
            }

            return newSlide;
        }));

        let countLinksTotal = 0;
        let countLinksCleared = 0;
        const remapLinks = (obj, countRemaps = 0) => {
            if (obj.hasOwnProperty("linkToSlide")) {
                ++countLinksTotal;
                if (this.slides.find(x => x.id === obj.linkToSlide)) {
                    // Maintain links to exsiting slides in the presentation by doing nothing
                } else if (newSlidesMap[obj.linkToSlide]) {
                    // Update links to duplicated slides
                    obj.linkToSlide = newSlidesMap[obj.linkToSlide];
                    ++countRemaps;
                } else {
                    // Clear orphaned links
                    delete obj.linkToSlide;
                    ++countLinksCleared;
                    ++countRemaps;
                }
            }
            for (const child of Object.values(obj)) {
                if (!!child && child instanceof Object) {
                    countRemaps = remapLinks(child, countRemaps);
                }
            }

            return countRemaps;
        };
        newSlides?.forEach(slide => {
            if (!slide?.dataState?.elements) return;
            if (remapLinks(slide.dataState.elements)) {
                slide.commit();
            }
        });

        this.slides.add(newSlides, { silent: true, at: startIndex, ...options });

        await this.updateSIPS(this.get("deletedSlides"), options);

        newSlides.forEach((slide, slideIndex) => {
            if (!options.skipUndo) {
                app.undoManager.set(UndoType.ADD_SLIDE, slide.id, null, {
                    slideData: slide.attributes,
                    index: startIndex + slideIndex
                });
            }

            if (!this.disconnected) {
                NotificationsService.notifyOnSlideAdded(this.id, slide.id)
                    .catch(err => logger.error(err, "[presentation] createSlides() NotificationsService.notifyOnSlideAdded() failed", { id: this.id, slideId: slide.id }));
            }
        });

        if (countLinksCleared) {
            ShowErrorDialog({
                title: "Not all slide links preserved",
                message: `The newly generated ${"slide".pluralize(newSlides.length > 1)} contained ${countLinksTotal} ${"link".pluralize(countLinksTotal > 1)} to other slides, but due to the destination slides not being present in this presentation, ${countLinksCleared} ${"link".pluralize(countLinksCleared > 1)} had to be cleared.`
            });
        }

        return newSlides;
    },

    destroySlides: async function(slideIds, options = {}) {
        if (!slideIds) {
            return [];
        }
        if (!_.isArray(slideIds)) {
            slideIds = [slideIds];
        }

        incUserProps({
            deleted_slides: slideIds.length
        });

        const sips = this.getSips();

        const justDeletedSlides = slideIds
            .map(slideId => this.slides.get(slideId))
            .filter(slide => !!slide && sips.indexOf(slide.id) !== -1);

        // We need to make sure slides are loaded before accessing their attributes
        await Promise.all(justDeletedSlides.map(slide => slide.load()));

        const presentationDeletedSlides = Object.assign({}, this.get("deletedSlides"));

        for (const slide of justDeletedSlides) {
            const slideIndex = sips.indexOf(slide.id);

            if (!options.skipUndo) {
                app.undoManager.set(UndoType.DELETE_SLIDE, slide.id, {
                    index: slideIndex,
                    slideData: _.cloneDeep(slide.attributes)
                });
            }

            sips.splice(slideIndex, 1);

            // Library slides won't go to deletedSlides
            if (!slide.get("libraryItemId")) {
                presentationDeletedSlides[slide.id] = new Date().getTime();
            }
        }

        if (!this.disconnected) {
            justDeletedSlides.forEach(slide => {
                NotificationsService.notifyOnSlideRemoved(this.id, slide.id)
                    .catch(err => logger.error(err, "[presentation] destroySlides() NotificationsService.notifyOnSlideRemoved() failed", { id: this.id, slideId: slide.id }));
            });
        }

        // NOTE: we reference slideIds here instead of justDeletedSlides
        // to mimic the old behavior
        const deletedSlideCount = -slideIds.length;
        const eventProps = {
            "slide_ids": slideIds,
            "slide_template_names": null,
            "current_slide_count": this.collection.getWorkspaceSlideCount(this.getWorkspaceId(), deletedSlideCount),
            "slides_created": deletedSlideCount,
        };
        trackActivity("Slide", "Delete", this.id, deletedSlideCount, eventProps, { audit: true });

        // Disconnect all deleted slides
        justDeletedSlides.forEach(slide => slide.disconnect());

        return this.setSips(sips, presentationDeletedSlides, options);
    },

    replaceSlide: function(deleteSlide, newSlides, options) {
        let insertIndex = this.getSlideIndex(deleteSlide.id);
        return this.destroySlides(deleteSlide.id, Object.assign({ silent: true }, options)).then(() => {
            return this.createSlides(newSlides, insertIndex, options).then(slides => slides[0]);
        });
    },

    batchDuplicateSlides: async function(slides, options = {}) {
        try {
            await checkSlideCount(this.collection, slides.length, this.getWorkspaceId());
        } catch (err) {
            throw err;
        }
        return this.createSlides(slides, options.insertIndex, options);
    },

    batchShareSlides: async function(slides, options = {}) {
        try {
            await checkSlideCount(this.collection, slides.length, this.getWorkspaceId());
        } catch (err) {
            throw err;
        }
        if (!slides) {
            return [];
        }
        if (!_.isArray(slides)) {
            slides = [slides];
        }
        let index = options.insertIndex || 0;
        let deletedSlides = { ...this.get("deletedSlides") };
        slides.forEach((slide, i) => {
            !options.skipUndo && app.undoManager.set(UndoType.ADD_SLIDE, slide.id, null, {
                slideData: slide.attributes,
                index: index + i
            });
            if (!this.disconnected) {
                NotificationsService.notifyOnSlideAdded(this.id, slide.id)
                    .catch(err => logger.error(err, "[presentation] batchShareSlides() NotificationsService.notifyOnSlideAdded() failed", { id: this.id, slideId: slide.id }));
            }
        });
        this.slides.add(slides, Object.assign({ silent: true, at: index }, options));
        await this.updateSIPS(deletedSlides, options);
        return slides;
    },

    batchSkipSlides: function(slideModels) {
        slideModels.forEach(async slideModel => {
            this.skipSlide(slideModel, true);
        });
    },

    batchUnskipSlides: function(slideModels) {
        slideModels.forEach(async slideModel => {
            this.skipSlide(slideModel, false);
        });
    },

    duplicateSlide: function(slide, options) {
        if (!slide) return null;
        app.undoManager.openGroup();
        const promise = this.batchDuplicateSlides([slide], options);
        app.undoManager.closeGroup();
        return promise;
    },

    cutSlides: async function(slides) {
        await this.copySlides(slides);

        // if trying to delete all slides, keep first slide
        if (slides.length === this.slides.length) {
            slides = slides.slice(1);
        }

        return this.destroySlides(slides.map(slide => slide.id));
    },

    copySlides: async function(slides) {
        // Ensure slides are done loading before copying them
        await Promise.all(slides.map(slide => slide.load()));

        const workspaceId = ds.selection.presentation.getWorkspaceId();
        const limitedWorkspace = (
            !!workspaceId &&
            app.user.features.isFeatureEnabled(
                FeatureType.PROHIBIT_EXTERNAL_WORKSPACE_MOVEMENT,
                workspaceId,
            ) &&
            workspaceId
        );

        const data = {
            // `limitedWorkspace` will either be false, or the workspaceId that is limited
            limitedWorkspace,
            // Passing attributes instead of backbone models
            slides: slides.map(s => s.attributes),
        };
        await clipboardWrite({
            [ClipboardType.SLIDES]: data,
        });

        const { slideTemplates: { slideTemplates } } = await getCanvasBundle(appVersion);
        const eventProps = {
            "source_slides": slides.map(s => s.get("id")),
            "slide_template_names": slides.map(s => slideTemplates[s.get("template_id")].title),
            "slide_count": slides.length,
        };
        trackActivity("Slide", "Copy", null, slides.length, eventProps, { audit: false });
        ShowSnackBar({
            message: slides.length > 1 ? `${slides.length} Slides Copied to Clipboard` : "Slide Copied to Clipboard"
        });
    },

    pasteSlides: async function(insertIndex, data, isSlideCreatedFromUserAction) {
        return await (async () => {
            if (data) {
                if (
                    !!data.limitedWorkspace &&
                    data.limitedWorkspace !== this.getWorkspaceId()
                ) {
                    ShowSnackBar({
                        message: `Copied ${data.slides.length > 1 ? "Slides are" : "Slide is"} Prohibited from being pasted to another workspace`
                    });
                    return null;
                }

                let slides = data.slides;

                app.undoManager.openGroup();
                const slideModels = await this.batchDuplicateSlides(slides, { insertIndex, isSlideCreatedFromUserAction });
                app.undoManager.closeGroup();

                const pastedSlideCount = slideModels.length;
                const { slideTemplates: { slideTemplates } } = await getCanvasBundle(appVersion);
                const eventProps = {
                    "slide_ids": slideModels.map(s => s.get("id")),
                    "source_slides": slides.map(s => s.id),
                    "slide_template_names": slideModels.map(s => slideTemplates[s.get("template_id")].title),
                    "slides_created": pastedSlideCount,
                };
                trackActivity("Slide", "Paste", null, pastedSlideCount, eventProps, { audit: true });
                ShowSnackBar({
                    message: slideModels.length > 1 ? `${slideModels.length} Slides Pasted from Clipboard` : "Slide Pasted from Clipboard"
                });

                return slideModels;
            }
            return null;
        })()
            .catch(err => {
                ErrorHandler.handleSlideLimitReached(err, { workspaceId: ds.selection.presentation.getWorkspaceId() });
                return null;
            });
    },

    moveSlides: function(slideIds, index, options = {}) {
        const slideRefs = this.get("slideRefs");

        // Removing moved slides
        const updatedSips = this.getSips().filter(slideId => !slideIds.includes(slideId));
        // Calcing insert index
        const insertIndex = Math.min(index, updatedSips.length);
        // Inserting moved slides
        updatedSips.splice(insertIndex, 0, ...slideIds);

        // Composing updated slide refs
        const updatedSlideRefs = {};
        updatedSips.forEach((slideId, slideIdx) => {
            updatedSlideRefs[slideId] = slideIdx;
        });

        const changeSet = this.update({ slideRefs: updatedSlideRefs });
        if (changeSet.hasUpdates) {
            app.undoManager.set(
                UndoType.SLIDE_ORDER,
                null,
                {
                    slideRefs,
                    movedSlides: slideIds
                },
                {
                    slideRefs: updatedSlideRefs,
                    movedSlides: slideIds
                }
            );

            if (!this.disconnected) {
                slideIds.forEach(slideId => {
                    NotificationsService.notifyOnSlideMoved(this.id, slideId)
                        .catch(err => logger.error(err, "[presentation] moveSlides() NotificationsService.notifyOnSlideMoved() failed", { id: this.id, slideId }));
                });
            }
        }

        // We still have to invoke this callback even if there were no changes in order
        // to refresh the slide grid and uncollapse the slide items that were being dragged
        return this.onSlideRefChange(this, this.get("slideRefs"), options);
    },

    getSlideIndex: function(slideId) {
        //NOTE: this is slow, so avoid using this in a loop. if you need to grab many ids, call sortSlides on the ids.
        const sips = this.getSips();
        const index = sips.indexOf(slideId);
        if (index === -1) {
            return 0;
        }
        return index;
    },

    getPublicLink: function() {
        return `${location.origin}/deck/${this.id}/${slug(this.get("name"))}`;
    },

    getPlayableSlideModels: function() {
        let playableSlideModels = [];
        this.slides.forEach(model => {
            if (!model.get("isSkipped")) {
                playableSlideModels.push(model);
            }
        });
        return playableSlideModels;
    },

    getEmbedCode: function(link, inputSizeOptions = { useCustomSize: false, width: null, height: null }) {
        let embedOptions = link.get("embedOptions");

        let url = `${link.getLinkURL()}?utm_source=beautiful_player&utm_medium=embed&utm_campaign=${this.id}`;

        let divSize;
        //The customSize set by client has highest priority, then customSize set on the link in database, else default.
        if (inputSizeOptions.useCustomSize) {
            divSize = `width:${inputSizeOptions.width}px;height:${inputSizeOptions.height}px;`;
        } else if (embedOptions.useCustomSize) {
            divSize = `width:${embedOptions.width}px;height:${embedOptions.height}px;`;
        } else {
            divSize = `width:100%;height:0;padding-bottom:calc(56.25% + 40px);`;
        }

        return `<div style="position:relative;${divSize}"><iframe allow="clipboard-write" allowfullscreen style="position:absolute; width: 100%; height: 100%;border: solid 1px #333;" src="${url}"></iframe><a href="${url}">View ${this.get("name")} on Beautiful.ai</a></div>`;
    },

    duplicate: async function(name = null) {
        try {
            await checkSlideCount(this.collection, Object.keys(this.get("slideRefs")).length, this.getWorkspaceId());
        } catch (err) {
            throw err;
        }

        return presentationsApi.copyPresentation({
            id: this.id,
            workspaceId: this.getWorkspaceId(),
            name
        });
    },

    openExternalUrl: async function(href) {
        return new Promise(resolve => {
            const isPublicLink = this.get("link")?.type === "public";

            if (!isPublicLink) {
                window.open(href, "_blank");
                resolve(true);
            } else {
                try {
                    const linkHostname = new URL(href).origin;
                    const serverHostname = new URL(serverUrl).origin;
                    if (linkHostname === serverHostname) {
                        window.open(href, "_blank");
                        resolve(true);
                        return;
                    }
                } catch {
                    // Ignore
                }

                ShowConfirmationDialog({
                    title: "Are You Sure?",
                    message: `Clicking this link will open a web page that is not controlled by Beautiful.ai. Are you sure you want to go to ${href}?`,
                    acceptCallback: () => {
                        window.open(href, "_blank");
                        resolve(true);
                    },
                    cancelCallback: () => {
                        resolve(false);
                    }
                });
            }
        });
    },

    showExportFailDialog: function() {
        this.closeExportDialog();
        const message = <div>We’re sorry, but an error occurred while exporting this presentation. Please try again or contact <a href="mailto:support@beautiful.ai?subject=Error in export">support@beautiful.ai</a> to report this issue.</div>;
        ShowMessageDialog({
            title: "Export Failure",
            message
        });
    },

    closeExportDialog: function() {
        if (this.progressDialog) {
            this.progressDialog.props.closeDialog();
            this.progressDialog = null;
        }
    },

    printToPDF: function(pdfCompressionType = "none") {
        this.enqueueExportCache("pdf", url => window.open(url, "_blank"), { title: "Preparing for print...", forPrinting: true, includeSkippedSlides: true, pdfCompressionType });
    },

    // Upon clicking export, this function checks if there are any unsupported templates being
    // used, warns the users that they cannot be exported if there are any, and then sends an export job to the backend
    enqueueExportCache: function(assetType, downloadUrlHandler, { title, forPrinting, includeSkippedSlides, pdfCompressionType }) {
        // Ensure that any changes to the current slide are saved before we begin
        if (ds.selection.slide?.finishedEditing) {
            ds.selection.slide.finishedEditing();
        }

        const templatesUsed = this.slides.map(s => s.attributes.template_id);
        const unsupportedSlides = _.intersection(templatesUsed, assetType === "zip" ? [] : UNSUPPORTED_TEMPLATES_FOR_EXPORT);

        const startExport = async () => {
            let cancelTask;
            const onTaskChanged = async task => {
                if (task.state === TaskState.ERROR) {
                    logger.error(new Error(task.errorMessage), "enqueueExportCache() task failed", { taskId: task.id, presentationId: this.id });
                    this.showExportFailDialog();
                    return;
                }

                if (task.state === TaskState.PREPARING) {
                    this.progressDialog = ShowDialog(ProgressDialog, {
                        title,
                        message: this.slides.length > 50 ? "Your presentation is over 50 slides. This may take a bit!" : null,
                        onCancel: () => {
                            if (cancelTask) {
                                cancelTask();
                            }

                            this.progressDialog.props.closeDialog();
                        }
                    });
                }

                if (task.state === TaskState.PROCESSING) {
                    if (this.progressDialog) {
                        this.progressDialog.setProgress(task.stateProgressPercents);
                    }
                    return;
                }

                if (task.state === TaskState.FINISHED) {
                    await downloadUrlHandler(task.signedUrl);
                    this.closeExportDialog();
                }
            };

            const taskProps = {
                presentationId: this.get("id"),
                presentationLinkId: this.get("link")?.id,
                hideBaiBranding: false,
                hideSmartSlideWatermark: false,
                isTeamUser: false,
                assetType,
                forPrinting,
                includeSkippedSlides,
                pdfCompressionType
            };
            const userFeatures = app.user?.features;
            if (userFeatures) {
                taskProps.hideBaiBranding = userFeatures.isFeatureEnabled(FeatureType.REMOVE_BAI_BRANDING, this.getWorkspaceId());
                taskProps.hideSmartSlideWatermark = userFeatures.isFeatureEnabled(FeatureType.SMART_SLIDES, this.getWorkspaceId());
                taskProps.isTeamUser = userFeatures.isFeatureEnabled(FeatureType.TEAMS, this.getWorkspaceId());
            }

            const { cancel } = await requestExportCache(onTaskChanged, taskProps);
            cancelTask = cancel;
        };

        if (unsupportedSlides.length) {
            ShowConfirmationDialog({
                title: "One or more slides will not be exported correctly",
                message: `Exporting the following slides is not supported: ${unsupportedSlides.join(", ")}.`,
                acceptCallback: () => startExport().catch(err => logger.error(err, "startExport() failed", { presentationId: this.id }))
            });
        } else {
            startExport().catch(err => logger.error(err, "startExport() failed", { presentationId: this.id }));
        }
    },

    downloadExportCache: function(assetType, { destination = "local", includeSkippedSlides = false, pdfCompressionType = "none" }, onCallback = null) {
        const props = {
            "saved_to": destination,
        };
        const audit = !!app.user;
        if (assetType === "pdf") {
            trackActivity("Presentation", "PDFExport", null, null, props, { audit });
        } else if (assetType === "pptx") {
            trackActivity("Presentation", "PPTXExport", null, null, props, { audit });
        } else if (assetType === "zip") {
            trackActivity("Presentation", "OfflineExport", null, null, props, { audit });
        }

        const downloadUrlHandler = async url => {
            let fileName;
            fileName = filenameForExport({ name: `${this.get("name")}`, assetType });

            try {
                await downloadFromUrl(url, fileName);
                onCallback && onCallback();
            } catch (err) {
                logger.error(err, "downloadExportCache() downloadFromUrl() failed", { presentationId: this.id, url });

                ShowErrorDialog({ title: "Error", message: "Sorry, we could not export the presentation" });
            }
        };

        this.enqueueExportCache(assetType, downloadUrlHandler, { title: "Exporting...", forPrinting: false, includeSkippedSlides, pdfCompressionType });
    },

    destroy(options) {
        //Call Backbone's destroy instead of the StorageModel's destroy so we can call our delete api instead.
        Backbone.Model.prototype.destroy.call(this, options);
        this.destroyed = true;
        this.adapter.disconnect();
        //delete the presentation via an endpoint.
        if ((!options || !options.remoteChange) && !this.disconnected) {
            return presentationsApi.deletePresentation({ id: this.id });
        } else {
            return Promise.resolve({});
        }
    },

    generateLoadPromise(...args) {
        return StorageModel.prototype.generateLoadPromise.call(this, ...args)
            .then(() => {
                if (!this.get("userId")) {
                    throw new Error("This is an invalid presentation.");
                }
                if (this.disconnected) {
                    return;
                }

                // If the model was created withe loadPermission different than read then we have to
                // load permissions from the api (firebase load will only fail if user doesn't have 'read'
                // permission, but to check for 'write' or 'owner' we have to ask the API)
                // NOTE: model with this.loadPermission === 'write' is created by calling
                // presentations.getPresentation() with permission === 'write' before the presentation
                // was loaded as part of the presentations collection
                return this.getUserPermissions(this.loadPermission !== "read").then(() => {
                    if (this.permissions[this.loadPermission]) {
                        return;
                    } else {
                        throw new PresentationPermissionDeniedError("Permission denied.");
                    }
                });
            }).then(() => {
                this.applyMigrations();
                return this;
            }).catch(err => {
                this.stopListening();

                if (
                    err instanceof PresentationPermissionDeniedError ||
                    err instanceof PresentationNotFoundError
                ) {
                    throw err;
                }

                if (err.statusCode === 403) {
                    throw new PresentationPermissionDeniedError(err);
                }
                if (err.statusCode === 404) {
                    throw new PresentationNotFoundError(err);
                }

                throw err;
            });
    },

    applyMigrations() {
        // migrate pre-2018 themes elementStyle property
        let migratedProps = {};

        switch (this.get("theme_styleElementStyle")) {
            case "default":
            case "outline":
            case "Outlined":
            case 0:
                migratedProps["theme_styleElementStyle"] = "outlined";
                break;
            case " muted":
            case "Muted":
            case 1:
                migratedProps["theme_styleElementStyle"] = "muted";
                break;
            case "Filled":
            case 2:
                migratedProps["theme_styleElementStyle"] = "filled";
                break;
        }

        switch (this.get("theme_styleWeight")) {
            case "thin":
                migratedProps["theme_styleWeight"] = "light";
                break;
        }

        switch (this.get("theme_styleFonts")) {
            case 1:
                migratedProps["theme_styleTitleFont"] = "sourcesanspro";
                migratedProps["theme_styleBodyFont"] = "sourcesanspro";
                break;
            case 2:
                migratedProps["theme_styleTitleFont"] = "bebasneue";
                migratedProps["theme_styleBodyFont"] = "sourcesanspro";
                break;
            case 3:
                migratedProps["theme_styleTitleFont"] = "robotoslab";
                migratedProps["theme_styleBodyFont"] = "roboto";
                break;
            case 4:
                migratedProps["theme_styleTitleFont"] = "bebasneue";
                migratedProps["theme_styleBodyFont"] = "robotoslab";
                break;
            case 5:
                migratedProps["theme_styleTitleFont"] = "roboto";
                migratedProps["theme_styleBodyFont"] = "roboto";
                break;
            case 6:
                migratedProps["theme_styleTitleFont"] = "montserrat";
                migratedProps["theme_styleBodyFont"] = "montserrat";
                break;
            case 7:
                migratedProps["theme_styleTitleFont"] = "playfair";
                migratedProps["theme_styleBodyFont"] = "raleway";
                break;
        }
        migratedProps["theme_styleFonts"] = null;
        this.update(migratedProps);
    },

    onUpdateError(err) {
        if (err.statusCode === 403) {
            this.invalid = true;
            this.trigger("permissionError", this);
        }
    },

    disconnect: function() {
        StorageModel.prototype.disconnect.call(this);
        this.slides.forEach(slide => {
            slide.disconnect();
        });
    },

    async getThumbnailUrl(slideId = null, size = "small", reloadCache = false) {
        if (!THUMBNAIL_SIZES[size]) {
            throw new Error(`Unknown thumbnail size ${size}`);
        }

        await this.loadPromise;

        if (!slideId) {
            slideId = this.getSips()[0];
        }

        // copied presentations have no slides initially
        if (!slideId) {
            await new Promise(resolve => {
                this.listenTo(this, "change:slideRefs", () => {
                    slideId = this.getSips()[0];
                    resolve();
                });
            });
        }

        return Thumbnails.getSignedUrlAndLoad(slideId, this.get("firstSlideModifiedAt"), this.id, THUMBNAIL_SIZES[size].suffix, 0, reloadCache);
    },

    /**
     * Conceptually, every presentation has a workspaceId which is either its orgId or "personal".
     * This allows us to easily identify an `undefined` or `null` workspaceId as a bug.
     *
     * Note how this differs from the data in Firebase, where a `null` orgId implicitly means that
     * the presentation is in a personal workspace.
     *
     * If you only want the orgId, then just call get("orgId") instead.
     *
     * If the user is collaborating on a presentation from a workspace they are not a member of
     * then the workspace returned is "personal".
     */
    getWorkspaceId() {
        // check if the user a member of the presentation's workspace
        const orgId = this.get("orgId");
        if (orgId && app.user && app.user.workspaces[orgId]) {
            return orgId;
        } else {
            return "personal";
        }
    },

    /**
     * Helper to easily get the count of presentations in this presentation's workspace.
     */
    getWorkspaceSlideCount() {
        if (!this.collection) {
            // this.collection will be undefined if the model is standalone
            // and not a part of the Presentations collection
            return null;
        }

        return this.collection.getWorkspaceSlideCount(this.getWorkspaceId());
    },

    async toObject() {
        const attrs = await StorageModel.prototype.toObject.call(this);
        const sips = this.getSips();

        //base64 encode images
        if (attrs.theme_logo) {
            const id = getLogoPath(attrs.theme_logo);
            await Logos.getSignedUrlAndLoad(id);
        }
        if (attrs.theme_logo_dark) {
            const id = getLogoPath(attrs.theme_logo_dark);
            await Logos.getSignedUrlAndLoad(id);
        }

        // Loading thumbnails of all sizes
        await Promise.all(Object.keys(THUMBNAIL_SIZES).map(size => this.getThumbnailUrl(null, size)));

        return attrs;
    },

    async fromObject(obj) {
        this.set(_.cloneDeep(obj.presentation));
    },

    getAnalytics() {
        if (arguments.length > 0) {
            throw new Error("unexpected arguments");
        }
        return {
            "presentation_id": this.get("id"),
        };
    },

    async getUserPermissions(fetchPermissions) {
        // If presentation loaded then user has read permissions
        this.permissions.read = true;

        // Is public?
        this.permissions.public = !!this.get("public");

        // Is owner?
        if (app.user?.id === this.get("userId")) {
            this.permissions.read = true;
            this.permissions.write = true;
            this.permissions.owner = true;
            return this.permissions;
        }

        if (!app.user) {
            if (this.loadPermission === "read") {
                return this.permissions;
            }

            // Anonymous can only request read permission
            throw new PresentationPermissionDeniedError("Permission denied.");
        }

        if (!fetchPermissions) {
            return this.permissions;
        }

        // Fetch all permissions (only once)
        if (!this.userPermissionsApiPromise) {
            this.userPermissionsApiPromise = Api.userPermissions.get({ id: this.id });
        }

        this.permissions = await this.userPermissionsApiPromise;

        return this.permissions;
    },

    hasSmartSlides() {
        const smartSlides = this.slides
            .filter(slide => slide.dataState?.template_id !== "authoring");
        const result = !!smartSlides.length;
        return result;
    },

    skipSlide(slide, isSkipped) {
        const skippedSlides = _.cloneDeep(this.get("skippedSlides")) ?? {};
        if (isSkipped) {
            skippedSlides[slide.id] = isSkipped;
        } else {
            delete skippedSlides[slide.id];
        }
        this.update({ skippedSlides }, { replaceKeys: true });

        if (slide.loaded && !isSkipped && slide.get("isSkipped") === true) {
            // Force-remove legacy isSkipped value if slide model is loaded
            slide.update({ isSkipped: false });
        }

        this.trigger("updateSkippedSlides");
    },

    isSlideSkipped(slide) {
        const skippedSlides = this.get("skippedSlides") ?? {};
        if (skippedSlides[slide.id] === true) {
            return true;
        }

        if (!slide.loaded) {
            logger.warn(`isSlideSkipped() slide ${slide.id} is not loaded, falling back to false`, { presentationId: this.id, slideId: slide.id });
            return false;
        }

        return !!slide.get("isSkipped");
    }
});

const Presentations = ReferenceCollection.extend({
    model: Presentation,
    ignoreErrors: true,
    referenceRoot: "users",
    getReferenceId: function() {
        return `${this.userId}/presentations`;
    },

    initialize: function() {
        this.type = "Presentations";
        this.userId = app.user.id;

        // analytics
        let presentationCount = 0;
        const updatePresentationCountUserProp = () => {
            if (this.length !== presentationCount) {
                setUserProps({
                    presentations: this.length,
                    publicPresentations: this.filter(p => p.get("public")).length
                });
                presentationCount = this.length;
            }
        };
        this.on("modelsLoaded", () => {
            updatePresentationCountUserProp();
            this.on("update", () => {
                updatePresentationCountUserProp();
            });
        });
    },

    reload: function() {
        this.loadPromise = null;
        return this.load();
    },

    getPresentation: async function(presentationId, permission = "read") {
        let presentation = this.get(presentationId);
        if (presentation) {
            if (presentation.loadPermission === "read" && presentation.loadPermission !== permission) {
                // If the presentation was initialized with different *lower* permissions
                // then we need to reload them
                presentation.loadPermission = permission;
                await presentation.getUserPermissions(true);
            }

            return presentation;
        }

        presentation = new Presentation({ id: presentationId }, {
            permission,
            remoteChange: true,
            userId: this.userId,
            autoLoad: false
        });

        this.add(presentation, { remoteChange: true, silent: true, loadModels: false });

        await presentation.load();

        return presentation;
    },

    createPresentation: async function(model, defaultSlides, options = {}) {
        const workspaceId = model.orgId || "personal";
        try {
            if (!options.isTour) {
                await checkSlideCount(this, defaultSlides.length, workspaceId);
            }
        } catch (err) {
            throw err;
        }

        const presentation = new Presentation(_.extend(model, {
            userId: this.userId,
            createdAt: new Date().getTime(),
            modifiedAt: new Date().getTime(),
            ...app.user.features.getFeatureProps(FeatureType.WORKSPACE_PLAYER_SETTINGS_DEFAULTS, AppController.orgId),
        }), {
            userId: this.userId
        });

        await presentation.loadPromise;

        // Setup the permissions on the new presentation
        await presentation.getUserPermissions(true);

        // create any default slides for the presentation
        const slideRefs = {};

        await Promise.all(defaultSlides.map(async (defaultSlide, index) => {
            const overrides = {};
            if (defaultSlide.data) {
                overrides.states = [defaultSlide.data];
            }
            if (defaultSlide.layout) {
                overrides.layout = defaultSlide.layout;
            }
            const slideData = await presentation.getTemplateSlideData(defaultSlide.templateId, overrides);

            slideData.version = appVersion;
            const slide = new Slide(slideData, {
                presentation,
                autoSync: false
            });
            await slide.load();
            slide.disconnect();
            slideRefs[slide.id] = index;
        }));

        presentation.update({ slideRefs });
        await presentation.updatePromise;
        return this.add(presentation);
    },

    setSort: function(sort) {
        this.comparator = function(item) {
            if (sort == "name") {
                return item.get("name").toLowerCase();
            } else {
                return -item.get(sort);
            }
        };
        this.sort();
    },

    emptyTrash: function(workspaceId) {
        const softDeletedPresentations = this.filter(presentation => presentation.get("softDeletedAt"));
        softDeletedPresentations.forEach(softDeletedPresentation => {
            if (softDeletedPresentation.getWorkspaceId() !== workspaceId) {
                return;
            }
            let isOwner = softDeletedPresentation.permissions.owner;
            const deletedSlideCount = -Object.keys(softDeletedPresentation.get("slideRefs") || {}).length;
            const presentationId = softDeletedPresentation.id;
            softDeletedPresentation.destroy({ orgId: workspaceId == "personal" ? null : workspaceId });
            if (isOwner) {
                incUserProps({
                    deleted_presentations: 1
                });
            }
            const eventProps = {
                "current_slide_count": this.getWorkspaceSlideCount(workspaceId),
                "slides_created": deletedSlideCount,
                "presentation_id": presentationId,
                "library_location": PresentationFilters.TRASH,
            };
            trackActivity("Presentation", "Delete", null, null, eventProps, { audit: true });
        });
    },

    getWorkspaceSlideCount: function(workspaceId, slideCountFromAction = 0) {
        if (!workspaceId) {
            throw new Error("workspaceId is required");
        }
        let totalSlideCount = slideCountFromAction;

        this.forEach(presentationModel => {
            if (presentationModel.getWorkspaceId() !== workspaceId || presentationModel.get("isTemplate")) {
                return;
            }
            //If to handle when the slides are not already loaded for the presentation (ie. editor view)
            if (!presentationModel.slides.length) {
                let slidesLength = 0;
                if (presentationModel.get("slideRefs")) {
                    slidesLength = Object.keys(presentationModel.get("slideRefs")).length;
                } else if (presentationModel.get("slides")) {
                    slidesLength = presentationModel.get("slides").length;
                }
                totalSlideCount += slidesLength;
            } else {
                totalSlideCount += presentationModel.slides.length;
            }
        });
        return totalSlideCount;
    },

    getWorkspaceDeletedCount: function(workspaceId) {
        if (!workspaceId) {
            throw new Error("workspaceId is required");
        }
        let deletedPresentationCount = 0;
        let deletedSlideCount = 0;

        this.forEach(presentationModel => {
            if (presentationModel.getWorkspaceId() !== workspaceId) {
                return;
            }
            if (presentationModel.has("softDeletedAt")) {
                deletedPresentationCount++;
                if (!presentationModel.slides.length) {
                    let slidesLength = 0;
                    if (presentationModel.get("slideRefs")) {
                        slidesLength = Object.keys(presentationModel.get("slideRefs")).length;
                    } else if (presentationModel.get("slides")) {
                        slidesLength = presentationModel.get("slides").length;
                    }
                    deletedSlideCount += slidesLength;
                } else {
                    deletedSlideCount += presentationModel.slides.length;
                }
            }
        });
        return { deletedPresentationCount, deletedSlideCount };
    },

    getWorkspacePresentationCount(workspaceId) {
        if (!workspaceId) {
            throw new Error("workspaceId is required");
        }
        let count = 0;
        this.forEach(presentationModel => {
            if (presentationModel.getWorkspaceId() !== workspaceId) {
                return;
            }
            count++;
        });
        return count;
    }
});

export { Presentation, Presentations, PresentationPrivacyType };
