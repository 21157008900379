import React from "react";
import StepWizard from "react-step-wizard";

import db from "js/db";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { SubscriptionStatus } from "common/constants";
import { getExperiments } from "js/core/services/experiments";

import { MarketingSurvey } from "./MarketingSurvey";
import { ChoosePlan } from "./ChoosePlan";

class Onboarding extends React.Component {
    state = {};

    async componentDidMount() {
        const userRef = db.getDbInstance("users").ref(`users/${this.props.firebaseUser.uid}`);
        const userSnap = await userRef.once("value");
        const user = userSnap.val();

        let {
            onboarding_plan_confirm: showPlan,
            onboarding_survey: showSurvey,
            must_verify_email: showVerify
        } = await getExperiments(["onboarding_plan_confirm", "onboarding_survey", "must_verify_email"]);

        // don't show pro plan chooser to team users, or editing collaborators
        if (window.baiSource == "team-invite" || window.baiSource === "collab:edit") {
            showPlan = false;
            await userRef.update({
                didConfirmPlan: true
            });
        }

        // don't show tour for team invites
        if (window.baiSource == "team-invite") {
            await userRef.update({
                hasTakenTour: true
            });
        }

        // don't show survey to editing collaborators
        if (window.baiSource === "collab:edit") {
            showSurvey = false;
        }

        this.setState({
            user,
            showPlan,
            showSurvey,
            showVerify
        });
    }

    render() {
        if (!this.state.user) {
            return null;
        }

        const { user, showSurvey, showPlan } = this.state;
        const unknownStatus = user.stripeSubscriptionId !== undefined && user.subscriptionStatus === undefined;
        const hasPlan = user.subscriptionStatus === SubscriptionStatus.ACTIVE || user.subscriptionStatus === SubscriptionStatus.TRIALING || user.subscriptionStatus === SubscriptionStatus.PAST_DUE || unknownStatus;

        return (
            <StepWizard
                transitions={{}}
                isLazyMount
            >
                {!user.edu && !user.userInfo?.complete && showSurvey && <MarketingSurvey />}
                {!user.edu && !user.didConfirmPlan && !hasPlan && !user.hasTakenTeamTrial && showPlan && <ChoosePlan continueUrl={window.location.pathname + window.location.search} />}
                <>
                    {this.props.children}
                </>
            </StepWizard>
        );
    }
}

export default withFirebaseUser(Onboarding);
