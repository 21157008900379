import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { app } from "js/namespaces";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { formatter } from "js/core/utilities/formatter";
import {
    Button,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Icon,
    MenuItem,
    Select as SelectInput
} from "@material-ui/core";

import { dialogTheme } from "js/react/materialThemeOverrides";

import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import AppController from "js/core/AppController";
import { RenewSubscription } from "./RenewSubscription";
import { isPPTAddin } from "js/config";

const Container = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1200;
    background: transparent;
    backdrop-filter: blur(5px);
`;

const AlertContainer = styled.div`
    color: red;
    font-size: 20px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;

    >div {
        margin-left: 10px;
    }
`;

const StyledLink = styled.a`
    color: #11a9e2;
`;

const WorkspaceSelector = styled(DialogContentText)`
&&& {
    display: flex;
    gap: 5px;
    align-items: center;
}`;

const StyledDialogContent = styled(DialogContent)`
    &&& {
        padding: 0 60px 50px;
    }
`;

const StyledDialogTitle = styled(DialogTitle)`
    &&& {
        padding: 50px 34px 30px;
    }
`;

function WorkspaceLockedDialog({
    subscription,
    canManageTeam,
    contactEmail,
    organizationId,
    modifiedAt
}) {
    const [orgId, setOrgId] = useState(organizationId);
    const [paymentForm, setPaymentForm] = useState(false);

    const workspaces = useMemo(() => Object.values(app.user.workspaces), [app.user.workspaces]);

    useEffect(() => {
        setOrgId(organizationId);
    }, [organizationId]);

    const currentWorkspace = workspaces.find(workspace => workspace.id === orgId);
    const teamName = currentWorkspace.getName();

    const canceledDate = subscription ? moment.unix(subscription.currentPeriodEnd ?? subscription.current_period_end) : moment(modifiedAt);
    const currentPeriodEnd = canceledDate.format("MMM DD, YYYY");

    const email = contactEmail ?? "support@beautiful.ai";

    const handleSwitchWorkspace = event => {
        setOrgId(event.target.value);
        AppController.switchWorkspace(event.target.value);

        if (AppController.view !== "library" && !isPPTAddin) {
            AppController.showLibrary();
        }
    };

    const showPaymentForm = () => setPaymentForm(true);

    return (
        <Container>
            {paymentForm ? (<RenewSubscription subscription={subscription} orgId={orgId} teamName={teamName}/>) : (
                <MuiThemeProvider theme={dialogTheme}>
                    <BeautifulDialog maxWidth="xl" fullWidth={false} hideBackdrop closeDialog={() => { }}>
                        <StyledDialogTitle>
                            <AlertContainer>
                                <Icon>error</Icon>
                                <div> {teamName}'s subscription has been canceled</div>
                            </AlertContainer>
                        </StyledDialogTitle>
                        <StyledDialogContent>
                            {canManageTeam && <DialogContentText>
                                <span>The {teamName} team’s subscription ended on {currentPeriodEnd}.</span>
                                <br />
                                <Button variant="text" onClick={showPaymentForm}>reactivate your subscription</Button> to unlock your workspace.
                            </DialogContentText>}
                            {!canManageTeam && <DialogContentText>
                                The {teamName} team’s subscription ended on {currentPeriodEnd}.
                                <br />
                                Please contact a team owner to reactivate your account.
                            </DialogContentText>}
                            <WorkspaceSelector component="div">
                                <b>Access your other workspaces to continue using Beautiful.ai:</b>
                                <SelectInput
                                    value={orgId}
                                    onChange={ handleSwitchWorkspace }
                                >
                                    {workspaces.map((workspace, index) => (
                                        <MenuItem key={index} value={workspace.id}>{workspace.getName()}</MenuItem>))}
                                </SelectInput>
                            </WorkspaceSelector>
                            <DialogContentText>
                                If you have any questions, reach out to <StyledLink href={`mailto:${email}`}>{email}</StyledLink>.
                            </DialogContentText>
                        </StyledDialogContent>
                    </BeautifulDialog>
                </MuiThemeProvider>
            )}
        </Container >
    );
}

export default WorkspaceLockedDialog;
