import React, { useEffect } from "react";
import styled from "styled-components";
import { StandalonePage, StandaloneForm } from "../Standalone";
import { getStaticUrl } from "js/config";

const OutroContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 650px;
    height: 488px;
    padding: 0px 202px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 9px 37px 0px rgba(0, 0, 0, 0.20);

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
        padding: 20px;
    }
`;

const OutroImage = styled.div`
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    background: url(${getStaticUrl("/images/auth/done-check.gif")});
    background-size: cover;

    @media (max-width: 768px) {
        width: 80px;
        height: 80px;
    }
`;

const OutroText = styled.div`
    color: #222;
    font-family: "Source Sans Pro";
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.5px;

    @media (max-width: 768px) {
        font-size: 18px;
        text-align: center;
    }
`;

const Outro = ({ onNext }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onNext();
        }, 1500);

        return () => clearTimeout(timer);
    }, [onNext]);

    return (
        <StandalonePage maxWidth={600}>
            <StandaloneForm open={true} hideBackdrop fullWidth style={{ overflow: "hidden" }}>
                <OutroContainer>
                    <OutroImage />
                    <OutroText>Thank you!</OutroText>
                </OutroContainer>
            </StandaloneForm>
        </StandalonePage>
    );
};

export default Outro;
