import Api from "js/core/api";
import getLogger, { LogGroup } from "js/core/logger";
import firebase from "js/firebase";
import { _ } from "js/vendor";

const logger = getLogger(LogGroup.EXPERIMENTS);

const experimentsCache: Record<string, Record<string, boolean>> = {};

let getExperimentsPromiseChain = Promise.resolve();
export async function getExperiments(experimentIds: string[]) {
    return new Promise((resolve, reject) => {
        // Chain to ensure we don't execute similar requests in parallel
        getExperimentsPromiseChain = getExperimentsPromiseChain
            .then(async () => {
                try {
                    const uid = firebase.auth().currentUser?.uid ?? "anonymous";
                    if (!experimentsCache[uid]) {
                        experimentsCache[uid] = {};
                    }
                    const missingExperiments = experimentIds.filter(id => !(id in experimentsCache[uid]));
                    if (missingExperiments.length > 0) {
                        const response = (await Api.experiments.post({ ids: missingExperiments })) as Record<string, boolean>;
                        _.assign(experimentsCache[uid], response);
                    }
                    return _.pick(experimentsCache[uid], experimentIds);
                } catch (err) {
                    logger.error(err, "[getExperiments] failed");
                    throw err;
                }
            })
            .then(resolve)
            .catch(reject);
    });
}
