import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Button, DialogActions, Icon, DialogTitle, DialogContent, DialogContentText } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import { getStaticUrl, isPPTAddin } from "js/config";
import { ds } from "js/core/models/dataService";
import { trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import { getPricingPageUrl } from "js/core/utilities/pricing";
import getUserProfile from "js/core/services/userProfiles";
import Loadable from "js/react/components/Loadable";
import { UserProfile } from "js/react/components/Avatar";

import { BeautifulDialog } from "../../components/Dialogs/BaseDialog";
import { ShowConfirmationDialog, ShowDialog, ShowUpgradeDialog } from "../../components/Dialogs/BaseDialog";
import { dialogTheme } from "../../materialThemeOverrides";
import { ScaryButton } from "../../components/UiComponents";
import CheckoutDialog from "../UserOptions/Billing/CheckoutDialog";
import { Gap20, Gap30, Gap50 } from "../../components/Gap";

export const UpgradePlanDialogType = {
    UPGRADE_PLAN: "upgrade_plan",
    UPGRADE_PLAN_TO_TEAM: "upgrade_plan_to_team",
    EXCEEDED_SLIDE_LIMIT: "exceeded_slide_limit",
    REMOVE_BRANDING: "remove_branding",
    TEAM_NOOP: "team-noop"
};

function Subheader(props) {
    let subheader;
    if (props.showTrial) {
        subheader = "Get started with a 14-day free trial of Beautiful.ai Pro to try our advanced features.";
    } else {
        subheader =
            "Fully loaded with advanced features so you'll have all the pro tools you need - to create not" +
            " only a beautiful presentation, but an impact. Every single time.";
    }

    return (
        <div>
            <div className="header-text">{subheader}</div>
            <Gap30 />
            {
                !app.isConstrained &&
                <Fragment>
                    <div className="feature-label">Just Some of the Features You'll Love in Pro:</div>
                    <Gap20 />
                </Fragment>
            }
        </div>
    );
}

function UpgradeToProContents(props) {
    return (
        <div>
            {
                !app.isConstrained &&
                <div className="feature-scroll">
                    <div className="feature-list">
                        <div className="feature">
                            <img src={getStaticUrl("/images/upgradePlan/Unlimited.svg")} />
                            <div className="frame">
                                <div className="title">Smart Slides</div>
                                <div className="description">
                                    Get unlimited access to all our Smart Slides with no watermarks.
                                </div>
                            </div>
                        </div>

                        <div className="feature">
                            <img src={getStaticUrl("/images/upgradePlan/Player.svg")} />
                            <div className="frame">
                                <div className="title">Remove Beautiful.ai Branding</div>
                                <div className="description">
                                    Remove Beautiful.ai branding and set custom playback options for your presentations.
                                </div>
                            </div>
                        </div>

                        <div className="feature">
                            <img src={getStaticUrl("/images/upgradePlan/Export.svg")} />
                            <div className="frame">
                                <div className="title">Editable PowerPoint Export</div>
                                <div className="description">Export your presentation to a fully editable PowerPoint.</div>
                            </div>
                        </div>

                        <div className="feature">
                            <img src={getStaticUrl("/images/upgradePlan/Branding.svg")} />
                            <div className="frame">
                                <div className="title">Custom Fonts</div>
                                <div className="description">Import your own fonts to match your company brand.</div>
                            </div>
                        </div>

                        <div className="feature">
                            <img src={getStaticUrl("/images/upgradePlan/Security.svg")} />
                            <div className="frame">
                                <div className="title">Secured Sharing</div>
                                <div className="description">
                                    Securely share your presentation so that only the people you want are able to view it.
                                </div>
                            </div>
                        </div>

                        <div className="feature">
                            <img src={getStaticUrl("/images/upgradePlan/Analytics.svg")} />
                            <div className="frame">
                                <div className="title">Analytics</div>
                                <div className="description">
                                    Track your presentations and see who is viewing them and how effective they are.
                                </div>
                            </div>
                        </div>

                        <div className="feature">
                            <img src={getStaticUrl("/images/upgradePlan/History.svg")} />
                            <div className="frame">
                                <div className="title">Revision History</div>
                                <div className="description">
                                    Keep an unlimited history of all your slide changes so you can revert back to an older
                                    version at any time.
                                </div>
                            </div>
                        </div>

                        <div className="feature">
                            <img src={getStaticUrl("/images/upgradePlan/Desktop.svg")} />
                            <div className="frame">
                                <div className="title">Desktop Player</div>
                                <div className="description">
                                    Play your presentations online or offline in our desktop application with improved
                                    support for multiple monitors and presenter notes.
                                </div>
                            </div>
                        </div>
                    </div>
                    <Gap20 />
                </div>
            }
            {
                app.isConstrained &&
                <div className="feature-scroll">
                    <div className="feature-list-header">Everything in Basic, plus:</div>
                    <div className="feature-list">
                        <div className="feature">
                            <Icon className="text-blue">check</Icon>
                            <div className="frame">
                                <div className="title">Unlimited Slides</div>
                            </div>
                        </div>

                        <div className="feature">
                            <Icon className="text-blue">check</Icon>
                            <div className="frame">
                                <div className="title">Remove Beautiful.ai Branding</div>
                            </div>
                        </div>

                        <div className="feature">
                            <Icon className="text-blue">check</Icon>
                            <div className="frame">
                                <div className="title">Editable PowerPoint Export</div>
                            </div>
                        </div>

                        <div className="feature">
                            <Icon className="text-blue">check</Icon>
                            <div className="frame">
                                <div className="title">Custom Fonts</div>
                            </div>
                        </div>

                        <div className="feature">
                            <Icon className="text-blue">check</Icon>
                            <div className="frame">
                                <div className="title">Secured Sharing</div>
                            </div>
                        </div>

                        <div className="feature">
                            <Icon className="text-blue">check</Icon>
                            <div className="frame">
                                <div className="title">Analytics</div>
                            </div>
                        </div>

                        <div className="feature">
                            <Icon className="text-blue">check</Icon>
                            <div className="frame">
                                <div className="title">Revision History</div>
                            </div>
                        </div>

                        <div className="feature">
                            <Icon className="text-blue">check</Icon>
                            <div className="frame">
                                <div className="title">Desktop Player</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

function handleUpgradeButton(props) {
    trackActivity("Upgrade", "Confirm", null, null, props.analytics, { audit: true });
    if (!isPPTAddin) {
        props.closeDialog();
    }

    const dialogProps = {
        showTrial: true,
        onClose: isPPTAddin ? () => {
            window.location.reload();
        } : undefined
    };

    ShowDialog(CheckoutDialog, dialogProps);
}

const StyledUpdgradeSpan = styled.span`
    color: #23aae0;
`;

export class PersonalUpgradePlanDialog extends Component {
    render() {
        const showTrial = !app.user.hasTakenTrial;
        const cta = showTrial ? "Try Free For 14 Days" : "Upgrade Plan";

        return (
            <MuiThemeProvider theme={dialogTheme}>
                <BeautifulDialog maxWidth="md" fullWidth={false} closeDialog={this.props.closeDialog}>
                    <div className="upgrade-plan-dialog">
                        <div className="outer">
                            <div className="inner">
                                <div className="header-intro">
                                    Upgrade to <StyledUpdgradeSpan>Beautiful.ai Pro</StyledUpdgradeSpan> for as low as{" "}
                                    <StyledUpdgradeSpan>$12</StyledUpdgradeSpan>/month.
                                </div>

                                <Subheader showTrial={showTrial} />

                                {
                                    !app.isConstrained &&
                                    <Fragment>
                                        <UpgradeToProContents />

                                        <div className="cta-block">
                                            <div className="cta-button" onClick={() => handleUpgradeButton(this.props)}>
                                                {cta}
                                            </div>
                                            <div className="cta-description">
                                                Learn more about our different plans{" "}
                                                <a
                                                    href={getPricingPageUrl(app && app.user.hasTakenTrial, app && app.user.has("hasTakenTeamTrial"), "basic")}
                                                    rel="noreferer, ,noopener"
                                                    target="_blank"
                                                >
                                                    here
                                                </a>
                                                .
                                            </div>
                                        </div>
                                    </Fragment>
                                }
                                {
                                    app.isConstrained &&
                                    <Fragment>
                                        <div className="cta-block">
                                            <div className="cta-button" onClick={() => handleUpgradeButton(this.props)}>
                                                {cta}
                                            </div>
                                            <Gap20 />
                                        </div>

                                        <UpgradeToProContents />

                                        <div className="cta-block">
                                            <div className="cta-description">
                                                Learn more about our different plans{" "}
                                                <a
                                                    href={getPricingPageUrl(app && app.user.hasTakenTrial, app && app.user.has("hasTakenTeamTrial"), "basic")}
                                                    rel="noreferer, ,noopener"
                                                    target="_blank"
                                                >
                                                    here
                                                </a>
                                                .
                                            </div>
                                        </div>
                                    </Fragment>
                                }

                            </div>
                        </div>
                    </div>
                </BeautifulDialog>
            </MuiThemeProvider>
        );
    }
}

const StyledSection = styled.p`
    font-size: 18px;
`;

export class PersonalSlideLimitUpgradePlanDialog extends Component {
    handleEmptyTrash = () => {
        this.props.closeDialog();

        const softDeletedPresentations = ds.presentations.filter(presentation => presentation.get("softDeletedAt"));
        if (softDeletedPresentations.length) {
            ShowConfirmationDialog({
                title: "Are you sure you want to delete all of the presentations in your trash?",
                message: "Your presentations will be permanently deleted and cannot be recovered.",
                okButtonLabel: "Empty Trash",
                warning: true
            }).then(accept => {
                if (!accept) {
                    return;
                }
                ds.presentations.emptyTrash(this.props.workspaceId);
            });
        }
    };

    render() {
        const freeSlideLimit = 100; // this needs to be loaded from user obj once that migration is completed

        const { deletedPresentationCount, deletedSlideCount } = ds.presentations.getWorkspaceDeletedCount(this.props.workspaceId);
        const totalPresentations = ds.presentations.getWorkspacePresentationCount(this.props.workspaceId);
        const activeSlideCount = ds.presentations.getWorkspaceSlideCount(this.props.workspaceId);

        return (
            <MuiThemeProvider theme={dialogTheme}>
                <BeautifulDialog maxWidth="md" fullWidth={false} closeDialog={this.props.closeDialog}>
                    <div className="upgrade-plan-dialog">
                        <div className="warning">
                            You have used up your {freeSlideLimit} free slide limit for your Basic Plan.
                        </div>
                        <div className="outer">
                            <div className="inner">
                                <StyledSection>
                                    You can continue to view and edit your current slides but you will not be able to add
                                    additional slides until you either upgrade to Beautiful.ai Pro Plan or delete slides or
                                    presentations to get below {freeSlideLimit} total slides.
                                </StyledSection>

                                <StyledSection>
                                    <strong>Total Presentations: </strong>
                                    {totalPresentations}&nbsp;&nbsp;&nbsp;&nbsp;
                                    <strong>Total Slides Used: </strong>
                                    {activeSlideCount}
                                </StyledSection>

                                {deletedPresentationCount > 0 && (
                                    <Fragment>
                                        <StyledSection>
                                            <strong>NOTE:</strong> You have {deletedPresentationCount} deleted{" "}
                                            {"presentation".pluralize(deletedPresentationCount != 1)} in your trash. You can
                                            permanently empty your trash to free up {deletedSlideCount}{" "}
                                            {"slide".pluralize(deletedSlideCount != 1)}.
                                        </StyledSection>
                                        <Gap20 />
                                        <MuiThemeProvider theme={dialogTheme}>
                                            {" "}
                                            <ScaryButton onClick={this.handleEmptyTrash}>
                                                Permanently Empty Trash Now
                                            </ScaryButton>
                                        </MuiThemeProvider>
                                    </Fragment>
                                )}
                                <Gap30 />
                                <hr />
                                <Gap20 />

                                <div className="cta-block">
                                    <div
                                        className="cta-button"
                                        onClick={() => {
                                            this.props.closeDialog();
                                            ShowUpgradeDialog({
                                                type: UpgradePlanDialogType.UPGRADE_PLAN,
                                                analytics: this.props.analytics,
                                                workspaceId: this.props.workspaceId
                                            });
                                        }}
                                    >
                                        Learn about upgrading to Beautiful.ai Pro
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BeautifulDialog>
            </MuiThemeProvider>
        );
    }
}

export class TeamSlideLimitUpgradePlanDialog extends Component {
    state = {
        admins: [],
        isLoading: true
    };

    async componentDidMount() {
        const orgId = this.props.workspaceId;
        const members = ds.teams.defaultTeamForOrg(orgId).get("members");
        const promises = Object.entries(members).filter(([uid, user]) => {
            return user.role === "owner";
        }).map(([uid, val]) => {
            return getUserProfile(uid);
        });

        const admins = await Promise.all(promises);
        this.setState({ admins, isLoading: false });
    }

    render() {
        const { admins, isLoading } = this.state;
        return (
            <MuiThemeProvider theme={dialogTheme}>
                <BeautifulDialog fullWidth={false} closeDialog={this.props.closeDialog}>
                    <DialogTitle>You have reached your 100 slide limit.</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To continue adding slides, ask your Team Admin to upgrade your account to Team Pro. Until then, you can continue to view and edit your slides.
                        </DialogContentText>
                        <h4>These Admins can update your account:</h4>
                        <Loadable isLoading={isLoading}>
                            <div className="admins">{admins.slice(0, 2).map(profile => <UserProfile key={profile.uid} profile={profile} />)}</div>
                        </Loadable>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.closeDialog} color="primary">Got It</Button>
                    </DialogActions>
                </BeautifulDialog>
            </MuiThemeProvider>
        );
    }
}

export class TeamUpgradePlanDialog extends Component {
    state = {
        admins: [],
        isLoading: true
    };

    async componentDidMount() {
        const orgId = this.props.workspaceId;
        const members = ds.teams.defaultTeamForOrg(orgId).get("members");
        const promises = Object.entries(members).filter(([uid, user]) => {
            return user.role === "owner";
        }).map(([uid, val]) => {
            return getUserProfile(uid);
        });

        const admins = await Promise.all(promises);
        this.setState({ admins, isLoading: false });
    }

    render() {
        const { admins, isLoading } = this.state;
        return (
            <MuiThemeProvider theme={dialogTheme}>
                <BeautifulDialog fullWidth={false} closeDialog={() => this.props.closeDialog(false)}>
                    <DialogTitle>Ask your Team Admin to upgrade your account.</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span>This feature is only available to Pro Team Members. To get access to this - along with many more features you'll love - ask your Team Admin to upgrade your account.</span>
                        </DialogContentText>
                        <h4>These Admins can update your account:</h4>
                        <Loadable isLoading={isLoading}>
                            <div className="admins">{admins.slice(0, 2).map(profile => <UserProfile key={profile.uid} profile={profile} />)}</div>
                        </Loadable>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.closeDialog(false)} color="primary">Got It</Button>
                    </DialogActions>
                </BeautifulDialog>
            </MuiThemeProvider>
        );
    }
}

export class PersonalRemoveBrandingDialog extends Component {
    render() {
        return (
            <MuiThemeProvider theme={dialogTheme}>
                <BeautifulDialog maxWidth="md" fullWidth={false} closeDialog={this.props.closeDialog}>
                    <div className="upgrade-plan-dialog">
                        <div className="warning">Want to remove the Beautiful.ai Watermark?</div>
                        <div className="outer">
                            <div className="inner">
                                <div className="header-intro">
                                    Upgrade to <StyledUpdgradeSpan >Beautiful.ai Pro</StyledUpdgradeSpan> for as low as{" "}
                                    <StyledUpdgradeSpan >$12</StyledUpdgradeSpan>/month.
                                </div>

                                <Subheader />

                                <UpgradeToProContents />

                                <div className="cta-block">
                                    <div className="cta-button" onClick={() => handleUpgradeButton(this.props)}>
                                        Upgrade to Beautiful.ai Pro
                                    </div>
                                    <div className="cta-description">
                                        Learn more about our different plans{" "}
                                        <a
                                            href={getPricingPageUrl(app && app.user.hasTakenTrial, app && app.user.has("hasTakenTeamTrial"), "basic")}
                                            rel="noreferer, ,noopener"
                                            target="_blank"
                                        >
                                            here
                                        </a>
                                        .
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BeautifulDialog>
            </MuiThemeProvider>
        );
    }
}

const StyledHeader = styled.div`
    display: flex;
    gap: 12px;
`;

const WarningText = styled.div`
        color: orangered;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        text-align: center;
`;

const HeaderText = styled.div`
    font-size: 25px;
    font-weight: 600;
`;

const FeaturesWrapper = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 480px) {
        flex-direction: column;
    }

`;

const FeatureWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
`;

const Feature = styled.div`
    display:flex;
    gap: 17px;
    font-size: 16px;
    line-height: 20px;
`;

const LearnMore = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: .5px;
    color: #666;

    a {
        color: #11A9E2
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    gap: 30px;
    justify-content: end;
`;

const UpgradePlanDialog = styled.div`
    font-family: "Source Sans Pro";
    color: #333;
    background: white;
    min-height: fit-content;
    overflow-y: auto;
    padding: 40px 50px;
`;

export class ForcedDowngradeDialog extends Component {
    render() {
        return (
            <MuiThemeProvider theme={dialogTheme}>
                <BeautifulDialog maxWidth="md" fullWidth={false} closeDialog={this.props.closeDialog}>
                    <UpgradePlanDialog>
                        <StyledHeader>
                            <img src={getStaticUrl("/images/billing/warning.svg")} />
                            <WarningText >
                                Your trial or subscription has ended
                            </WarningText>
                        </StyledHeader>
                        <Gap20 />

                        <HeaderText>
                            Renew your subscription to continue to enjoy Beautiful.ai Pro features, including:
                        </HeaderText>
                        <Gap50 />
                        <FeaturesWrapper>
                            <FeatureWrapper>
                                <Feature>
                                    <img src={getStaticUrl("/images/check.svg")} />
                                    <span>All Generative AI Features</span>
                                </Feature>
                                <Feature>
                                    <img src={getStaticUrl("/images/check.svg")} />
                                    <span>Slides Without Watermarks</span>
                                </Feature>
                                <Feature>
                                    <img src={getStaticUrl("/images/check.svg")} />
                                    <span>
                                        Custom Fonts</span>
                                </Feature>
                                <Feature>
                                    <img src={getStaticUrl("/images/check.svg")} />
                                    <span>Folders</span>
                                </Feature>
                            </FeatureWrapper>
                            <FeatureWrapper>
                                <Feature>
                                    <img src={getStaticUrl("/images/check.svg")} />
                                    <span>
                                        Revision History
                                    </span>
                                </Feature>
                                <Feature>
                                    <img src={getStaticUrl("/images/check.svg")} />
                                    <span>Secure Sharing</span>
                                </Feature>
                                <Feature>
                                    <img src={getStaticUrl("/images/check.svg")} />
                                    <span>Presentation Analytics</span>
                                </Feature>
                                <Feature>
                                    <img src={getStaticUrl("/images/check.svg")} />
                                    <span>Offline Player</span>
                                </Feature>
                            </FeatureWrapper>
                        </FeaturesWrapper>
                        <Gap50 />
                        <LearnMore>
                            Learn more about our plans  <a
                                href={getPricingPageUrl(app && app.user.hasTakenTrial, app && app.user.has("hasTakenTeamTrial"), "basic")}
                                rel="noreferer, ,noopener"
                                target="_blank"
                            >
                                here
                            </a>.
                        </LearnMore>
                        <Gap20 />
                        <ButtonWrapper>
                            <Button variant="text" style={{
                                color: "#666"
                            }} onClick={this.props.closeDialog}>Dismiss</Button>
                            <Button color="primary" onClick={() => {
                                window.location.href = `${window.location.origin}/checkout`;
                            }}>Renew Now</Button>
                        </ButtonWrapper>
                    </UpgradePlanDialog>
                </BeautifulDialog>
            </MuiThemeProvider>
        );
    }
}
