import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    DialogActions,
    Button,
    TextField
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import { clipboardWrite, ClipboardType } from "js/core/utilities/clipboard";
import debug from "js/core/debug";
import { app } from "js/namespaces";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import LogRocket from "logrocket";
import { ShowSnackBar } from "js/react/components/Dialogs/SnackBar.js";
import Api from "js/core/api";
import styled from "styled-components";
import Spinner from "js/react/components/Spinner";

let logRocketLink = "";
LogRocket.getSessionURL(sessionURL => {
    logRocketLink = sessionURL;
});

const StyledHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 25px 34px;
`;

const StyledHeaderTitle = styled.div`
    font-family: Source Sans Pro, sans-serif;
    font-weight: 600;
    font-size: 23px;
    line-height: 27.6px;
    letter-spacing: 0.5px;
    color: #333;
`;

const StyledSubtitle = styled.div`
    font-family: Source Sans Pro, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #666;
`;

const StyledDebugInfo = styled.pre`
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledTextField = styled(TextField)`
    margin-bottom: 10px !important;
`;

const StyledIssueDisplay = styled.div`
    background-color: #edf7ed;
    color: #1e4620;
    padding: 10px;
    
    div {
        padding-bottom: 5px;
    }

    h2 {
        margin-block-start: 0;
    }
`;

const StyledErrorDisplay = styled.div`
    background-color: #fdeded;
    color: #5f2120;
    padding: 10px;
    
    div {
        padding-bottom: 5px;
    };

    h2 {
        margin-block-start: 0;
    }
`;

function makeEnrichedDebugInfo() {
    return {
        ...debug.info,
        clientTimestamp: new Date(debug.info.timestamp).toLocaleString(),
        clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        userId: app.user.id,
        email: app.user.getEmail(),
        userAgent: window.navigator.userAgent,
        platform: window.navigator.platform,
        resolution: `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}x${window.devicePixelRatio}`,
        presentationId: PresentationEditorController?.presentation?.id,
        slideId: PresentationEditorController?.currentSlide?.id,
        url: window.location.href,
        logRocketLink: logRocketLink
    };
}

function createTicketButton({ onClick, isLoading, ...props }) {
    return (
        <Button
            disabled={!props.description || !props.summary || isLoading}
            variant="contained"
            color="primary"
            onClick={()=>onClick(props)}
        >
            Create Jira Issue
        </Button>
    );
}

function copyToClipboardButton({ issueLink }) {
    return (
        <Button
            variant="contained"
            color="primary"
            onClick={() => {
                clipboardWrite({
                    [ClipboardType.TEXT]: issueLink
                }).then(
                    ShowSnackBar({
                        message: `Issue Link Copied to Clipboard`
                    }));
            }}
        >
            Copy to Clipboard
        </Button>
    );
}

function SuccessContent(props) {
    const { issueLink, issueId } = props;

    return (
        <DialogContent>
            <StyledIssueDisplay>
                <h2> Jira Issue Created Successfully </h2>
                <div>Thank you for helping maintain the quality of our product!</div>
                <div>An engineer will investigate this issue and may reach out to you directly for more detail.</div>
                <br/>
                <div>Ticket ID: {issueId} </div>
                <div>Ticket Link: <a href={issueLink} target="_blank">{issueLink}</a></div>
            </StyledIssueDisplay>
        </DialogContent>
    );
}

function ErrorContent(props) {
    const { error } = props;

    return (
        <StyledErrorDisplay>
            <h2> Error Creating Issue </h2>
            <div> {error.name}: {error.message}</div>
        </StyledErrorDisplay>
    );
}

export function InternalBugReportDialog({ closeDialog = () => {} }) {
    const debugInfo = makeEnrichedDebugInfo();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [stepsToReproduce, setStepsToReproduce] = useState("");
    const [expectedResult, setExpectedResult] = useState("");
    const [actualResult, setActualResult] = useState("");
    const [savedIssue, setSavedIssue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleClick = props => {
        setIsLoading(true);
        Api.jira.post(props)
            .then(res => {
                setSavedIssue({
                    ...res.body,
                    issueLink: "https://beautifulai.atlassian.net/browse/" + res.body.key
                });
                setIsLoading(false);
            })
            .catch(err => {
                setError(err);
                setIsLoading(false);
            });
    };

    return (
        <BeautifulDialog
            open
            closeDialog={closeDialog}
            maxWidth = { "md" }
            closeButton>
            <StyledHeader>
                <StyledHeaderTitle>Submit a bug for the engineering team to investigate</StyledHeaderTitle>
                <StyledSubtitle>This functionality is available to employees only. <br/>
                    If you have a feature request or feedback on functionality,
                    please submit it <a href="https://beautifulai.atlassian.net/jira/polaris/projects/PROD/ideas/view/3379954" target="_blank">here</a> instead.
                </StyledSubtitle>
            </StyledHeader>
            {isLoading && <div style={{ width: "100%" }}><Spinner /></div> }

            {savedIssue
                ? <SuccessContent
                    issueId = {savedIssue.key}
                    issueLink={savedIssue.issueLink}
                />
                : <DialogContent>
                    <StyledTextField
                        disabled={!!savedIssue}
                        variant={"outlined"}
                        autoFocus={true}
                        text={title}
                        fullWidth={true}
                        onChange={ evt=>setTitle(evt.target.value)}
                        placeholder="A brief summary of the issue"
                    />
                    <StyledTextField
                        disabled={!!savedIssue}
                        variant={"outlined"}
                        text={description}
                        fullWidth={true}
                        onChange={ evt=>setDescription(evt.target.value)}
                        placeholder="In as much detail as possible, describe what happened"
                        multiline
                    />
                    <StyledTextField
                        disabled={!!savedIssue}
                        variant={"outlined"}
                        text={stepsToReproduce}
                        fullWidth={true}
                        onChange={ evt=>setStepsToReproduce(evt.target.value)}
                        placeholder="Steps to reproduce (1, 2, ...)"
                        multiline
                    />
                    <StyledTextField
                        disabled={!!savedIssue}
                        variant={"outlined"}
                        text={expectedResult}
                        fullWidth={true}
                        onChange={ evt=>setExpectedResult(evt.target.value)}
                        placeholder="Expected result"
                    />
                    <StyledTextField
                        disabled={!!savedIssue}
                        variant={"outlined"}
                        text={description}
                        fullWidth={true}
                        onChange={ evt=>setActualResult(evt.target.value)}
                        placeholder="Actual result"
                    />

                    {error
                        ? <ErrorContent error={error}/>
                        : <Accordion>
                            <AccordionSummary
                                expandIcon={<ArrowDropDown />}>
                                Debug Info
                            </AccordionSummary>
                            <AccordionDetails>
                                <StyledDebugInfo>
                                    {JSON.stringify(debugInfo, Object.keys(debugInfo).sort(), 2)}
                                </StyledDebugInfo>
                            </AccordionDetails>
                        </Accordion>}
                </DialogContent>}
            <DialogActions>
                {savedIssue
                    ? copyToClipboardButton(savedIssue)
                    : createTicketButton({
                        ...debugInfo,
                        description,
                        stepsToReproduce,
                        expectedResult,
                        actualResult,
                        summary: title,
                        onClick: handleClick,
                        isLoading
                    })}
            </DialogActions>
        </BeautifulDialog>
    );
}
