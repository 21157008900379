import { trackActivity } from "js/core/utilities/utilities";
import { Presentation } from "js/core/models/presentation";
import { PresentationLibraryController } from "js/editor/PresentationLibrary/PresentationLibraryController";
import { UIController } from "js/editor/dialogs/UIController";
import { ds } from "js/core/models/dataService";
import { TeamFoldersController } from "js/core/dataServices/TeamFoldersDataService";

export class PresentationTemplateDataService {
    constructor(config) {
        this.config = config;

        ds.presentations.on("add remove change", this.onChange);
        ds.presentations.on("update", this.onChange);

        this.onChange();
    }

    onChange = async () => {
        let templates = await PresentationTemplateController.getTeamTemplates();
        this.config.onCollectionChanged(templates);
    }

    unsubscribe() {
        ds.presentations.off("add remove change", this.onChange);
        ds.presentations.off("update", this.onChange);
    }
}

export class PresentationTemplateController {
    static async getTeamTemplates() {
        let allTeamFolder = ds.teams.find(teamFolder => teamFolder.get("isDefault") && teamFolder.get("orgId") === UIController.getOrganizationId());
        if (allTeamFolder?.has("sharedResources")) {
            let templates = ds.presentations
                .filter(template => (
                    template.get("isTemplate") === true &&
                    template.get("orgId") === UIController.getOrganizationId()
                ))
                .map(template => {
                    let {
                        ratedByUsers = {},
                        createdById,
                        modifiedById,
                        ...rest
                    } = template.toJSON();
                    let ratedByCount = Object.keys(ratedByUsers).length;
                    if (!createdById) {
                        createdById = rest.userId;
                    }
                    if (!modifiedById) {
                        modifiedById = createdById;
                    }

                    let item = {
                        template,
                        ...rest,
                        createdById,
                        modifiedById,
                        ratedByUsers,
                        ratedByCount,
                        sips: template.getSips()
                    };
                    return item;
                });

            await Promise.all(templates.map(x => x.template.getUserPermissions(true)));

            return templates;
        }

        return [];
    }

    static async createPresentationTemplate(presentation, fieldUpdates) {
        // duplicate the presentation
        const presentationId = await PresentationLibraryController.duplicatePresentation(presentation, null, false);

        const presentationTemplate = await ds.presentations.getPresentation(presentationId);
        return this.convertToPresentationTemplate(presentationTemplate, fieldUpdates);
    }

    static async convertToPresentationTemplate(presentationTemplate, fieldUpdates = {}) {
        await presentationTemplate.load();

        presentationTemplate.update({
            isTemplate: true,
            isPublished: false,
            ...fieldUpdates,
        });
        let allTeamFolder = TeamFoldersController.getAllTeamMembersFolder();
        await TeamFoldersController.setTemplatePermissions([presentationTemplate], allTeamFolder.id);

        trackActivity("PresentationTemplates", "PresentationTemplateAdded", null, null, {});

        return presentationTemplate;
    }

    static async publishPresentationTemplate(template) {
        template.update({ isPublished: true });
    }

    static async unpublishPresentationTemplate(template) {
        template.update({ isPublished: false });
    }

    static async deletePresentationTemplate(template) {
        let allTeamFolder = TeamFoldersController.getAllTeamMembersFolder();
        await TeamFoldersController.deleteTemplatePermissions([template], allTeamFolder.id);
    }
}
