import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { StandalonePage, StandaloneForm } from "../Standalone";
import { getStaticUrl } from "js/config";

const fadeout = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const IntroContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 650px;
    height: 488px;
    padding: 0px 202px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 9px 37px 0px rgba(0, 0, 0, 0.20);
    animation: ${props => props.fadeOut ? css`${fadeout} 0.5s ease-in-out` : css``};

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
        padding: 20px;
    }
`;

const IntroHeader = styled.h2`
    color: #222222;
    font-family: "Source Sans Pro";
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.5px;
    text-wrap: nowrap;

    @media (max-width: 768px) {
        font-size: 18px;
        text-align: center;
    }
`;

const IntroImage = styled.div`
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    background: url(${getStaticUrl("/images/bai-bot/bai-bot-shake.gif")});
    background-size: cover;

    @media (max-width: 768px) {
        width: 40px;
        height: 40px;
    }
`;

const Intro = ({ onNext }) => {
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        const delay = (600 + 1 / (1 + Math.exp(-.25 * 0)) * 800) + 100;
        const timer = setTimeout(() => {
            setFadeOut(true);
            setTimeout(onNext, 500);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [onNext]);

    return (
        <StandalonePage maxWidth={600}>
            <StandaloneForm open={true} hideBackdrop fullWidth style={{ overflow: "hidden" }}>
                <IntroContainer fadeOut={fadeOut ? fadeOut : undefined}>
                    <IntroImage />
                    <IntroHeader>Help us get to know you!</IntroHeader>
                </IntroContainer>
            </StandaloneForm>
        </StandalonePage>
    );
};

export default Intro;
