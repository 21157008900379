import React from "react";
import styled from "styled-components";
import moment from "moment";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Icon
} from "@material-ui/core";

import { dialogTheme } from "js/react/materialThemeOverrides";

import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import AppController from "js/core/AppController";

const Container = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1200;
    background: transparent;
    backdrop-filter: blur(5px);
`;

const AlertContainer = styled.div`
    color: red;
    font-size: 15px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;

    >div {
        margin-left: 10px;
    }
`;

const Header = styled.div`
    margin-top: 20px;
`;

const StyledDialogContent = styled(DialogContent)`
    &&& {
        padding: 0 34px 20px;
    }
`;

const StyledDialogActions = styled(DialogActions)`
    &&& {
        padding: 0 34px 20px;
    }
`;

function WorkspaceBlockedDialog({
    invoice,
    isPastDue,
    subscription,
    canManageTeam,
    forceLockWorkspace,
    contactEmail
}) {
    const daysUntilDowngrade = moment.unix(subscription.currentPeriodStart)
        .add(365, "days")
        .diff(moment(), "days");

    const handleSwitchToPersonalWorkspace = () => {
        AppController.switchWorkspace("personal");

        AppController.showLibrary();
    };

    const email = contactEmail ?? "support@beautiful.ai";

    return (
        <Container>
            <MuiThemeProvider theme={dialogTheme}>
                <BeautifulDialog maxWidth="md" fullWidth={false} hideBackdrop closeDialog={() => { }}>
                    <DialogTitle>
                        <AlertContainer>
                            <Icon>error</Icon>
                            <div>Your workspace is blocked</div>
                        </AlertContainer>
                        {forceLockWorkspace && <Header>Contact your account manager to unlock this workspace</Header>}
                        {!forceLockWorkspace && <Header>Please pay your invoice to unlock this workspace</Header>}
                    </DialogTitle>
                    {isPastDue && <StyledDialogContent>
                        {invoice && <DialogContentText>
                            The invoice from {moment.unix(invoice.createdAt).format("MM/DD/YYYY")} hasn't been paid yet.
                        </DialogContentText>}
                        <DialogContentText>
                            Your workspace will be removed in <b>{daysUntilDowngrade} days</b>.
                        </DialogContentText>
                        {canManageTeam && invoice && <DialogContentText>
                            You can view and pay the invoice using this <a href={invoice.url} target="_blank">link</a>.
                        </DialogContentText>}
                        <DialogContentText>
                            If you have questions, please contact <a href={`mailto:${email}`}>{email}</a>.
                        </DialogContentText>
                    </StyledDialogContent>}
                    {!isPastDue && <StyledDialogContent>
                        <DialogContentText>
                            Your workspace is currently locked. Please contact <a href={`mailto:${email}`}>{email}</a> for assistance.
                        </DialogContentText>
                    </StyledDialogContent>}
                    <StyledDialogActions>
                        <Button
                            color="primary"
                            onClick={handleSwitchToPersonalWorkspace}>
                            Switch to personal workspace
                        </Button>
                    </StyledDialogActions>
                </BeautifulDialog>
            </MuiThemeProvider>
        </Container >
    );
}

export default WorkspaceBlockedDialog;
