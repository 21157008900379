export interface IScheduledTask {
    id: string;
    createdAt: number;
    modifiedAt: number;
    type: ScheduledTaskType;
    payload: {
        [key: string]: any
    }
    scheduledTime: number;
    state: {
        type: String,
        enum: ScheduledTaskState,
        default: "queued"
    },
    error: string;
}

export enum ScheduledTaskState {
    QUEUED = "queued",
    SCHEDULED = "scheduled",
    PROCESSED = "processed",
    COMPLETED = "completed",
    FAILED = "failed",
}

export enum ScheduledTaskType {
    INVOKE_NOTIFICATIONS_PROCESSOR = "invokeNotificationsProcessor",
    INVOKE_EMAILS_PROCESSOR = "invokeEmailsProcessor",
    CHECK_SALESFORCE_PRESENTATION_SHARES = "checkSalesforcePresentationShares",
    DELETE_PRESENTATION = "deletePresentation",
    DELETE_SLIDE = "deleteSlide",
}
