import "./suppressConsole";

import React from "react";
import ReactDOM from "react-dom";

import firebase from "js/firebase";

// entry point

// workaround for "failed to render" bug that is caused by out of date signed urls that won't reload
let usingBackForwardCache = false;
try {
    usingBackForwardCache = window.performance.getEntriesByType("navigation")[0].type === "back_forward";
} catch (err) {
    // quietly ignore
}
if (document.wasDiscarded || usingBackForwardCache) {
    window.location.reload();
}

// firebase
firebase.initializeApp(window.baiFirebase.config);

// react
const root = document.createElement("div");
root.id = "react-root";
root.style.display = "contents";
document.body.appendChild(root);

// firebase dependent code
(async () => {
    // preload
    await import(/* webpackMode: "eager" */ "js/preload");

    const { IntegratorController } = await import(/* webpackMode: "eager" */ "common/integrators/IntegratorController");
    await IntegratorController.initApp();

    const { default: Adapter } = await import(/* webpackMode: "eager" */ "js/core/storage/adapter");
    const { default: FirebaseAdapter } = await import(/* webpackMode: "eager" */ "js/core/storage/firebaseAdapter");
    Adapter.setDefaultClass(FirebaseAdapter);

    const { default: App } = await import(/* webpackMode: "eager" */ "./App");

    // render
    ReactDOM.render(<App />, root);
})();
