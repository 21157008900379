import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces.js";
import { Theme } from "js/core/models/theme";
import Api from "js/core/api";
import { presentations as presentationsApi } from "apis/callables";
import { THEME_ATTRIBUTES_MAPPING, getThemeFromPresentationModel } from "common/themes";
import { SharedTheme } from "js/core/models/sharedTheme";
import getLogger, { LogGroup } from "js/core/logger";
import StorageModel from "js/core/storage/storageModel";
import { PERMISSION_RESOURCE_TYPE } from "common/constants";
import { isRenderer } from "js/config";

const logger = getLogger(LogGroup.THEMES);

class ThemeManager {
    getTheme(themeId) {
        return new Promise((resolve, reject) => {
            if (!themeId) {
                logger.warn("No user theme provided", { themeId });
                if (ds.userThemes && ds.userThemes.filter(theme => !theme.get("private")).length > 0) {
                    logger.warn("Falling back to first user theme", { themeId });
                    resolve(ds.userThemes.filter(theme => !theme.get("private"))[0]);
                } else {
                    logger.warn("Falling back to first built-in theme", { themeId });
                    resolve(ds.builtInThemes.first());
                }
            }
            let theme;
            if (ds.userThemes) {
                theme = ds.userThemes.get(themeId);

                if (!theme) {
                    logger.warn("Migrating builtInTheme to userTheme", { themeId });
                    // if this is a pre-Sept 2016 presentation, it's themeId may be a builtInTheme so check the userThemes to find a builtInTheme that matches
                    theme = ds.userThemes.find({ builtInThemeID: themeId });
                    if (theme) {
                        ds.selection.presentation.update({ themeId: theme.id });
                    }
                }
            }

            if (!theme) {
                logger.warn("Falling back on builtInTheme (this should only happen in player)", { themeId });
                theme = ds.builtInThemes.find({ id: themeId });
            }

            if (theme) {
                resolve(theme);
            } else {
                StorageModel.fetchData(themeId, { root: "user_themes" }).then(data => {
                    resolve(new Theme(data));
                }).catch(() => {
                    logger.warn(`No theme with id ${themeId} was found. Falling back to first userTheme`, { themeId });
                    resolve(ds.builtInThemes.first());
                });
            }
        });
    }

    async loadTheme(presentation) {
        const theme = this.loadThemeFromPresentation(presentation);

        if (!theme.has("colors")) {
            theme.set("colors", theme.getColors());
        }
        if (!theme.has("iconStyle")) {
            theme.set("iconStyle", "chunky");
        }

        if (!presentation.has("theme_colors") && !presentation.has("sharedThemeId")) {
            logger.info("Migrating theme into presentation", { presentationId: presentation.id });

            // Copy properties from userTheme
            const defaultTheme = await this.getTheme(presentation.get("themeId"));
            // Migrate old theme
            switch (defaultTheme.get("styleWeight")) {
                case "thin":
                    defaultTheme.set({
                        styleElementStyle: 0,
                        styleFontWeight: "light"
                    });
                    break;
                case "medium":
                    defaultTheme.set({
                        styleElementStyle: 1,
                        styleFontWeight: "light"
                    });
                    break;
                case "heavy":
                    defaultTheme.set({
                        styleElementStyle: 2,
                        styleFontWeight: "heavy"
                    });
                    break;
            }

            // Merge theme with any existing presentation properties
            Object.keys(defaultTheme.attributes).forEach(key => {
                if (!theme.has(key)) {
                    theme.set(key, defaultTheme.get(key));
                }
            });

            this.saveThemeToPresentation(theme, presentation, { internalChange: true });
        }

        await theme.loadTheme();

        if (theme.hasMigrationChanges) {
            // Won't be migrating the presentation model from renderer or player
            if (!isRenderer && !window.isPlayer && !presentation.has("sharedThemeId")) {
                this.saveThemeToPresentation(theme, presentation, { internalChange: true });
            }
        }

        app.currentTheme = theme;
        return theme;
    }

    async saveThemeToPresentation(theme, presentation, presentationUpdateOptions = {}) {
        const timestamp = new Date().getTime();

        const updates = {
            firstSlideModifiedAt: timestamp,
            sharedThemeId: null
        };

        THEME_ATTRIBUTES_MAPPING.forEach(keys => {
            const presentationKey = keys[0];
            const themeKey = keys[1];
            updates[presentationKey] = theme.attributes[themeKey] != undefined ? theme.attributes[themeKey] : null;
        });

        presentation.update(updates, { replaceKeys: true, unsetSilent: true, ...presentationUpdateOptions });
        await presentation.updatePromise;
        if (!isRenderer && !window.isPlayer && !presentation.disconnected) {
            // Requesting server to update modifiedAt values on slides
            await presentationsApi.updateSlidesMetadata({ id: presentation.id });
        }
    }

    async saveSharedThemeToPresentation(theme, presentation, presentationUpdateOptions = {}) {
        const timestamp = new Date().getTime();

        const updates = {
            firstSlideModifiedAt: timestamp,
            sharedThemeId: theme.id,
            themeId: null
        };

        THEME_ATTRIBUTES_MAPPING.forEach(keys => {
            const presentationKey = keys[0];
            updates[presentationKey] = null;
        });

        presentation.update(updates, { replaceKeys: true, unsetSilent: true, ...presentationUpdateOptions });

        if (!presentation.disconnected) {
            await presentation.updatePromise;
            // Requesting server to update modifiedAt values on slides
            await presentationsApi.updateSlidesMetadata({ id: presentation.id });
        }
    }

    loadThemeFromPresentation(presentation) {
        if (presentation.has("sharedThemeId")) {
            return new SharedTheme({ id: presentation.get("sharedThemeId") });
        } else {
            const theme = getThemeFromPresentationModel(presentation.attributes);
            return new Theme(theme, { disconnected: true, autoLoad: false });
        }
    }

    themeChanged(presentation) {
        for (let i = 0; i < THEME_ATTRIBUTES_MAPPING.length; i++) {
            if (presentation.hasChanged(THEME_ATTRIBUTES_MAPPING[i][0])) {
                return true;
            }
        }
        if (presentation.hasChanged("sharedThemeId")) {
            return true;
        }
        return false;
    }

    async createTeamTheme(themeAttributes, orgId, team, name) {
        const theme = {
            ...themeAttributes,
            id: null,
            orgId,
            isBuiltIn: false,
            name,
        };
        const newTheme = new SharedTheme(theme);
        newTheme.set("sharedThemeId", newTheme.id);

        // add to team's sharedResources
        team.update({ "sharedResources": { [PERMISSION_RESOURCE_TYPE.SHARED_THEME]: { [newTheme.id]: true } } });

        // grant the team read permissions (owners/librarians get transparently upgraded to write permission)
        await Api.teamPermissions.put({
            teamId: team.id,
            resourceIds: [newTheme.id],
            resourceType: "sharedThemes",
            permission: "read",
            type: "theme"
        });

        return newTheme;
    }

    async deleteTeamTheme(defaultTeam, sharedThemeId) {
        await Api.teamPermissions.delete({
            teamId: defaultTeam.id,
            resourceIds: [sharedThemeId],
            resourceType: "sharedThemes"
        });

        defaultTeam.update({
            sharedResources: {
                [PERMISSION_RESOURCE_TYPE.SHARED_THEME]: {
                    [sharedThemeId]: null
                }
            }
        });
    }

    switchTheme(theme) {
        app.currentTheme = theme;

        // reset glyphCache
        Object.keys(app.glyphCache).map(key => {
            app.glyphCache[key] = {};
        });

        if (ds.selection.presentation) {
            if (this.isSharedThemeEditor || theme instanceof SharedTheme) {
                app.themeManager.saveSharedThemeToPresentation(theme, ds.selection.presentation);
            } else {
                app.themeManager.saveThemeToPresentation(theme, ds.selection.presentation);
            }
        }
    }
}

app.themeManager = new ThemeManager();

export { ThemeManager };
