import { Icon, MenuItem } from "@material-ui/core";
import React from "react";
import { PresentationLibraryController } from "js/editor/PresentationLibrary/PresentationLibraryController";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog,
} from "js/react/components/Dialogs/BaseDialog";
import { PresentationFilters } from "common/constants";
import { ShareDialog } from "js/react/views/PresentationSettings/dialogs/ShareDialog";
import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import { UIController } from "js/editor/dialogs/UIController";
import MoveWorkspacesDialog from "js/react/components/Dialogs/MoveWorkspacesDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import InputConfirmationDialog from "js/react/components/Dialogs/InputConfirmationDialog";
import { PresentationTemplateController } from "js/core/dataServices/PresentationTemplateDataService";
import { TeamFoldersController } from "js/core/dataServices/TeamFoldersDataService";
import { FeatureType } from "common/features";
import ShareViewOnlyPresentationDialog from "js/react/views/PresentationSettings/dialogs/ShareViewOnlyPresentationDialog";
import CreateTeamTemplateDialog from "js/react/views/TeamResources/dialogs/CreateTeamTemplateDialog";
import AppController from "js/core/AppController";

export function renderPresentationContextMenu(selectedPresentations, currentFilter) {
    let handleEdit = event => {
        const presentation = selectedPresentations[0].presentationModel;
        const openInNewTab = event.metaKey || event.shiftKey;
        PresentationLibraryController.editPresentation({ presentation, openInNewTab });
    };

    let handlePresent = () => {
        PresentationLibraryController.playPresentation(selectedPresentations[0].presentationModel);
    };

    let handleRename = () => {
        ShowDialog(InputConfirmationDialog, {
            title: "Rename presentation",
            message: null,
            input: {
                value: selectedPresentations[0].name
            },
            acceptCallback: value => {
                try {
                    PresentationLibraryController.renamePresentation(selectedPresentations[0].presentationModel, value);
                } catch (err) {
                    ShowErrorDialog({
                        title: "Unable to rename presentation",
                        message: err.message
                    });
                }
            },
        });
    };

    let handleDuplicate = async () => {
        const dialog = ShowDialog(ProgressDialog, {
            title: "Duplicating presentation...",
        });
        try {
            await PresentationLibraryController.duplicatePresentation(selectedPresentations[0].presentationModel, currentFilter);
        } catch (err) {
            ShowErrorDialog({ title: "Unable to duplicate presentation(s)", message: err.message });
        } finally {
            dialog.props.closeDialog();
        }
    };

    let handleDelete = async () => {
        let presentations = selectedPresentations.map(p => p.presentationModel);
        await Promise.all(presentations.map(p => p.getUserPermissions(true)));
        const ownedPresentations = presentations.filter(p => p.permissions.owner);
        const collaboratedPresentations = presentations.filter(p => !p.permissions.owner);
        if (ownedPresentations.length) {
            const dialog = ShowDialog(ProgressDialog, {
                title: "Moving presentations to trash...",
            });
            try {
                await PresentationLibraryController.trashPresentations(ownedPresentations, currentFilter);
            } catch (err) {
                ShowErrorDialog({ title: "Unable to delete presentation(s)", message: err.message });
            } finally {
                dialog.props.closeDialog();
            }
        }
        if (collaboratedPresentations.length) {
            ShowConfirmationDialog({
                title: "Are you sure you want to stop collaborating on the selected presentation(s)?",
                message: "Shared presentations will be unshared with you and you will no longer be able to view or edit them unless they are reshared with you.",
                acceptCallback: () => {
                    PresentationLibraryController.deletePresentations(collaboratedPresentations);
                }
            });
        }
    };

    let handleDeletePermanently = () => {
        ShowConfirmationDialog({
            title: "Are you sure you want to permanently delete this presentation?",
            message: "You can't undo this action.",
            acceptCallback: () => {
                PresentationLibraryController.deletePresentations(selectedPresentations.map(p => p.presentationModel));
            }
        });
    };

    let handleMoveWorkspaces = () => {
        ShowDialog(MoveWorkspacesDialog, {
            presentations: selectedPresentations,
            currentWorkspaceId: UIController.getWorkspaceId(),
            workspaces: Object.values(app.user.workspaces),
            teams: ds.teams
        });
    };

    let handleRecoverFromTrash = () => {
        PresentationLibraryController.recoverPresentations(selectedPresentations.map(p => p.presentationModel));
    };

    let handleShareViewOnlyPresentation = () => {
        ShowDialog(ShareViewOnlyPresentationDialog, { presentation: selectedPresentations[0].presentationModel });
    };

    let handleSendPresentation = () => {
        ShowDialog(ShareDialog, { presentation: selectedPresentations[0].presentationModel });
    };

    let handleSharePresentation = () => {
        ShowDialog(ShareDialog, { presentation: selectedPresentations[0].presentationModel, selectedPanel: "collaborate" });
    };

    let handleRemoveFromFolder = async () => {
        let progressDialog = ShowDialog(ProgressDialog, {
            title: `Removing presentation...`,
        });
        for (let presentation of selectedPresentations) {
            await PresentationLibraryController.removePresentationFromFolder(presentation.presentationModel, currentFilter.folderId);
        }
        progressDialog.props.closeDialog();
    };

    let handleRemoveFromTeamFolder = async () => {
        let teamFolder = ds.teams.get(currentFilter.folderId);
        let progressDialog = ShowDialog(ProgressDialog, {
            title: `Removing presentation from ${teamFolder.get("name")}...`,
        });
        await TeamFoldersController.removePresentationFromTeamFolder(selectedPresentations[0].presentationModel, currentFilter.folderId, currentFilter.subFolderId);
        progressDialog.props.closeDialog();
    };

    let handleConvertToTemplate = async () => {
        let existingTags = [];
        let presentation = selectedPresentations[0].presentationModel;
        let name = presentation.get("name") || "";
        let description = presentation.get("description") || "";
        let tags = presentation.get("tags") || [];
        ds.selection.element = null;
        ShowDialog(CreateTeamTemplateDialog, {
            title: "Create a template from this presentation?",
            subTitle: "Publish a copy of this presentation for your team to start from—or save it as a draft and publish it later. View and edit this template in Team Resources at any time.",
            name,
            description,
            tags,
            existingTags,
            callback: async ({
                isPublished,
                name,
                description,
                tags,
            }) => {
                await PresentationTemplateController.createPresentationTemplate(
                    presentation,
                    {
                        isTemplateConfirmed: true,
                        isPublished,
                        name,
                        description,
                        tags,
                    },
                );
            }
        });
    };

    let menuItems = [];

    let renderMoveToAnotherWorkspace = (hasOwned, includeDivider = false) => {
        if (hasOwned && Object.values(app.user.workspaces).length > 1) {
            let prohibitExternalWorkspaceMovement = app.user.features.isFeatureEnabled(
                FeatureType.PROHIBIT_EXTERNAL_WORKSPACE_MOVEMENT,
                UIController.getWorkspaceId()
            );
            if (!prohibitExternalWorkspaceMovement) {
                menuItems.push(
                    <MenuItem
                        key="move_workspace"
                        divider={includeDivider}
                        onClick={handleMoveWorkspaces}
                    >
                        <Icon>business</Icon>Move To Another Workspace
                    </MenuItem>);
            }
        }
    };

    if (selectedPresentations.length == 1) {
        let presentation = selectedPresentations[0];

        if (currentFilter.type == PresentationFilters.TRASH) {
            menuItems.push(
                <MenuItem key="recover" onClick={handleRecoverFromTrash}>
                    <Icon>restore_from_trash</Icon>
                    Recover From Trash
                </MenuItem>
            );
            menuItems.push(
                <MenuItem key="delete-permanently" onClick={handleDeletePermanently}>
                    <Icon>delete_forever</Icon>Delete Permanently
                </MenuItem>
            );
        } else {
            menuItems.push(
                <MenuItem key="present-all" divider onClick={handlePresent}>
                    <Icon>present_to_all</Icon>Present
                </MenuItem>
            );

            if (presentation.presentationModel.permissions.owner || presentation.presentationModel.permissions.write) {
                menuItems.push(
                    <MenuItem key="edit" onClick={handleEdit}>
                        <Icon>edit</Icon>Edit
                    </MenuItem>
                );
                menuItems.push(<hr key="divider2" />);

                menuItems.push(
                    <MenuItem key="send" onClick={handleSendPresentation}>
                        <Icon>share</Icon>Share...
                    </MenuItem>
                );
                menuItems.push(
                    <MenuItem key="share" divider onClick={handleSharePresentation}>
                        <Icon>person_add_alt_1</Icon>Invite Collaborators...
                    </MenuItem>
                );

                {
                    app.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, UIController.getWorkspaceId()) &&
                        menuItems.push(
                            <MenuItem key="convert-to-template" divider onClick={handleConvertToTemplate}>
                                <Icon>file_copy</Icon>Create Team Template...
                            </MenuItem>
                        );
                }
                menuItems.push(
                    <MenuItem key="rename" onClick={handleRename}>
                        <Icon>label</Icon>Rename
                    </MenuItem>
                );
            } else {
                menuItems.push(
                    <MenuItem divider key="send" onClick={handleShareViewOnlyPresentation}>
                        <Icon>share</Icon>Share...
                    </MenuItem>
                );
            }

            menuItems.push(
                <MenuItem key="duplicate" onClick={handleDuplicate}>
                    <Icon>content_copy</Icon>Duplicate
                </MenuItem>
            );

            renderMoveToAnotherWorkspace(presentation.presentationModel.permissions.owner, false);

            menuItems.push(<hr key="divider4" />);

            if (currentFilter.type == PresentationFilters.FOLDER) {
                menuItems.push(
                    <MenuItem key="remove" divider onClick={handleRemoveFromFolder}>
                        <Icon>remove_circle</Icon>Remove from Folder
                    </MenuItem>
                );
            }
            if (
                currentFilter.type == PresentationFilters.TEAM_FOLDER &&
                app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_MODIFY_ALL_TEAM_MEMBERS_FOLDER, AppController.workspaceId) &&
                app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_ALL_TEAM_MEMBERS_FOLDER, AppController.workspaceId)
            ) {
                menuItems.push(
                    <MenuItem key="remove" divider onClick={handleRemoveFromTeamFolder}>
                        <Icon>remove_circle</Icon>Remove from Team Folder
                    </MenuItem>
                );
            }

            if (presentation.presentationModel.permissions.owner) {
                menuItems.push(
                    <MenuItem key="trash" onClick={handleDelete}>
                        <Icon>delete</Icon>Move To Trash
                    </MenuItem>
                );
            } else {
                menuItems.push(
                    <MenuItem key="unshare" onClick={handleDelete}>
                        <Icon>delete</Icon>Unshare with me
                    </MenuItem>
                );
            }
        }
    } else {
        if (currentFilter.type == PresentationFilters.TRASH) {
            menuItems.push(
                <MenuItem key="recover"
                    onClick={handleRecoverFromTrash}>
                    <Icon>restore_from_trash</Icon>
                    Recover From Trash
                </MenuItem>
            );
            menuItems.push(
                <MenuItem key="delete-permanently"
                    onClick={handleDeletePermanently}>
                    <Icon>delete_forever</Icon>
                    Delete Permanently
                </MenuItem>
            );
        } else {
            if (
                currentFilter.type == PresentationFilters.FOLDER &&
                app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_MODIFY_ALL_TEAM_MEMBERS_FOLDER, AppController.workspaceId) &&
                app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_ALL_TEAM_MEMBERS_FOLDER, AppController.workspaceId)
            ) {
                menuItems.push(
                    <MenuItem key="remove"
                        onClick={handleRemoveFromFolder}>
                        <Icon>remove_circle</Icon>
                        Remove from Folder
                    </MenuItem>
                );
            }

            let hasShared = false;
            let hasOwned = false;
            for (let presentation of selectedPresentations) {
                if (presentation.presentationModel.permissions.owner) {
                    hasOwned = true;
                } else {
                    hasShared = true;
                }
            }

            renderMoveToAnotherWorkspace(hasOwned, true);

            let label;
            if (hasOwned && hasShared) {
                label = "Move to Trash or Unshare";
            } else if (hasOwned) {
                label = "Move to Trash";
            } else {
                label = "Unshare with me";
            }

            menuItems.push(
                <MenuItem key="trash"
                    onClick={handleDelete}>
                    <Icon>delete</Icon>
                    {label}
                </MenuItem>
            );
        }
    }

    return menuItems;
}
