import { LimitErrors } from "common/constants";
import getLogger from "js/core/logger";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import { ShowUpgradeDialog } from "js/react/components/Dialogs/BaseDialog";

const logger = getLogger();

export default class ErrorHandler {
    static handleSlideLimitReached(err, { workspaceId }) {
        if (!workspaceId) {
            throw new Error("workspaceId is required");
        }
        if (err.message === LimitErrors.SLIDE_COUNT_LIMIT_REACHED) {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.EXCEEDED_SLIDE_LIMIT,
                workspaceId,
                analytics: { "cta": "Slide Limit Reached" }
            });
        } else {
            logger.error(err, "handleSlideLimitReached()", { workspaceId });
        }
    }
}
