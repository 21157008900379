import { Backbone } from "js/vendor";
import StorageModel from "./storageModel";
import { staggerPromises } from "../utilities/utilities";
import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.STORAGE);

function getModelIds(models) {
    if (!models) {
        return [];
    }
    models = Array.isArray(models) ? models : [models];
    return models.map(model => {
        if (typeof (model) === "string") {
            return model;
        } else if (model.id) {
            return model.id;
        } else {
            return null;
        }
    }).filter(key => {
        return typeof (key) === "string";
    });
}

const ReferenceCollection = Backbone.Collection.extend({
    model: StorageModel,

    ignoreErrors: false,
    referenceRoot: null,
    getReferenceRoot: function() {
        return this.referenceRoot;
    },

    referenceId: null,
    getReferenceId: function() {
        return this.referenceId;
    },

    createAdapter: function() {
        //this is meant to be overwritten by reference collections which extend from this.
    },

    constructor: function(models, options) {
        Backbone.Collection.call(this, null, options);
        options = Object.assign({
            autoLoad: true,
            autoLoadModels: true
        }, options);
        if (options.ignoreErrors != null) {
            this.ignoreErrors = options.ignoreErrors;
        }
        this.modelOptions = options.modelOptions || {};
        let refModel = {
            id: options.referenceId || this.getReferenceId()
        };
        const modelIds = getModelIds(models);
        modelIds.forEach(id => {
            refModel[id] = true;
        });
        this.references = new StorageModel(refModel, {
            root: options.referenceRoot || this.getReferenceRoot(),
            defaultValue: options.defaultValue || {},
            autoLoad: options.autoLoad,
            adapter: options.adapter || this.createAdapter()
        });

        this.loading = {};

        this._initialModels = models;

        if (options.autoLoad) {
            this.load();
        }
    },

    loadModel: function(model) {
        return this.prepareModels([model]).then(
            ([model]) => {
                if (model && !this.has(model.id)) {
                    this.add(model, {
                        remoteChange: true,
                        initialize: true
                    });
                }
                return model;
            });
    },

    load: function(loadOptions = {}) {
        if (!this.loadPromise) {
            this.loadPromise = this.references.load().then(() => {
                let models = this._initialModels;
                const modelIds = getModelIds(models);
                const newModels = Object.keys(this.references.attributes).map(key => {
                    if (key === "id" || key === "null" || key === "_changeId" || key === "modifiedAt" || !key) {
                        return null;
                    }
                    const index = modelIds.indexOf(key);
                    if (index !== -1 && typeof (models[index]) !== "string") {
                        return models[index];
                    }
                    return { id: key };
                }).filter(model => model);

                this.loadModelsPromise = staggerPromises(
                    newModels.reverse().map(model => () => this.loadModel(model)),
                    loadOptions.maxConcurrent || 10,
                    loadOptions.delay || 16);

                this.loadModelsPromise.then(() => this.trigger("modelsLoaded"));

                this.listenTo(this.references, "change", (_, options) => {
                    if (options && options.remoteChange) {
                        options.removedKeys.forEach(key => {
                            this.remove(this.get(key), {
                                remoteChange: true
                            });
                        });
                        Object.keys(this.references.attributes).forEach(id => {
                            if (id === "id" || id === "modifiedAt" || this.has(id)) {
                                return;
                            }
                            const model = new this.model({ id: id }, this.modelOptions);
                            model.load().then(() => {
                                this.add(model, {
                                    remoteChange: true
                                });
                            });
                        });
                        this.each(model => {
                            if (!this.references.has(model.id)) {
                                this.remove(model, {
                                    remoteChange: true
                                });
                            }
                        });
                    }
                });

                delete this._initialModels;
            });
        }
        return this.loadPromise;
    },

    loadModels: function(loadOptions) {
        return this.load(loadOptions).then(() => {
            return this.loadModelsPromise;
        });
    },

    add: async function(models, options) {
        const singular = !Array.isArray(models);
        options = Object.assign({ loadModels: true }, options);

        if (options.loadModels === false) {
            models = await this.getOrCreateModels(models);
            Backbone.Collection.prototype.add.call(this, models, options);
            return singular ? models[0] : models;
        }

        return this.prepareModels(models).then(models => {
            const addedModels = models.filter(model => {
                delete this.loading[model.id];
                return !this.has(model.id);
            });
            if (!options.initialize && !options.remoteChange) {
                const update = {};
                addedModels.forEach(model => {
                    update[model.id] = true;
                });
                this.references.update(update);
            }
            Backbone.Collection.prototype.add.call(this, addedModels, options);
            return singular ? models[0] : models;
        });
    },

    remove: function(models, options) {
        options = Object.assign({ loadModels: true }, options);

        if (!options.initialize && !options.remoteChange) {
            const keys = getModelIds(models);
            const update = {};
            keys.forEach(key => {
                update[key] = null;
            });
            this.references.update(update);
        }
        // Keep remove and add behaving the same.
        const results = Backbone.Collection.prototype.remove.call(this, models, options);

        if (options.remoteChange) {
            // Ensure models are disconnected
            if (Array.isArray(models)) {
                models.forEach(model => model.disconnect(options));
            } else {
                models.disconnect(options);
            }
        }

        if (options.loadModels === false) {
            return results;
        } else {
            return Promise.resolve(results);
        }
    },

    prepareModels: async function(models) {
        models = Array.isArray(models) ? models : [models];
        models = await this.getOrCreateModels(models);
        return Promise.all(models.map(model => {
            if (!this.loading[model.id] && !model.skipReferenceCollectionLoad) {
                if (this.ignoreErrors) {
                    this.loading[model.id] = model.load().catch(err => {
                        delete this.loading[model.id];
                        model.disconnect();
                        logger.error(err, "[ReferenceCollection] error loading model", { id: model.id });
                        return null;
                    });
                } else {
                    this.loading[model.id] = model.load();
                }
            } else {
                this.loading[model.id] = model;
            }
            return this.loading[model.id];
        })).then(models => {
            return models.filter(model => {
                return model !== null;
            });
        });
    },

    prepareModel: async function(model) {
        // prepareModel normally does nothing
        return model;
    },

    getOrCreateModels: async function(models) {
        if (!models) {
            return [];
        }
        models = Array.isArray(models) ? models : [models];
        models = await Promise.all(models.map(async model => {
            if (!model) {
                return null;
            }
            if (typeof (model) === "string") {
                model = this.get(model) || new this.model({ id: model }, this.modelOptions);
            } else if (!(model instanceof Backbone.Model)) {
                if (Object.keys(model).length === 1 && Object.keys(model)[0] === "id") {
                    // if only key is id, run optional prepareModel method
                    model = await this.prepareModel(model);
                }
                model = new this.model(model, this.modelOptions);
            }
            return model;
        }));

        return models.filter(model => {
            return model != null;
        });
    },

    disconnect: function() {
        this.each(model => {
            if (model.disconnect) {
                model.disconnect();
            }
        });
        this.references.disconnect();
    }

});

export default ReferenceCollection;
