import React, { Component } from "reactn";
import styled from "styled-components";
import { Fab, Icon } from "@material-ui/core";

import { FeatureType } from "common/features";
import AppController from "js/core/AppController";
import { _ } from "js/vendor";
import { UIPane } from "js/react/components/UiComponents";
import { SearchThrottleWait } from "common/constants";
import {
    SlideTemplatesByCategoryList,
    SlideTemplateSearchPreview,
} from "js/react/views/AddSlide/Panes/SlideTemplatesPane";
import { SlideGalleryPreviewThumbnail } from "js/react/views/AddSlide/Panes/SlideGalleryPane";
import { CopySlideThumbnail } from "js/react/views/AddSlide/Panes/CopySlidePane";
import Spinner from "js/react/components/Spinner";
import { Gap10 } from "js/react/components/Gap";
import {
    gallerySlidesDataService,
    userSlidesDataService,
    teamSlidesDataService,
    slideTemplatesDataService,
} from "js/react/views/AddSlide/DataServices";
import { UIActionType, isUIActionRestricted } from "js/core/uiActionsRestrictors";
import { NoMatchTemplatesNotice } from "js/react/components/Notice";
import { appVersion } from "js/config";
import { getCanvasBundle } from "js/canvas";
import { openSuggestTemplateForm } from "js/core/utilities/externalLinks";

import { SlideSearchPreview } from "./Components/SlideSearchPreview";
import {
    SearchBarContainer,
    SearchBarInnerContainer,
    UIPaneResultsContainer,
    SlideSearchInput
} from "./Components/SearchBox";
import { TeamSlideThumbnail } from "./Components/TeamSlideThumbnail";

export const SearchPreview = styled.div`
  background: white;
  padding: 20px;
  margin: 0px 0px 20px;
`;
export const SearchPreviewHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-left: 20px;
  margin-bottom: 24px;
  color: #111;
  display: flex;
  align-items: center;
`;

const StyledSearchBarContainer = styled(SearchBarContainer)`
    gap: 20px;
`;

export const SEE_ALL_SLIDES_TEMPLATE = "See all slides templates";
export const SEE_ALL_SHARED_SLIDES = "See all shared slides";
export const START_FROM_ONE_OF_YOUR_TEAM_SLIDE_TEMPLATE = "Start from one of your team's Slide Templates";
export const USE_ONE_OF_YOUR_TEAM_SHARED_SLIDES = "Use one of your team's Shared Slides";
export const BROWSE_ALL_PRESENTATIONS = "Browse all presentations";
export const START_FROM_ONE_OF_THE_SLIDES_IN_YOUR_OTHER_PRESENTATIONS = "Start from one of the slides in your other presentations";
export const MORE_IDEAS = "More ideas";
export const START_FROM_OUR_INSPARATIONAL_GALLERY = "Start from our Inspiration Gallery";

export class SearchSlidesPane extends Component {
    state = {
        query: "",
        isGalleryResultsLoaded: false,
        isTeamSlideResultsLoaded: false,
        isCopySlideResultsLoaded: false,
        isSlideTemplatesLoaded: false,
        isSearchedSlideTemplateLoaded: false,
        searchedSlideTemplateResults: [],
        slideTemplateResults: [],
        slideGalleryResults: [],
        teamSlideResults: [],
        copySlideResults: [],
        isTemplateClicked: false,
        isShowingClassicTemplates: false,
    }

    constructor() {
        super();

        this.slideTemplates = null;

        this.searchBarRef = React.createRef();
        this.scrollRef = React.createRef();
        this.doSearchThrottled = _.throttle(this.doSearch, SearchThrottleWait, { leading: false });
    }

    componentDidMount() {
        this.doSearch();
        this.loadSlideTemplates();
    }

    async loadSlideTemplates() {
        const { slideTemplates: { slideTemplates, classicTemplates } } = await getCanvasBundle(appVersion);
        this.slideTemplates = slideTemplates;
        this.classicTemplates = classicTemplates;
        this.setState({ isSlideTemplatesLoaded: true });
    }

    handleSearch = query => {
        this.setState({
            query: query,
            isTemplateResultsLoaded: false,
            isCopySlideResultsLoaded: false,
            isGalleryResultsLoaded: false,
            isTeamSlideResultsLoaded: false,
            slideTemplateResults: [],
            searchedSlideTemplateResults: [],
            isTemplateClicked: false,
            isShowingClassicTemplates: false
        }, () => {
            this.doSearchThrottled();
        });
    }

    doSearch = async () => {
        const { organizationId, forTeamSlide = false } = this.props;
        const { query } = this.state;

        if (!query) {
            this.setState({
                slideGalleryResults: [],
                teamSlideResults: [],
                copySlideResults: [],
                searchedSlideTemplateResults: [],
                slideTemplatesResults: [],
                isCopySlideResultsLoaded: true,
                isGalleryResultsLoaded: true,
                isTeamSlideResultsLoaded: true,
                isSearchedSlideTemplateLoaded: true
            });
            return;
        }

        const fullTextSearchQuery = query;
        const size = 8;
        const minScore = 1;

        // the limit for the search results is now set to the default SEARCH_QUERY_SIZE which is 500
        await Promise.all([
            slideTemplatesDataService.search({ fullTextSearchQuery }).then(searchedSlideTemplateResults => {
                this.setState({
                    searchedSlideTemplateResults,
                    isSearchedSlideTemplateLoaded: true,
                });
            }),
            userSlidesDataService.search({ fullTextSearchQuery, size, minScore, organizationId }).then(copySlideResults => {
                this.setState({
                    copySlideResults,
                    isCopySlideResultsLoaded: true,
                });
            }),
            // if  we're searching within the team resources, we shouldn't look for team slides
            (
                (organizationId && !forTeamSlide)
                    ? teamSlidesDataService.search({ fullTextSearchQuery, size, organizationId })
                    : Promise.resolve([])
            ).then(
                teamSlideResults => {
                    this.setState({
                        teamSlideResults,
                        isTeamSlideResultsLoaded: true,
                    });
                }
            ),
            gallerySlidesDataService.search({ fullTextSearchQuery, size, minScore }).then(
                slideGalleryResults => {
                    this.setState({
                        slideGalleryResults,
                        isGalleryResultsLoaded: true,
                    });
                }
            )
        ]);
    }

    handleClearSearch = () => {
        this.searchBarRef.current.updateQuery("");
    }

    handleSelectTemplate = ({ id: templateId, title }) => {
        const { onTemplateSelected } = this.props;

        const template = this.slideTemplates[templateId];

        if (
            !AppController.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_INSPIRATION_SLIDES, AppController.workspaceId) ||
            templateId === "slidePlaceholder"
        ) {
            onTemplateSelected(template);
        } else {
            this.searchBarRef.current.updateQuery(title);
            this.setState({
                isShowingClassicTemplates: false,
                query: title,
                isTemplateClicked: true
            });

            if (template) {
                this.setSlideTemplateResults(template);
            }
        }
    }

    setSlideTemplateResults = template => {
        const { relatedTemplates, ...selectedTemplatesProps } = _.find(this.slideTemplates, { id: template.prototype.constructor.id });

        // Classic special case
        if (selectedTemplatesProps.id === "authoring") {
            this.setState({
                slideTemplateResults: this.classicTemplates,
                isShowingClassicTemplates: true
            });
            return;
        }

        const options = [
            { ...selectedTemplatesProps }
        ];

        // Incase the template has no related templates, we'll just return the selected template
        relatedTemplates?.map(templateId => {
            const { relatedTemplates, ...rest } = _.find(this.slideTemplates, { id: templateId });
            options.push({ ...rest });
        });

        this.setState({
            isShowingClassicTemplates: false,
            slideTemplateResults: options
        });
    }

    handleSelectViewAll = pane => {
        const { onSelectViewAll } = this.props;
        const { query } = this.state;

        this.setGlobal({ searchSlidesQuery: query });
        onSelectViewAll(pane);
    }

    renderSlides = () => {
        const {
            forTeamSlide = false,
            showUserTheme,
            onTemplateSelected,
            onToggleUserTheme,
            onAddBlankClassicSlide,
        } = this.props;
        const {
            query,
            slideTemplateResults,
            searchedSlideTemplateResults,
            slideGalleryResults,
            teamSlideResults,
            copySlideResults,
            isCopySlideResultsLoaded,
            isGalleryResultsLoaded,
            isTeamSlideResultsLoaded,
            isSlideTemplatesLoaded,
            isTemplateClicked,
            isSearchedSlideTemplateLoaded,
        } = this.state;

        // Pre render our templates without performing a search
        // Render the templates if the user has clicked on a template
        if (
            isTemplateClicked &&
            (
                !isSlideTemplatesLoaded ||
                (
                    !isCopySlideResultsLoaded &&
                    !isGalleryResultsLoaded &&
                    !isTeamSlideResultsLoaded
                )
            )
        ) {
            return (
                <div className="search-results-frame">
                    <SlideTemplateSearchPreview
                        items={slideTemplateResults}
                        onSelected={onTemplateSelected}
                        showUserTheme={showUserTheme}
                        onToggleUserTheme={onToggleUserTheme}
                    />
                </div>
            );
        }

        const searchedSlideTemplateItems = searchedSlideTemplateResults.filter(({ id }) => !isUIActionRestricted(UIActionType.ADD_SLIDE, { templateId: id }));
        const slideGalleryItems = slideGalleryResults.filter(({ template_id }) => !isUIActionRestricted(UIActionType.ADD_SLIDE, { templateId: template_id }));
        // isDisabled is a flag that is set on to indicate that the template is hidden from the user
        const teamSharedSlideItems = teamSlideResults.filter(({ templateId, isTemplate, isDisabled }) => !isTemplate && !isDisabled && !isUIActionRestricted(UIActionType.ADD_SLIDE, { templateId }));
        const teamSlideTemplateItems = teamSlideResults.filter(({ templateId, isTemplate, isDisabled }) => isTemplate && !isDisabled && !isUIActionRestricted(UIActionType.ADD_SLIDE, { templateId }));
        const copySlideItems = copySlideResults.filter(({ templateId }) => !isUIActionRestricted(UIActionType.ADD_SLIDE, { templateId }));

        const slideSearchPreviewsItems = [
            {
                show: (
                    !forTeamSlide &&
                    teamSlideTemplateItems.length
                ),
                selectViewAllTitle: SEE_ALL_SLIDES_TEMPLATE,
                title: START_FROM_ONE_OF_YOUR_TEAM_SLIDE_TEMPLATE,
                thumbnail: TeamSlideThumbnail,
                expanded: true,
                items: teamSlideTemplateItems,
                onSelected: this.props.onTeamSlideTemplateSelected,
                onSelectViewAll: () => this.handleSelectViewAll("slide-team-templates"),
                showUserTheme,
                hideUseCurrentThemeSwitch: true
            },
            {
                show: (
                    !forTeamSlide &&
                    teamSharedSlideItems.length
                ),
                selectViewAllTitle: SEE_ALL_SHARED_SLIDES,
                title: USE_ONE_OF_YOUR_TEAM_SHARED_SLIDES,
                thumbnail: TeamSlideThumbnail,
                expanded: true,
                items: teamSharedSlideItems,
                onSelected: this.props.onTeamSlideSelected,
                onSelectViewAll: () => this.handleSelectViewAll("slide-library"),
                showUserTheme,
                hideUseCurrentThemeSwitch: true
            },
            {
                show: (
                    AppController.user.features.isFeatureEnabled(
                        FeatureType.WORKSPACE_CAN_ACCESS_INSPIRATION_SLIDES,
                        AppController.workspaceId
                    ) &&
                    slideGalleryItems.length
                ),
                selectViewAllTitle: MORE_IDEAS,
                title: START_FROM_OUR_INSPARATIONAL_GALLERY,
                expanded: true,
                thumbnail: SlideGalleryPreviewThumbnail,
                items: slideGalleryItems,
                onSelected: this.props.onGallerySlideSelected,
                onSelectViewAll: () => this.handleSelectViewAll("slide-gallery"),
                showUserTheme,
                onToggleUserTheme: this.props.onToggleUserTheme
            },
            {
                show: copySlideItems.length,
                selectViewAllTitle: BROWSE_ALL_PRESENTATIONS,
                title: START_FROM_ONE_OF_THE_SLIDES_IN_YOUR_OTHER_PRESENTATIONS,
                thumbnail: CopySlideThumbnail,
                showUserTheme,
                items: copySlideItems,
                onSelected: slide => this.props.onCopySlideSelected([slide]),
                onSelectViewAll: () => this.handleSelectViewAll("copy-slide"),
                hideUseCurrentThemeSwitch: true
            },
        ];
        const slideSearchPreviews = slideSearchPreviewsItems.filter(({ show }) => show);

        const hasNoMatches = (
            // Are we loaded?
            isSearchedSlideTemplateLoaded &&
            isTeamSlideResultsLoaded &&
            isGalleryResultsLoaded &&
            isCopySlideResultsLoaded &&
            // Do we have results?
            !slideTemplateResults.length &&
            !searchedSlideTemplateItems.length &&
            !slideSearchPreviews.length
        );

        if (
            query.length &&
            hasNoMatches
        ) {
            return (
                <NoMatchTemplatesNotice
                    onAddClassicSlide={onAddBlankClassicSlide}
                />
            );
        }

        if (query.length > 0) {
            return (
                <div className="search-results-frame">
                    {
                        (!!slideTemplateResults.length || !!searchedSlideTemplateItems.length) &&
                        <SlideTemplateSearchPreview
                            items={isTemplateClicked ? slideTemplateResults : searchedSlideTemplateItems}
                            onSelected={template => this.onSelected(template)}
                            showUserTheme={this.props.showUserTheme}
                            onToggleUserTheme={this.props.onToggleUserTheme}
                        />
                    }
                    {
                        (!isCopySlideResultsLoaded && !isGalleryResultsLoaded && !isTeamSlideResultsLoaded)
                            ? <Spinner />
                            : slideSearchPreviews
                                .map(({ show, ...rest }, index) =>
                                    <SlideSearchPreview key={index} {...rest} />
                                )
                    }
                </div>
            );
        }
    }

    onSelected = template => {
        const { onClassicTemplateSelected, onTemplateSelected } = this.props;
        const { isShowingClassicTemplates } = this.state;

        if (isShowingClassicTemplates) {
            onClassicTemplateSelected(template);
        } else {
            onTemplateSelected(template);
        }
    }

    render() {
        const { query } = this.state;

        return (
            <UIPane>
                <StyledSearchBarContainer>
                    <SearchBarInnerContainer>
                        <SlideSearchInput
                            ref={this.searchBarRef}
                            onSearch={this.handleSearch}
                            placeholder="Search for a slide..."
                            showShortCuts
                        />
                    </SearchBarInnerContainer>
                </StyledSearchBarContainer>

                <UIPaneResultsContainer>
                    {query.length == 0 &&
                        <div className="slide-templates-list">
                            <SlideTemplatesByCategoryList
                                onSelected={this.handleSelectTemplate}
                                hideUseCurrentThemeSwitch={this.props.hideUseCurrentThemeSwitch}
                                showUserTheme={this.props.showUserTheme}
                                onToggleUserTheme={this.props.onToggleUserTheme}
                            />
                        </div>
                    }
                    {this.renderSlides()}
                </UIPaneResultsContainer>
                <Fab
                    variant="extended"
                    className="floating-fab expanding-fab"
                    onClick={openSuggestTemplateForm}
                >
                    <Icon className="text-blue">how_to_vote</Icon>
                    <Gap10 />
                    <span>Suggest a template</span>
                </Fab>
            </UIPane>
        );
    }
}
