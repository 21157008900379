import React, { Component, getGlobal, setGlobal } from "reactn";
import PropTypes from "prop-types";
import { Icon } from "@material-ui/core";

import { _, $ } from "js/vendor";
import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import { AssetType } from "common/constants";
import { trackActivity, trackActivityViaParams } from "js/core/utilities/utilities";
import {
    BlueButton, Divider,
    UIContainer, UINavigation,
    UINavigationItem, UIPane,
    UIPaneContents, UIPaneHeader
} from "js/react/components/UiComponents";
import { Gap30 } from "js/react/components/Gap";
import IconsPane from "js/react/views/AddAssets/Panes/IconsPane";
import LoadingPane from "js/react/views/AddAssets/Panes/LoadingPane";
import { StockPhotosPane } from "js/react/views/AddAssets/Panes/StockPhotosPane";
import LogosPane from "js/react/views/AddAssets/Panes/LogosPane";
import RecentlyUsedAssetsPane from "js/react/views/AddAssets/Panes/RecentlyUsedAssetsPane";
import PresentationAssetsPane from "js/react/views/AddAssets/Panes/PresentationAssetsPane";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { Key } from "js/core/utilities/keys";

import "css/add-assets.scss";
import { PresentationLibraryPane } from "js/editor/PresentationLibrary/PresentationLibrary";
import { StockVideosPane } from "js/react/views/AddAssets/Panes/StockVideosPane";
import ImportMediaDialog from "js/react/views/ImportMedia/ImportMediaDialog";
import * as geom from "js/core/utilities/geom";
import TeamAssetsPane from "js/react/views/TeamResources/panes/TeamAssetsPane";
import { UIController } from "js/editor/dialogs/UIController";
import { UIActionType, isUIActionRestricted } from "js/core/uiActionsRestrictors";
import { FeatureType } from "common/features";
import ProBadge from "js/react/components/ProBadge";
import { UpgradePlanDialogType } from "../MarketingDialogs/UpgradePlanDialog";
import AISearchPane from "js/react/views/AddAssets/Panes/AISearchPane";
import AppController from "js/core/AppController";
import { WebImagesPane } from "./Panes/WebImagesPane";

class AddAssetsContainer extends Component {
    lockSlide = true;

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            userFolders: null,
        };

        switch (props.assetType) {
            case AssetType.ICON:
                this.state.selectedPaneId = "icons";
                break;
            case AssetType.LOGO:
                this.state.selectedPaneId = "logos";
                break;
            case AssetType.VIDEO:
            case AssetType.STOCK_VIDEO:
                if (app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_STOCK_LIBRARIES, AppController.workspaceId)) {
                    this.state.selectedPaneId = "stock-videos";
                } else {
                    this.state.selectedPaneId = "team-assets";
                }
                break;
            case AssetType.IMAGE:
            default:
                if (app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_STOCK_LIBRARIES, AppController.workspaceId)) {
                    this.state.selectedPaneId = "stock-photos";
                } else if (app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_WEB_IMAGES, AppController.workspaceId)) {
                    this.state.selectedPaneId = "web-images";
                } else {
                    this.state.selectedPaneId = "team-assets";
                }
                break;
        }

        $(window).on("keydown", event => {
            if (event.which == Key.ESCAPE) {
                this.handleBackButton();
            }
        });

        // Clear any lingering search term when the assets container is opened
        setGlobal({ lastPhotoSearchTerm: null });
    }

    handleBackButton = () => {
        if (this.state.selectedPresentationId) {
            this.setState({
                selectedPaneId: "select-presentation",
                selectedPresentationId: null
            });
            return;
        }
        this.setState({ isOpen: false });
    };

    handleCancel = () => {
        this.props.onCancel && this.props.onCancel();
    }

    closeContainer = () => {
        this.props.closeDialog();
    };

    processAsset = (asset, isNew) => {
        const {
            selectedPaneId,
        } = this.state;
        const {
            callback,
            bindTo = "content_value",
        } = this.props;
        let {
            type,
            fileType,
            url,
            previewUrl,
            id,
            size,
            assetProps,
            assetName,
            tags,
            hasAlpha,
        } = asset;

        if (!size) {
            size = new geom.Size(asset.get("w"), asset.get("h"));
        }

        const trackParams = {
            selectedPaneId,
        };
        if (type === AssetType.IMAGE) {
            trackParams.category = "Image";
        }
        if (type === AssetType.LOGO) {
            trackParams.category = "Logo";
        }
        if (type === AssetType.VIDEO || type === AssetType.STOCK_VIDEO) {
            trackParams.category = "Video";
        }
        trackParams.action = trackParams.action || "Added";
        trackActivityViaParams(trackParams);

        const model = {
            content_type: type,
            content_url: null,
            content_attribution: asset.get && asset.get("attribution"),
            assetProps: _.merge({}, assetProps, {
                originalSize: size,
            }),
            assetName,
            tags,
        };
        model[bindTo] = id;

        if (type === AssetType.STOCK_VIDEO) {
            model.content_url = url;
            model.previewUrl = previewUrl;
        }

        // If we have a transparent image, remove the background color
        if (
            (
                type === AssetType.IMAGE ||
                type === AssetType.LOGO
            ) &&
            fileType !== "svg" &&
            hasAlpha
        ) {
            model.cellColor = "none";
        }

        callback(model, isNew, asset);
    }

    handleSelectNewAsset = asset => {
        if (this.state.isAlreadyClicked) return;
        this.setState({
            isAlreadyClicked: true
        });
        this.processAsset(asset, true);
    };

    handleSelectExistingAsset = asset => {
        if (this.state.isAlreadyClicked) return;
        this.setState({
            isAlreadyClicked: true
        });
        this.processAsset(asset, false);
    };

    importAsset = async () => {
        const { backgroundVideoOnly = true, hideVideos } = this.props;
        const { selectedPaneId } = this.state;

        ShowDialog(ImportMediaDialog, {
            fileTypes: !hideVideos ? ["image", "video"] : ["image"],
            imageAsLogo: selectedPaneId === "logos",
            backgroundVideoOnly,
            onSuccess: asset => {
                // If we upload a video as a background,
                // for an asset type that is not a video,
                // we want to set it to loop and autoplay
                if (this.props.assetType !== AssetType.VIDEO &&
                    this.props.assetType !== AssetType.STOCK_VIDEO &&
                    asset.type === AssetType.VIDEO) {
                    asset.assetProps = {
                        ...asset.assetProps,
                        autoPlay: true,
                        loop: true,
                        controls: true,
                    };
                }

                this.processAsset(asset, true);
                this.closeContainer();
            }
        });
    }

    handleCopyAssetPresentationClicked = presentation => {
        this.setState({
            selectedPaneId: "select-presentation-asset",
            selectedPresentation: presentation.presentationModel
        });
    };

    deselectUserFolder = () => {
        this.setState({
            selectedSecondaryId: null
        });
    };

    renderSelectedPane = () => {
        let {
            selectedPaneId,
            selectedPresentation,
            prefilledSearchQuery,
        } = this.state;
        const {
            backgroundVideoOnly = true,
            hideVideos,
            hidePhotos,
            hideLogos,
        } = this.props;

        const { lastLogoSearchTerm } = getGlobal();
        if (selectedPaneId === "logos" && lastLogoSearchTerm) {
            selectedPaneId = "search-logos";
        }

        const assetTypes = [
            !hidePhotos && AssetType.IMAGE,
            !hideLogos && AssetType.LOGO,
            !hideVideos && AssetType.VIDEO,
            !hideVideos && AssetType.STOCK_VIDEO
        ].filter(val => val);

        switch (selectedPaneId) {
            case "recently-used-media":
                return (
                    <RecentlyUsedAssetsPane
                        key="recently-used-media"
                        assetTypes={assetTypes}
                        showSearch={false}
                        backgroundVideoOnly={backgroundVideoOnly}
                        workspaceId={UIController.getWorkspaceId()}
                        addAssetCallback={this.handleSelectExistingAsset}
                        handleConfirm={this.closeContainer}
                        handleSearch={query => {
                            this.setState({
                                selectedPaneId: AssetType.IMAGE,
                                selectedSecondaryId: "stock_photos",
                                prefilledSearchQuery: query
                            });
                        }}
                    />
                );
            case "icons":
                return (
                    <IconsPane
                        addAssetCallback={this.handleSelectExistingAsset}
                        handleConfirm={this.closeContainer}
                        workspaceId={UIController.getWorkspaceId()}
                        searchType="icons"
                    />
                );
            case "logos":
                return (
                    <RecentlyUsedAssetsPane
                        key="logos"
                        assetTypes={[AssetType.LOGO]}
                        showSearch={true}
                        searchPrompt="Search for logos..."
                        addAssetCallback={this.handleSelectExistingAsset}
                        handleConfirm={this.closeContainer}
                        handleSearch={query => {
                            this.setState({
                                selectedPaneId: "search-logos",
                                prefilledSearchQuery: query
                            });
                        }}
                    />
                );
            case "ai-search":
                return (
                    <AISearchPane
                        key="generate"
                        allowSearch
                        addAssetCallback={this.handleSelectExistingAsset}
                        handleConfirm={this.closeContainer}
                    />
                );
            case "stock-photos":
                return (
                    <StockPhotosPane
                        addAssetCallback={this.handleSelectNewAsset}
                        handleConfirm={this.closeContainer}
                        prefilledSearchQuery={prefilledSearchQuery}
                        onPrefilledSearchQueryComplete={() => {
                            this.setState({
                                prefilledSearchQuery: null
                            });
                        }}
                    />
                );
            case "stock-videos":
                return (
                    <StockVideosPane
                        addAssetCallback={this.handleSelectNewAsset}
                        handleConfirm={this.closeContainer}
                        prefilledSearchQuery={prefilledSearchQuery}
                        onPrefilledSearchQueryComplete={() => {
                            this.setState({
                                prefilledSearchQuery: null
                            });
                        }}
                    />
                );
            case "web-images":
                return (
                    <WebImagesPane
                        addAssetCallback={this.handleSelectNewAsset}
                        handleConfirm={this.closeContainer}
                        prefilledSearchQuery={prefilledSearchQuery}
                        onPrefilledSearchQueryComplete={() => {
                            this.setState({
                                prefilledSearchQuery: null
                            });
                        }}
                    />
                );
            case "search-logos":
                return (
                    <LogosPane
                        addAssetCallback={this.handleSelectNewAsset}
                        handleConfirm={this.closeContainer}
                        prefilledSearchQuery={prefilledSearchQuery}
                        resetPreviouslyViewedState={() => {
                            this.setState({ selectedPaneId: "logos" });
                            this.renderSelectedPane();
                        }}
                        onPrefilledSearchQueryComplete={() => {
                            this.setState({
                                prefilledSearchQuery: null
                            });
                        }}
                    />
                );
            case "select-presentation":
                return (
                    <UIPane>
                        <UIPaneHeader>Choose a presentation to copy assets from</UIPaneHeader>
                        <UIPaneContents>
                            <PresentationLibraryPane
                                showLibraryViewSelect={false}
                                workspaceId={UIController.getWorkspaceId()}
                                collection={ds.presentations}
                                readOnly
                                showWorkspaceChooser={false}
                                onSelectedPresentation={this.handleCopyAssetPresentationClicked}
                            />
                        </UIPaneContents>
                    </UIPane>
                );
            case "select-presentation-asset":
                return (
                    <PresentationAssetsPane
                        assetTypes={assetTypes}
                        addAssetCallback={this.handleSelectExistingAsset}
                        handleConfirm={this.closeContainer}
                        selectedPresentation={selectedPresentation}
                        backgroundVideoOnly={backgroundVideoOnly}
                    />
                );
            case "team-assets":
                return (
                    <TeamAssetsPane
                        addAssetCallback={this.handleSelectExistingAsset}
                        handleConfirm={this.closeContainer}
                    />
                );
            default:
                return (<LoadingPane />);
        }
    };

    render() {
        const { isOpen, selectedPaneId } = this.state;

        const workspaceId = AppController.workspaceId;

        const hideTeamAssets = this.props.hideTeamAssets;
        const hideIcons = this.props.hideIcons || isUIActionRestricted(UIActionType.ADD_ASSET, { assetType: AssetType.ICON });
        const hideVideos = this.props.hideVideos || isUIActionRestricted(UIActionType.ADD_ASSET, { assetType: AssetType.STOCK_VIDEO });
        const hidePhotos = this.props.hidePhotos || isUIActionRestricted(UIActionType.ADD_ASSET, { assetType: AssetType.IMAGE });
        const hideLogos = this.props.hideLogos || isUIActionRestricted(UIActionType.ADD_ASSET, { assetType: AssetType.LOGO });
        const hideAISearch = this.props.hideAISearch || app.user.features.isFeatureEnabled(FeatureType.PROHIBIT_GENERATIVE_AI, workspaceId);

        return (
            <UIContainer
                isOpen={isOpen}
                className="add-asset-container"
                onClose={() => {
                    this.closeContainer();
                    this.handleCancel();
                }}
            >
                <UINavigation
                    selected={selectedPaneId}
                    onSelect={id => {
                        this.setState({ selectedPaneId: id });

                        let props = {
                            selectedPaneId,
                        };

                        trackActivity("Image", "PaneSelected", null, null, props, { audit: false, skipAmplitude: true });
                    }}
                    onBack={this.handleBackButton}
                >
                    <BlueButton onClick={e => this.importAsset()}>
                        <Icon>cloud_upload</Icon>Import...
                    </BlueButton>
                    <Gap30 />
                    {
                        !hideTeamAssets &&
                        <UINavigationItem
                            id="team-assets"
                            title="Team Assets"
                            icon="group"
                            proBadge={
                                <ProBadge
                                    upgradeType={UpgradePlanDialogType.TEAM_NOOP}
                                    show={!app.user.features.isFeatureEnabled(FeatureType.VIEW_LIBRARY_ITEMS, workspaceId)}
                                    analytics={{ cta: "TeamAssets", ...ds.selection?.presentation?.getAnalytics() }}
                                    workspaceId={workspaceId}
                                />
                            }
                        />
                    }
                    <UINavigationItem
                        id="recently-used-media"
                        title="Recent Media"
                        icon="star_border"
                    />
                    <UINavigationItem
                        id="select-presentation"
                        title="Find in slides"
                        icon="search"
                    />
                    <Divider color="#444" margin={20} />
                    {
                        !hidePhotos &&
                        app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_STOCK_LIBRARIES, workspaceId) &&
                        <UINavigationItem
                            id="stock-photos"
                            title="Stock Images"
                            icon="camera_alt"
                        />
                    }
                    {
                        !hidePhotos &&
                        app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_WEB_IMAGES, workspaceId) &&
                        <UINavigationItem
                            id="web-images"
                            title="Web Images"
                            icon="language"
                        />
                    }
                    {
                        !hideVideos &&
                        app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_STOCK_LIBRARIES, workspaceId) &&
                        <UINavigationItem
                            id="stock-videos"
                            title="Stock Videos"
                            icon="video_library"
                        />
                    }
                    {!hideIcons && (
                        <UINavigationItem
                            id="icons"
                            title="Icons"
                            icon="filter_vintage"
                        />
                    )}
                    {!hideLogos && (
                        <UINavigationItem
                            id="logos"
                            title="Logos"
                            icon="sort_by_alpha"
                        />
                    )}
                    {!hideAISearch && <Divider color="#444" margin={20} />}
                    {!hideAISearch && (
                        <UINavigationItem
                            id="ai-search"
                            title="GENERATE WITH AI..."
                            image={<div className="icon-container">
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 20 20" fill="currentColor" >
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.8334 4.82669C11.5692 4.50521 12.0834 3.77101 12.0834 2.91671C12.0834 1.76611 11.1506 0.833374 10 0.833374C8.84945 0.833374 7.91671 1.76611 7.91671 2.91671C7.91671 3.77101 8.43091 4.50521 9.16671 4.82669V7.50004H5.33337C4.2288 7.50004 3.33337 8.39547 3.33337 9.50004V10.4167C1.95266 10.4167 0.833374 11.536 0.833374 12.9167C0.833374 14.2974 1.95266 15.4167 3.33337 15.4167V16.3334C3.33337 17.4379 4.2288 18.3334 5.33337 18.3334H14.6667C15.7713 18.3334 16.6667 17.4379 16.6667 16.3334V15.4167C18.0474 15.4167 19.1667 14.2974 19.1667 12.9167C19.1667 11.536 18.0474 10.4167 16.6667 10.4167V9.50004C16.6667 8.39547 15.7713 7.50004 14.6667 7.50004H10.8334V4.82669ZM14.5834 12.9167C14.5834 13.8372 13.8372 14.5834 12.9167 14.5834C11.9962 14.5834 11.25 13.8372 11.25 12.9167C11.25 11.9962 11.9962 11.25 12.9167 11.25C13.8372 11.25 14.5834 11.9962 14.5834 12.9167ZM7.08337 14.5834C8.00385 14.5834 8.75004 13.8372 8.75004 12.9167C8.75004 11.9962 8.00385 11.25 7.08337 11.25C6.1629 11.25 5.41671 11.9962 5.41671 12.9167C5.41671 13.8372 6.1629 14.5834 7.08337 14.5834Z" />
                                    </svg>
                                </span>
                            </div>}
                            proBadge={<>
                                <ProBadge
                                    upgradeType={UpgradePlanDialogType.UPGRADE_PLAN}
                                    show={!app.user.features.isFeatureEnabled(FeatureType.DESIGNER_BOT, workspaceId)}
                                    analytics={{ cta: "ImageBot", ...ds.selection?.presentation?.getAnalytics() }}
                                    workspaceId={workspaceId}
                                />
                            </>
                            }
                        />
                    )}
                </UINavigation>
                {this.renderSelectedPane()}
            </UIContainer >
        );
    }
}

AddAssetsContainer.propTypes = {
    assetType: PropTypes.string,
    callback: PropTypes.func
};

export default AddAssetsContainer;
