import React, { Component } from "react";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import {
    Close as CloseIcon
} from "@material-ui/icons";

import { ShortcutContent } from "js/editor/shortcutView";

import PresentationEditorController, { PanelType } from "../PresentationEditorController";
import AppController from "js/core/AppController";
import { RightSidePanel } from "./EditorPanels";

const Container = styled.div`
     background: #f1f1f1;
    font-size: 14px;
    cursor: default;
    max-width: 100vw;
    max-height: 100vh;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    display: flex;
`;

const Contents = styled.div`
    padding: 10px;
    overflow: unset;
    background: #f1f1f1;
    clear: both;
    position: relative;
    height: calc(100% - 40px);
`;

const Title = styled.div`
    font-size: 14px;
    color: #333;
    letter-spacing: .9px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 20px;
`;

const CloseIconButton = styled(IconButton)`
    &&& {
      color: #ccc;
      width: 40px;
      height: 40px;
    }`;

const Header = styled.div`
    height: 32px;
    padding: 0px 10px;
    background: #f1f1f1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledConatiner = styled(Container)`  
    box-shadow: 0px 0px 20px rgba(0,0,0,.2);
`;

class ShortcutPanel extends Component {
    render() {
        return (
            <RightSidePanel
                visible={AppController.externalActivePanel === PanelType.SHORTCUTS}
                width={285}
                onClose={() => AppController.setExternalActivePanel(null) }
            >
                <StyledConatiner>
                    <Header>
                        <Title>Keyboard Shortcuts</Title>
                        <CloseIconButton size="small" onClick={() => AppController.setExternalActivePanel(null) }>
                            <CloseIcon/>
                        </CloseIconButton>
                    </Header>
                    <Contents>
                        <ShortcutContent />
                    </Contents>
                </StyledConatiner>
            </RightSidePanel>
        );
    }
}

export default ShortcutPanel;
