import React from "react";
import moment from "moment/moment";

import { _, $, Backbone, SVG, tinycolor } from "js/vendor";
import { ds } from "js/core/models/dataService";
import StorageModel from "js/core/storage/storageModel";
import { app } from "js/namespaces";
import {
    BackgroundStyleType,
    CanvasEventType,
    PositionType,
    PaletteColorType
} from "common/constants";
import { getStaticUrl } from "js/config";
import { Key } from "js/core/utilities/keys";
import getLogger from "js/core/logger";
import renderReactRoot, { renderReactDialog } from "js/react/renderReactRoot";
import ProBadge from "js/react/components/ProBadge";
import { StylePopupMenu } from "js/react/views/Editor/StylePopupMenu";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { prefixZeros } from "js/core/utilities/utilities";
import { ColorPalettePopupMenu } from "js/react/views/Editor/ColorPalettePopupMenu";

import PresentationEditorController from "./PresentationEditor/PresentationEditorController";

import "css/controls.scss";

const logger = getLogger();

let activeConfigId = null;
let $ui_menu = null;
let openPopupMenu = null;

async function setModel(config, value, options) {
    if (config.model instanceof StorageModel) {
        config.model.update({
            [config.property]: value
        }, options);
    } else if (config.model instanceof Backbone.Model) {
        config.model.set({
            [config.property]: value
        }, options);
    } else {
        if (config.clearSelectionAfterChange) {
            ds.selection.element = null;
        }

        config.model[config.property] = value;
        if (app.currentCanvas) {
            let transitionModel = config.transitionModel == undefined ? true : config.transitionModel;

            if (config.markStylesAsDirty || config.property.toLowerCase().contains("color")) {
                app.currentCanvas.markStylesAsDirty();
            }

            await app.currentCanvas.updateCanvasModel(transitionModel).then(() => {
                if (config.closeMenuAfterChange && config.callback) {
                    config.callback();
                }
            }).catch(err => {
                ShowWarningDialog({
                    title: "Sorry, we were unable to make this change",
                    message: err.message,
                });
            });
        }
    }
}

function getConfigValue(config, property) {
    if (typeof config[property] == "function") {
        return config[property]();
    } else {
        return config[property];
    }
}

function saveCurrentState(options = {}) {
    if (app.currentCanvas) {
        app.currentCanvas.trigger(CanvasEventType.BEFORE_MODEL_CHANGE, options);
    }
}

function getPropertyFromModel(model, property) {
    if (model instanceof Backbone.Model) {
        return model.get(property);
    } else {
        return model[property];
    }
}

export const Tab = Backbone.View.extend({
    getTitle: function() {
        return "Tab";
    },

    render: function() {
        return this;
    },

    show: function() {
        this.$el.show();
        this.onShown();
    },

    onShown: function() {
    },

    hide: function() {
        this.$el.hide();
        this.onHidden();
    },

    onHidden: function() {
    }
});

export const controls = {
    OPTIONS_MENU: "optionsMenu",
    CUSTOM_PANEL: "customPanel",
    DATE_PICKER: "datepicker",
    TIME_PICKER: "timepicker",
    NUMERIC: "numeric",
    BUTTON: "button",
    BUTTON_TOGGLE: "buttonToggle",
    SLIDER: "slider",
    CHECKBOX: "checkbox",
    DROPDOWN_MENU: "popupMenu",
    ICON_DROPDOWN_MENU: "iconDropDownMenu",
    POPUP_BUTTON: "popupButton",
    SECTION: "section",
    COLOR_PICKER: "colorPicker",
    // COLOR_PALETTE: "colorPalette",
    COLOR_PALETTE_PICKER: "colorPalettePicker",
    TOGGLE: "toggle",
    MULTI_TOGGLE: "multiToggle",
    ICON_TOGGLE: "iconToggle",
    SPACER: "spacer",
    POSITION_PICKER: "positionPicker",
    OPTIONS_LIST: "optionsList",
    ICON_MENU: "iconMenu",

    createControl: function(view, config, owner) {
        var $control;
        switch (config.type) {
            case controls.SECTION:
                $control = this.createSection(view, config, owner);
                break;
            case controls.OPTIONS_MENU:
                $control = this.createOptionsMenu(view, config, owner);
                break;
            case controls.DATE_PICKER:
                $control = this.createDatePicker(view, config, owner);
                break;
            case controls.TIME_PICKER:
                $control = this.createTimePicker(view, config, owner);
                break;
            case controls.NUMERIC:
                $control = this.createNumericStepper(view, config, owner);
                break;
            case controls.BUTTON:
                $control = this.createButton(view, config, owner);
                break;
            case controls.BUTTON_TOGGLE:
                $control = this.createButtonToggle(view, config, owner);
                break;
            case controls.SLIDER:
                $control = this.createSlider(view, config, owner);
                break;
            case controls.CHECKBOX:
                $control = this.createCheckbox(view, config, owner);
                break;
            case controls.DROPDOWN_MENU:
                $control = this.createDropdownMenu(view, config, owner);
                break;
            case controls.ICON_DROPDOWN_MENU:
                $control = this.createIconDropdownMenu(view, config, owner);
                break;
            case controls.COLOR_PICKER:
                $control = this.createColorPicker(view, config, owner);
                break;
            // case controls.COLOR_PALETTE:
            //     $control = this.createColorPalette(view, config, owner);
            //     break;
            case controls.COLOR_PALETTE_PICKER:
                $control = this.createColorPalettePicker(view, config, owner);
                break;
            case controls.POPUP_BUTTON:
                $control = this.createPopupButton(view, config, owner);
                break;
            case controls.TOGGLE:
                $control = this.createToggle(view, config, owner);
                break;
            case controls.MULTI_TOGGLE:
                $control = this.createMultiToggle(view, config, owner);
                break;
            case controls.ICON_TOGGLE:
                $control = this.createIconToggle(view, config, owner);
                break;
            case controls.SPACER:
                $control = this.createSpacer(view, config, owner);
                break;
            case controls.POSITION_PICKER:
                $control = this.createPositionPicker(view, config, owner);
                break;
            case controls.OPTIONS_LIST:
                $control = this.createOptionsList(view, config, owner);
                break;
            case controls.ICON_MENU:
                $control = this.createIconSelectMenu(view, config, owner);
                break;
        }
        if (config.label) {
            $control.attr("data-label", config.label);
        }
        $control.attr("title", config.title || config.tooltip);
        let toolTipClasses = "right";
        if (config.enabled === false) {
            $control.attr("disable", true);
            toolTipClasses += " disabled";
        }

        $control.tooltip({
            position: {
                my: "left+12 top", at: "right center-18"
            },
            classes: {
                "ui-tooltip": toolTipClasses
            },
            show: {
                effect: "fadeIn",
                delay: 500,
                duration: 200
            },
            hide: {
                effect: "fadeOut",
                duration: 200
            }
        });
        if (!config.allowCtrlPropagation) {
            $control.on("click", event => {
                event.stopPropagation();

                // deselect a text element if we are editing text
                //TODO
                // if (ds.selection.element && ds.selection.element.textLayout) {
                //     ds.selection.element = ds.selection.element.parentElement;
                // }
            });
        }

        // let $container = $.div().append($control);
        // return $container;
        return $control;
    },

    setEnabledState: function($control, config) {
        if (config.enabled == false) {
            $control.addClass("disabled");
        } else {
            $control.removeClass("disabled");
        }
    },

    toggleButtonClearAll: function($control, config) {
        $control.parent()[0].querySelectorAll(".control.button-toggle").forEach(button => {
            if (button.classList.contains("active")) {
                button.classList.remove("active");
                button.classList.add("inactive");
            }
        });
    },

    createSection: function(view, config, owner) {
        var $control = $.div("section").text(config.label);
        return $control;
    },

    createTimePicker: function(view, config) {
        var $control = $.div("control timepicker");
        let value = config.value.replace(/[^0-9\:]/g, "");
        let merideim = /am/i.test(config.value) ? "am" : "pm";

        // reset the inputs
        function reset() {
            $input.val(value);
            $merideim.val(merideim);
        }

        // check for labels
        if (config.label) {
            $control.append($.label(config.label));
        }

        // configuration
        const isTimeOfDay = config.type === "time-of-day";
        const isDuration = config.type === "duration";
        const useMerideim = config.includeMerideim;
        const placeholder = isTimeOfDay ? "3:45 PM" : "15:00";

        // not usable
        if (!(isTimeOfDay || isDuration)) {
            throw new Error("Invalid timepicker type");
        }

        // notifies the change
        function notifyChange(input) {
            if (isTimeOfDay) {
                const { time = value, merideim = "am" } = parseAsTimeOfDay(input);
                value = time;

                // failed to parse
                if (isNaN(value)) {
                    reset();
                    return config.callback(null);
                }

                // update the input
                $input.find("input").val(time);
                $merideim && $merideim.val(merideim);

                // get the final time value
                const updated = `${time} ${merideim.toUpperCase()}`;

                // notify of the change
                config.callback(updated);
            } else if (isDuration) {
                const { time, minutes, seconds } = parseAsDuration(input);
                $input.find("input").val(time);

                // failed to parse
                if (isNaN(minutes) || isNaN(seconds)) {
                    reset();
                    config.callback(null);
                }

                // notify of the change
                config.callback((minutes * 60) + seconds);
            }
        }

        // main input
        let $input = controls.createInput(view, {
            value,
            focusOnSelect: true,
            placeholder,
            callback: notifyChange
        });

        // including meridem control
        let $merideim;
        if (useMerideim) {
            $merideim = controls.createMultiToggle(view, {
                value: merideim,
                options: [{ label: "AM", value: "am" }, { label: "PM", value: "pm" }],
                callback: merideim => {
                    notifyChange(`${value} ${merideim.toUpperCase()}`);
                }
            });
        }

        // assemble
        $control.append($input);
        $merideim && $control.append($merideim);

        return $control;
    },

    createDatePicker: function(view, config, owner) {
        var $control = $.div(`control datepicker ${config.inline ? "datepicker-inline" : ""}`);
        var $date = $.div("date-value");
        var $datepicker = $.div("picker");

        // handles date changes
        function onSelectDate(date) {
            saveCurrentState();
            if (config.callback) {
                config.callback(date);
            } else {
                setModel(config, date);
            }
            $date.text(moment(date).format("M/D/YYYY"));
        }

        if (config.label) {
            $control.append($.label(config.label));
        }

        const currentDate = config.value;

        $control.append($datepicker);
        $date.text(moment(currentDate).format("M/D/YYYY"));

        if (config.inline) {
            $date.hide();
            $datepicker.datepicker({
                defaultDate: currentDate,
                onSelect: onSelectDate
            });
        } else {
            $control.append($date);
            $datepicker.hide();

            // handle showing the view
            $control.on("click", function() {
                $datepicker.datepicker("dialog", currentDate, onSelectDate, {}, [$(this).offset().left, $(this).offset().top + $(this).height()]);
            });
        }

        return $control;
    },

    createButton: function(view, config, owner) {
        var $control = $.div("control button");

        this.setEnabledState($control, config);

        if (config.className) {
            $control.addClass(config.className);
        }

        $control.on("mousedown", event => {
            saveCurrentState();
            event.stopPropagation();
            $control.addClass("mousedown");
        });

        $control.on("mouseup mouseleave", event => {
            $control.removeClass("mousedown");
        });

        if (config.callback) {
            $control.on("click", event => {
                event.stopPropagation();

                // suppress double-click on button
                if (this.lastClick && new Date() - this.lastClick < 250) {
                    return;
                }
                this.lastClick = new Date();

                config.callback();
            });
        }

        if (config.id) {
            $control.attr("id", config.id);
        }

        if (config.label) {
            $control.addEl($.label(config.label));
        } else {
            $control.addClass("icon no_label");
        }

        if (config.icon) {
            $control.prepend($.icon(config.icon));
        }

        if (config.color) {
            $control.css("background", config.color);
        }

        if (config.invisible) {
            $control.css("opacity", 0);
        }

        return $control;
    },

    createButtonToggle: function(view, config, owner) {
        let $control = $.div("control button-toggle");

        this.setEnabledState($control, config);

        $control.on("mousedown", event => {
            event.preventDefault();
            saveCurrentState();
            event.stopPropagation();
        });

        $control.on("click", event => {
            event.stopPropagation();
            // If we have a group of toggles, clear all the other toggles
            this.toggleButtonClearAll($control);

            toggled = !toggled;
            if (config.model && config.property) {
                setModel(config, toggled);
            }
            if (config.callback) {
                config.callback(toggled);
            }
            $control.toggleClass("inactive active");
        });

        if (config.icon) {
            $control.prepend($.icon(config.icon));
        }

        let toggled = false;
        if (config.model && config.property) {
            toggled = config.model[config.property];
        } else {
            toggled = config.value || false;
        }

        if (toggled) {
            $control.addClass("active");
        } else {
            $control.addClass("inactive");
        }

        return $control;
    },

    createSlider: function(view, config, owner) {
        let $control = $.div("control slider");

        $control.addClass(config.className);

        this.setEnabledState($control, config);

        if (config.icon) {
            $control.append($.icon(config.icon));
        }
        if (config.label) {
            $control.append($("<label></label>").text(config.label));
        }
        config.lastValidValue = config.model[config.property];
        const $slider = $("<input id='slider' type='range'/>");
        const stopDrag = () => {
            if (app.isDraggingItem) {
                app.isDraggingItem = false;
                if (config.model && config.property) {
                    // when slider is complete, we need to send another change event to the element but because we've already set the final value in the input event handler above, we need to null the value first so the Backbone event is raised
                    if (config.model instanceof Backbone.Model) {
                        config.model.set(config.property, null, { silent: true });
                        config.model.set(config.property, parseFloat($slider.val()), { isDraggingSlider: false });
                        if (config.onEnd) {
                            config.onEnd();
                        }
                    } else {
                        app.currentCanvas.updateCanvasModel(false).then(() => {
                            if (config.onEnd) {
                                config.onEnd();
                            }
                        });
                    }
                } else {
                    if (config.onEnd) {
                        config.onEnd();
                    }
                }
            }
        };
        $slider
            .attr("min", config.min || 0)
            .attr("max", config.max || 100)
            .attr("step", config.step || 1)
            .on("input", () => {
                if (config.callback) {
                    config.callback($slider.val());
                } else {
                    if (config.model && config.property) {
                        if (config.model instanceof Backbone.Model) {
                            config.model.set(config.property, parseFloat($slider.val()), { isDraggingSlider: true });
                        } else {
                            let val = parseFloat($slider.val());
                            config.model[config.property] = val;
                            if (config.onDrag) {
                                config.onDrag(val);
                            }

                            app.currentCanvas.throttledRefreshCanvas().then(() => {
                                config.lastValidValue = config.model[config.property];
                            }).catch(err => {
                                ShowWarningDialog({
                                    title: "Sorry, we were unable to make this change",
                                    message: err.message,
                                });
                                config.model[config.property] = config.lastValidValue;
                                stopDrag();
                                ds.selection.element = null;
                            });
                        }
                    }
                }
            })
            .on("mousedown", event => {
                saveCurrentState();
                event.stopPropagation();

                app.isDraggingItem = true;
                if (config.onStart) {
                    config.onStart();
                }

                $(document).one("mouseup", stopDrag);
            });

        if (config.value !== undefined) {
            $slider.val(config.value);
        } else if (config.property) {
            $slider.val(getPropertyFromModel(config.model, config.property));
        }

        $control.append($slider);
        if (config.iconAfter) {
            $control.append($.icon(config.iconAfter));
        }

        return $control;
    },

    hideColorPicker: function($picker, pickerContainer, event) {
        if (event.keyCode === Key.ENTER) {
            $picker.spectrum("hide");
            pickerContainer.off("keydown");
        }
    },

    createColorPicker: function(view, config, owner) {
        var $control = $.div("control color_picker");

        this.setEnabledState($control, config);

        if (config.label) {
            $control.append($.label(config.label));
        }

        var $picker = $.input("text");
        $control.append($picker);

        let paletteColors = config.paletteColors;

        // Determine what color we start off with
        let color = config.selectedColor || config.model[config.property];
        color = paletteColors[color] || color;

        $picker.spectrum({
            color,
            showButtons: false,
            chooseText: "Done",
            showInput: true,
            showPalette: true,
            showSelectionPalette: false,
            hideAfterPaletteSelect: true,
            clickoutFiresChange: true,
            preferredFormat: "hex",
            palette: Object.values(paletteColors),
            change: selectedColor => {
                saveCurrentState();

                let color;

                // match selected color against theme colors
                for (let [name, paletteColor] of Object.entries(config.paletteColors)) {
                    if (tinycolor.equals(tinycolor(paletteColor), selectedColor)) {
                        color = name;
                    }
                }

                if (!color) {
                    color = selectedColor.toRgbString();
                }

                if (config.callback) {
                    config.callback(color);
                } else {
                    setModel(config, color);
                }
            },
            show: () => {
                const pickerContainer = $picker.spectrum("container");

                pickerContainer.on("keydown", this.hideColorPicker.bind(this, $picker, pickerContainer));

                pickerContainer.clickShield(
                    () => {
                        $picker.spectrum("hide");
                        pickerContainer.clickShield(false);
                    },
                    true // This is needed to prevent a race condition with the SideBar clickShield
                );
            }
        });

        // Cleanup the overlay containers after the base element is removed
        $control.on("remove", () => {
            const pickerContainer = $picker.spectrum("container");
            pickerContainer.remove();
        });

        return $control;
    },

    createSpacer(view, config, owner) {
        const width = (config.width ?? 30) + "px";
        return $.div("spacer").css({ padding: 0, width });
    },

    createColorPalettePicker: function(view, config, owner) {
        let $control = $.div("control color_picker");

        this.setEnabledState($control, config);

        if (config.label) {
            $control.addEl($.label(config.label));
        }

        let $chit = $control.addEl($.div("color_picker_chit"));

        let currentColor;

        let elementBackgroundColor = view.element.getParentBackgroundColor(view.element);

        // build palette list
        let palette = [];

        if (config.includeAuto || config.showAuto) {
            let autoColor;
            if (config.getAutoColor) {
                autoColor = config.getAutoColor();
            } else {
                let slideColor = app.currentCanvas.getSlideColor();

                if (slideColor === "colorful") {
                    autoColor = "colorful";
                } else {
                    autoColor = view.element.canvas.getTheme().palette.getForeColor("slide", view.element.canvas.getTheme().palette.getColor(slideColor), elementBackgroundColor).toRgbString();
                }
            }

            palette.push({
                type: "color",
                value: "auto",
                color: autoColor,
                callback: () => {
                    if (config.getAutoColor) {
                        $chit.css("backgroundColor", view.element.canvas.getTheme().palette.getColor(config.getAutoColor()).toRgbString());
                    }
                }
            });
        }

        if (config.includeNone || config.showNone) {
            palette.push({
                type: "color",
                value: "none",
                color: "none"
            });
        }

        let backgroundColor = config.getBackgroundColor ? config.getBackgroundColor() : app.currentCanvas.getBackgroundColor();

        if (config.includePrimary || config.showPrimary) {
            palette.push({
                type: "color",
                value: "primary",
                color: view.element.canvas.getTheme().palette.getForeColor("primary", null, backgroundColor).toRgbString()
            });
        }
        if (config.includeSecondary || config.showSecondary) {
            palette.push({
                type: "color",
                value: "secondary",
                color: view.element.canvas.getTheme().palette.getForeColor("secondary", null, backgroundColor).toRgbString()
            });
        }

        if (config.includeWhite || config.showWhite) {
            palette.push({
                type: "color",
                value: "rgb(254,254,254)",
                color: "white"
            });
        }

        if (config.colors) {
            for (var color of config.colors) {
                palette.push({ type: "color", value: color.value, color: color.color });
            }
        } else {
            if (config.showBackgroundColors || config.includeBackgroundColors) {
                _.each(view.element.canvas.getTheme().palette.getBackgroundColors(), (color, key) => {
                    if (config.omitCurrentBackgroundColor && key == elementBackgroundColor.name) return;

                    // if ((config.showColors !== false || key == "background_dark" || key == "background_light") && key != elementBackgroundColor.name) {
                    palette.push({ type: "color", value: key, color: color });
                    // }
                });
            } else if (config.showChartColors) {
                _.each(view.element.canvas.getTheme().palette.getChartColors(), (color, key) => {
                    palette.push({ type: "color", value: key, color: color });
                });
            } else {
                _.each(view.element.canvas.getTheme().palette.getSlideColors(), (color, key) => {
                    palette.push({ type: "color", value: key, color: color });
                });
            }
        }

        if (config.includePositiveNegative || config.showPositiveNegative) {
            palette.push({
                type: "color",
                value: "positive",
                color: view.element.canvas.getTheme().palette.getForeColor("positive", null, backgroundColor)
            });
            palette.push({
                type: "color",
                value: "negative",
                color: view.element.canvas.getTheme().palette.getForeColor("negative", null, backgroundColor)
            });
        }

        if (config.omitColors) {
            palette = _.filter(palette, item => {
                return _.find(config.omitColors, color => color.name == item.value) == null;
            });
        }

        if (config.includeAddImage) {
            palette.push({ type: "color", value: "add_photo", color: "add_photo" });
        }

        let disablePalette = elementBackgroundColor.isColor;

        if (config.selectedColor) {
            currentColor = config.selectedColor;
        } else {
            let defaultValue = getPropertyFromModel(config.model, config.property);
            if (defaultValue) {
                currentColor = defaultValue;
            } else {
                currentColor = config.defaultColor;
            }
        }

        if (currentColor == undefined) {
            currentColor = palette[0].value;
        }

        if (currentColor instanceof tinycolor) {
            currentColor = currentColor.name;
        }

        $control.data("selectedValue", currentColor);

        let renderSelectedColorChit = value => {
            $chit.empty();
            let selectedItem = _.find(palette, item => item.value == value || item.color == value);
            if (!selectedItem) {
                switch (value) {
                    case PaletteColorType.BACKGROUND_DARK:
                        selectedItem = _.find(palette, item => item.value == PaletteColorType.BACKGROUND_LIGHT || item.color == PaletteColorType.BACKGROUND_LIGHT);
                        break;
                    case PaletteColorType.BACKGROUND_LIGHT:
                        selectedItem = _.find(palette, item => item.value == PaletteColorType.BACKGROUND_DARK || item.color == PaletteColorType.BACKGROUND_DARK);
                        break;
                }
            }
            if (!selectedItem) {
                selectedItem = { color: value };
            }
            $chit.css("backgroundColor", selectedItem.color);

            if (value == "auto") {
                if (selectedItem.color == "colorful") {
                    $chit.addClass("colorful_chit");
                    let $gradientContainer = $chit.addEl($.div("container").css("position", "absolute").css("border-radius", 0));
                    for (let color of view.element.canvas.getTheme().palette.getColorfulColors()) {
                        $gradientContainer.append($.div().css("background", view.element.canvas.getTheme().palette.getColor(color).toRgbString()));
                    }
                }

                if (config.autoLabel) {
                    $chit.append($.div("auto-label", config.autoLabel[0]));
                } else {
                    $chit.append($.div("auto-label", "S"));
                }
                if (tinycolor(selectedItem.color).isDark()) {
                    $chit.css("color", "white").css("text-shadow", "0 0 1px black");
                } else {
                    $chit.css("color", "black");
                }
            }
            if (value == "none") {
                // $chit.append($.div("none-label", ""));
                $chit.css("color", "transparent");
                $chit.append($(`<svg width="18px" height="18px"><line x1="18" y1="0" x2="0" y2="18" stroke="black" stroke-width="3"/></svg>`));
            }
        };

        if (currentColor == BackgroundStyleType.IMAGE) {
            $chit.append($.icon("photo_camera"));
        } else {
            renderSelectedColorChit(currentColor);
        }

        $control.on("mousedown", event => {
            event.preventDefault();
            renderReactDialog(ColorPalettePopupMenu, {
                target: $chit,
                palette,
                disablePalette,
                selectedColor: currentColor,
                showDecorationStyles: config.showDecorationStyles,
                showMutedDecorationStyles: config.showMutedDecorationStyles ?? true,
                showDecorationShapes: config.showDecorationShapes,
                autoLabel: config.autoLabel,
                position: view.getOffset().toString().contains("top") ? PositionType.TOP : PositionType.BOTTOM,
                onSelectColor: value => {
                    if (config.callback) {
                        config.callback(value, "color");
                    } else {
                        setModel(config, value);
                    }
                    renderSelectedColorChit(value);
                },
                onSelectDecorationStyle: value => {
                    if (config.callback) {
                        config.callback(value, "decorationStyle");
                    } else {
                        setModel({
                            model: config.model,
                            transitionModel: false,
                            property: "decorationStyle",
                            markStylesAsDirty: true
                        }, value);
                    }
                },
            }, 15000); // make sure it's above dropdown menus
        });

        return $control;
    },

    createCheckbox: function(view, config, owner) {
        var $control = $.div("control checkbox");

        this.setEnabledState($control, config);

        var $checkbox = $.input("checkbox");

        if (config.property) {
            $checkbox.prop("checked", getPropertyFromModel(config.model, config.property));
            $checkbox.on("change", function() {
                saveCurrentState();
                setModel(config, this.checked);
                if (config.callback) {
                    config.callback.call(this, this.checked);
                }
            });
        } else {
            $checkbox.prop("checked", config.value || config.defaultValue || false);
            $checkbox.on("change", function() {
                saveCurrentState();
                if (config.callback) {
                    config.callback.call(this, this.checked);
                }
            });
        }
        $control.append($checkbox);

        var checkboxId = _.uniqueId("checkbox");
        $checkbox.attr("id", checkboxId);
        $control.append($("<label></label>").attr("for", checkboxId).text(config.label));

        //view.listenTo(config.model, "change:" + config.property, function (model, value) {
        //    $checkbox.val(value);
        //});

        return $control;
    },

    createInput: function(view, config, owner) {
        var $control = $.div("control textinput");

        this.setEnabledState($control, config);

        if (config.label) {
            $control.append($.label(config.label));
        }

        var $input = $.input("text").attr("placeholder", config.placeholder);
        if (config.model && config.property) {
            $input.val(getPropertyFromModel(config.model, config.property));
        } else if (config.value) {
            $input.val(config.value);
        }
        $control.append($input);

        $input.on("keydown", event => {
            event.stopPropagation();

            switch (event.which) {
                case Key.ENTER:
                    $input.trigger("blur");
                    break;
                case Key.ESCAPE:
                    if (config.model && config.property) {
                        $input.val(getPropertyFromModel(config.model, config.property));
                    } else if (config.value) {
                        $input.val(config.value);
                    }
                    break;
            }
        });

        $input.on("focus", function() {
            saveCurrentState();
        });

        $input.on("blur", function() {
            if (config.model && config.property) {
                switch (config.valueType) {
                    case "integer":
                        if ($input.val() != "") {
                            setModel(config, parseInt($input.val()));
                        } else {
                            setModel(config, null);
                        }
                        break;
                    case "float":
                        if ($input.val() != "") {
                            setModel(config, parseFloat($input.val()));
                        } else {
                            setModel(config, null);
                        }
                        break;
                    default:
                        setModel(config, $input.val());
                }
            }
            if (config.callback) {
                config.callback($input.val());
            }
        });

        if (config.focusOnSelect) {
            $input.on("click", () => {
                $input.focus().select();
            });
        }

        // view.listenTo(config.model, "change:" + config.property, function (model, value) {
        //     $input.val(value);
        // });

        return $control;
    },

    createTaggedInput: function(view, config, owner) {
        const $control = $.div("control tagged_input");

        if (config.label) {
            $control.append($.label(config.label));
        }

        const $input = $.input("text").attr("placeholder", config.placeholder).val("");
        $control.append($input);
        $input.tagEditor({
            maxTags: 50,
            placeholder: config.placeholder,
            forceLowercase: true,
            removeDuplicates: true,
            sortable: false,
            delimiter: config.split || " ,;",
            initialTags: config.initialTags,
            onChange: function(field, editor, tags) {
                saveCurrentState();
                let validTags;
                let invalidTags;

                if (config.validate) {
                    validTags = [];
                    invalidTags = [];
                    tags.forEach(tag => {
                        if (config.validate(tag)) {
                            validTags.push(tag);
                        } else {
                            invalidTags.push(tag);
                            const $tagElement = $(`li:contains('${tag}')`, editor);
                            $tagElement.addClass("invalid-tag");
                            $tagElement.attr("title", config.validateErrorMessage || "Tag is invalid");
                        }
                    });
                } else {
                    validTags = tags;
                    invalidTags = [];
                }
                if (config.model && config.property) {
                    setModel(config, tags, { silent: true });
                }
                if (config.onChange) {
                    config.onChange(validTags, invalidTags);
                }
            },
            removeWithBackspace: true
        });

        if (config.model && config.property) {
            view.listenTo(config.model, "change:" + config.property, () => {
                const tags = $input.tagEditor("getTags")[0].tags;
                tags.forEach(tag => {
                    $input.tagEditor("removeTag", tag);
                });
                config.model.get(config.property).forEach(tag => {
                    $input.tagEditor("addTag", tag);
                });
            });
        }

        return $control;
    },

    createNumericStepper: function(view, config, owner) {
        var $control = $.div("control numeric");

        this.setEnabledState($control, config);

        if (config.label) {
            $control.append($.label(config.label));
        }

        let $container = $control.addEl($.div("numeric-container"));

        let $stepper = $("<input type='number'/>").attr("placeholder", config.placeholder);

        let updateValue = delta => {
            let val = parseFloat($stepper.val()) || config.min;
            val += delta;
            if (val > config.max) {
                val = config.max;
            }
            if (val < config.min) {
                val = config.min;
            }
            $stepper.val(val);

            commit();
        };

        $stepper.on("click", event => {
            $stepper.focus();
            $stepper.select();
            event.stopPropagation();
            event.stopImmediatePropagation();
        });

        $stepper.on("keydown", event => {
            if (event.which == Key.UP_ARROW) {
                if (event.shiftKey) {
                    updateValue((config.step || 1) * 10);
                } else {
                    updateValue((config.step || 1));
                }
            } else if (event.which == Key.DOWN_ARROW) {
                if (event.shiftKey) {
                    updateValue((config.step || 1) * -10);
                } else {
                    updateValue((config.step || 1) * -1);
                }
            }
        });

        $stepper.on("blur", event => {
            commit();
        });

        let $dec = $container.addEl($.div("stepper-button").append($.icon("arrow_drop_down")));
        $dec.on("click", event => {
            updateValue((config.step || 1) * -1);
        });

        $container.append($stepper);

        let $inc = $container.addEl($.div("stepper-button").append($.icon("arrow_drop_up")));
        $inc.on("click", event => {
            updateValue(config.step || 1);
        });

        if (config.model && config.property != null) {
            $stepper.val(getPropertyFromModel(config.model, config.property));
        } else if (config.value != null) {
            $stepper.val(config.value);
        }
        $stepper.css("width", $stepper.val().length + "ch");

        let commit = () => {
            $stepper.css("width", $stepper.val().length + "ch");

            saveCurrentState();
            let val = parseFloat($stepper.val());

            if (!Number.isNaN(val)) {
                if (config.min) {
                    val = Math.max(config.min, val);
                }
                if (config.max) {
                    val = Math.min(config.max, val);
                }
            } else {
                val = undefined;
            }

            if (config.model) {
                setModel(config, val);
            }
            if (config.callback) {
                config.callback(val);
            }
        };

        // check what should trigger a commit
        let { triggerEvent } = config;
        if (!triggerEvent && config.commitOnInput !== false) {
            triggerEvent = "input";
        }

        // wire up events, if needed
        if (triggerEvent) {
            $stepper.on(triggerEvent, () => commit());
        }

        $stepper.on("keydown", event => event.stopPropagation());

        $stepper.on("keypress", event => {
            event.stopPropagation();
            $stepper.css("width", ($stepper.val().length + 1) + "ch");
            if (event.which == Key.ENTER) {
                commit();
            }
        });

        // REFACTOR TODO
        // if (config.model) {
        //     view.listenTo(config.model, "change:" + config.property, (model, value) => {
        //         $stepper.val(value);
        //     });
        // }

        return $control;
    },

    createDropdownMenu: function(view, config, owner) {
        var $control = $.div("control dropdown_menu_prompt");

        if (config.dropDownStyle == "icon") {
            $control.addClass("icon_menu");
        }

        this.setEnabledState($control, config);

        if (config.label) {
            $control.append($.label(config.label));
        }
        if (config.icon) {
            $control.append($.icon(config.icon));
        }
        const $selectedItem = $.div("selected_item");

        const $itemGroup = $.span("selected_item_group");
        if (config.dropDownStyle !== "icon") {
            $itemGroup.append($.span("selected_item_label"));
        }
        $selectedItem.append($itemGroup);
        $selectedItem.append($.icon("arrow_drop_down popup_arrow"));
        $control.append($selectedItem);

        const $menu = $.div("popup_menu");
        _.each(config.items, menuItem => {
            $menu.append($.div("popup_menu_item").attr("data-value", menuItem.value).text(menuItem.label));
        });

        const setCurrentItem = val => {
            const renderItem = item => {
                if (config.dropDownStyle !== "icon") {
                    $selectedItem.find(".selected_item_label").text(item.label);
                } else {
                    // Remove the item current icon and then add the updated icon if we have one
                    $selectedItem.find(".selected_item_icon").remove();
                    if (item.icon) {
                        $itemGroup.prepend($.icon(item.icon || "", "selected_item_icon"));
                    } else if (item.image) {
                        let imageUrl = item.image;
                        if (!imageUrl.startsWith("http") && !imageUrl.startsWith("/static")) {
                            imageUrl = getStaticUrl(imageUrl);
                        }

                        $itemGroup.prepend($.img(imageUrl, "selected_item_icon"));
                    }
                }
            };

            if (config.items) {
                var item = _.find(config.items, { value: val });
                $menu.find(".popup_menu_item").removeClass("selected");
                if (item) {
                    renderItem(item);
                    $menu.find("[data-value='" + val + "']").addClass("selected");
                } else {
                    if (config.nullValue) {
                        item = _.find(config.items, { value: config.nullValue });
                        if (item) {
                            renderItem(item);
                        }
                    } else {
                        renderItem({ label: val });
                    }
                }
            } else {
                renderItem({ label: val });
            }
        };
        if (config.property) {
            setCurrentItem(getPropertyFromModel(config.model, config.property) || config.defaultValue || config.items[0].value);

            // REFACTOR TODO
            // view.listenTo(config.model, "change:" + config.property, (model, value) => setCurrentItem(value));
        } else if (config.value != null) {
            setCurrentItem(config.value);
        }

        $menu.on("mousedown", ".popup_menu_item", function(event) {
            if (config.callback) {
                config.callback($(this).data("value"));
            }
            if (config.property) {
                setModel(config, $(this).data("value"));
            }
            setCurrentItem($(this).data("value"));
            $menu.hide();
            $menu.clickShield(false);
            event.stopPropagation();
        });

        controls._createMenu($selectedItem, config, view, value => {
            config.value = value;
            setCurrentItem(value);
        });

        return $control;
    },

    createIconDropdownMenu: function(view, config, owner) {
        config.dropDownStyle = "icon";
        return this.createDropdownMenu(view, config, owner);
    },

    _createMenu: function($control, config, view, onSelect, isOpen, onClose) {
        var self = this;
        let triggerEvent = config.triggerOn || "click";
        let isAnimating;
        let activeWidth;
        let activeHeight;
        let nextLayoutCheck;

        let showMenu = async (animate, openState) => {
            PresentationEditorController.showEditorPopup(true);
            $control.addClass("control-active");

            // notify when opened, if needed
            if (config.onOpen) {
                setTimeout(config.onOpen);
            }

            if ($control.attr("disabled") == "disabled") {
                return;
            }

            $control.opacity(1);

            let $menu;

            const dispose = clearOpenMenu => {
                stopLayoutMonitor();

                $control.removeClass("control-active");
                $(document).off("keyup.menu");
                $control.opacity("");

                if (clearOpenMenu || activeConfigId !== config.id) {
                    // if (openPopupMenu && config.id == openPopupMenu.id) {
                    openPopupMenu = null;
                }
            };

            const onCloseMenu = () => {
                dispose(true);

                closeMenu($menu, config);
                config.onClose && config.onClose();
                // }
                // app.currentCanvas.saveCanvasModel(false);
                if (onClose) onClose();
            };

            const refreshLayout = () => {
                layoutMenu(calcMenuLayout());
            };

            $menu = $("body").addEl(await self._renderMenu(config, onCloseMenu, onSelect, null, refreshLayout));

            config.menuClass && $menu.addClass(config.menuClass);

            if ($control.closest(".modal_dialog").length) {
                $control.closest(".modal_dialog").after($menu);
            }

            $menu.data("parent", this);
            $menu.data("control", $control);

            $menu.one("close", onCloseMenu);
            $control.closePopupMenu = onCloseMenu;

            $(document).on("keyup.menu", event => {
                event.stopPropagation();
                if (event.which === 27) {
                    onCloseMenu();
                }
            });

            let maxLabelWidth = $(_.maxBy($menu.find(".control.toggle > label, .control.multi_toggle.singleline > label, .control.slider > label, .control.dropdown_menu_prompt > label"), e => $(e).width())).width() + 10;
            for (let label of $menu.find(".control.toggle > label, .control.positionPicker > label, .control.multi_toggle.singleline > label, .control.slider > label, .control.dropdown_menu_prompt > label, .control.numeric > label")) {
                $(label).css("min-width", maxLabelWidth);
            }

            $menu.clickShield(onCloseMenu, true);

            // stop monitoring for changes
            const stopLayoutMonitor = () => {
                cancelAnimationFrame(nextLayoutCheck);
            };

            // watches for menu size changes and forces a reflow, if needed
            const startLayoutMonitor = () => {
                // if detached
                if (activeConfigId !== config.id) {
                    cancelAnimationFrame(nextLayoutCheck);
                    dispose();
                    return;
                }

                nextLayoutCheck = requestAnimationFrame(startLayoutMonitor);

                // check if the size changed at all
                const currentWidth = $menu.width();
                const currentHeight = $menu.height();
                if (!(activeWidth === currentWidth && activeHeight === currentHeight)) {
                    // can't reflow while animating
                    if (isAnimating) {
                        return;
                    }

                    // replace the size
                    activeWidth = currentWidth;
                    activeHeight = currentHeight;

                    // confirm the target control still exists
                    if (!$control.is(":visible") || !$control.length) {
                        dispose();
                        return;
                    }

                    // recalculate the layout
                    menuLayout = calcMenuLayout();
                    layoutMenu(menuLayout, false);
                }
            };

            let calcMenuLayout = () => {
                let menuLeft, menuTop, arrowLeft, arrowTop, menuPosition;

                const offset = $control.offset();
                const cOuterWidth = $control.outerWidth();
                const mOuterWidth = $menu.outerWidth();

                menuLeft = offset.left + cOuterWidth / 2 - mOuterWidth / 2;
                // NOTE: The left nav menus have more padding than
                //   the right, so we reduce the right margin by 4
                menuLeft = Math.clamp(menuLeft, 20, window.innerWidth - 20 + 4 - mOuterWidth);

                arrowLeft = offset.left - menuLeft + cOuterWidth / 2 - 8;

                let menuOffset = 10;

                let defaultPosition = "bottom";
                if (view && view.getOffset) {
                    defaultPosition = view.getOffset().toString().contains("top") ? "top" : "bottom";
                }

                if (defaultPosition == "top" || (offset.top + $control.height() + menuOffset + $menu.outerHeight() + 50 > window.innerHeight)) {
                    // menu can't fit on bottom, lets put it on top
                    menuTop = offset.top - $menu.outerHeight() - 6;
                    if (menuTop > 20) {
                        menuPosition = "above";
                        // menu is far enough (>20px) from the top, so we'll position it normally
                        arrowTop = $menu.outerHeight() - 1;
                    } else {
                        menuPosition = "over";
                        // menu can't fit on top because top would be <20px, so try to middle align the control without an arrow
                        menuTop = offset.top + $control.height() / 2 - $menu.outerHeight() / 2;
                        if (menuTop < 20) {
                            // can't fit middle aligned because top would be <20px, so just set it to 20px
                            menuTop = 20;
                        }
                    }
                } else {
                    menuPosition = "below";
                    menuTop = offset.top + $control[0].getBoundingClientRect().height + menuOffset;
                    arrowTop = -15;
                }

                return { menuLeft, menuTop, arrowLeft, arrowTop, menuPosition };
            };

            let layoutMenu = (layout, animate) => {
                // since a menu can be updated, make sure to remove any old arrows
                $menu.find(".menu_arrow").remove();

                if (layout.menuPosition != "over") {
                    let $menuArrow = $menu.addEl($.div("menu_arrow"));
                    $menuArrow.left(layout.arrowLeft);
                    $menuArrow.top(layout.arrowTop);
                    if (layout.menuPosition == "above") {
                        $menuArrow.addClass("point-down");
                    } else {
                        $menuArrow.addClass("point-up");
                    }
                }

                $menu.addClass(layout.menuPosition);
                if (layout.menuPosition == "below") {
                    $menu.css("transform-origin", layout.arrowLeft + 10 + "px 0%");
                } else {
                    $menu.css("transform-origin", layout.arrowLeft + 10 + "px 100%");
                }

                $menu.left(layout.menuLeft);

                if (animate) {
                    isAnimating = true;

                    $menu.opacity(0);
                    _.defer(function() {
                        $menu.top(layout.menuTop);

                        $menu.velocity({
                            scale: [1, 0],
                            opacity: 1
                        }, {
                            duration: 100,
                            complete: () => isAnimating = false
                        });
                    });
                } else {
                    $menu.top(layout.menuTop);
                }

                if (config.id) {
                    openPopupMenu = {
                        id: config.id,
                        menuLeft: layout.menuLeft,
                        menuTop: layout.menuTop,
                        menuPosition: layout.menuPosition,
                        arrowLeft: layout.arrowLeft,
                        arrowTop: layout.arrowTop,
                        $menu: $menu
                    };
                }

                // save the sizing
                activeWidth = $menu.width();
                activeHeight = $menu.height();
            };

            let menuLayout;
            if (openState) {
                menuLayout = openState;
            } else {
                menuLayout = calcMenuLayout();
            }
            layoutMenu(menuLayout, animate);

            // store a ref to the open menu (unless it's a child of an already open menu)
            // if ($menu && $(this).parents(".dropdown_menu")[0] != $menu[0]) {
            activeConfigId = config.id;
            startLayoutMonitor();
            //}

            // if (config.id) {
            //     openPopupMenu = {
            //         id: config.id,
            //         menuLeft: menuLayout.menuLeft,
            //         menuTop: menuLayout.menuTop,
            //         menuPosition: menuLayout.menuPosition,
            //         arrowLeft: menuLayout.arrowLeft,
            //         arrowTop: menuLayout.arrowTop,
            //         $menu: $menu
            //     }
            // }
        };

        if (config.overwriteTriggerHandler) {
            showMenu(true);
        } else {
            $control.on(triggerEvent, function(event) {
                event.stopPropagation(); // stop click from opening presentation when clicking on presentationItem options menu
                showMenu(true);
            });
        }

        if (isOpen) {
            if (config.id == openPopupMenu.id) {
                openPopupMenu.$menu.clickShield(false);
                openPopupMenu.$menu.remove();
            }

            showMenu(false, openPopupMenu);
        }
    },

    getConfigItems: async function(config, onClose) {
        let items = config.items;
        if (items.constructor?.name === "AsyncFunction") {
            items = await items(onClose);
            config.items = items;
        } else if (typeof items == "function") {
            items = items(onClose);
            config.items = items;
        }
        return items;
    },

    _renderMenu: async function(config, onClose, onSelect, onRefresh, layoutMenu) {
        const $menu = $.div("dropdown_menu");

        const $contents = $menu.addEl($.div("menu_contents"));
        if (config.items) {
            $contents.addClass("menu-list");
            const items = await this.getConfigItems(config, onClose);

            for (let menuItem of items) {
                switch (menuItem.type) {
                    case "menu":
                        var $submenu = $contents.addEl($.div("submenu_item", menuItem.label).attr("data-label", menuItem.label));
                        if (menuItem.icon) {
                            $submenu.prepend($.icon(menuItem.icon));
                        }
                        // Preprocess the submenu items so we have them ready to
                        //   render and only have to generate them once.
                        await this.getConfigItems(menuItem.menu, () => closeMenu($menu));
                        $submenu.data("config", menuItem.menu);
                        break;
                    case "color":
                        $contents.addClass("colors");
                        if (menuItem.value == "none") {
                            let $chit = $contents.addEl($.div("color_chit none").css("color", "white").attr("data-value", "none"));
                            let $label = $chit.addEl($.div("auto", "NONE"));
                            $label.css("color", "black").attr("title", "None");
                        } else if (menuItem.value == "add_photo") {
                            $contents.addEl($.div("color_chit add_photo").attr("data-value", "add_photo"));
                        } else if (menuItem.value == "auto") {
                            let $chit = $contents.addEl($.div("color_chit").css("color", menuItem.color).attr("data-value", menuItem.value).append($.div("background").css("background", app.currentCanvas.getBackgroundColor().toRgbString())));

                            if (menuItem.color == "colorful") {
                                $chit.addClass("colorful_chit");
                                let $gradientContainer = $chit.addEl($.div("container").css("position", "absolute"));
                                for (let color of app.currentTheme.palette.getColorfulColors()) {
                                    $gradientContainer.append($.div().css("background", app.currentTheme.palette.getColor(color).toRgbString()));
                                }
                            }

                            let $label;
                            if (config.autoLabel) {
                                $label = $chit.addEl($.div("auto", config.autoLabel.toUpperCase()));
                            } else {
                                $label = $chit.addEl($.div("auto", "SLIDE"));
                            }
                            if (tinycolor(menuItem.color).isDark()) {
                                $label.css("color", "white").css("text-shadow", "0 0 1px black");
                            } else {
                                $label.css("color", "black");
                            }

                            $chit.attr("title", "Use Slide Color");
                        } else {
                            let $chit = $contents.addEl($.div("color_chit").css("color", menuItem.color).attr("data-value", menuItem.value).append($.div("background").css("background", app.currentCanvas.getBackgroundColor().toRgbString())));

                            if (menuItem.value == "theme") {
                                $chit.attr("title", "Theme Color 1");
                            } else if (menuItem.value.startsWith("accent")) {
                                $chit.attr("title", "Theme Color " + (parseInt(menuItem.value.substring(6)) + 1));
                            } else {
                                $chit.attr("title", menuItem.value.toTitleCase().replaceAll("_", " "));
                            }
                        }
                        break;
                    case "divider":
                        $contents.append("<hr/>");
                        break;
                    case "control": {
                        let menuItemView;
                        if (typeof (menuItem.view) == "function") {
                            menuItemView = menuItem.view();
                        } else {
                            menuItemView = menuItem.view;
                        }
                        const $item = $contents.addEl($.div("menu_control"));
                        $item.append(menuItemView);
                        menuItemView.closeMenu = onClose;
                        if (menuItem.icon) {
                            $item.prepend($.icon(menuItem.icon));
                        }
                    }
                        break;
                    case "info":
                        $contents.append($.div("info").append(menuItem.label));
                        break;
                    case "item":
                    default: {
                        let itemClass;
                        if (menuItem.image && config.menuClass && config.menuClass.contains("icon-menu")) {
                            itemClass = "image_menu_item";
                        } else {
                            itemClass = "menu_item";
                        }

                        const $item = $contents.addEl($.div(itemClass).data("value", menuItem.value));

                        if (menuItem.label) {
                            $item.append($.label(menuItem.label));
                        }
                        if (menuItem.callback) {
                            $item.data("callback", menuItem.callback);
                        }
                        if (menuItem.icon) {
                            $item.prepend($.icon(menuItem.icon));
                        } else if (menuItem.image) {
                            let imageUrl = menuItem.image;
                            if (!imageUrl.startsWith("http") && !imageUrl.startsWith("/static")) {
                                imageUrl = getStaticUrl(imageUrl);
                            }

                            $item.prepend($.img(imageUrl));
                        }
                        if (menuItem.beforeShow && menuItem.beforeShow() == false) {
                            $item.addClass("disabled");
                        }
                        if (menuItem.enabled === false) {
                            $item.addClass("disabled");
                        }
                        if ((config.model && config.property && menuItem.value == config.model[config.property]) || menuItem.value == config.value) {
                            $item.addClass("selected");
                        }

                        if (menuItem.shortcut) {
                            $item.append($.div("shortcut").append(menuItem.shortcut));
                        }

                        if (menuItem.attrs) {
                            $item.attr(menuItem.attrs);
                        }

                        if (menuItem.showProBadge) {
                            let $proBadge = $.div("probadge-menu-item");
                            $item.append($proBadge);

                            renderReactRoot(ProBadge, {
                                show: true,
                                analytics: { "cta": menuItem.proBadgeAnalytics },
                                workspaceId: menuItem.proBadgeWorkspaceId,
                                upgradeType: menuItem.upgradeType
                            }, $proBadge[0]);
                        }

                        if (menuItem.showBetaBadge) {
                            let $betaBadge = $.div("betabadge-menu-item");
                            $item.append($betaBadge);
                        }
                    }
                        break;
                }
            }

            // prevent 2nd context menus on top of menu
            $menu.on("contextmenu", event => {
                return false;
            });

            $menu.on("click", ".menu_item, .image_menu_item, .color_chit", function(event) {
                saveCurrentState();
                event.stopPropagation();

                let itemData = $(this).data();

                if (onSelect) {
                    onSelect(itemData.value, config.itemId);
                }

                if (itemData.callback) {
                    itemData.callback();
                }

                if (itemData.value == "add_photo" && config.imageCallback) {
                    config.imageCallback();
                    return;
                }

                if (config.property) {
                    setModel(config, itemData.value);
                }
                if (config.callback) {
                    config.callback(itemData.value, config.itemId, onClose);
                }

                onClose($menu);
            });

            $menu.on("mouseenter", ".submenu_item", async event => {
                const $submenuItem = $(event.currentTarget);

                if (!$submenuItem.data("menu") || !($submenuItem.data("menu").is(":visible"))) {  // don't recreate the submenu
                    // render the submenu from the config data stored in the submenu item
                    const config = $submenuItem.data("config");
                    const $submenu = await this._renderMenu(config, () => closeMenu($menu));
                    $submenu.addClass("submenu");
                    $submenuItem.addEl($submenu);

                    if ($submenu.offset().top + $submenu.outerHeight() > window.innerHeight) {
                        $submenu.top(window.innerHeight - ($submenu.offset().top + $submenu.outerHeight()));
                    }

                    if ($submenuItem.offset().left + $submenuItem.outerWidth() + $submenu.outerWidth() > window.innerWidth) {
                        $submenu.left(-$submenu.outerWidth()).top(0);
                    } else {
                        $submenu.left($submenuItem.outerWidth()).top(0);
                    }

                    if ($submenu.offset().top + $submenu.outerHeight() > window.innerHeight) {
                        $submenu.top(window.innerHeight - ($submenu.offset().top + $submenu.outerHeight()));
                    }

                    // store a reference to the submenu in the jquery object so we can remove it
                    $submenuItem.data("menu", $submenu);
                }
            });
            $menu.on("mouseleave", ".submenu_item", event => {
                if ($(event.currentTarget).data("menu") && $(event.target).closest(".custom").length == 0) {
                    $(event.currentTarget).data("menu").remove();
                }
            });
        } else if (config.menuContents) {
            // generate contents (if any)
            let $menu = config.menuContents.call(this, onClose, onSelect, onRefresh);

            // append the menu content if something was generated
            if ($menu) {
                $contents.addClass("custom");
                $contents.addClass(config.customMenuClass);
                $menu.layoutMenu = layoutMenu;
                $contents.append($menu);
            }
        }

        return $menu;
    },

    createOptionsMenu: function(view, config, owner) {
        var $control = $.div("options_menu_button");
        controls._createMenu($control, config, view);
        return $control;
    },

    createMenu: function(view, config, owner) {
        var $control = $.div("control menu_button");
        if (config.icon) {
            $control.append($.icon(config.icon));
        }
        if (config.label) {
            $control.append($.label(config.label));
        }
        if (config.showArrow != false) {
            $control.append($.icon("arrow_drop_down", "popup_arrow"));
        }

        controls._createMenu($control, config, view);
        return $control;
    },

    createPopupButton: function(view, config, owner, onClose) {
        var $control = $.div("control popup button noborder");

        if (config.className) {
            $control.addClass(config.className);
        }

        this.setEnabledState($control, config);

        if (config.label) {
            $control.addEl($.label(config.label));
        } else {
            $control.addClass("icon");
        }
        if (config.icon) {
            $control.prepend($.icon(config.icon));
            if (config.label) {
                $control.addClass("hasIconLabel");
            }
        }
        if (config.showArrow != false) {
            $control.append($.icon("arrow_drop_down", "popup_arrow"));
        }

        controls._createMenu($control, config, view, null, this.isMenuOpen(config.id), onClose);

        return $control;
    },

    isMenuOpen: function(configId) {
        return openPopupMenu && openPopupMenu.id == configId;
    },

    createButtonWidget: function(config) {
        let $widget = $.div("ui_widget");
        if (config.className) {
            $widget.addClass(config.className);
        }
        if (config.showLine === true) {
            $widget.append($.div("horizontal_line"));
        }
        let $button;
        if (config.icon) {
            $button = $widget.addEl($.div("icon_button button"));
            $button.append($.icon(config.icon));
        } else {
            $button = $widget.addEl($.div("button with_label", config.label));
        }

        if (config.callback) {
            $button.on("click", event => {
                event.stopPropagation();
                config.callback();
            });
        }

        return $widget;
    },

    createIconMenuWidget: function(config) {
        let $control = $.div("ui_widget");

        $control.width(22).height(22);
        // create the button label
        let $button;
        if (config.icon) {
            $button = $control.addEl($.div("icon_button button"));
            $button.append($.icon(config.icon));
        } else {
            $button = $control.addEl($.div("add_component_button button", config.label));
        }

        $button.width(18).height(18);

        config.menuClass = "icon-menu";

        if (config.items.length == 1) {
            config.menuClass += " onecol";
        }
        if (config.items.length == 2) {
            config.menuClass += " twocol";
        }

        controls._createMenu($control, config, null, null, this.isMenuOpen(config.id));

        return $control;
    },

    createPropertyMenu: function(view, config, owner) {
        var $control = $.div("control popup button noborder");
        if (config.label) {
            $control.addEl($.label(config.label));
        } else {
            $control.addClass("icon");
        }
        if (config.icon) {
            $control.prepend($.icon(config.icon));
        }
        if (config.showArrow != false) {
            $control.append($.icon("arrow_drop_down", "popup_arrow"));
        }

        config.menuContents = function(close) {
            return $.div("hello");
        };

        controls._createMenu($control, config, view);

        return $control;
    },

    createContextMenu: async function(event, config) {
        var onClose = function() {
        };
        return await this._renderMenu(config, onClose, null);
    },

    createIconSelectMenu: function(view, config, owner) {
        var $control = $.div("control icon_menu");

        let selectedIcon;
        if (config.selectedIcon) {
            if (typeof config.selectedIcon == "function") {
                selectedIcon = config.selectedIcon();
            } else {
                selectedIcon = config.selectedIcon;
            }
        } else {
            let selectedValue = config.value || config.model[config.property];
            let selectedItem = config.items.find(item => item.value == selectedValue);
            if (selectedItem) {
                selectedIcon = selectedItem.image;
            } else {
                selectedIcon = config.items[0].image;
            }
        }

        let $icon = $control.addEl($.img(getStaticUrl(selectedIcon)));

        // var selectedValue = config.selectedValue || getPropertyFromModel(config.model, config.property);
        //
        // var $icon = $control.addEl($.icon(_.find(config.items, {value: selectedValue}).icon));
        if (config.showArrow !== false) {
            $control.append($.icon("arrow_drop_down", "popup_arrow"));
        }

        controls._createMenu($control, config, view, function(value) {
            $icon.remove();
            $icon = $.img(getStaticUrl(_.find(config.items, { value: value }).image));
            $control.prepend($icon);
            if (config.model && config.property) {
                setModel(config, value);
            }
        });

        return $control;
    },

    createImageSelectMenu: function(view, config, owner) {
        var $control = $.div("control image_menu");

        this.setEnabledState($control, config);

        config.label && $control.append($.label(config.label));

        $control.data("selectedValue", config.selectedValue || getPropertyFromModel(config.model, config.property) || config.items[0].value);

        var $icon = $control.addEl($.img(_.find(config.items, { value: $control.data("selectedValue") }).image));

        if (config.showArrow !== false) {
            $control.append($.icon("arrow_drop_down", "popup_arrow"));
        }

        controls._createMenu($control, config, view, function(value) {
            $icon.remove();
            $icon = $.img(_.find(config.items, { value: value }).image);
            $control.prepend($icon);
            $control.data("selectedValue", value);
            // if (config.model && config.property) {
            //     setModel(config, value);
            // }
            // if (config.callback) {
            //     config.callback(value);
            // }
        });

        return $control;
    },

    createToggle: function(view, config, owner) {
        let $control = $.div("control toggle");

        this.setEnabledState($control, config);

        if (config.label && !config.labelAtEnd) {
            $control.append($.label(config.label));
        }

        let $toggles = $control.addEl($.div("toggle_container"));
        let $button = $toggles.addEl($.div("toggle_button"));

        $toggles.append($.div("toggle_label on", config.onLabel || "On"));
        $toggles.append($.div("toggle_label off", config.offLabel || "Off"));

        let value = true;
        if (config.property) {
            $control.attr("id", config.property);
            value = getPropertyFromModel(config.model, config.property) || false;
        } else {
            value = config.value != undefined ? config.value : config.toggled;
        }

        if (config.reverse) {
            value = !value;
        }

        if (value != null) {
            $control.toggleClass("selected", value);
        } else {
            $control.removeClass("selected");
            $control.addClass("mixed");
        }

        $toggles.on("click", async () => {
            if ($control.attr("disabled")) return;

            config.onClick && config.onClick();
            saveCurrentState();
            $control.removeClass("mixed");
            $control.find("toggle_label").show();
            $control.toggleClass("selected");
            if (config.property) {
                try {
                    if (config.model instanceof Backbone.Model) {
                        await setModel(config, !config.model.get(config.property));
                    } else {
                        await setModel(config, !config.model[config.property]);
                    }
                } catch (err) {
                    if (config.model instanceof Backbone.Model) {
                        await setModel(config, config.model.previous(config.property), { silent: true });
                    }
                    $control.toggleClass("selected");
                    logger.error(err, "ui.js setModel() failed");
                    ShowWarningDialog({
                        title: "Sorry, we were unable to make this change",
                        message: err.message,
                    });
                }
            }
            config.callback && config.callback($control.hasClass("selected"));
        });

        if (config.label && config.labelAtEnd) {
            $control.append($.label(config.label).addClass("at-end"));
        }

        return $control;
    },

    createIconToggle: function(view, config, owner) {
        let $control = $.div("control icon-toggle");

        this.setEnabledState($control, config);

        let $icon = $control.addEl($.div("icon"));
        $icon.append($.img(getStaticUrl(config.icon)));

        if (config.label) {
            $control.addEl($.label(config.label));
        }

        $control.on("click", () => {
            if ($control.attr("disabled")) return;

            saveCurrentState();
            $control.removeClass("mixed");
            $control.toggleClass("selected");

            if (config.property) {
                setModel(config, !config.model[config.property]);
            }
            config.callback && config.callback($control.hasClass("selected"));
        });

        if ((config.property && config.model[config.property]) || config.selected) {
            $control.addClass("selected");
        }

        return $control;
    },

    createMultiToggle: function(view, config, owner) {
        let $control = $.div("control multi_toggle");

        if (config.singleLine) {
            $control.addClass("singleline");
        }
        this.setEnabledState($control, config);

        if (config.label) {
            $control.append($.label(config.label));
        }

        if (!config.options) {
            config.options = [{ label: "OFF", value: false }, { label: "ON", value: true }];
        }

        let $toggles = $control.addEl($.div("toggle_container"));
        for (let option of config.options) {
            let $toggle = $toggles.addEl($.div("toggle_button" + (config.firstOptionStyledSame ? "" : " first-option-default"), option.label).attr("data-value", option.value).attr("data-disabled", option.disabled));
            if (option.icon) {
                $toggle.append($.icon(option.icon));
            }
        }

        function clearSelection() {
            $control.find(".selected").removeClass("selected");
        }

        function setSelection(value) {
            clearSelection();
            $control.find(`.toggle_button[data-value=${value}]`).addClass("selected");
        }

        if (config.property) {
            setSelection(getPropertyFromModel(config.model, config.property) || config.options[0].value);
        } else {
            setSelection(config.value || config.selectedValue || false);
        }

        $toggles.val = value => {
            setSelection(value || false);
        };

        $toggles.on("click", ".toggle_button", event => {
            saveCurrentState();
            let $target = $(event.currentTarget);
            let value = $target.attr("data-value");
            if ($target.attr("data-disabled") === "true") {
                if (config.callback) {
                    config.callback(value);
                }
                return;
            }

            clearSelection();
            $target.addClass("selected");

            if (value == "true") value = true;
            if (value == "false") value = false;

            if (config.callback) {
                config.callback(value);
            }
            if (config.property) {
                setModel(config, value);
            }
        });

        return $control;
    },

    createHoldButton: function(view, config, owner) {
        let $control = $.div("control hold_button");

        let $icon = $control.addEl($.icon(config.icon));
        let $label;
        if (config.label) {
            $label = $control.addEl($.label(config.label));
        }

        let isWarning = false;

        $control.on("mouseover", event => {
            if (config.holdLabel && $label) {
                $label.text(config.holdLabel);
            }
        });

        $control.on("mouseout", event => {
            if (isWarning) return;

            if ($label) {
                $label.text(config.label);
            }
        });

        $control.on("mousedown", event => {
            $icon.opacity(0);

            if (config.holdLabel && $label) {
                $label.text(config.holdLabel);
            }
            let circleSVG = SVG($control[0]);
            let circle = circleSVG.circle(20).cx(15).cy(15).addClass("path");

            if (config.mousedown) {
                config.mousedown();
            }

            _.defer(() => {
                $(circle.node).css("stroke-dashoffset", 0);
                $(circle.node).css("transition", "stroke-dashoffset " + (config.holdTime || 500) + "ms linear");
            });

            let timeout = setTimeout(() => {
                $control.off("mouseup.hold");
                $(circle.node).css("stroke-dashoffset", "");
                circleSVG.remove();
                $icon.opacity(1);
                clearTimeout(timeout);
                if (config.callback) {
                    config.callback();
                }
            }, config.holdTime || 500);

            let clickTime = new Date().getTime();

            $control.one("mouseup.hold", event => {
                if (new Date().getTime() - clickTime < 175) {
                    isWarning = true;
                    $label.velocity("callout.tada", {
                        complete: event => {
                            isWarning = false;
                        }
                    });
                }

                $(circle.node).css("stroke-dashoffset", "");
                circleSVG.remove();
                $icon.opacity(1);

                clearTimeout(timeout);
            });
        });

        return $control;
    },

    createOptionsList: function(view, config, owner) {
        let $control = $.div(`control options-list-icons ${config.appendClasses || ""}`);
        if (config.label) {
            $control.addEl($.label(config.label));
        }

        this.setEnabledState($control, config);

        let $items = $control.addEl($.div("option-items"));

        for (let item of config.items) {
            let $option = $.div("option-item");
            item.icon && $option.append($.icon(item.icon));
            item.img && $option.append($.img(item.img));
            item.label && $option.append($.div("option-label", item.label));
            $items.append($option);

            $option.click(() => {
                saveCurrentState();
                $control.find(".selected").removeClass("selected");
                $option.addClass("selected");
                if (config.callback) {
                    config.callback(item.value);
                }
            });

            (item.value == config.selectedValue) && $option.addClass("selected");
        }

        config.style && $control.addClass(config.style);

        return $control;
    },

    createPositionPicker: function(view, config, owner) {
        var $control = $.div("control positionPicker");
        if (config.label) {
            $control.addEl($.label(config.label).css({ marginRight: 10 }));
        }

        let $position = $control.addEl($.div("position_menu"));
        if (config.showAuto || config.excludeCorners) {
            $position.addClass("show-auto");
        }

        $position.append($.div("position_item").attr("data-value", "top_left"));
        $position.append($.div("position_item").attr("data-value", "top"));
        $position.append($.div("position_item").attr("data-value", "top_right"));
        $position.append($.div("position_item").attr("data-value", "left"));
        if (config.showAuto) {
            $position.append($.div("position_item", "AUTO").attr("data-value", "auto"));
        } else {
            $position.append($.div("position_item").attr("data-value", "center"));
        }
        $position.append($.div("position_item").attr("data-value", "right"));
        $position.append($.div("position_item").attr("data-value", "bottom_left"));
        $position.append($.div("position_item").attr("data-value", "bottom"));
        $position.append($.div("position_item").attr("data-value", "bottom_right"));

        if (config.excludeCorners) {
            $position.find(".position_item:nth-child(1),.position_item:nth-child(3),.position_item:nth-child(5),.position_item:nth-child(7), .position_item:nth-child(9)").css("visibility", "hidden");
        }

        if (config.showAuto) {
            $position.find(".position_item:nth-child(5)").css("visibility", "visible");
        }

        if (config.property) {
            $position.find(`[data-value=${getPropertyFromModel(config.model, config.property) || config.manualTextPosition || "center"}]`).addClass("selected");
        }
        if (config.value) {
            $position.find(`[data-value=${config.value}]`).addClass("selected");
        }

        $position.on("click", event => {
            saveCurrentState();
            let position = $(event.target).attr("data-value");
            $position.find(".selected").removeClass("selected");
            $(event.target).addClass("selected");
            // onSelect(position);

            if (config.property) {
                setModel(config, position);
            }
            if (config.callback) {
                config.callback(position);
            }
        });

        return $control;
    },

    createIconGrid: function(view, config) {
        let $control = $.div("control icon-grid");
        if (config.cols) {
            $control.addClass("col" + config.cols);
        }

        for (let item of config.items) {
            let $item = $.div("icon-grid-item");
            $item.append($.img(getStaticUrl(item.icon)));
            const label = $.label(item.label);
            if (config.labelClass) {
                label.addClass(config.labelClass);
            }
            $item.append(label);

            $control.append($item);
            $item.on("click", event => {
                if (config.model && config.property) {
                    setModel(config, item.value);
                } else if (config.callback) {
                    config.callback(item.value);
                }
                $control.find(".icon-grid-item").removeClass("selected");
                $item.addClass("selected");
            });

            if (config.model && config.property && item.value == config.model[config.property]) {
                $item.addClass("selected");
            } else if (config.value && item.value == config.value) {
                $item.addClass("selected");
            }

            if (getConfigValue(item, "enabled") == false) {
                $item.addClass("disabled");
            }
        }

        return $control;
    },

    createIconList: function(view, config) {
        let $control = $.div("control icon_list");
        if (config.label) {
            $control.append($.label(config.label));
        }

        let $icons = $control.addEl($.div("icons"));
        for (let item of config.items) {
            let $icon = $icons.addEl($.div("icon"));
            $icon.data("value", item.value);
            $icon.append($.img(getStaticUrl(item.src)));
            if (item.label) {
                $icon.append($.label(item.label));
            }

            if (config.model && config.property && item.value == (getPropertyFromModel(config.model, config.property))) {
                $icon.addClass("selected");
            }
        }

        $icons.on("click", ".icon", event => {
            if (config.model && config.property) {
                config.model.set(config.property, $(event.currentTarget).data("value"));
                $control.find(".selected").removeClass("selected");
                $(event.currentTarget).addClass("selected");
            }
        });

        return $control;
    },

    createControlGroup: function(view, config) {
        let $control = $.div("control control-group");

        $control.addClass(config.direction || "horizontal");

        for (let item of config.items) {
            if (item == "divider") {
                $control.append($.hr().css("width", "100%"));
            } else {
                let controlView;
                if (typeof (item) == "function") {
                    controlView = item();
                } else {
                    controlView = item;
                }
                $control.append($.div("menu_control").append(controlView));

                if (config.labelWidth) {
                    $control.find("label").css("width", config.labelWidth);
                }
            }
        }

        return $control;
    }
};

export function closeMenu($menu, config = {}) {
    // make sure we blur any active element like a input box so it's value is set when we close
    if (document.activeElement && !config.preventBlurOnMenuClose) {
        PresentationEditorController.showEditorPopup(false);
        document.activeElement.blur();
    }
    $menu.clickShield(false);
    $menu.remove();
    if ($ui_menu) {
        $ui_menu.clickShield(false);
        $ui_menu.remove();
    }

    if ($ui_menu == $menu) {
        $ui_menu = null;
    }
}

let svgIcons = {
    addPhoto: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
            id="mask0"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
            style={{
                maskType: "alpha",
            }}
        >
            <path d="M24 24H0V0H24V24Z" fill="white" />
        </mask>
        <g mask="url(#mask0)">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M3 4V1H5V4H8V6H5V9H3V6H0V4H3ZM6 10V7H9V4H16L17.83 6H21C22.1 6 23 6.9 23 8V20C23 21.1 22.1 22 21 22H5C3.9 22 3 21.1 3 20V10H6ZM13 19C15.76 19 18 16.76 18 14C18 11.24 15.76 9 13 9C10.24 9 8 11.24 8 14C8 16.76 10.24 19 13 19ZM9.8 14C9.8 15.77 11.23 17.2 13 17.2C14.77 17.2 16.2 15.77 16.2 14C16.2 12.23 14.77 10.8 13 10.8C11.23 10.8 9.8 12.23 9.8 14Z"
                fill="white" />
        </g>
    </svg>,
    addText: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 5V8H8V20H11V8H16V5H3ZM22 10H13V13H16V20H19V13H22V10Z" fill="white" />
    </svg>
};

export function createDefaultOverlayButton(svg, icon, label = "", callback) {
    return (
        <g>
            <circle r={46} fill="#50bbe6" />
            {svgIcons[icon]}
            {label && <text x="23" y="50">{label}</text>}
        </g>
    );
}

export class SvgUIButton extends React.Component {
    render() {
        let { icon, label, onClick } = this.props;
        return (
            <g>
                <circle r={46} fill="#50bbe6" onClick={onClick} />
                {svgIcons[icon]}
                {label && <text x="23" y="50">{label}</text>}
            </g>
        );
    }
}

export const Dialog = Backbone.View.extend({
    fullScreen: false,
    canClose: true,
    closeOnEscape: true,
    allowUndo: false,

    events: {
        "click .accept": "onAcceptDialog",
        "click .cancel": "onCancelDialog"
    },

    initialize: function(options) {
        if (options) {
            this.title = options.title;
            this.html = options.html;
            this.buttons = options.buttons;
            this.renderHTML = options.renderHTML;
            this.acceptCallback = options.acceptCallback;
            this.acceptOnClose = options.acceptOnClose;
            this.cancelCallback = options.cancelCallback;
            this.canClose = options.canClose == undefined ? true : options.canClose;
            this.closeOnEscape = options.closeOnEscape == undefined ? true : options.closeOnEscape;

            if (options.className) {
                this.$el.addClass(options.className);
            }
        }

        this.setupDialog(options);
        this.$el.on("click", () => {
            if (ds.selection) {
                ds.selection.element = null;
            }
        });
    },

    setupDialog: function(options) {
    },

    getTitle: function() {
        return this.title;
    },

    getHeaderHTML: function() {
        return "";
    },

    render: function() {
        this.$el.empty();
        this.$el.addClass("modal_dialog");

        this.$header = this.$el.addEl($.div("header"));
        if (this.draggable) {
            this.$el.draggable({
                handle: ".header"
            });
        }

        var title = this.getTitle();
        if (title) {
            this.$header.addEl($.div("title").text(this.getTitle()));
        }
        this.$header.addEl(this.getHeaderHTML());

        if (this.canClose) {
            var $closeButton = this.$header.addEl($.div("close_button"));
            $closeButton.on("click", () => this.onCloseDialog());
        }

        this.$contents = this.$el.addEl($.div("contents"));
        this.renderContents(this.$contents);

        this.isRendered = true;

        return this;
    },

    renderContents: function($contents) {
        if (typeof this.renderHTML === "function") {
            this.renderHTML($contents);
            $contents.append(this.buttons);
        } else if (typeof this.html === "string") {
            $contents.append(this.html);
            $contents.append(this.buttons);
        }
    },

    addTab: function(tab, selected) {
        if (!this.$tabBar) {
            this.$tabBar = this.$header.addEl($.div("tabs"));
        }

        var $tab = this.$tabBar.addEl($.div("tab", tab.getTitle()));
        $tab.attr("data-tab-id", tab.id);

        tab.parent = this;

        $tab.on("click", () => {
            this.$tabBar.find(".tab").removeClass("selected");
            $tab.addClass("selected");
            if (this.selectedTab) {
                this.selectedTab.hide();
            }
            tab.show();
            this.selectedTab = tab;
        });

        this.$contents.append(tab.render().$el);

        if (selected) {
            tab.show();
            this.selectedTab = tab;
            $tab.addClass("selected");
        } else {
            tab.hide();
        }
    },

    showTab: function(tabId) {
        var $tab = this.$tabBar.find(".tab[data-tab-id='" + tabId + "']");
        if ($tab) {
            $tab.trigger("click");
        }
    },

    renderButtons: function($contents, options) {
        var config = _.defaults(options || {}, {
            showAccept: true,
            acceptLabel: "Save",
            showCancel: true,
            cancelLabel: "Cancel"
        });

        var $buttons = $.div("buttons");

        if (config.showAccept) {
            $buttons.addEl(controls.createButton(this, {
                label: config.acceptLabel,
                callback: () => this.onAcceptDialog()
            }));
            // var $accept = $buttons.addEl($.div("control button accept").text(config.acceptLabel));
        }
        if (config.showCancel) {
            $buttons.addEl(controls.createButton(this, {
                label: config.cancelLabel,
                callback: () => this.onCancelDialog()
            }));
            // var $cancel = $buttons.addEl($.div("control button cancel").text(config.cancelLabel));
        }
        $contents.append($buttons);
    },

    show: function(options) {
        if (ds.selection) {
            ds.selection.element = null;
            ds.selection.rolloverElement = null;
        }

        options = options || { transparentClickshield: false, animate: true };
        var self = this;

        if (!this.isRendered) {
            this.render();
        }

        this.$dialogContainer = $.div("dialog_container");
        this.$dialogContainer.append(this.$el);

        if (options.$parent) {
            options.$parent.append(this.$el);
        } else {
            $("body").append(this.$dialogContainer);
        }

        this.$el.opacity(0);
        if (app.dialogManager) {
            app.dialogManager.registerDialog(this);
        }

        var offsetX, offsetY;
        if (this.fullScreen) {
            options.transparentClickshield = true;
            var padding = 0;
            this.$el.css("width", `calc(100vw - ${padding * 2}px)`);
            this.$el.css("height", `calc(100vh - ${padding * 2}px)`);
            offsetX = window.innerWidth / 2 - this.$el.width() / 2;
            offsetY = window.innerHeight / 2 - this.$el.height() / 2;
            // offsetX = 0;
            // offsetY = 0;
        } else if (options.$target) {
            switch (options.location) {
                case "above":
                    //offsetX = options.$target.offset().left + (options.$target.width() / 2 - this.$el.width() / 2);
                    offsetX = window.innerWidth / 2 - this.$el.width() / 2;
                    offsetY = options.$target.offset().top - this.$el.height();
                    offsetY = Math.max(offsetY, 0);
                    break;
                case "below":
                    //offsetX = options.$target.offset().left + (options.$target.width() / 2 - this.$el.width() / 2);
                    offsetX = window.innerWidth / 2 - this.$el.width() / 2;
                    offsetY = options.$target.offset().top + options.$target.height();
                    offsetY = Math.min(offsetY, window.innerHeight - this.$el.height());
                    break;
                case "center":
                default:
                    //offsetX = options.$target.offset().left + (options.$target.width() / 2 - this.$el.width() / 2);
                    offsetX = window.innerWidth / 2 - this.$el.width() / 2;
                    offsetY = options.$target.offset().top + (options.$target.height() / 2 - this.$el.height() / 2);
            }
        } else if (options.$parent) {
            offsetX = options.$parent.width() / 2 - this.$el.width() / 2;
            offsetY = options.$parent.height() / 2 - this.$el.height() / 2;
        } else {
            offsetX = Math.max(0, window.innerWidth / 2 - this.$el.width() / 2);
            offsetY = Math.max(0, window.innerHeight / 2 - this.$el.height() / 2);
        }
        this.$el.left(offsetX).top(offsetY);

        this.$el.show();

        $(window).on("keydown", $.proxy(this.onKeyDown, this));
        $(window).on("keypress", $.proxy(this.onKeyPress, this));

        if (options.animate) {
            this.$el.velocity("transition.slideUpBigIn", {
                duration: 400,
                complete: () => {
                    if (!this.hasLoaded) {
                        this.onLoaded();
                        this.hasLoaded = true;
                    }
                    this.trigger("shown");
                }
            });
        } else {
            this.$el.opacity(1);
            if (!this.hasLoaded) {
                this.onLoaded();
                this.hasLoaded = true;
            }
            this.trigger("shown");
        }

        // this.$el.clickShield(() => this.onCloseDialog(), options.transparentClickshield, 10010);

        if (options.transparentClickshield) {
            this.$dialogContainer.css("background", "none");
        } else {
            this.$dialogContainer.css("background", "rgba(0,0,0,.33)");
        }

        if (options.closeOnBackgroundClick !== false) {
            this.$dialogContainer.on("click", event => {
                if ($(event.target).hasClass("dialog_container")) {
                    this.onCloseDialog();
                }
            });
        }

        this.onShown();
    },

    onShown: function() {
    },

    onLoaded: function() {
    },

    close: function() {
        this.$dialogContainer.off("click");
        // this.$el.clickShield(false);
        // this.$el.velocity("transition.fadeOut", {
        this.$dialogContainer.velocity("transition.fadeOut", {
            complete: () => {
                $(window).off("keydown", $.proxy(this.onKeyDown, this));
                $(window).off("keypress", $.proxy(this.onKeyPress, this));
                $(window).off("popstate.dialog");

                if (this.isPersistent) {
                    //this.$el.velocity("transition.expandOut", {duration: 400});
                    this.$dialogContainer.hide();
                } else {
                    this.cleanUp();
                    this.$dialogContainer.remove();
                }
            }
        });

        app.dialogManager.unregisterDialog(this);
    },

    cleanUp: function() {

    },

    onKeyDown: function(event) {
        if (event.which == 27 && this.closeOnEscape && this.canClose) {
            this.close();
        }
        if (!this.reactEvents) {
            event.stopPropagation();
        }
    },

    onKeyPress: function(event) {
        event.stopPropagation();
    },

    onAcceptDialog: function() {
        if (this.acceptCallback) {
            this.acceptCallback(this.$el);
        }
        this.close();
    },

    onCancelDialog: function() {
        if (this.cancelCallback) {
            this.cancelCallback(this.$el);
        }
        this.close();
    },

    onCloseDialog: function() {
        if (this.acceptOnClose) {
            this.onAcceptDialog();
        } else {
            this.onCancelDialog();
        }
    },

    showDialog: function(title, message, callback) {
        var dialog = new Dialog({
            title: title,
            html: message,
            className: "warning_dialog",
            acceptCallback: callback,
            acceptOnClose: true
        });

        dialog.show({ $parent: this.$el });
    }
});

export function CreateColorChit(view, element = view.element, options) {
    let $colorControl = view.$controlBar.addEl($.div("control color_picker"));
    let $chit = $colorControl.addEl($.div("color_picker_chit"));

    let setChitColor = () => {
        let color = element.model.color;
        if (element.model.color == "auto" || element.model.color == null) {
            color = element.getSlideColor().toRgbString() || element.canvas.getTheme().palette.getColor(app.currentCanvas.getSlideColor()).toRgbString();
        } else {
            color = element.canvas.getTheme().palette.getColor(element.model.color).toRgbString();
        }
        $chit.css("backgroundColor", color);
    };
    setChitColor();

    if (view.getOffset().toString().contains("top")) {
        options.position = "top";
    }

    $colorControl.on("mousedown", () => {
        renderReactDialog(StylePopupMenu, {
            target: $colorControl,
            element: element,
            ...options,
            callback: () => setChitColor()
        });
    });

    return $colorControl;
}

export function parseAsTimeOfDay(input) {
    let [hours, minutes = 0] = input.split(/\:/g).map(v => parseInt(v) || 0);

    // rounding
    hours %= 24;
    minutes %= 60;

    // ensure range
    if (hours === 0) {
        hours = 12;
    }

    // check the merideim
    let merideim = "am";

    // check for forced
    if (/am/i.test(input)) {
        merideim = "am";
    }

    if (/pm/i.test(input)) {
        merideim = "pm";
    }

    // late times can override
    if (hours > 12) {
        merideim = "pm";
        hours -= 12;
    }

    // save the input value
    const time = `${hours}:${prefixZeros(minutes, 2)}`;
    return { time, hours, minutes, merideim };
}

export function parseAsDuration(str) {
    let minutes;
    let seconds;

    const toNumbersOnly = str => {
        return parseInt(str.replace(/[^0-9]/g, ""));
    };

    // check input
    let input = str.trim();

    // used seconds
    if (/\d+ ?s/i.test(input)) {
        minutes = 0;
        seconds = toNumbersOnly(input);
    } else if (/\d+ ?m/i.test(input)) {
        // used minutes
        minutes = toNumbersOnly(input);
        seconds = 0;
    } else {
        // default parsing
        [minutes, seconds = 0] = input.split(/[\: .\-]/g).map(v => parseInt(v) || 0);
    }

    // perform rounding
    minutes += Math.floor(seconds / 60);
    seconds %= 60;

    // save the input value
    const time = `${minutes || 0}:${prefixZeros(seconds, 2)}`;
    return { time, minutes, seconds };
}
