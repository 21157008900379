import { $ } from "js/vendor";
import sanitizeHtml from "sanitize-html";

// Overriding the toString() method of Range to make it respect
// line breaks
Range.prototype.toString = function() {
    const wrapperDiv = document.createElement("div");
    wrapperDiv.style.setProperty("position", "absolute");
    wrapperDiv.style.setProperty("left", "0px");
    wrapperDiv.style.setProperty("top", "0px");
    wrapperDiv.style.setProperty("display", "block");
    wrapperDiv.style.setProperty("hyphens", "manual");
    wrapperDiv.style.setProperty("overflow-wrap", "normal");
    const rangeFragment = this.cloneContents();
    wrapperDiv.append(rangeFragment);
    document.body.appendChild(wrapperDiv);
    const text = wrapperDiv.innerText;
    wrapperDiv.remove();
    return text;
};

export function getSelection() {
    const selection = window.getSelection();

    if ($(selection.anchorNode).hasClass("input-field") || $(selection.anchorNode).hasClass("MuiInputBase-root")) {
        return;
    }

    if (selection.type !== "None") {
        return selection;
    }
}

// replaces span elements with supports font elements
export function convertSpansToFontElements(el) {
    // if using a string, convert to an element
    if (typeof el === "string") {
        const str = el;
        el = document.createElement("pre");
        el.innerHTML = str;
    }

    el.querySelectorAll("span").forEach(spanElement => {
        const fontElement = document.createElement("font");
        fontElement.setAttribute("style", spanElement.getAttribute("style"));
        fontElement.replaceChildren(...spanElement.childNodes);
        spanElement.replaceWith(fontElement);
    });

    return el.innerHTML;
}

export function sanitizeHtmlText(html, include = {}) {
    include = {
        font: false,
        bold: true,
        color: true,
        ...include,
    };

    // Filter which styles we keep
    let allowedStyles = {
        "*": {
            "font-size": [/./],
            "font-family": [/./],
            "font-weight": [/./],
            "color": [/./],
        }
    };
    if (!include.font) {
        delete allowedStyles["*"]["font-size"];
        delete allowedStyles["*"]["font-family"];
    }
    if (!include.bold) {
        delete allowedStyles["*"]["font-weight"];
    }
    if (!include.color) {
        delete allowedStyles["*"]["color"];
    }

    const sanitizedHtmlText = sanitizeHtml(html, {
        allowedTags: ["b", "i", "u", "em", "strong", "sub", "sup", "strike", "a", "font", "br", "div", "span"],
        allowedAttributes: {
            "a": ["href", "target", "style", "class", "id"],
            "font": ["style", "class", "data-uid", "data-inner-text"],
            "span": ["style", "class", "contenteditable"]
        },
        // URL schemes we permit
        // Extending the default config to allow 'bai' "protocol"
        // Do not overuse to avoid security issues XXS attacks
        allowedSchemes: ["http", "https", "ftp", "mailto", "tel", "bai"],
        allowedStyles,
    })
        // By some reason self-closing br tags break contenteditable
        // when there's a blank line
        .replace(/<br\s*\/>/g, "<br>");

    // Special case for leftovers from empty lines
    if (sanitizedHtmlText === "<br>") {
        return "";
    }

    return sanitizedHtmlText;
}

// Get the URL from a string, with an optional lower flag
export function getUrlFromText(str, lower = false) {
    // Return error immediately if no string is provided or if it's not a string
    if (!str || typeof str !== "string") {
        throw new TypeError(`The str argument should be a string, got ${typeof str}`);
    }

    const regexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()'@:%_\+.~#?!&//=]*)/gi;

    const urls = str.match(regexp);
    // if urls are found return them
    // if lower is true, return urls in lowercase
    if (urls) {
        return urls.map(item => lower ? item.toLowerCase() : item);
    }

    // nothing has been found, just return null
    return null;
}

export function clearUrlsFromText(text) {
    const urls = getUrlFromText(text);

    // If no urls are found, return the text as is
    if (!urls) return text;

    urls.forEach(url => text = text.replace(url, ""));
    return text.replaceAll("  ", " "); // Clear double space
}

export function removeFormattingFromHtmlText(html, allowedTags = [], allowedAttributes = {}) {
    return sanitizeHtml(html, {
        allowedTags: ["br", ...allowedTags],
        allowedAttributes
    })
        // By some reason self-closing br tags break contenteditable
        // when there's a blank line
        .replace(/<br\s*\/>/g, "<br>");
}

export function htmlToText(html) {
    return sanitizeHtml(html, {
        allowedTags: [],
        allowedAttributes: {}
    });
}

export function getSelectionState(element) {
    const selectionState = {
        start: null,
        end: null,
        textLength: null,
        isAllSelected: false,
        isAtStart: false,
        isAtEnd: false
    };

    const selection = getSelection();
    if (!selection) {
        return selectionState;
    }

    const range = selection.getRangeAt(0);
    const selectedLength = range.toString().length;
    const preCaretRange = document.createRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);

    const preCaretRangeText = preCaretRange.toString();
    selectionState.start = preCaretRangeText.length - selectedLength;
    selectionState.end = preCaretRangeText.length;

    let elementText = element.innerText;
    if (elementText.endsWith("\n")) {
        elementText = elementText.slice(0, -1);
    }
    selectionState.textLength = elementText.length;

    if (selectionState.start === 0 && selectionState.end === elementText.length) {
        selectionState.isAllSelected = true;
    }

    if (selectionState.start === selectionState.end) {
        selectionState.isAtStart = selectionState.start === 0;

        // See if moving one step further won't change the selection text
        // to handle special cases when element inner html ends with empty divs
        // that can't be selected but affect the text length
        if (selectionState.start === elementText.length - 1) {
            selection.modify("move", "forward", "character");
            const extendedRange = selection.getRangeAt(0);
            const extendedPreCaretRange = document.createRange();
            extendedPreCaretRange.selectNodeContents(element);
            extendedPreCaretRange.setEnd(extendedRange.endContainer, extendedRange.endOffset);
            if (extendedPreCaretRange.toString() === preCaretRangeText) {
                selectionState.isAtEnd = true;
            }
            selection.modify("move", "backward", "character");
        } else if (selectionState.start === elementText.length) {
            selectionState.isAtEnd = true;
        }
    }

    return selectionState;
}

export function setSelection(selectionState, element) {
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.selectAllChildren(element);
    selection.collapseToStart();
    const { start, end } = selectionState;
    for (let i = 0; i < start; i++) {
        selection.modify("move", "forward", "character");
    }
    for (let i = 0; i < end - start; i++) {
        selection.modify("extend", "forward", "character");
    }
    return selection;
}

export function runForNodeAndAllChildNodes(parentNode, callback) {
    callback(parentNode);
    for (const child of parentNode.childNodes) {
        runForNodeAndAllChildNodes(child, callback);
    }
}
