import React, { Component, Fragment } from "react";
import { Icon } from "@material-ui/core";
import styled from "styled-components";

import { FlexBox } from "js/react/components/LayoutGrid";
import { app } from "js/namespaces";
import { LibraryViewSelect } from "js/editor/PresentationLibrary/PresentationLibrary";
import { UIPaneSideBar, UIPaneWithSideBar } from "js/react/components/UiComponents";
import { SideBarItem } from "js/editor/PresentationLibrary/PresentationLibrarySideBar";
import { Gap10 } from "js/react/components/Gap";
import { FeatureType } from "common/features";
import { UIController } from "js/editor/dialogs/UIController";
import AppController from "js/core/AppController";
import { Divider } from "js/react/components/UiComponents";
import { LibraryView } from "common/constants";

import { TeamTemplatePane } from "js/react/views/AddSlide/Panes/TeamTemplatePane";
import PresentationTemplatesPane from "./panes/PresentationTemplatesPane";
import TeamBrandingGuardrailsPane from "./panes/TeamBrandingGuardrailsPane";
import TeamAssetsPane from "./panes/TeamAssetsPane";
import TeamFoldersPane from "./panes/TeamFoldersPane";
import TeamCustomLoaderPane from "./panes/TeamCustomLoaderPane";
import TeamSharedThemePane from "./panes/TeamSharedThemePane";

export const TeamResourceTypes = {
    PRESENTATION_TEMPLATES: "presentation-templates",
    SHARED_SLIDES: "shared-slides",
    TEAM_THEMES: "themes",
    TEAM_FOLDERS: "folders",
    TEAM_BRANDING_GUARDRAILS: "branding-guardrails",
    TEAM_ASSETS: "assets",
    TEAM_CUSTOM_LOADER: "custom-loader",
    SLIDE_TEMPLATES: "slide-templates"
};

const Styler = styled.div`
    width: 100%;
    height: 100%;
    display: flex;

    .search-container {
        padding-top: 5px;
    }
`;

const UpgradeBanner = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: orangered;
    color: white;
    text-align: center;
    font-weight: bold;
`;

export default class TeamResources extends Component {
    state = {
        selectedPane: TeamResourceTypes.PRESENTATION_TEMPLATES
    }

    constructor(props) {
        super(props);

        if (props.pane) {
            this.state.selectedPane = props.pane;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.pane != prevProps.pane) {
            this.setState({ selectedPane: this.props.pane });
        }
    }

    handleSelectResourceType = type => {
        AppController.showTeamResources({ pane: type });
    }

    renderSelectedPane = () => {
        let { selectedPane } = this.state;

        switch (selectedPane) {
            case TeamResourceTypes.PRESENTATION_TEMPLATES:
                return <PresentationTemplatesPane />;
            case TeamResourceTypes.SHARED_SLIDES:
                return (
                    <TeamTemplatePane
                        title="Shared Slide"
                        isTemplate={false}
                        organizationId={AppController.orgId}
                        resourceType={TeamResourceTypes.SHARED_SLIDES}
                        trackActivity="SharedSlideLibrary"
                        isManageLibrary />
                );
            case TeamResourceTypes.SLIDE_TEMPLATES:
                return (
                    <TeamTemplatePane
                        title="Slide template"
                        isTemplate={true}
                        organizationId={AppController.orgId}
                        resourceType={TeamResourceTypes.SLIDE_TEMPLATES}
                        trackActivity="slideTemplateLibrary"
                        isManageLibrary />
                );
            case TeamResourceTypes.TEAM_THEMES:
                return <TeamSharedThemePane organizationId={AppController.orgId} />;
            case TeamResourceTypes.TEAM_FOLDERS:
                return <TeamFoldersPane />;
            case TeamResourceTypes.TEAM_BRANDING_GUARDRAILS:
                return <TeamBrandingGuardrailsPane />;
            case TeamResourceTypes.TEAM_ASSETS:
                return <TeamAssetsPane editable />;
            case TeamResourceTypes.TEAM_CUSTOM_LOADER:
                return <TeamCustomLoaderPane />;
        }
    }

    // NOTE: We have to use `className="selected"` instead of `props.selected`
    //   because a bug with `styled and` the application of `.dark-mode`
    selectedClass(filter) {
        return filter ? "selected" : "";
    }

    render() {
        let { selectedPane } = this.state;
        let { onSwitchType, sideBarWidth, onResizeSidebar } = this.props;

        let isOwner = app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM, UIController.getOrganizationId());

        return (
            <UIPaneWithSideBar>
                <UIPaneSideBar
                    style={{ width: sideBarWidth }}
                    onResize={onResizeSidebar}
                >
                    <LibraryViewSelect libraryView={LibraryView.TEAM_RESOURCES} onSwitchType={onSwitchType} />
                    <SideBarItem
                        className={this.selectedClass(selectedPane == TeamResourceTypes.PRESENTATION_TEMPLATES)}
                        onClick={() => this.handleSelectResourceType(TeamResourceTypes.PRESENTATION_TEMPLATES)}
                    >
                        <Icon>video_library</Icon>
                        Presentation Templates
                    </SideBarItem>
                    <Divider margin={0} />
                    <SideBarItem
                        className={this.selectedClass(selectedPane == TeamResourceTypes.SLIDE_TEMPLATES)}
                        onClick={() => this.handleSelectResourceType(TeamResourceTypes.SLIDE_TEMPLATES)}
                    >
                        <Icon>library_add</Icon>
                        Slide Templates
                    </SideBarItem>
                    <SideBarItem
                        className={this.selectedClass(selectedPane == TeamResourceTypes.SHARED_SLIDES)}
                        onClick={() => this.handleSelectResourceType(TeamResourceTypes.SHARED_SLIDES)}
                    >
                        <Icon>library_books</Icon>
                        Shared Slides
                    </SideBarItem>
                    <Divider margin={0} />
                    <SideBarItem
                        className={this.selectedClass(selectedPane == TeamResourceTypes.TEAM_THEMES)}
                        onClick={() => this.handleSelectResourceType(TeamResourceTypes.TEAM_THEMES)}
                    >
                        <Icon>color_lens</Icon>
                        Themes
                    </SideBarItem>
                    <SideBarItem
                        className={this.selectedClass(selectedPane == TeamResourceTypes.TEAM_ASSETS)}
                        onClick={() => this.handleSelectResourceType(TeamResourceTypes.TEAM_ASSETS)}
                    >
                        <Icon>photo_library</Icon>
                        Assets
                    </SideBarItem>
                    <Divider margin={0} />
                    <SideBarItem
                        className={this.selectedClass(selectedPane == TeamResourceTypes.TEAM_CUSTOM_LOADER)}
                        onClick={() => this.handleSelectResourceType(TeamResourceTypes.TEAM_CUSTOM_LOADER)}
                    >
                        <Icon>play_circle_outline</Icon>
                        Player Settings
                    </SideBarItem>
                    <SideBarItem
                        className={this.selectedClass(selectedPane == TeamResourceTypes.TEAM_BRANDING_GUARDRAILS)}
                        onClick={() => this.handleSelectResourceType(TeamResourceTypes.TEAM_BRANDING_GUARDRAILS)}
                    >
                        <Icon>lock</Icon>
                        Brand Guardrails
                    </SideBarItem>
                    {isOwner &&
                        <Fragment>
                            <Divider margin={0} />
                            <SideBarItem
                                className={this.selectedClass(selectedPane == TeamResourceTypes.TEAM_FOLDERS)}
                                onClick={() => this.handleSelectResourceType(TeamResourceTypes.TEAM_FOLDERS)}
                            >
                                <Icon>folder</Icon>
                                Team Folders
                            </SideBarItem>
                        </Fragment>
                    }
                </UIPaneSideBar>

                <FlexBox fill vertical top>
                    <Styler>
                        <Gap10 />
                        {this.renderSelectedPane()}
                    </Styler>
                </FlexBox>
            </UIPaneWithSideBar>
        );
    }
}
