import React, { Component } from "react";
import { Button, DialogActions, DialogTitle } from "@material-ui/core";
import { FlexSpacer } from "js/react/components/Gap";
import { BeautifulDialog, DialogContent } from "js/react/components/Dialogs/BaseDialog";
import styled from "styled-components";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { Key } from "js/core/utilities/keys";

const TextBox = styled.textarea`
  width: calc(100% - 10px);
  height: 70vh;
  resize: none;
  background: black;
  color: white;
  line-height: 1.3;
  font-size: 15px;
`;

export class CustomStyleSheetDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            styles: this.props.theme.get("customStyles") || ""
        };

        this.textRef = React.createRef();
    }

    handleKeyDown = event => {
        switch (event.which) {
            case Key.KEY_S:
                if (event.metaKey) {
                    event.preventDefault();
                    this.handleSave();
                }
                break;
        }
    }

    handleChange = event => {
        this.setState({ styles: event.target.value });
    }

    handleSave = () => {
        this.props.theme.update({
            customStyles: this.state.styles
        });
        this.props.closeDialog();

        app.themeManager.switchTheme(this.props.theme);

        app.themeManager.loadTheme(ds.selection.presentation).then(() => {
            ds.selection.element = null;
            app.currentCanvas.getCanvasElement().markStylesAsDirty();
            app.currentCanvas.refreshCanvas();
        });
    }

    render() {
        let { styles } = this.state;

        return (
            <BeautifulDialog maxWidth="lg" closeDialog={this.props.closeDialog}>
                <DialogTitle>
                    Custom Style Sheet
                </DialogTitle>
                <DialogContent>
                    <TextBox ref={this.textRef} onKeyDown={this.handleKeyDown} onChange={this.handleChange} value={styles} />
                </DialogContent>
                <DialogActions>
                    <FlexSpacer />
                    <Button onClick={this.props.closeDialog}>Cancel</Button>
                    <Button onClick={this.handleSave}>Save Changes</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}

