import React, { Component } from "react";
import styled from "styled-components";
import { BeautifulDialog } from "js/react/components/Dialogs/BaseDialog";
import { PresentationLibraryPane } from "js/editor/PresentationLibrary/PresentationLibrary";
import { ds } from "js/core/models/dataService";

const PresentationLibraryContainer = styled.div`
  display: grid;
  height: 100%;
`;

const StyledDialog = styled(BeautifulDialog)`
    &&& {
        .MuiDialog-paper {
            height: 100% !important;
        }
    }
`;

const StyledPresentationLibraryPane = styled(PresentationLibraryPane)`
    &&& {
        overflow: auto;
        height: 100%;
    }
`;

export class PresentationLibraryDialog extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { onSelectedPresentation, closeDialog, maxWidth = false } = this.props;

        return (
            <StyledDialog onClose={closeDialog} maxWidth={maxWidth}>
                <PresentationLibraryContainer>
                    <StyledPresentationLibraryPane
                        workspaceId={ds.selection.presentation.getWorkspaceId()}
                        collection={ds.presentations}
                        readOnly={true}
                        showCloseIcon={true}
                        onSelectedPresentation={presentation => {
                            onSelectedPresentation(presentation);
                            closeDialog();
                        }}
                        onCloseDialog={closeDialog}
                    />
                </PresentationLibraryContainer>
            </StyledDialog>
        );
    }
}
