import { Backbone, _ } from "js/vendor";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { UndoType, TextFocusType, DEFAULT_PROGRESS_DURATION_MS } from "common/constants";
import getLogger, { LogGroup } from "js/core/logger";
import ErrorHandler from "js/core/utilities/errorHandler";
import { ShowConfirmationDialog, ShowDialog, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import NotificationsService from "js/core/services/notifications";
import PresentationEditorController, { PanelType } from "js/editor/PresentationEditor/PresentationEditorController";
import { computeChangeSet } from "common/utils/changeset";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";

const logger = getLogger(LogGroup.UNDO);

/**
 * Base class for an undo-able command.
 * Note - This needs to always pull for the object it is modifying instead of maintaining internal reference to the
 * object. This will allow commands to work in a state where the original object may no longer be the same original
 * object.
 */
class Command {
    /**
     *
     * @param {string} objectId - The object id of the slide/presentation to modify with this command.
     * @param oldState - the original value from a changeset call or the original object state.
     * @param newState - the update value from a changeset call or the update object state.
     */
    constructor(objectId, oldState, newState, options = {}) {
        this.objectId = objectId;
        this.oldState = _.cloneDeep(oldState);
        this.newState = _.cloneDeep(newState);
        this.options = options;
        this.presentationId = ds.selection.presentation?.id;
    }

    getSlide() {
        return this.getPresentation().slides.get(this.objectId);
    }

    getPresentation() {
        return ds.presentations.get(this.presentationId);
    }

    getTargetSlideIndex() {
        return this.targetSlideIndex ?? null;
    }

    undo() {
        throw new Error("Command.undo not implemented");
    }

    redo() {
        throw new Error("Command.undo not implemented");
    }
}

/**
 * A command for modifying slide data.
 */
class ModifySlideCommand extends Command {
    async _update({ attributes, selectedElementUniquePath, focusedBlockId, focusedBlockSelectionState }) {
        const slide = this.getSlide();
        if (!slide) {
            return handleError(removeUndoStackCommands);
        }

        if (PresentationEditorController.currentSlide !== slide) {
            await PresentationEditorController.setCurrentSlide(slide);
        }

        const canvasController = PresentationEditorController.getCurrentCanvasController();

        ds.selection.element = null;

        const updateSlide = async (silent, isRetry = false) => {
            slide.update(attributes, { removeMissingKeys: true, silent });
            try {
                await slide.updatePromise;
            } catch (err) {
                if (err.status === 409 && !isRetry) {
                    // Just retry on 409s, the slide adater will get the in sync
                    // automatically
                    return updateSlide(silent, true);
                }
                throw err;
            }
        };

        if (attributes.version !== slide.get("version") || attributes.template_id !== slide.get("template_id")) {
            await updateSlide(true);
            await canvasController.reloadCanvas();
            return;
        }

        if (this.options.clearLayout) {
            canvasController.canvas.layouter.clear();
        }
        await updateSlide(false);

        if (!selectedElementUniquePath) {
            return;
        }

        const element = canvasController.canvas.getElementByUniquePath(selectedElementUniquePath);
        if (!element) {
            return;
        }

        if (ds.selection.element !== element) {
            ds.selection.element = element;
        }

        if (!focusedBlockId) {
            return;
        }

        ds.selection.element.overlay?.focusBlock(
            focusedBlockId,
            TextFocusType.POSITION,
            focusedBlockSelectionState?.start ?? 0,
            (focusedBlockSelectionState?.end ?? 0) - (focusedBlockSelectionState?.start ?? 0)
        );

        // Ugly but ensures we let block editor do its work before finalizing undo()
        await new Promise(resolve => _.defer(() => resolve()));
    }

    undo() {
        return this._update(this.oldState);
    }

    redo() {
        return this._update(this.newState);
    }

    toString() {
        return "ModifySlideCommand:" + this.objectId;
    }
}

/**
 * A command for modifying presentation data.
 */
class ModifySlideOrderCommand extends Command {
    undo() {
        const presentation = this.getPresentation();
        presentation.update({ slideRefs: this.oldState.slideRefs });
        presentation.trigger("slideorder");

        this.oldState.movedSlides.forEach(slideId => {
            NotificationsService.notifyOnSlideMoved(this.presentationId, slideId)
                .catch(err => logger.error(err, "[ModifySlideOrderCommand] NotificationsService.notifyOnSlideMoved() failed", { presentationId: this.presentationId, slideId }));
        });

        return Promise.resolve();
    }

    redo() {
        const presentation = this.getPresentation();
        presentation.update({ slideRefs: this.newState.slideRefs });
        presentation.trigger("slideorder");

        this.newState.movedSlides.forEach(slideId => {
            NotificationsService.notifyOnSlideMoved(this.presentationId, slideId)
                .catch(err => logger.error(err, "[ModifySlideOrderCommand] NotificationsService.notifyOnSlideMoved() failed", { presentationId: this.presentationId, slideId }));
        });

        return Promise.resolve();
    }

    toString() {
        return "ModifySlideOrderCommand:" + this.presentationId;
    }
}

/**
 * A command for deleting a slide.
 */
class DeleteSlideCommand extends Command {
    undo() {
        return createSlide(this, this.oldState)
            .then(targetSlideIndex => {
                this.targetSlideIndex = targetSlideIndex;
            });
    }

    redo() {
        return destroySlide(this, this.oldState)
            .then(targetSlideIndex => {
                this.targetSlideIndex = targetSlideIndex;
            });
    }

    toString() {
        return "DeleteSlideCommand:" + this.objectId;
    }
}

/**
 * A command for creating a slide.
 */
class CreateSlideCommand extends Command {
    undo() {
        return destroySlide(this, this.newState)
            .then(targetSlideIndex => {
                this.targetSlideIndex = targetSlideIndex;
            });
    }

    redo() {
        return createSlide(this, this.newState)
            .then(targetSlideIndex => {
                this.targetSlideIndex = targetSlideIndex;
            });
    }

    toString() {
        return "CreateSlideCommand:" + this.objectId;
    }
}

/**
 * A command that is a collection of many commands.
 */
class CommandGroup {
    constructor(commands) {
        this.commands = commands;
    }

    undo() {
        let chain = Promise.resolve();
        for (let i = this.commands.length - 1; i >= 0; i--) {
            chain = chain.then(() => this.commands[i].undo());
        }
        return chain;
    }

    redo() {
        let chain = Promise.resolve();
        for (let i = 0; i < this.commands.length; i++) {
            chain = chain.then(() => this.commands[i].redo());
        }
        return chain;
    }

    getTargetSlideIndex(forRedo = false) {
        let targetSlideIndex = null;
        if (forRedo) {
            for (let i = 0; i < this.commands.length; i++) {
                targetSlideIndex = this.commands[i].getTargetSlideIndex() ?? targetSlideIndex;
            }
        } else {
            for (let i = this.commands.length - 1; i >= 0; i--) {
                targetSlideIndex = this.commands[i].getTargetSlideIndex() ?? targetSlideIndex;
            }
        }
        return targetSlideIndex;
    }

    toString() {
        return "CommandGroup:\n  " + this.commands.join("\n  ");
    }

    get oldState() {
        return this.commands[0]?.oldState;
    }

    get newState() {
        return this.commands[0]?.newState;
    }
}

async function removeUndoStackCommands() {
    app.undoManager.undoStack = app.undoManager.undoStack.filter(command => {
        if (command instanceof ModifySlideCommand) {
            return command.getSlide();
        }
    });
    app.undoManager.stackPosition = app.undoManager.undoStack.length - 1;
    app.undoManager.trigger("undoStackChanged", app.undoManager.stackPosition);
}

async function reloadPage() {
    location.reload();
}

async function handleError(callback) {
    await ShowErrorDialog({
        title: "We can't make that change",
        message: "The item you tried to edit may no longer exist. We've made the latest available update instead.",
        onClose: () => {
            return callback();
        },
        acceptOnClose: true
    });
}

function createSlide(command, state) {
    const presentation = command.getPresentation();
    const slideState = _.cloneDeep(state.slideData);
    slideState.id = command.objectId;
    return presentation.addExistingSlides(slideState, state.index, { silent: true, skipUndo: true })
        .then(slides => presentation.getSlideIndex(slides[0].id))
        .catch(err => ErrorHandler.handleSlideLimitReached(err, { workspaceId: ds.selection.presentation.getWorkspaceId() }));
}

function destroySlide(command, state) {
    const presentation = command.getPresentation();
    const prevSlideIndex = state.index === 0 ? 1 : state.index - 1;
    const slide = command.getSlide();
    if (!slide) {
        return handleError(removeUndoStackCommands);
    }
    return presentation.destroySlides(slide.id, { silent: true, skipUndo: true })
        .then(() => prevSlideIndex);
}

/**
 * The public facing undo manager that handles keeping track of the undo/redo stack and simplifies how commands are created.
 */
class UndoManager {
    constructor() {
        _.extend(this, Backbone.Events);

        this.undoStack = [];
        this.stackPosition = -1;
        this.undoGroupIsOpen = false;
        this.enabled = true;
        this.isUndoing = false;
    }

    openGroup() {
        if (this.undoGroupIsOpen) {
            logger.warn("Trying to open group, but undo group is already open");
            return handleError(reloadPage);
        } else {
            this.undoGroupIsOpen = true;
            this.undoGroup = [];
        }
    }

    closeGroup() {
        if (!this.undoGroupIsOpen) {
            logger.warn("Trying to close group, but no undo group is open");
            return handleError(reloadPage);
        } else {
            this.undoGroupIsOpen = false;
            if (this.undoGroup.length > 0) {
                this.pushCommand(new CommandGroup(this.undoGroup));
            }
        }
    }

    pushCommand(command) {
        const oldCopy = _.cloneDeep(command.oldState) || {};
        const newCopy = _.cloneDeep(command.newState) || {};

        delete oldCopy.focusedBlockId;
        delete oldCopy.focusedBlockSelectionState;
        delete newCopy.focusedBlockId;
        delete newCopy.focusedBlockSelectionState;

        // Skip if there is no data update
        const changeSet = computeChangeSet(oldCopy, newCopy, true);
        if (!changeSet.hasUpdates) {
            return;
        }

        if (this.undoGroupIsOpen) {
            this.undoGroup.push(command);
        } else {
            this.undoStack = _.dropRight(this.undoStack, this.undoStack.length - this.stackPosition - 1);
            this.undoStack.push(command);
            this.stackPosition = this.undoStack.length - 1;
        }

        this.trigger("undoStackChanged", this.stackPosition);
    }

    set(type, objectId, oldState, newState, options = {}) {
        let command;
        switch (type) {
            case UndoType.SLIDE_DATA:
                command = new ModifySlideCommand(objectId, oldState, newState, options);
                break;
            case UndoType.DELETE_SLIDE:
                command = new DeleteSlideCommand(objectId, oldState, newState, options);
                break;
            case UndoType.ADD_SLIDE:
                command = new CreateSlideCommand(objectId, oldState, newState, options);
                break;
            case UndoType.SLIDE_ORDER:
                command = new ModifySlideOrderCommand(objectId, oldState, newState, options);
                break;
            default:
                logger.error(new Error(`Unsupported undo type ${type}`), `Unsupported undo type ${type}`);
                return;
        }
        this.pushCommand(command);
    }

    isCreateOrDeleteCommand(command) {
        return command instanceof DeleteSlideCommand || command instanceof CreateSlideCommand;
    }

    shouldUpdateSlideRefs(command) {
        if (this.isCreateOrDeleteCommand(command)) {
            return true;
        } else if (command instanceof CommandGroup) {
            return command.commands.some(cmd => this.isCreateOrDeleteCommand(cmd));
        } else {
            return false;
        }
    }

    async _getCommandAndCheckIfCanExecute(stackPosition) {
        if (!this.enabled) {
            return null;
        }

        const command = this.undoStack[stackPosition];
        if (!command) {
            return null;
        }

        if (command instanceof ModifySlideCommand) {
            const canvasController = PresentationEditorController.getCurrentCanvasController();
            if (!canvasController || !canvasController.canvas) {
                return null;
            }

            const slide = command.getSlide();
            if (!slide) {
                return null;
            }

            // Special case to allow undoing when chart panel is open (for current slide)
            const isChartEditorOpen =
                PresentationEditorController.activePanel === PanelType.ELEMENT &&
                PresentationEditorController.elementPanelView?.constructor?.__super__?.type === "ChartPanel" &&
                slide.id === canvasController.slide.id;

            if (!canvasController.canvas.isEditable && !isChartEditorOpen) {
                return null;
            }

            if (command.oldState.attributes.version < command.newState.attributes.version) {
                if (!(await ShowConfirmationDialog({
                    title: "Revert this slide to an older version?",
                    message: "You may notice unexpected differences in your slide and some features may no longer be available.",
                    okButtonLabel: "Continue"
                }))) {
                    return null;
                }
            }
        }

        return command;
    }

    async _undo() {
        const command = await this._getCommandAndCheckIfCanExecute(this.stackPosition);
        if (!command) {
            return false;
        }

        this.stackPosition--;

        await command.undo();

        this.trigger("undo", command);

        const presentation = ds.selection.presentation;

        if (this.shouldUpdateSlideRefs(command)) {
            await presentation.onSlideRefChange(presentation, presentation.get("slideRefs"));
        }

        const targetSlideIndex = command.getTargetSlideIndex(false);
        if (targetSlideIndex) {
            await PresentationEditorController.setCurrentSlideByIndex(targetSlideIndex);
        }

        return true;
    }

    async _redo() {
        const command = await this._getCommandAndCheckIfCanExecute(this.stackPosition + 1);
        if (!command) {
            return false;
        }

        this.stackPosition++;

        await command.redo();

        this.trigger("redo", command);

        const presentation = ds.selection.presentation;

        if (this.shouldUpdateSlideRefs(command)) {
            await presentation.onSlideRefChange(presentation, presentation.get("slideRefs"));
        }

        const targetSlideIndex = command.getTargetSlideIndex(true);
        if (targetSlideIndex) {
            await PresentationEditorController.setCurrentSlideByIndex(targetSlideIndex);
        }

        return true;
    }

    undo() {
        this.isUndoing = true;
        let progressDialog = null;
        const timeoutId = setTimeout(() => {
            progressDialog = ShowDialog(ProgressDialog);
        }, DEFAULT_PROGRESS_DURATION_MS);

        return this._undo().then(data => {
            clearTimeout(timeoutId);
            if (progressDialog) {
                progressDialog.close();
            }
            this.isUndoing = false;
            return data;
        });
    }

    redo() {
        this.isUndoing = true;
        let progressDialog = null;
        const timeoutId = setTimeout(() => {
            progressDialog = ShowDialog(ProgressDialog);
        }, DEFAULT_PROGRESS_DURATION_MS);

        return this._redo().then(data => {
            clearTimeout(timeoutId);
            if (progressDialog) {
                progressDialog.close();
            }
            this.isUndoing = false;
            return data;
        });
    }

    reset() {
        this.undoStack = [];
        this.stackPosition = -1;
        this.trigger("reset");
    }
}

export default UndoManager;
