import React from "react";
import styled from "styled-components";

import { Button } from "@material-ui/core";
import { KeyboardArrowLeft as KeyboardArrowLeftIcon, Info as InfoIcon } from "@material-ui/icons";

import { app } from "js/namespaces";
import { FeatureType } from "js/core/models/features";
import { AiProvider } from "common/aiConstants";
import { getStaticUrl } from "js/config";
import { themeColors } from "js/react/sharedStyles";
import AppController from "js/core/AppController";
import { openPricingPage } from "js/core/utilities/externalLinks";
import { ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import { BlueButton, UIPane } from "js/react/components/UiComponents";
import { Notice } from "js/react/components/Notice";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import { FlexSpacer, Gap20 } from "js/react/components/Gap";
import { PopupMenu } from "js/react/components/PopupMenu";

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 0px;
    background: ${themeColors.lightGray};
    display: flex;
    flex-direction: column;
    font-family: 'Source Sans Pro';
    font-style: normal;
`;

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px 40px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const FooterContainer = styled.div`
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FooterCreditsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    > span {
        color: ${themeColors.darkGray};
        font-size: 12px;
        font-family: Source Sans Pro;
        font-weight: 600;
        line-height: 150%;
    }
`;

const CreditsInfoButton = styled(InfoIcon)`
    &&& {
        color: ${themeColors.ui_blue};
        height: 18px;
        width: 18px;
        cursor: pointer;
    }
`;

const DialogMessageContainer = styled.div`
    > p:first-child {
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.5px;
        color: #222222;
    }

    > p {
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: 0.5px;
    }
`;

const PoweredByContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    line-height: 150%;
    font-size: 15px;

    > div {
        font-weight: 600;
        color: ${themeColors.darkGray};

        display: flex;
        align-items: center;

        > img {
            height: 18px;
            width: 18px;
            margin-right: 10px;
        }

        > a {
            text-decoration: none;
        }
    }

    > div:nth-child(2) {
        font-weight: 400;
        font-size: 12px;
    }
`;

const BackButtonContainer = styled(Button)`
    &&& {
        color: ${themeColors.mediumGray};

        svg {
            width: 30px;
            height: 30px;
        }
    }
`;

function BackButton({ onClick, disabled }: { onClick: React.MouseEventHandler<HTMLButtonElement>, disabled: boolean }) {
    return (<BackButtonContainer
        onClick={onClick}
        disabled={disabled}
    >
        <KeyboardArrowLeftIcon /> Back
    </BackButtonContainer>);
}

function PoweredBy({ provider }: { provider: AiProvider }) {
    return (
        <PoweredByContainer>
            {provider === AiProvider.ANTHROPIC && <>
                <div>Powered by Anthropic</div>
                <div>Content generated may be incorrect, inappropriate, or incomplete. Please use at your own risk and be sure to verify accurate results.</div>
            </>}
            {provider === AiProvider.OPENAI && <div>
                <img src={getStaticUrl("/images/ai-search/dall-e-logo.png")} />
                <a href="https://openai.com/" target="_blank">Powered by OpenAI</a>
            </div>}
        </PoweredByContainer>
    );
}

interface PaneFooterProps {
    aiCreditsBalance?: number;
    showBackButton?: boolean;
    backButtonDisabled?: boolean;
    provider?: AiProvider;
    onBackButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function PaneFooter({
    aiCreditsBalance = undefined,
    showBackButton = false,
    backButtonDisabled = false,
    provider = AiProvider.ANTHROPIC,
    onBackButtonClick = () => { },
}: PaneFooterProps) {
    const handleShowGetCreditsDialog = () => {
        const workspaceId = AppController.workspaceId;

        ShowConfirmationDialog({
            title: "Generate more slides and presentations with more credits.",
            message: <DialogMessageContainer>
                <p>Use credits to create images based on your prompts. To purchase additional credits for you and your
                    team, contact us.</p>
                {workspaceId === "personal" && <p>You have {aiCreditsBalance} credits remaining.</p>}
                {workspaceId !== "personal" && <p>Your team has {aiCreditsBalance} credits remaining.</p>}
            </DialogMessageContainer>,
            okButtonLabel: "Contact Us",
            cancelButtonLabel: "Never mind",
            acceptCallback: () => window.open("mailto:support@beautiful.ai")
        });
    };

    return (<FooterContainer>
        {/* Left side */}
        {showBackButton && <BackButton disabled={backButtonDisabled} onClick={onBackButtonClick} />}
        {!showBackButton && <PoweredBy provider={provider} />}

        {/* @ts-ignore */}
        <FlexSpacer />

        {/* Right side */}
        {aiCreditsBalance !== undefined && <FooterCreditsContainer>
            {aiCreditsBalance === -1 && <span>Free while in beta</span>}
            {aiCreditsBalance !== -1 && <>
                <span>{aiCreditsBalance} Credits Available</span>
                <CreditsInfoButton onClick={handleShowGetCreditsDialog} />
            </>}
        </FooterCreditsContainer>}
        <Gap20 />
    </FooterContainer>);
}

interface PaneContainerProps {
    cta: string;
    children: React.ReactNode;
    fetching?: boolean;
}

export function PaneContainer({
    children,
    cta,
    fetching = false
}: PaneContainerProps) {
    const hasDesignedBotFeature = AppController.user?.features.isFeatureEnabled(FeatureType.DESIGNER_BOT, AppController.workspaceId) ?? true;
    const designerBotDisabled = AppController.user?.features.isFeatureEnabled(FeatureType.PROHIBIT_GENERATIVE_AI, AppController.workspaceId) ?? false;

    let Children = children;

    if (!hasDesignedBotFeature || designerBotDisabled) {
        Children = (
            <Notice
                title="Let DesignerBot generate custom slides and presentations."
                message={<>
                    <div>Upgrade to Beautiful.ai Pro to use AI to generate content for you.</div>
                    <BlueButton
                        // @ts-ignore
                        style={{ marginBottom: 0 }}
                        onClick={() => openPricingPage(app.user.analyticsPersonalPlan, { cta })}
                    >
                        Get Started
                    </BlueButton>
                </>}
            />
        );
    }

    return (<UIPane>
        <Container>
            <FetchingClickShield visible={fetching} backgroundColor={themeColors.lightGray} />
            {Children}
        </Container>
    </UIPane>);
}

export const PaneContent = React.forwardRef<HTMLDivElement, { children: React.ReactNode }>(
    function PaneContent({ children }: { children: React.ReactNode }, ref: React.Ref<HTMLDivElement>) {
        return (<ContentContainer ref={ref}>
            {children}
        </ContentContainer>);
    }
);

export const AddContextPopupMenu = styled(PopupMenu)`
    flex-shrink: 0;
    height: 50px;
  button {
    background: #e1e1e1;
    padding: 10px 16px;
    border: solid 1px #ccc;
  }

  label {
    font-size: 20px;
    font-weight: bold;
  }

  .drop-down-arrow {
    font-size: 24px !important;
  }
`;

export const ContextSourceContainer = styled.div`
    display: flex;
    flex-grow: 2;
    gap: 10px;
    width: 100%;
    background: white;
    border: solid 1px #ccc;
    padding: 20px 10px 20px 20px;
`;

export const ContextNote = styled.div`
    display: flex;
    gap: 10px;
    height: 50px;
    align-items: center;
    font-size: 14px;
    background: ${themeColors.selection};
    padding: 12px 16px;
`;

export const ContextBillingNote = styled.div`
    font-size: 12px;
    color: #444;
`;
