import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/analytics";

let firebaseExport = firebase;
if (window.firebase) {
    // mock for tests
    firebaseExport = window.firebase;
} else {
    // debug
    window.firebase = firebaseExport;
}

export default firebaseExport;
