import React from "react";

import { app } from "js/namespaces";
import { FeatureType } from "js/core/models/features";
import PaymentIssueDialog from "js/react/views/UserOptions/Billing/PaymentIssueDialog";
import WorkspaceBlockedDialog from "js/react/views/UserOptions/Billing/WorkspaceBlockedDialog";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import Api from "js/core/api";
import { reactMount, reactUnmount } from "js/react/renderReactRoot";
import { SubscriptionStatus } from "common/constants";
import WorkspaceLockedDialog from "js/react/views/UserOptions/Billing/WorkspaceLockedDialog";

let workspaceBlockedDialogContainer;
async function checkPastDue(workspaceId) {
    if (workspaceBlockedDialogContainer) {
        reactUnmount(workspaceBlockedDialogContainer);
        workspaceBlockedDialogContainer.remove();
    }

    const organizationId = workspaceId !== "personal" ? workspaceId : undefined;
    const canManageTeam = organizationId && app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM, workspaceId);

    if (!organizationId) {
        const personalSubscriptionStatus = app.user.attributes.subscriptionStatus;
        const personalSubscriptionPastDue = personalSubscriptionStatus === undefined || personalSubscriptionStatus === SubscriptionStatus.PAST_DUE;
        if (!personalSubscriptionPastDue) {
            return false;
        }
    }

    const { isPastDue, isEnterprise, subscription, charge, invoice, forceLockWorkspace, contactEmail, isCanceled, modifiedAt } = await Api.pastDue.get({ organizationId });

    if (isEnterprise && (forceLockWorkspace || isPastDue)) {
        workspaceBlockedDialogContainer = document.createElement("div");
        workspaceBlockedDialogContainer.id = "workspace-blocked-dialog-container";
        document.body.append(workspaceBlockedDialogContainer);
        reactMount(
            <WorkspaceBlockedDialog
                invoice={invoice}
                subscription={subscription}
                canManageTeam={canManageTeam}
                isPastDue={isPastDue}
                forceLockWorkspace={forceLockWorkspace}
                contactEmail={contactEmail}
            />,
            workspaceBlockedDialogContainer,
            false
        );
        return true;
    }

    // if account is canceled, but not an enterprise account, show the dialog
    if (isCanceled) {
        workspaceBlockedDialogContainer = document.createElement("div");
        workspaceBlockedDialogContainer.id = "workspace-blocked-dialog-container";
        document.body.append(workspaceBlockedDialogContainer);
        reactMount(
            <WorkspaceLockedDialog
                organizationId={organizationId}
                subscription={subscription}
                canManageTeam={canManageTeam}
                forceLockWorkspace={forceLockWorkspace}
                contactEmail={contactEmail}
                modifiedAt={modifiedAt}
            />,
            workspaceBlockedDialogContainer,
            false
        );
        return true;
    }

    if ((canManageTeam || workspaceId === "personal") && isPastDue) {
        return new Promise(resolve => {
            ShowDialog(PaymentIssueDialog, {
                orgId: organizationId,
                subscription,
                charge,
                callback: success => resolve(!success)
            });
        });
    }
}

export default checkPastDue;
