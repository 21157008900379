import React from "react";
import { Redirect } from "react-router-dom";

import { withFirebaseAuth } from "js/react/views/Auth/FirebaseAuthContext";
import { LoadingPage } from "js/react/components/LoadingPage";
import ErrorMessage from "js/app/ErrorMessage";
import { serverContext } from "js/config";
import getLogger, { LogGroup } from "js/core/logger";
import { RemoveSplashScreen } from "js/editor/SplashScreen";

const logger = getLogger(LogGroup.AUTH);

class SsoConsume extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: serverContext.customToken ? "pending" : "failed"
        };
    }

    async componentDidMount() {
        RemoveSplashScreen(true);

        const { auth } = this.props;
        try {
            await signOutIfNeeded(auth);
            logger.info("Signing in with custom token");
            await auth.signInWithCustomToken(serverContext.customToken);
            this.setState({ status: "succeeded" });
        } catch (err) {
            this.setState({ status: "failed" });
        }
    }

    render() {
        switch (this.state.status) {
            case "pending":
                return (
                    <LoadingPage />
                );
            case "succeeded":
                return (
                    <Redirect to={serverContext.relayState || "/"} />
                );
            case "failed":
                return (
                    <ErrorMessage message={"Sorry, we could not authenticate you."} />
                );
        }
    }
}

async function signOutIfNeeded(auth) {
    // sign out of firebase
    if (auth.currentUser) {
        logger.info("Will sign out of firebase");
        await auth.signOut();
        logger.info("Did sign out of firebase");
    }
}

export default withFirebaseAuth(SsoConsume);
