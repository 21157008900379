import { app } from "js/namespaces";
import { $, _ } from "js/vendor";
import { HorizontalAlignType, PositionType, VerticalAlignType } from "common/constants";

export const AnchorType = {
    FREE: "anchor-free",
    CENTER: "anchor-center",
    TOP: "anchor-top",
    LEFT: "anchor-left",
    RIGHT: "anchor-right",
    BOTTOM: "anchor-bottom",
    TOP_LEFT: "anchor-top-left",
    TOP_RIGHT: "anchor-top-right",
    BOTTOM_LEFT: "anchor-bottom-left",
    BOTTOM_RIGHT: "anchor-bottom-right"
};

export class Point {
    constructor(x, y) {
        if (typeof (x) == "object") {
            y = x.y;
            x = x.x;
        }
        this.x = x;
        this.y = y;
    }

    equals({ x, y }) {
        return this.x === x && this.y === y;
    }

    clone() {
        return new Point(this.x, this.y);
    }

    offset(x, y) {
        if (x instanceof Point) {
            return new Point(this.x + x.x, this.y + x.y);
        } else {
            return new Point(this.x + x, this.y + y);
        }
    }

    offsetAngle(angle, rx, ry) {
        if (!ry) {
            ry = rx;
        }
        angle = angle * Math.PI / 180;
        return new Point(this.x + rx * Math.cos(angle), this.y + ry * Math.sin(angle));
    }

    offsetAngleRadians(angle, rx, ry) {
        if (!ry) {
            ry = rx;
        }
        return new Point(this.x + rx * Math.cos(angle), this.y + ry * Math.sin(angle));
    }

    plus(x, y) {
        return this.offset(x, y);
    }

    minus(x, y) {
        if (x instanceof Point) {
            return new Point(this.x - x.x, this.y - x.y);
        } else {
            return new Point(this.x - x, this.y - y);
        }
    }

    delta(x, y) {
        if (x instanceof Point) {
            return new Point(x.x - this.x, x.y - this.y);
        }
        return new Point(x - this.x, y - this.y);
    }

    scale(scale) {
        return new Point(this.x * scale, this.y * scale);
    }

    multiply(scale) {
        return this.scale(scale);
    }

    magnitude() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    normalize() {
        let length = this.magnitude();
        return this.scale(1 / length);
    }

    toString() {
        return "Point{x: " + this.x + " y: " + this.y + "}";
    }

    toCSSString() {
        return this.x + "px," + this.y + "px";
    }

    toArray() {
        return [this.x, this.y];
    }

    toObject() {
        return { x: this.x, y: this.y };
    }

    toCSSObject() {
        return { left: this.x, top: this.y };
    }

    angle() {
        return Math.atan2(this.y, this.x);
    }

    min(other) {
        return new Point(Math.min(other.x, this.x), Math.min(other.y, this.y));
    }

    max(other) {
        return new Point(Math.max(other.x, this.x), Math.max(other.y, this.y));
    }

    lerp(end, ratio) {
        return new Point((end.x - this.x) * ratio + this.x, (end.y - this.y) * ratio + this.y);
    }

    distance(end) {
        const x = end.x - this.x;
        const y = end.y - this.y;
        return Math.sqrt(x * x + y * y);
    }

    rotate(angle, originPt) {
        let radians = angle * Math.PI / 180;
        let s = Math.sin(radians);
        let c = Math.cos(radians);

        let px = this.x - originPt.x;
        let py = this.y - originPt.y;

        return new Point(px * c - py * s + originPt.x, px * s + py * c + originPt.y);
    }

    rotateByArcLength(length, radius, center) {
        let arc = length / (radius * Math.PI / 180);
        let pointAngle = center.angleToPoint(this);
        return Point.PointFromAngle(radius, pointAngle + arc, center);
    }

    rotate90CW() {
        return new Point(
            -this.y,
            this.x,
        );
    }

    rotate90CCW() {
        return new Point(
            this.y,
            -this.x,
        );
    }

    angleToPoint(pt) {
        let angle = Math.atan2(pt.y - this.y, pt.x - this.x) * 180 / Math.PI;
        if (angle < 0) {
            return 360 + angle;
        } else {
            return angle;
        }
    }

    constrain(bounds) {
        let x = this.x;
        let y = this.y;
        if (x < bounds.left) x = bounds.left;
        if (x > bounds.right) x = bounds.right;
        if (y < bounds.top) y = bounds.top;
        if (y > bounds.bottom) y = bounds.bottom;
        return new Point(x, y);
    }

    static PointFromAngle(radius, angle, center) {
        angle = DegreesToRadians(angle);
        return new Point(center.x + radius * Math.cos(angle), center.y + radius * Math.sin(angle));
    }
}

export class Line {
    constructor(start, end) {
        this.start = start;
        this.end = end;
    }

    get length() {
        return this.start.distance(this.end);
    }

    get midpoint() {
        return new Point(this.start.x + (this.end.x - this.start.x) / 2, this.start.y + (this.end.y - this.start.y) / 2);
    }

    get angle() {
        return Math.atan2(this.end.y - this.start.y, this.end.x - this.start.x);
    }

    get bounds() {
        return new Rect(this.start, this.end);
    }

    isPointOnLine(point, threshold = 0) {
        // Checking if the point is within the bounds of the line inflated
        // by the threshold
        if (!this.bounds.inflate(threshold).contains(point)) {
            return false;
        }

        // If the point is inside the bounds, then calculate the minimal
        // distance from the point to the line and comapare it with the thereshold
        const a = this.length;
        const b = this.start.distance(point);
        const c = this.end.distance(point);
        const p = (a + b + c) / 2;
        const distance = 2 * Math.sqrt(p * (p - a) * (p - b) * (p - c)) / a;
        return distance <= threshold;
    }

    intersection(line) {
        let x1 = this.start.x;
        let y1 = this.start.y;
        let x2 = this.end.x;
        let y2 = this.end.y;
        let x3 = line.start.x;
        let y3 = line.start.y;
        let x4 = line.end.x;
        let y4 = line.end.y;

        var ua, ub, denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
        if (denom == 0) {
            return null;
        }
        ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom;
        ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom;
        return {
            intersectPt: new Point(x1 + ua * (x2 - x1), y1 + ua * (y2 - y1)),
            seg1: ua >= 0 && ua <= 1,
            seg2: ub >= 0 && ub <= 1
        };
    }

    isVertical() {
        return this.start.x == this.end.x;
    }

    isHorizontal() {
        return this.start.y == this.end.y;
    }

    toArray() {
        return [this.start.toArray(), this.end.toArray()];
    }

    multiply(scale) {
        return new Line(this.start.multiply(scale), this.end.multiply(scale));
    }
}

export class Size {
    constructor(width, height) {
        this.width = width;
        this.height = height;
    }

    toString() {
        return "Size{width: " + this.width + " height: " + this.height + "}";
    }

    toArray() {
        return [this.width, this.height];
    }

    clone() {
        return new Size(this.width, this.height);
    }

    square() {
        var size = Math.min(this.width, this.height);
        return new Size(size, size);
    }

    inflate(padding) {
        return this.addPadding(padding);
    }

    deflate(padding) {
        return this.removePadding(padding);
    }

    addPadding(padding) {
        var left, right, top, bottom;
        if (isNaN(padding)) {
            left = padding.left || 0;
            right = padding.right || 0;
            top = padding.top || 0;
            bottom = padding.bottom || 0;
        } else {
            left = right = top = bottom = padding;
        }
        return new Size(this.width + left + right, this.height + top + bottom);
    }

    removePadding(padding) {
        var left, right, top, bottom;
        if (isNaN(padding)) {
            left = padding.left || 0;
            right = padding.right || 0;
            top = padding.top || 0;
            bottom = padding.bottom || 0;
        } else {
            left = right = top = bottom = padding;
        }
        return new Size(this.width - left - right, this.height - top - bottom);
    }

    equals(target) {
        return target.width == this.width && target.height == this.height;
    }

    get aspectRatio() {
        if (this.height == 0) return 1;
        return this.width / this.height;
    }

    area() {
        return this.width * this.height;
    }

    scale(scale) {
        return new Size(this.width * scale, this.height * scale);
    }
}

export class Rect {
    get bottom() {
        return this.top + this.height;
    }

    get right() {
        return this.left + this.width;
    }

    set right(value) {
        this.width = value - this.left;
    }

    set bottom(value) {
        this.height = value - this.top;
    }

    get centerH() {
        return this.left + this.width / 2;
    }

    get centerV() {
        return this.top + this.height / 2;
    }

    get size() {
        return new Size(this.width, this.height);
    }

    get position() {
        return new Point(this.left, this.top);
    }

    get center() {
        return new Point(this.centerH, this.centerV);
    }

    get x() {
        return this.left;
    }

    get y() {
        return this.top;
    }

    get aspectRatio() {
        return this.size.aspectRatio;
    }

    constructor(left, top, width, height) {
        if (left instanceof Point) {
            this.left = left.x;
            this.top = left.y;
            if (top instanceof Size) {
                this.width = top.width;
                this.height = top.height;
            } else if (top instanceof Point) {
                this.left = Math.min(left.x, top.x);
                this.top = Math.min(left.y, top.y);
                this.width = Math.abs(top.x - left.x);
                this.height = Math.abs(top.y - left.y);
            } else {
                this.width = parseFloat(top);
                this.height = parseFloat(width);
            }
        } else if (left instanceof Object) {
            this.top = left.top || left.y;
            this.left = left.left || left.x;
            this.width = left.width;
            this.height = left.height;
        } else {
            this.top = parseFloat(top);
            this.left = parseFloat(left);

            if (width instanceof Size) {
                this.width = width.width;
                this.height = width.height;
            } else {
                this.width = parseFloat(width);
                this.height = parseFloat(height);
            }
        }
    }

    toString() {
        return "Rect{left: " + this.left + " top:" + this.top + " width: " + this.width + " height: " + this.height + "}";
    }

    toObject() {
        return {
            left: this.left,
            top: this.top,
            width: this.width,
            height: this.height
        };
    }

    toXYObject() {
        return {
            x: this.left,
            y: this.top,
            width: this.width,
            height: this.height
        };
    }

    toSVG() {
        return {
            style: {
                transform: `translateX(${this.left}px) translateY(${this.top}px)`
            },
            width: this.width,
            height: this.height
        };
    }

    toArray() {
        return [this.left, this.top, this.right, this.bottom];
    }

    getSize() {
        return new Size(this.width, this.height);
    }

    equals(rect) {
        if (!rect) return false;
        return this.left == rect.left && this.top == rect.top && this.width == rect.width && this.height == rect.height;
    }

    alignInContainer(container, hAlign = HorizontalAlignType.CENTER, vAlign = VerticalAlignType.MIDDLE) {
        let x, y;

        switch (hAlign) {
            case HorizontalAlignType.LEFT:
                x = container.left;
                break;
            case HorizontalAlignType.CENTER:
                x = container.centerH - this.width / 2;
                break;
            case HorizontalAlignType.RIGHT:
                x = container.right - this.width;
                break;
        }

        switch (vAlign) {
            case VerticalAlignType.TOP:
                y = container.top;
                break;
            case VerticalAlignType.MIDDLE:
                y = container.centerV - this.height / 2;
                break;
            case VerticalAlignType.BOTTOM:
                y = container.bottom - this.height;
                break;
        }

        return new Rect(x, y, this.width, this.height);
    }

    positionInContainer(container, position) {
        switch (position) {
            case PositionType.FILL:
                return container;
            case PositionType.LEFT:
                return this.alignInContainer(container, HorizontalAlignType.LEFT, VerticalAlignType.MIDDLE);
            case PositionType.TOP_LEFT:
                return this.alignInContainer(container, HorizontalAlignType.LEFT, VerticalAlignType.TOP);
            case PositionType.BOTTOM_LEFT:
                return this.alignInContainer(container, HorizontalAlignType.LEFT, VerticalAlignType.BOTTOM);
            case PositionType.TOP:
                return this.alignInContainer(container, HorizontalAlignType.CENTER, VerticalAlignType.TOP);
            case PositionType.BOTTOM:
                return this.alignInContainer(container, HorizontalAlignType.CENTER, VerticalAlignType.BOTTOM);
            case PositionType.RIGHT:
                return this.alignInContainer(container, HorizontalAlignType.RIGHT, VerticalAlignType.MIDDLE);
            case PositionType.TOP_RIGHT:
                return this.alignInContainer(container, HorizontalAlignType.RIGHT, VerticalAlignType.TOP);
            case PositionType.BOTTOM_RIGHT:
                return this.alignInContainer(container, HorizontalAlignType.RIGHT, VerticalAlignType.BOTTOM);
            case PositionType.CENTER:
            default:
                return this.alignInContainer(container, HorizontalAlignType.CENTER, VerticalAlignType.MIDDLE);
        }
    }

    offset(x, y) {
        if (x.top) {
            y = x.top;
            x = x.left;
        }
        if (x instanceof Point) {
            y = x.y;
            x = x.x;
        }
        return new Rect(this.left + x, this.top + y, this.width, this.height);
    }

    setPosition(x, y) {
        if (x.top) {
            y = x.top;
            x = x.left;
        }
        if (x instanceof Point) {
            y = x.y;
            x = x.x;
        }
        return new Rect(x, y, this.width, this.height);
    }

    multiply(scale) {
        return new Rect(this.left * scale, this.top * scale, this.width * scale, this.height * scale);
    }

    scaleSize(scale) {
        return new Rect(this.left, this.top, this.width * scale, this.height * scale);
    }

    scale(scale) {
        return new Rect(this.left + (this.width - this.width * scale) / 2, this.top + (this.height - this.height * scale) / 2, this.width * scale, this.height * scale);
    }

    shrink(scale) {
        return new Rect(this.left + this.width * scale / 2, this.top + this.height * scale / 2, this.width - this.width * scale, this.height - this.height * scale);
    }

    union(rect) {
        if (!rect) return this.clone();
        if (rect.width == 0 && rect.height == 0) {
            return this.clone();
        }
        return new Rect(Math.min(this.left, rect.left), Math.min(this.top, rect.top), Math.max(this.right, rect.right) - Math.min(this.left, rect.left), Math.max(this.bottom, rect.bottom) - Math.min(this.top, rect.top));
    }

    clone() {
        let rect = new Rect(this.left, this.top, this.width, this.height);
        rect.rotate = this.rotate;
        return rect;
    }

    inflate(padding) {
        var left, right, top, bottom;
        if (isNaN(padding)) {
            left = padding.left || 0;
            right = padding.right || 0;
            top = padding.top || 0;
            bottom = padding.bottom || 0;
        } else {
            left = right = top = bottom = padding;
        }
        return new Rect(this.left - left, this.top - top, this.width + left + right, this.height + top + bottom);
    }

    addPadding(padding) {
        var left, right, top, bottom;
        if (isNaN(padding)) {
            left = padding.left || 0;
            right = padding.right || 0;
            top = padding.top || 0;
            bottom = padding.bottom || 0;
        } else {
            left = right = top = bottom = padding;
        }
        return new Rect(this.left - left, this.top - top, this.width + left + right, this.height + top + bottom);
    }

    removePadding(padding) {
        var left, right, top, bottom;
        if (isNaN(padding)) {
            left = padding.left || 0;
            right = padding.right || 0;
            top = padding.top || 0;
            bottom = padding.bottom || 0;
        } else {
            left = right = top = bottom = padding;
        }
        return new Rect(this.left + left, this.top + top, this.width - left - right, this.height - top - bottom);
    }

    deflate(options) {
        var left, right, top, bottom;
        if (isNaN(options)) {
            left = options.left || 0;
            right = options.right || 0;
            top = options.top || 0;
            bottom = options.bottom || 0;
        } else {
            left = right = top = bottom = options;
        }
        return new Rect(this.left + left, this.top + top, this.width - left - right, this.height - top - bottom);
    }

    expand(options) {
        var left = options.left || 0;
        var right = options.right || 0;
        var top = options.top || 0;
        var bottom = options.bottom || 0;
        return new Rect(this.left - left, this.top + -top, this.width + left + right, this.height + top + bottom);
    }

    intersects(rect, omitEdges) {
        if (!rect) return false;
        if (omitEdges) {
            if (rect.left > this.right - 1 || this.left + 1 > rect.right) return false;
            if (rect.top > this.bottom - 1 || this.top + 1 > rect.bottom) return false;
        } else {
            if (rect.left > this.right || this.left > rect.right) return false;
            if (rect.top > this.bottom || this.top > rect.bottom) return false;
        }
        return true;
    }

    intersection(rect) {
        var left = Math.max(this.left, rect.left);
        var top = Math.max(this.top, rect.top);
        var bottom = Math.min(this.bottom, rect.bottom);
        var right = Math.min(this.right, rect.right);
        return new Rect(left, top, right - left, bottom - top);
    }

    contains(x, y) {
        if (x instanceof Rect) {
            return this.left <= x.left && this.top <= x.top && this.right >= x.right && this.bottom >= x.bottom;
        } else if (x instanceof Point) {
            return this.left <= x.x && this.right >= x.x && this.top <= x.y && this.bottom >= x.y;
        } else {
            return this.left <= x && this.right >= x && this.top <= y && this.bottom >= y;
        }
    }

    intersectsLine(x1, y1, x2, y2) {
        let intersections = {
            left: false,
            top: false,
            bottom: false,
            right: false,
            any: false
        };

        if ((this.right < x1 && this.right < x2) || (this.left > x1 && this.left > x2) || (this.bottom < y1 && this.bottom < y2) || (this.top > y1 && this.top > y2)) {
            return intersections;
        }

        // check sides
        let leftIntersection = new Point(this.left, y2 + (this.left - x2) / (x1 - x2) * (y1 - y2));
        intersections.left = leftIntersection.y >= this.top && leftIntersection.y <= this.bottom;

        let rightIntersection = new Point(this.right, y2 + (this.right - x2) / (x1 - x2) * (y1 - y2));
        intersections.right = rightIntersection.y >= this.top && rightIntersection.y <= this.bottom;

        let topIntersection = new Point(x2 + (this.top - y2) / (y1 - y2) * (x1 - x2), this.top);
        intersections.top = topIntersection.x >= this.left && topIntersection.x <= this.right;

        let bottomIntersection = new Point(x2 + (this.bottom - y2) / (y1 - y2) * (x1 - x2), this.bottom);
        intersections.bottom = bottomIntersection.x >= this.left && bottomIntersection.x <= this.right;

        intersections.any = intersections.left || intersections.top || intersections.right || intersections.bottom;

        return intersections;
    }

    area() {
        if (this.width > 0 && this.height > 0) {
            return this.width * this.height;
        } else {
            return 0;
        }
    }

    distanceToPoint(point, position = AnchorType.CENTER) {
        const center = this.getPoint(position);
        return Math.sqrt(Math.pow(point.x - center.x, 2) + Math.pow(point.y - center.y, 2));
    }

    distanceFromBorderToPoint(point) {
        if (point.x < this.left) {
            if (point.y < this.top) {
                return this.distanceToPoint(point, AnchorType.TOP_LEFT);
            }
            if (point.y > this.bottom) {
                return this.distanceToPoint(point, AnchorType.BOTTOM_LEFT);
            }
            return this.left - point.x;
        }

        if (point.x > this.right) {
            if (point.y < this.top) {
                return this.distanceToPoint(point, AnchorType.TOP_RIGHT);
            }
            if (point.y > this.bottom) {
                return this.distanceToPoint(point, AnchorType.BOTTOM_RIGHT);
            }
            return point.x - this.right;
        }

        if (point.y < this.top) {
            return this.top - point.y;
        }
        if (point.y > this.bottom) {
            return point.y - this.bottom;
        }

        // Inside bounds
        return 0;
    }

    overlaps(rect) {
        return this.contains(rect.left, rect.top) && this.contains(rect.left, rect.bottom) && this.contains(rect.right, rect.top) && this.contains(rect.right, rect.bottom);
    }

    reduce(options) {
        return new Rect(this.left, this.top, this.width - options.left - options.right, this.height - options.top - options.bottom);
    }

    zeroOffset() {
        return new Rect(0, 0, this.width, this.height);
    }

    square(useLargestDimension = false) {
        var size;
        if (useLargestDimension) {
            size = Math.max(this.width, this.height);
        } else {
            size = Math.min(this.width, this.height);
        }
        return new Rect(this.left, this.top, size, size);
    }

    fitToSize(size) {
        let scale = Math.min(size.width / this.width, size.height / this.height);
        return this.scale(scale);
    }

    fitInRect(parentRect) {
        let fittedRect = this.clone();
        if (fittedRect.left < parentRect.left) {
            fittedRect = fittedRect.offset(parentRect.left - fittedRect.left, 0);
        }
        if (fittedRect.right > parentRect.right) {
            fittedRect = fittedRect.offset(parentRect.right - fittedRect.right, 0);
        }
        if (fittedRect.top < parentRect.top) {
            fittedRect = fittedRect.offset(0, parentRect.top - fittedRect.top);
        }
        if (fittedRect.bottom > parentRect.bottom) {
            fittedRect = fittedRect.offset(0, parentRect.bottom - fittedRect.bottom);
        }
        return fittedRect;
    }

    resize(size) {
        return new Rect(this.left, this.top, size.width, size.height);
    }

    getPoint(type) {
        switch (type) {
            case "center":
            case AnchorType.CENTER:
            case AnchorType.FREE:
                return new Point(this.centerH, this.centerV);
            case "top-center":
            case AnchorType.TOP:
                return new Point(this.centerH, this.top);
            case "top-right":
            case AnchorType.TOP_RIGHT:
                return new Point(this.right, this.top);
            case "middle-left":
            case AnchorType.LEFT:
                return new Point(this.left, this.centerV);
            case "middle-right":
            case AnchorType.RIGHT:
                return new Point(this.right, this.centerV);
            case "bottom-left":
            case AnchorType.BOTTOM_LEFT:
                return new Point(this.left, this.bottom);
            case "bottom-center":
            case AnchorType.BOTTOM:
                return new Point(this.centerH, this.bottom);
            case "bottom-right":
            case AnchorType.BOTTOM_RIGHT:
                return new Point(this.right, this.bottom);
            case "top-left":
            case AnchorType.TOP_LEFT:
            default:
                return new Point(this.left, this.top);
        }
    }

    /**
     * Offsets the rect so it's horizontally and vertically centered relative to another rect
     */
    centerInRect(container) {
        return new Rect(container.centerH - this.width / 2, container.centerV - this.height / 2, this.width, this.height);
    }

    /**
     * Subtracts a rect from this rectangle and returns the largest remaining rect.
     * @param rect to subtract
     */
    largestRemainingRect(rect) {
        const leftWidth = rect.left - this.left;
        const rightWidth = this.right - rect.right;
        const topHeight = rect.top - this.top;
        const bottomHeight = this.bottom - rect.bottom;

        const leftWidthArea = leftWidth * this.height;
        const rightWidthArea = rightWidth * this.height;
        const topHeightArea = topHeight * this.width;
        const bottomHeightArea = bottomHeight * this.width;

        if (leftWidthArea > rightWidthArea && leftWidthArea > topHeightArea && leftWidthArea > bottomHeightArea) {
            return new Rect(this.left, this.top, leftWidth, this.height);
        } else if (rightWidthArea > topHeightArea && rightWidthArea > bottomHeightArea) {
            return new Rect(rect.right, this.top, rightWidth, this.height);
        } else if (topHeightArea > bottomHeightArea) {
            return new Rect(this.left, this.top, this.width, topHeight);
        } else {
            return new Rect(this.left, rect.bottom, this.width, bottomHeight);
        }
    }

    /**
     * Create an array of rects that are equally spaced cells based on number of rows and columns.
     * @param rows
     * @param columns
     * @returns {Array} - the array is in row column order, ie:
     * rows = 2
     * columns = 3
     * [(row1:col1), (row1:col2), (row1:col3), (row2:col1), (row2:col2), (row2:col3)]
     */
    createGrid(rows, columns) {
        const grid = [];
        rows = rows || 1;
        columns = columns || 1;
        for (let column = 0; column < columns; column++) {
            for (let row = 0; row < rows; row++) {
                grid.push(new Rect(this.left + this.width * row / rows, this.top + this.height * column / columns, this.width / rows, this.height / columns));
            }
        }
        return grid;
    }

    /**
     * Normalizes the rect if it has negative width or height
     */
    normalize() {
        const normalizedRect = this.clone();
        if (normalizedRect.width < 0) {
            normalizedRect.left += normalizedRect.width;
            normalizedRect.width = Math.abs(normalizedRect.width);
        }
        if (normalizedRect.height < 0) {
            normalizedRect.top += normalizedRect.height;
            normalizedRect.height = Math.abs(normalizedRect.height);
        }
        return normalizedRect;
    }

    static FromBBox(bbox) {
        return new Rect(bbox.x, bbox.y, bbox.width, bbox.height);
    }

    static FromBoundingClientRect(rect) {
        return new Rect(rect.left, rect.top, rect.width, rect.height);
    }

    static FromObject(rect) {
        return new Rect(rect.left, rect.top, rect.width, rect.height);
    }
}

export function getCenteredRect(innerRect, outerRect) {
    if (innerRect instanceof Size) {
        innerRect = new Rect(0, 0, innerRect);
    }
    if (outerRect instanceof Size) {
        outerRect = new Rect(0, 0, outerRect);
    }
    return innerRect.centerInRect(outerRect);
}

export function getRectFromPoints(point1, point2) {
    let left = Math.min(point1.x, point2.x);
    let top = Math.min(point1.y, point2.y);
    let right = Math.max(point1.x, point2.x);
    let bottom = Math.max(point1.y, point2.y);
    return new Rect(left, top, right - left, bottom - top);
}

export function fitImageToRect(source, target) {
    var scaleH = target.width / source.width;
    var scaleV = target.height / source.height;
    return Math.min(scaleH, scaleV);
}

export function fillRect(source, target) {
    var scaleH = target.width / source.width;
    var scaleV = target.height / source.height;
    return Math.max(scaleH, scaleV);
}

export function centerImageInRect(image, rect) {
    var offsetX = (rect.width - image.width) / 2;
    var offsetY = (rect.height - image.height) / 2;
    return new Point(offsetX, offsetY);
}

export function fitRectInCircle(diameter, w, h) {
    let hypotenuse = Math.sqrt((w * w) + (h * h));
    let multiple = diameter / hypotenuse;
    return new Size(w * multiple, h * multiple);
}

export function adjustBoundsForStroke(bounds, style) {
    if (style["stroke-width"] && style["stroke-width"] > 0) {
        var strokeWidth = style["stroke-width"];
        return bounds.deflate({
            left: strokeWidth / 2,
            top: strokeWidth / 2,
            width: strokeWidth,
            height: strokeWidth
        });
    }
}

export function isAbsoluteValue(value) {
    return value.indexOf("%") == -1;
}

export function isRelativeValue(value) {
    return value.indexOf("%") != -1;
}

export function RadiansToDegrees(radians) {
    return radians * 180 / Math.PI;
}

export function DegreesToRadians(degrees) {
    return degrees * Math.PI / 180;
}

export function getCSSTransform(transform) {
    if (transform.hasOwnProperty("x")) {
        transform.translateX = transform.x;
    }
    if (transform.hasOwnProperty("y")) {
        transform.translateY = transform.y;
    }

    let css = `translateX(${transform.translateX}px) translateY(${transform.translateY}px)`;

    if (transform.scale) {
        css += ` scale(${transform.scale})`;
    }
    if (transform.scaleX) {
        css += ` scaleX(${transform.scaleX})`;
    }
    if (transform.scaleY) {
        css += ` scaleY(${transform.scaleY})`;
    }
    if (transform.rotate && transform.rotate != 0) {
        css += ` rotate(${transform.rotate}deg)`;
    }

    if (transform.rotateZ && transform.rotateZ != 0) {
        css += ` rotateZ(${transform.rotateZ}deg)`;
    }

    if (transform.skew && transform.skew != 0) {
        css += ` skew(${transform.skew}deg)`;
    }
    return css;
}

export function fitStyleSizeConstaints(bounds, style, parentBounds) {
    var constrainedBounds = bounds.clone();

    if (style["min-width"]) {
        constrainedBounds.width = Math.max(constrainedBounds.width, getStyleValue(style["min-width"], parentBounds.width));
    }
    if (style["max-width"]) {
        constrainedBounds.width = Math.min(constrainedBounds.width, getStyleValue(style["max-width"], parentBounds.width));
    }
    if (style["min-height"]) {
        constrainedBounds.height = Math.max(constrainedBounds.height, getStyleValue(style["min-height"], parentBounds.height));
    }
    if (style["max-height"]) {
        constrainedBounds.height = Math.min(constrainedBounds.height, getStyleValue(style["max-height"], parentBounds.height));
    }

    return constrainedBounds;
}

export function getWidthStyleValue(style, bounds) {
    var value = getStyleValue(style["width"] || "100%", bounds);

    if (style["min-width"]) {
        var min = getStyleValue(style["min-width"], bounds);
        value = Math.max(value, min);
    }
    if (style["max-width"]) {
        var max = getStyleValue(style["max-width"], bounds);
        value = Math.min(value, max);
    }

    return value;
}

export function getHeightStyleValue(style, bounds) {
    var value = getStyleValue(style["height"] || "100%", bounds);

    if (style["min-height"]) {
        var min = getStyleValue(style["min-height"], bounds);
        value = Math.max(value, min);
    }
    if (style["max-height"]) {
        var max = getStyleValue(style["max-height"], bounds);
        value = Math.min(value, max);
    }

    return value;
}

export function getStyleValue(value, bounds) {
    if (value == "fit") return bounds;
    if (value == "fill") return 10;

    if (typeof value == "number") return value;
    if (value == undefined || value == null) {
        return 0;
    }

    var val = parseInt(value.replace(/%|px/, ""));
    if (isAbsoluteValue(value)) {
        return val;
    } else {
        return val / 100 * bounds;
    }
}

export function getPadding(style, containerWidth, containerHeight) {
    var padding = {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
    };

    if (style["padding"]) {
        if (typeof style["padding"] == "number") {
            padding.left = padding.right = padding.top = padding.bottom = style["padding"];
        } else {
            var vals = style["padding"].split(" ");
            if (vals.length == 1) {
                padding.left = padding.right = getStyleValue(vals[0], containerWidth);
                padding.top = padding.bottom = getStyleValue(vals[0], containerHeight);
            } else if (vals.length == 2) {
                padding.left = padding.right = getStyleValue(vals[0], containerWidth);
                padding.top = padding.bottom = getStyleValue(vals[1], containerHeight);
            } else if (vals.length == 3) {
                padding.left = getStyleValue(vals[0], containerWidth);
                padding.top = padding.bottom = getStyleValue(vals[1], containerHeight);
                padding.right = getStyleValue(vals[2], containerWidth);
            } else {
                padding.left = getStyleValue(vals[0], containerWidth);
                padding.top = getStyleValue(vals[1], containerHeight);
                padding.right = getStyleValue(vals[2], containerWidth);
                padding.bottom = getStyleValue(vals[3], containerHeight);
            }
        }
    }

    if (style["padding-left"]) {
        padding.left = getStyleValue(style["padding-left"], containerWidth);
    }
    if (style["padding-right"]) {
        padding.right = getStyleValue(style["padding-right"], containerWidth);
    }
    if (style["padding-top"]) {
        padding.top = getStyleValue(style["padding-top"], containerHeight);
    }
    if (style["padding-bottom"]) {
        padding.bottom = getStyleValue(style["padding-bottom"], containerHeight);
    }

    return padding;
}

export class Convert {
    static ScreenToCanvasCoordinates(canvas, x, y) {
        if (x instanceof Point) {
            y = x.y;
            x = x.x;
        }

        let offset = canvas.$el.offset();
        let scale = canvas.getScale();
        let canvasX = (x - offset.left) / scale;
        let canvasY = (y - offset.top) / scale;

        return new Point(canvasX, canvasY);
    }

    static CanvasToElementCoordinates(element, x, y) {
        if (x instanceof Point) {
            y = x.y;
            x = x.x;
        }
        return new Point(x - element.canvasBounds.left + element.styles.paddingLeft + element.selectionPadding, y - element.canvasBounds.top + element.styles.paddingTop + element.selectionPadding);
    }

    static ScreenToElementCoordinates(canvas, element, x, y) {
        return this.CanvasToElementCoordinates(element, this.ScreenToCanvasCoordinates(canvas, x, y));
    }

    static ScreenToSelectionLayerCoordinates(x, y) {
        let $selectionLayer = $("#selection_layer");
        let offset = $selectionLayer.offset();
        return new Point(x - offset.left, y - offset.top);
    }

    static ElementToCanvasCoordinates(element, x, y) {
        if (x instanceof Point) {
            y = x.y;
            x = x.x;
        }
        return new Point(x + element.canvasBounds.left, y + element.canvasBounds.top);
    }

    static ElementToSelectionCoordinates(element, x, y) {
        if (x instanceof Point) {
            y = x.y;
            x = x.x;
        }

        return new Point((element.bounds.left + x + element.selectionPadding) * element.canvas.getScale(), (element.bounds.top + y + element.selectionPadding) * element.canvas.getScale());
    }

    static CanvasToScreenCoordinates(canvas, x, y) {
        if (x instanceof Point) {
            y = x.y;
            x = x.x;
        }

        let offset = canvas.$el.offset();
        let scale = canvas.getScale();
        let screenX = (x + offset.left) * scale;
        let screenY = (y + offset.top) * scale;

        return new Point(screenX, screenY);
    }

    static ElementToScreenCoordinates(element, x, y) {
        return this.CanvasToScreenCoordinates(element.canvas, this.ElementToCanvasCoordinates(element, x, y));
    }

    static ClientBoundingBoxToSelectionCoordinates(svg, element) {
        let $selectionLayer = $("#selection_layer");
        let offset = $selectionLayer.offset();
        return Rect.FromBBox(svg.getBoundingClientRect()).offset(-(offset.left + element.selectionBounds.multiply(app.currentCanvas.canvasScale).left), -(offset.top + element.selectionBounds.multiply(app.currentCanvas.canvasScale).top));
    }
}
