import { $, _, Backbone } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { getStaticUrl } from "js/config";

import "css/help.scss";

export const HelpBubblePositionType = {
    LEFT: "left",
    ABOVE: "above",
    BELOW: "below",
    RIGHT: "right"
};

export const HelpBubble = Backbone.View.extend({
    className: "help_bubble",

    initialize: function(options) {
        this.options = options;
    },

    render: function() {
        this.$el.addClass(this.options.style);
        this.$el.opacity(0);

        if (this.options.target) {
            this.$arrowBorder = this.$el.addEl(
                $.div("bubbleArrow bubbleArrowBorder")
            );
            this.$arrow = this.$el.addEl($.div("bubbleArrow"));
        }

        if (this.options.style === undefined || (typeof this.options.style === "string" && !this.options.style.includes("simple"))) {
            this.$icon = this.$el.addEl(
                $.img(getStaticUrl("/images/help/designerbot_white.png"))
            );
        }

        // this.$el.append($.div("title", this.options.title));
        let $body = this.$el.addEl($.div("body").html(this.options.body));

        if (this.options.style == "simple") {
            $body.css({ "max-width": 250 });
        }

        if (this.options.showOkButton) {
            let $buttons = this.$el.addEl($.div("buttons"));
            let $okButton = $buttons.addEl(
                $.div("button", this.options.okButtonLabel || "Ok")
            );
            $okButton.on("click", () => this.remove());
        }

        if (this.options.buttons && this.options.buttons.length) {
            let $buttons = this.$el.addEl($.div("buttons"));
            if (this.options.buttonsAlignment) {
                $buttons.css({
                    "display": "flex",
                    "justify-content": this.options.buttonsAlignment,
                    "height": "auto",
                });
            }
            for (let button of this.options.buttons) {
                let $button = $buttons.addEl($.div("button", button.label));
                $button.addClass(button.style);
                $button.on("click", () => {
                    this.remove();
                    button.action();
                });
            }
        }

        return this;
    },

    ARROWSIZE: 20,

    position: function() {
        let x;
        let y;

        let arrowX;
        let arrowY;

        let actualWidth = this.$el.outerWidth();
        let actualHeight = this.$el.outerHeight();
        let targetBounds;

        if (this.options.target) {
            targetBounds = geom.Rect.FromBoundingClientRect(
                $(this.options.target)[0].getBoundingClientRect()
            );

            targetBounds.left += this.options.targetOffsetX || 0;
            targetBounds.top += this.options.targetOffsetY || 0;

            switch (this.options.position) {
                case HelpBubblePositionType.BELOW:
                    x = targetBounds.centerH - actualWidth / 2;

                    y = targetBounds.bottom + this.ARROWSIZE;
                    arrowX =
                        actualWidth / 2 -
                        this.ARROWSIZE / 2 -
                        (this.options.offsetX || 0);
                    arrowY = -this.ARROWSIZE / 2;

                    break;
                case HelpBubblePositionType.ABOVE:
                    x = targetBounds.centerH - actualWidth / 2;
                    y =
                        targetBounds.top -
                        this.$el.outerHeight() -
                        this.ARROWSIZE / 2;
                    arrowX =
                        this.$el.outerWidth() / 2 -
                        this.ARROWSIZE / 2 -
                        (this.options.offsetX || 0);
                    arrowY = this.$el.outerHeight() - this.ARROWSIZE / 2;
                    break;
                case HelpBubblePositionType.LEFT:
                    x =
                        targetBounds.left -
                        this.ARROWSIZE -
                        this.$el.outerWidth();
                    y = targetBounds.centerV - this.$el.outerHeight() / 2;
                    arrowX = this.$el.outerWidth() - this.ARROWSIZE / 2 - 1;
                    arrowY =
                        this.$el.outerHeight() / 2 -
                        this.ARROWSIZE / 2 -
                        (this.options.offsetY || 0);
                    break;
                case HelpBubblePositionType.RIGHT:
                default:
                    x = targetBounds.right + this.ARROWSIZE;
                    y = targetBounds.centerV - this.$el.outerHeight() / 2;
                    arrowX = -this.ARROWSIZE / 2;
                    arrowY =
                        this.$el.outerHeight() / 2 -
                        this.ARROWSIZE / 2 -
                        (this.options.offsetY || 0);
                    break;
            }

            x += this.options.offsetX || 0;
            y += this.options.offsetY || 0;
        } else {
            // center on page
            x = window.innerWidth / 2 - actualWidth / 2;
            y = window.innerHeight / 2 - actualHeight / 2;
        }

        arrowX += this.options.arrowXOffset || 0;

        switch (this.options.animate) {
            case "tour":
                this.$el.left(x).top(y);

                if (this.options.target) {
                    this.$arrowBorder.left(arrowX).top(arrowY);

                    switch (this.options.position) {
                        case HelpBubblePositionType.BELOW:
                            this.$arrow.left(arrowX).top(arrowY + 1);
                            this.$el.velocity(
                                {
                                    translateY: [0, 200],
                                    opacity: [1, 0]
                                },
                                {
                                    duration: 400
                                }
                            );
                            break;
                        case HelpBubblePositionType.ABOVE:
                            this.$arrow.left(arrowX).top(arrowY - 1);

                            this.$el.velocity(
                                {
                                    translateY: [0, -200],
                                    opacity: [1, 0]
                                },
                                {
                                    duration: 400
                                }
                            );

                            break;
                        case HelpBubblePositionType.LEFT:
                            this.$arrow.left(arrowX - 1).top(arrowY);
                            this.$el.velocity(
                                {
                                    translateX: [0, -200],
                                    opacity: [1, 0]
                                },
                                {
                                    duration: 400
                                }
                            );
                            break;
                        case HelpBubblePositionType.RIGHT:
                        default:
                            this.$arrow.left(arrowX + 1).top(arrowY);

                            this.$el.velocity(
                                {
                                    translateX: [0, 200],
                                    opacity: [1, 0]
                                },
                                {
                                    duration: 400
                                }
                            );
                            break;
                    }
                } else {
                    this.$el.velocity("transition.bounceIn");
                }

                break;
            case "prompt":
                this.$el.find(".buttons").opacity(0);
                this.$el.find(".body").opacity(0);

                var height = 73;

                this.$el.left(x).top(0);

                this.$el.outerHeight(height);
                this.$arrow.left(arrowX).top(0);

                this.$el.velocity(
                    {
                        translateY: targetBounds.centerV - height / 2,
                        height: height,
                        width: 80,
                        opacity: 0
                    },
                    {
                        duration: 0
                    }
                );

                this.$arrow.velocity(
                    {
                        translateY: height / 2 - this.ARROWSIZE / 2,
                        rotateZ: 45,
                        opacity: 0
                    },
                    {
                        duration: 0
                    }
                );

                this.$el.velocity("transition.bounceIn");
                this.$arrow.velocity(
                    {
                        opacity: 1
                    },
                    {
                        duration: 200,
                        queue: false
                    }
                );
                this.$el.velocity("callout.tada", {
                    complete: () => {
                        $.Velocity.animate(
                            this.$arrow,
                            {
                                translateY: arrowY,
                                rotateZ: 45
                            },
                            {
                                duration: 333
                            }
                        );

                        $.Velocity.animate(
                            this.$el,
                            {
                                width: actualWidth,
                                height: actualHeight,
                                translateY: [
                                    y,
                                    targetBounds.centerV - height / 2
                                ]
                            },
                            {
                                duration: 333
                            }
                        ).then(() => {
                            $.Velocity.animate(this.$el.find(".body"), {
                                opacity: 1
                            });
                            $.Velocity.animate(this.$el.find(".buttons"), {
                                opacity: 1
                            });
                        });
                    }
                });

                break;

            case "none":
            case null:
                this.$arrow.left(arrowX).top(arrowY);
                this.$el.left(x).top(y);
                this.$el.opacity(1);
                break;
        }

        if (this.$icon) {
            setInterval(() => {
                switch (Math.round(Math.random() * 1)) {
                    case 0:
                        this.$icon.velocity("callout.swing");
                        break;
                    case 1:
                        this.$icon.velocity("callout.tada");
                        break;
                }
            }, 5000);
        }

        if (this.options.customStyle) {
            this.$el.css(this.options.customStyle);
        }
    },

    remove: function() {
        this.$el.clickShield(false);
        Backbone.View.prototype.remove.apply(this, arguments);
    }
});

export const Help = {
    createBubble(options) {
        let bubble = new HelpBubble(options);
        $("body").append(bubble.render().$el);

        bubble.$el.clickShield(() => bubble.remove());

        _.defer(() => {
            bubble.position();
        });
    },

    showAddImageHelp() {
        // Help.createBubble({
        //     title: "How to position your image tray",
        //     body: "<p>You can use these buttons to try out different ways to position your image tray on your slide.</p><p>If an option isn't available, it's because DesignerBot doesn't think it would look good on this slide or there just isn't enough room to make it fit.</p>",
        //     target: ".editor_menu .layouts",
        //     position: HelpBubblePositionType.RIGHT,
        //     animate: true,
        //     offsetY: 50,
        //     showOkButton: true,
        //     okButtonLabel: "Got It!"
        // });
    },

    showReplaceImageHelp() {
        // Help.createBubble({
        //     title: "Just a heads up!",
        //     body: "<p>DesignerBot advises that you keep your slides simple so you will only be able to replace your existing image tray and not add another one.</p><p>If you want to add multiple images to a slide, you might want to check out the Image Grid template. It's very cool!</p>",
        //     target: "#image.menu_button",
        //     position: HelpBubblePositionType.RIGHT,
        //     animate: true,
        //     showOkButton: true,
        //     okButtonLabel: "Got It!"
        // });
    },

    showThemeHelp() {
        // Help.createBubble({
        //     title: "Ooh, pretty colors...",
        //     body: "<p>You can change the foreground and background colors on your slide from here.</p><p>DesignerBot loves color but feels strongly that too much of it can get out of hand and will pick where to apply the colors you choose. But if you are really jonesing for a rainbow, try the colorful option.</p>",
        //     target: ".color_group",
        //     position: HelpBubblePositionType.RIGHT,
        //     animate: true,
        //     offsetY: 70
        // });
        // _.delay(() => {
        //     Help.createBubble({
        //         title: "All your themes are belong to us",
        //         body: "<p>DesignerBot loves making themes but is happy to share some of the fun with you.</p><p>Click <strong>Edit Theme</strong> and you'll be able to quickly customize your theme palette and style.</p><p>DesignerBot hopes you'll try the octagons. The math was hard.</p>",
        //         target: ".button.edit-theme",
        //         position: HelpBubblePositionType.RIGHT,
        //         animate: true,
        //         offsetY: 50
        //     });
        // }, 1000);
    }
};
