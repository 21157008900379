import React, { useEffect, useState } from "react";
import { Button, Icon, IconButton } from "@material-ui/core";
import { AccountBoxOutlined, AssignmentTurnedInOutlined, MonetizationOnOutlined, MicNoneOutlined, PersonOutlineOutlined, SchoolOutlined, ArrowBackIos } from "@material-ui/icons";
import styled, { keyframes, css } from "styled-components";
import { StandalonePage, StandaloneForm } from "../Standalone";
import ProgressBar from "js/react/components/ProgressBar";
import { getStaticUrl } from "js/config";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateX(40%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const fadeout = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const PresentationTypePaneContainer = styled.div`
    display: flex;
    width: 650px;
    height: 488px;
    padding-bottom: 103px;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 9px 37px 0px rgba(0, 0, 0, 0.20);

    @media (max-height: 650px) {
        height: calc(100vh - 50px);
        overflow-y: auto;
    }

    @media (max-width: 768px) {
        width: calc(100vw - 20px);
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 650px;
    height: 50px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const LogoImage = styled.img`
    width: 100px;
    height: 16px;
    margin-top: 17px;
    margin-bottom: 17px;
    margin-left: 66.5px;

    @media (max-width: 768px) {
        margin-left: 20%;
    }
`;

const CompleteButton = styled(Button)`
    width: 97px;
`;

const ProgressBarContainer = styled.div`
    display: flex;
    width: 100%;
    height: 3px;
    flex-direction: column;
    align-items: flex-start;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #FFF;
`;

const PresentationTypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: 66px;

    @media (max-width: 768px) {
        margin-top: 20px;
    }
`;

const Headings = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const HeaderText = styled.div`
    color: #222222;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.5px;
    opacity: 0;
    animation: ${props => props.fadeOut ? css`${fadeout} 0.5s ease-in-out` : css`${fadeIn} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards`};
    animation-delay: ${props => props.delay && !props.fadeOut ? `${props.delay}s` : "0s"};
    animation-fill-mode: forwards;
    will-change: opacity;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const SubHeaderText = styled.h3`
    color: #999999;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.5px;
    margin-top: 5px;
    opacity: 0;
    animation: ${props => props.fadeOut ? css`${fadeout} 0.5s ease-in-out` : css`${fadeIn} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards`};
    animation-delay: ${props => props.delay && !props.fadeOut ? `${props.delay}s` : "0s"};
    animation-fill-mode: forwards;
    will-change: opacity;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const OptionsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 500px;
    justify-items: center;
    align-items: center;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
    }
`;

const OptionButton = styled(Button)`
    position: relative; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 164px;
    height: 63px;
    border-radius: 1px;
    background: #FFF;
    opacity: 0;
    animation: ${props => props.fadeOut ? css`${fadeout} 0.5s ease-in-out` : css`${fadeIn} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards`};
    animation-delay: ${props => props.delay && !props.fadeOut ? `${props.delay}s` : "0s"};
    animation-fill-mode: forwards;
    will-change: opacity;
`;

const OptionImage = styled(Icon)`
    position: absolute;
    top: 0;
    left: 0; 
    margin: 10px 0 0 10px;
    color: #11A9E2;
    font-size: 20px;
`;

const OptionName = styled.span`
    position: absolute;
    color: #999;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    text-align: left;
    margin-top: 20px;

    /* Safari-specific styles */
    ${isSafari && `
        margin-top: 5px;
    `}
`;

const PresentationsType = ({ onNext, onBack, prevProgress, handleChange, value, firstQuestionValue }) => {
    const [selectedOptions, setSelectedOptions] = useState(value || []);
    const [progress, setProgress] = useState(prevProgress);
    const [fadeOut, setFadeOut] = useState(false);
    const [isCompleteButtonDisabled, setIsCompleteButtonDisabled] = useState(true);

    const calculateDelay = index => {
        return 0.6 + 1 / (1 + Math.exp(-0.25 * index)) * 0.8;
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setProgress(100);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        setIsCompleteButtonDisabled(selectedOptions.length === 0);
    }, [selectedOptions]);

    const handleOptionClick = (key, option) => {
        let newSelectedOptions;
        if (selectedOptions.includes(option)) {
            newSelectedOptions = selectedOptions.filter(item => item !== option);
        } else if (selectedOptions.length < 3) {
            newSelectedOptions = [...selectedOptions, option];
        } else {
            return;
        }
        setSelectedOptions(newSelectedOptions);
        handleChange(key, newSelectedOptions);
    };

    const handleCompleteButtonClick = () => {
        setFadeOut(true);
        setTimeout(() => {
            onNext();
        }, 500);
    };

    const handleBackButtonClick = () => {
        setFadeOut(true);
        setTimeout(() => {
            onBack();
        }, 500);
    };

    return (
        <StandalonePage maxWidth={600}>
            <StandaloneForm open={true} hideBackdrop fullWidth style={{ overflow: "hidden" }}>
                <PresentationTypePaneContainer>
                    <Header>
                        <IconButton
                            size="small"
                            onClick={() => handleBackButtonClick()}
                            style={{ marginLeft: "20px", width: "28px", height: "28px" }}
                        >
                            <ArrowBackIos style={{ fontSize: "16px", paddingLeft: "5px" }} />
                        </IconButton>
                        <LogoImage src={getStaticUrl("/images/onboarding/beautiful-header.svg")} alt="Logo" />
                        <CompleteButton
                            variant="contained"
                            color="primary"
                            disabled={isCompleteButtonDisabled}
                            onClick={() => handleCompleteButtonClick()}
                            style={{
                                visibility: isCompleteButtonDisabled ? "hidden" : "visible",
                                height: "28px",
                                marginRight: "20px"
                            }}
                        >
                            COMPLETE
                        </CompleteButton>
                    </Header>
                    <ProgressBarContainer>
                        <ProgressBar progress={progress} prevProgress={prevProgress} />
                    </ProgressBarContainer>
                    <PresentationTypeContainer>
                        <Headings>
                            <HeaderText data-test-id="presentations-type-header" fadeOut={fadeOut} delay={calculateDelay(0)}>What kind of presentations will you create?</HeaderText>
                            <SubHeaderText fadeOut={fadeOut} delay={calculateDelay(1)}>Select up to 3</SubHeaderText>
                        </Headings>
                        {(firstQuestionValue === "sales" || firstQuestionValue === "other") && (
                            <OptionsContainer>
                                <OptionButton
                                    selected={selectedOptions.includes("sales-pitches") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "sales-pitches")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("sales-pitches") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(2)}
                                >
                                    <OptionImage style={{ fontSize: "20px" }}>signal_cellular_alt</OptionImage>
                                    <OptionName>Sales pitches</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("customer-deliverables") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "customer-deliverables")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("customer-deliverables") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(3)}
                                >
                                    <OptionImage as={AssignmentTurnedInOutlined} style={{ fontSize: "20px" }}></OptionImage>
                                    <OptionName>Customer deliverables</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("company-meetings") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "company-meetings")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("company-meetings") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(4)}
                                >
                                    <OptionImage style={{ fontSize: "20px" }}>groups</OptionImage>
                                    <OptionName>Company meetings</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("conferences/events") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "conferences/events")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("conferences/events") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(5)}
                                >
                                    <OptionImage as={MicNoneOutlined} style={{ fontSize: "20px" }}></OptionImage>
                                    <OptionName>Conferences/events</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("fundraising-decks") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "fundraising-decks")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("fundraising-decks") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(6)}
                                >
                                    <OptionImage as={MonetizationOnOutlined} style={{ fontSize: "20px" }}></OptionImage>
                                    <OptionName>Fundraising decks</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("hr-and-training") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "hr-and-training")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("hr-and-training") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(7)}
                                >
                                    <OptionImage as={AccountBoxOutlined} style={{ fontSize: "20px" }}></OptionImage>
                                    <OptionName>HR and training</OptionName>
                                </OptionButton>
                                {firstQuestionValue === "other" && (
                                    <OptionButton
                                        selected={selectedOptions.includes("personal-use") ? true : false}
                                        onClick={() => handleOptionClick("presentationsType", "personal-use")}
                                        fadeOut={fadeOut}
                                        style={{
                                            gridColumn: "2 / 3",
                                            border: selectedOptions.includes("personal-use") ? "2px solid #11A9E2" : "0.5px solid #999"
                                        }}
                                        delay={calculateDelay(8)}
                                    >
                                        <OptionImage as={PersonOutlineOutlined} style={{ fontSize: "20px" }}></OptionImage>
                                        <OptionName >Personal use</OptionName>
                                    </OptionButton>
                                )}
                            </OptionsContainer>
                        )}
                        {firstQuestionValue === "marketing" && (
                            <OptionsContainer>
                                <OptionButton
                                    selected={selectedOptions.includes("sales-pitches") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "sales-pitches")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("sales-pitches") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(2)}
                                >
                                    <OptionImage style={{ fontSize: "20px" }}>signal_cellular_alt</OptionImage>
                                    <OptionName>Sales pitches</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("fundraising-decks") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "fundraising-decks")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("fundraising-decks") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(3)}
                                >
                                    <OptionImage as={MonetizationOnOutlined} style={{ fontSize: "20px" }}></OptionImage>
                                    <OptionName>Fundraising decks</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("conferences/events") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "conferences/events")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("conferences/events") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(4)}
                                >
                                    <OptionImage as={MicNoneOutlined} style={{ fontSize: "20px" }}></OptionImage>
                                    <OptionName>Conferences/events</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("company-meetings") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "company-meetings")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("company-meetings") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(5)}
                                >
                                    <OptionImage style={{ fontSize: "20px" }}>groups</OptionImage>
                                    <OptionName>Company meetings</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("customer-deliverables") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "customer-deliverables")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("customer-deliverables") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(6)}
                                >
                                    <OptionImage as={AssignmentTurnedInOutlined} style={{ fontSize: "20px" }}></OptionImage>
                                    <OptionName>Customer deliverables</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("hr-and-training") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "hr-and-training")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("hr-and-training") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(7)}
                                >
                                    <OptionImage as={AccountBoxOutlined} style={{ fontSize: "20px" }}></OptionImage>
                                    <OptionName>HR and training</OptionName>
                                </OptionButton>
                            </OptionsContainer>
                        )}
                        {firstQuestionValue === "educator-or-student" && (
                            <OptionsContainer>
                                <OptionButton
                                    selected={selectedOptions.includes("conferences/events") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "conferences/events")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("conferences/events") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(2)}
                                >
                                    <OptionImage as={MicNoneOutlined} style={{ fontSize: "20px" }}></OptionImage>
                                    <OptionName>Conferences/events</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("academic-reports") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "academic-reports")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("academic-reports") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(3)}
                                >
                                    <OptionImage style={{ fontSize: "20px" }}>science</OptionImage>
                                    <OptionName>Academic reports</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("lectures") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "lectures")}
                                    fadeOut={fadeOut}
                                    style={{
                                        border: selectedOptions.includes("lectures") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(4)}
                                >
                                    <OptionImage style={{ fontSize: "20px" }}>present_to_all</OptionImage>
                                    <OptionName>Lectures</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("instructional-material") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "instructional-material")}
                                    fadeOut={fadeOut}
                                    style={{
                                        gridColumn: "1 / 3",
                                        gridRow: "2",
                                        border: selectedOptions.includes("instructional-material") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(5)}
                                >
                                    <OptionImage as={SchoolOutlined}></OptionImage>
                                    <OptionName>Instructional material</OptionName>
                                </OptionButton>
                                <OptionButton
                                    selected={selectedOptions.includes("personal-use") ? true : false}
                                    onClick={() => handleOptionClick("presentationsType", "personal-use")}
                                    fadeOut={fadeOut}
                                    style={{
                                        gridColumn: "2 / 4",
                                        gridRow: "2",
                                        border: selectedOptions.includes("personal-use") ? "2px solid #11A9E2" : "0.5px solid #999"
                                    }}
                                    delay={calculateDelay(5)}
                                >
                                    <OptionImage as={PersonOutlineOutlined}></OptionImage>
                                    <OptionName>Personal use</OptionName>
                                </OptionButton>
                            </OptionsContainer>
                        )}
                    </PresentationTypeContainer>
                </PresentationTypePaneContainer>
            </StandaloneForm>
        </StandalonePage>
    );
};

export default PresentationsType;
