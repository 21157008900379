import { _ } from "js/vendor";
import { presentations as presentationsApi } from "apis/callables";
import type { IPresentation } from "common/interfaces";
import db from "js/db";

import ApiAdapter from "./apiAdapter";

class PresentationsAdapter extends ApiAdapter<IPresentation, {}> {
    async _createApi(model: IPresentation): Promise<IPresentation> {
        return await presentationsApi.createPresentation(model);
    }

    async _updateApi(
        changesetUpdates: Partial<IPresentation>, changesetOriginal: Partial<IPresentation>
    ): Promise<Partial<IPresentation>> {
        const { modifiedAt } = await presentationsApi.updatePresentation({
            id: this.id,
            changesetOriginal,
            changesetUpdates
        });
        return { modifiedAt };
    }

    async _getApi(): Promise<IPresentation> {
        return await presentationsApi.getPresentation({
            id: this.id,
        });
    }

    _composeModel(baseModel: Partial<IPresentation>): IPresentation {
        return { ...baseModel, id: this.id } as IPresentation;
    }

    _getPusherChannelId(): string {
        return `private-presentation-${this.id}`;
    }

    createUid() {
        return db().push().key as string;
    }
}

export default PresentationsAdapter;
