import React from "react";
import Cookies from "js-cookie";

import firebase from "js/firebase";
import getLogger, { LogGroup } from "js/core/logger";
import db from "js/db";

const FirebaseAuthContext = React.createContext();
export const FirebaseAuthConsumer = FirebaseAuthContext.Consumer;
export const TESTING_Provider = FirebaseAuthContext.Provider;

const logger = getLogger(LogGroup.AUTH);

export class FirebaseAuthProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = { didInit: false };
        this.auth = React.createRef(null);
    }

    componentDidMount() {
        const auth = this.props.auth || firebase.auth();
        this.auth.current = auth;

        logger.info("Waiting for Firebase Auth to initalize");

        auth.onAuthStateChanged(user => {
            logger.info("Firebase Auth state changed");
            if (user && user.isAnonymous || user && (user.email === undefined || user.email === null)) {
                // discard legacy anonymous users
                logger.info("Signing out legacy anonymous Firebase Auth user");
                auth.signOut();
            } else {
                Cookies.set("logged-in", !!user);
                if (user) {
                    // Updating last signed in timestamp if user record exists
                    const userRef = db("users").child(user.uid);
                    userRef.child("id").once("value")
                        .then(snap => snap.exists())
                        .then(userRecordIdExists => {
                            if (userRecordIdExists) {
                                return userRef.child("lastSignedInAt").set(Date.now());
                            }
                        })
                        .catch(err => logger.error(err, "failed to update user model"));
                }
                this.setState({ didInit: true });
            }
        });
    }

    render() {
        const { children } = this.props;
        return (
            <FirebaseAuthContext.Provider
                value={this.state.didInit ? this.auth.current : null}
            >
                {children}
            </FirebaseAuthContext.Provider>
        );
    }
}

/**
 * Workaround for ad-hoc ReactDOM.render trees within Backbone views,
 * which are unable to access the context tree. You *must* ensure that
 * there's an initalized FirebaseAuthProvider somewhere above this.
 */
export function UNSAFE_FirebaseAuthProvider({ children }) {
    return (
        <FirebaseAuthContext.Provider value={firebase.auth()}>
            {children}
        </FirebaseAuthContext.Provider>
    );
}

/**
 * HOC which injects `firebase.auth()` instance as `auth` prop. Very rarely needed!
 */
export function withFirebaseAuth(Component) {
    // eslint-disable-next-line react/display-name
    return React.forwardRef((props, ref) => (
        <FirebaseAuthContext.Consumer>
            {auth => <Component {...props} ref={ref} auth={auth} />}
        </FirebaseAuthContext.Consumer>
    ));
}
