import React, { Fragment } from "react";
import { _ } from "js/vendor";

import { app } from "js/namespaces";
import { ShowErrorDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import * as browser from "js/core/utilities/browser";
import getLogger, { LogGroup } from "js/core/logger";

import { sanitizeHtmlText } from "./htmlTextHelpers";
import { htmlRegex } from "./regex";
import { getStaticUrl } from "js/config";

const logger = getLogger(LogGroup.CLIPBOARD);

export const ClipboardType = {
    TEXT: "text/plain",
    HTML: "text/html",
    PNG: "image/png",
    JPEG: "image/jpeg",
    ASSET: "web application/bai-asset",
    ELEMENT_MODEL: "web application/bai-element-model",
    ANNOTATION: "web application/bai-annotation",
    AUTHORING: "web application/bai-authoring",
    BLOCKS: "web application/bai-blocks",
    CELLS: "web application/bai-cells",
    SLIDES: "web application/bai-slides",
    STYLES: "web application/bai-styles",
};

// Provide a mapping of mimeType:value.
//   This allows muliple formats to be written at once.
export const clipboardWrite = async (mimeTypeMapping, keepHTMLInPlainText = false) => {
    // Store a copy for synchronous queries elsewhere
    app.lastCopy = mimeTypeMapping;

    if (!keepHTMLInPlainText) {
        // Strip HTML from any plain text
        const plainText = mimeTypeMapping[ClipboardType.TEXT];
        if (plainText) {
            mimeTypeMapping[ClipboardType.TEXT] = plainText.replace(htmlRegex, "");
        }
    }

    // Chrome is the only browser that fully supports clipboard.write
    if (navigator.clipboard?.write && browser.isChrome) {
        const permission = navigator.permissions && await navigator.permissions.query({ name: "clipboard-write" });
        if (permission?.state === "denied") {
            debouncePermissionError(new Error("Clipboard write denied"), "clipboardWrite() clipboard write denied");
            return;
        }

        const mapping = Object.entries(mimeTypeMapping)
            .reduce((result, [mimeType, value]) => {
                if (mimeType.includes("application/bai")) {
                    value = JSON.stringify(value);
                }

                const blobType = mimeType.replace("web ", "");
                const blob = new Blob(
                    [value],
                    { type: blobType },
                );

                result[mimeType] = blob;
                return result;
            }, {});

        const item = new window.ClipboardItem(mapping);
        await navigator.clipboard.write([item]);
    } else {
        // Just write text if we can't be more specific
        let [
            mimeType,
            value,
        ] = Object.entries(mimeTypeMapping)[0];

        if (mimeType.includes("application/bai")) {
            value = `${mimeType}:${JSON.stringify(value)}`;
        }
        await navigator.clipboard.writeText(value);
    }
};

const debouncePermissionError = _.debounce((error, message) => {
    logger.error(error, message);
    ShowErrorDialog({
        title: "Clipboard support is not enabled for Beautiful.ai in your browser.",
        message: <Fragment>
            In most browsers, you can click on the lock icon to the left of the URL to toggle clipboard permissions.
            Other browsers may require different steps.
            <img src={getStaticUrl("/images/enableClipboard.gif")} style={{ width: "100%", marginTop: 20 }} />
        </Fragment>
    });
}, 100);

const debounceSupportError = _.debounce(() => {
    logger.error(new Error("Clipboard unsupported"), "clipboardRead() unable to read from clipboard due to lack of browser support.");
    ShowWarningDialog({
        title: "Clipboard unsupported by browser.",
        message: "Unfortunately, due to technical limitations, your browser does not support copy/paste of Beautiful.ai elements. We recommend Google Chrome for the best experience when using Beautiful.ai."
    });
}, 100);

// Provide an array of acceptible mimeType matches.
//   First mimeType takes precedence.
export const clipboardRead = async (mimeTypes = [], event = null) => {
    let value;

    // Chrome is the only browser that fully supports clipboard.read
    if (navigator.clipboard?.read && browser.isChrome) {
        const permission = navigator.permissions && await navigator.permissions.query({ name: "clipboard-read" });
        if (permission?.state === "denied") {
            debouncePermissionError(new Error("Clipboard read denied"), "clipboardRead() clipboard read denied");
            return value;
        }

        const parseTextViaMimeType = (text, mimeType) => {
            if (mimeType.includes("application/bai")) {
                return JSON.parse(text);
            } else if (mimeType === ClipboardType.HTML) {
                return sanitizeHtmlText(text);
            } else if (mimeType === ClipboardType.TEXT) {
                return text;
            }
        };

        try {
            // This is primarily for grabbing clipboard text sent via "Help Me Write" context command on Chrome
            const clipboardData = event?.originalEvent?.clipboardData || event?.clipboardData;
            if (clipboardData) {
                const mimeType = mimeTypes.find(mimeType => clipboardData.types.includes(mimeType));
                if (mimeType) {
                    const text = clipboardData.getData(mimeType);

                    value = parseTextViaMimeType(text, mimeType);
                }
            }

            if (!value) {
                const items = await navigator.clipboard.read();
                const item = items.find(x => mimeTypes.some(mimeType => x.types.includes(mimeType)));
                if (item) {
                    const mimeType = mimeTypes.find(type => item.types.includes(type));
                    const blob = await item.getType(mimeType);
                    const text = await blob.text();

                    value = parseTextViaMimeType(text, mimeType);
                }
            }
        } catch (err) {
            logger.error(err, "clipboardRead() failed");
        }

    // Just read text if we can't be more specific
    } else {
        // Technically, Firefox supports event clipboard pasting, but it clears its
        //   clipboard after `clipboardData.getData` is called. Beyond the fact this
        //   is annoying to work around, it prevents the use case of pasting multiple
        //   times in a row. So we're just flagging it as unsupported.
        if (browser.isFirefox) {
            debounceSupportError();
            return value;
        }

        let text;

        // Use event clipboard if available
        const clipboardData = event?.originalEvent?.clipboardData || event?.clipboardData;
        if (clipboardData) {
            if (
                mimeTypes.includes(ClipboardType.HTML) &&
                clipboardData.types.contains("text/html")
            ) {
                text = clipboardData.getData("text/html");
            } else if (clipboardData.types.contains("text/plain")) {
                text = clipboardData.getData("text/plain");
            }
        } else if (navigator.clipboard?.readText) {
            // Use clipboard.readText if available
            text = await navigator.clipboard.readText();
        } else {
            debounceSupportError();
            return value;
        }

        if (text) {
            // Look for the application mime type flag if we're looking for any application mime types
            const applicationMimeTypes = mimeTypes.filter(mimeType => mimeType.includes("application/bai"));
            const mimeType = applicationMimeTypes.find(mimeType => text.includes(`${mimeType}:`));
            if (mimeType) {
                value = JSON.parse(text.replace(`${mimeType}:`, ""));
            } else if (mimeTypes.includes(ClipboardType.HTML)) {
                value = sanitizeHtmlText(text);
            } else if (mimeTypes.includes(ClipboardType.TEXT)) {
                value = text;
            }
        }
    }

    return value;
};
