import React, { Component } from "react";
import styled from "styled-components";
import { CloudUpload } from "@material-ui/icons";

import getLogger from "js/core/logger";
import { Gap30 } from "js/react/components/Gap";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { FileType, fileTypesToExtensions } from "js/core/utilities/fileSourcesAndTypes";

const logger = getLogger();

const DragDropContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed #11A9E2;
    width: 100%;
    height: 100%;
    padding: 30px 50px;
    margin-bottom: 30px;
    & .label-text {
        font-size: 18px;
        font-weight: 600;
        & .text-blue {
            cursor: pointer;
        }
    }
    :hover {
        cursor: pointer;
        background: #f1f1f1;
    }
`;

interface Props {
    fileTypes: FileType[]
    handleImportedFile: (file: File) => void,
}

export default class LocalFilePicker extends Component<Props> {
    uploadRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);

        this.uploadRef = React.createRef<HTMLInputElement>();
    }

    componentDidMount() {
        // @ts-ignore
        window.addEventListener("paste", this.handleClipboardEvent);
    }

    componentWillUnmount() {
        // @ts-ignore
        window.removeEventListener("paste", this.handleClipboardEvent);
    }

    handleClipboardEvent = async (event: React.ClipboardEvent) => {
        event.stopPropagation();
        // check if there is valid file pasted
        try {
            if (event.clipboardData?.files.length) {
                return await this.handleFileSelectChange(event, true);
            }
        } catch {
            // check if there is plain text pasted if there is no valid file
        }
    }

    handleFileSelectChange = async (event: React.ChangeEvent | React.DragEvent | React.ClipboardEvent, skipErrorDialog?: boolean) => {
        try {
            // @ts-ignore
            const files = (event.target?.files || event.dataTransfer?.files || event.clipboardData?.files) as FileList;
            const file = files[0];

            this.props.handleImportedFile(file);
        } catch (err) {
            if (skipErrorDialog) throw err;

            logger.error(err, "[UploadLocalFile] handleFileSelectChange() failed");
            ShowErrorDialog({
                error: "Sorry, we could not process your request",
                message: err.message
            });
        }
    }

    handleMediaOptionClick = (event?: React.DragEvent) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            this.handleFileSelectChange(event);
        } else {
            this.uploadRef.current.click();
        }
    }

    render() {
        return (
            <DragDropContainer
                onDragEnter={e => e.preventDefault()}
                onDragOver={e => e.preventDefault()}
                onDragLeave={e => e.preventDefault()}
                onDrop={e => this.handleMediaOptionClick(e)}
                onClick={() => this.handleMediaOptionClick()}
            >
                <input // @ts-ignore
                    ref={this.uploadRef}
                    type="file"
                    style={{ display: "none" }}
                    accept={this.props.fileTypes.map(fileType => fileType + "/*").join(", ")}
                    onChange={e => this.handleFileSelectChange(e)}
                />
                <CloudUpload style={{ width: 50, height: 50, color: "#11a9e2" }} />
                <Gap30 />
                <span className="label-text">
                    Paste from clipboard, drag and drop or <span className="text-blue">browse files...</span>
                </span>
            </DragDropContainer>
        );
    }
}
