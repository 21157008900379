import date from "js/core/utilities/date";
import moment from "moment/moment";
import momentDurationFormatSetup from "moment-duration-format";
import parseFormat from "moment-parseformat";
import { ACCEPTED_DATE_FORMATS } from "common/constants";
import { FormatType, CellChangeStyle } from "common/constants";
import { numeral } from "js/vendor";

momentDurationFormatSetup(moment);

const formatter = {

    formatAxis: function(value, axis, isFirst) {
        let formatOptions = axis.userOptions;
        if (!formatOptions) {
            return value;
        }
        if (formatOptions.type === "datetime") {
            return this.formatDateForAxis(value, formatOptions, isFirst);
        }
        if (isNaN(value)) {
            return value;
        }
        let currency = formatOptions.labelCurrency === "none" || formatOptions.labelCurrency === undefined ? "" : formatOptions.labelCurrency;
        value = this.scaleValue(value, formatOptions.labelFormat);
        if (axis.coll !== "xAxis") {
            value = formatOptions.labelAbbreviation && formatOptions.labelFormat !== "percent" ? this.setAbbreviation(value) : this.numberWithCommas(value, formatOptions.labelFormat);
        }
        return currency + value;
    },

    formatDateForAxis(value, options, isFirst) {
        let d = date(value).toMoment();
        let interval = options.dateInterval;
        let format = options.dateFormat;
        let simpleDate = options.dateSimple;

        let label;

        switch (interval) {
            case "daily":
                if (simpleDate) {
                    if (isFirst || d.date() === 1) {
                        label = d.format("MMM");
                    } else {
                        label = d.format("D");
                    }
                } else {
                    if (format === "monthFirst") {
                        if (isFirst || d.date() === 1) {
                            label = d.format("MMM D");
                        } else {
                            label = d.format("D");
                        }
                    } else {
                        if (isFirst || d.date() === 1) {
                            label = d.format("D MMM");
                        } else {
                            label = d.format("D");
                        }
                    }
                }
                break;
            case "monthly":
                if (isFirst || d.month() === 0) {
                    label = simpleDate ? d.format("YYYY") : d.format("MMM 'YY");
                } else {
                    label = d.format("MMM");
                }
                break;
            case "quarterly":
                if (isFirst || d.quarter() === 1) {
                    label = simpleDate ? "'" + d.format("YY") : "Q" + d.quarter() + " '" + d.format("YY");
                } else {
                    label = "Q" + d.quarter();
                }
                break;
            case "yearly":
                if (simpleDate) {
                    if (isFirst) {
                        label = d.format("YYYY");
                    } else {
                        label = "'" + d.format("YY");
                    }
                } else {
                    label = d.format("YYYY");
                }
                break;
            default:
                label = d.format("MM/DD/YY");
                break;
        }
        return label;
    },

    scaleValue(value, scale) {
        switch (scale) {
            case "percent":
                return value / 100;
            case "ones":
                return value;
            case "thousands":
                return value * 1000;
            case "millions":
                return value * 1000000;
            case "billions":
                return value * 1000000000;
            case "trillions":
                return value * 1000000000000;
            default:
                return value;
        }
    },

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    numberWithCommas(x, labelFormat) {
        let formattedNumber;
        if (x % 1 != 0) {
            formattedNumber = numeral(x).format("0.0[000000]");
        } else {
            formattedNumber = numeral(x).format(",");
        }
        if (formattedNumber.contains(".")) {
            formattedNumber = formattedNumber.replace(/0+$/g, "");
        }

        if (labelFormat === "percent") {
            formattedNumber += "%";
        }
        return formattedNumber;
    },

    setAbbreviation(value) {
        let abs = Math.abs(value);
        let newVal;
        if (abs >= 1000000000000) {
            newVal = this.numberWithCommas(value / 1000000000000) + "T";
        } else if (abs >= 1000000000) {
            newVal = this.numberWithCommas(value / 1000000000) + "B";
        } else if (abs >= 1000000) {
            newVal = this.numberWithCommas(value / 1000000) + "M";
        } else if (abs >= 1000) {
            newVal = this.numberWithCommas(value / 1000) + "K";
        } else {
            newVal = this.numberWithCommas(value);
        }
        return newVal;
    },

    getDefaultFormatOptions() {
        return {
            decimal: "auto",
            separator: true,
            abbreviated: false,
            changeStyle: CellChangeStyle.NONE,
            changeColor: true,
            currency: "$",
            dateFormat: "MMM Do",
            accountingStyle: false
        };
    },

    formatValue(value, format, formatOptions) {
        switch (format) {
            case FormatType.PERCENT:
            case FormatType.NUMBER: {
                let options = Object.assign({}, formatOptions);
                let formattedString = this.formatNumber(value, this.getFormatString(format, options));
                if (options.abbreviated == "upper") {
                    return formattedString.toUpperCase();
                } else {
                    return formattedString;
                }
            }
            case FormatType.CURRENCY: {
                let formattedString;
                if (formatOptions.accountingStyle) {
                    formattedString = numeral(value).format(this.getFormatString(format, formatOptions));
                } else {
                    let formattedValue = numeral(value).format(this.getFormatString(format, formatOptions));
                    if (formattedValue.startsWith("+") || formattedValue.startsWith("-")) {
                        formattedString = formattedValue[0] + formatOptions.currency + formattedValue.substr(1);
                    } else {
                        formattedString = formatOptions.currency + formattedValue;
                    }
                }
                if (formatOptions.abbreviated == "upper") {
                    return formattedString.toUpperCase();
                } else {
                    return formattedString;
                }
            }
            case FormatType.DATE:
                return this.formatDate(value, this.getFormatString(format, formatOptions));
            default:
                return value;
        }
    },

    formatNumber(value, format) {
        return numeral(value).format(format);
    },

    formatDuration(duration, type = "seconds", format) {
        let m = moment.duration(duration, type);
        return moment.duration(duration, type).format(format);
    },

    formatDate(value, format) {
        switch (format) {
            case "none":
                return value;
            case "abbr-month":
                return moment(value).format("MMM").charAt(0);
            case "abbr-month-year":
                return moment(value).format("MMM").charAt(0) + " " + moment(value).format("YY");
            default:
                return moment(value).format(format);
        }
    },

    formatTimestamp(time, words = false, raw = false) {
        const t = moment.unix(time);

        if (words || t.isBefore(moment().subtract(1, "w"))) {
            return t.format("MMM DD YYYY");
        } else if (raw) {
            return t.format("MM/DD/YYYY");
        } else {
            return t.fromNow();
        }
    },

    detectFormatFromString(s) {
        let options = this.getDefaultFormatOptions();
        if (numeral.validateEx(s)) {
            options.decimal = s.contains(".") ? (s.length - s.indexOf(".") - 1) : 0;
            options.separator = true;
            options.abbreviated = false;

            if (s.startsWith("$")) {
                options.currency = s[0];
                options.accountingStyle = false;
                return {
                    format: FormatType.CURRENCY,
                    formatOptions: options
                };
            } else if (s.contains("%")) {
                // String contains % and a '.' so we assume it's a percentage meaning we need to remove 2 and not 1
                options.decimal = s.contains(".") ? (s.length - s.indexOf(".") - 2) : 0;
                return {
                    format: FormatType.PERCENT,
                    formatOptions: options
                };
            } else {
                return {
                    format: FormatType.NUMBER,
                    formatOptions: options
                };
            }
        } else if (moment(s).isValid()) {
            if (moment(s, ACCEPTED_DATE_FORMATS, true).isValid()) {
                // parseFormat gives priority to "MM/YY" over "MM/DD" so we need to check for that first
                options.dateFormat = moment(s, "M/DD").isValid() ? "M/D" : parseFormat(s);

                return {
                    format: FormatType.DATE,
                    formatOptions: options
                };
            } else {
                return {
                    format: FormatType.TEXT,
                    formatOptions: options
                };
            }
        } else {
            return {
                format: FormatType.TEXT,
                formatOptions: options
            };
        }
    },

    getFormatString(format, formatOptions) {
        let formatString = "";

        if (format == FormatType.NUMBER || format == FormatType.CURRENCY) {
            formatString += "0";
            if (formatOptions.separator) {
                formatString += ",0";
            }
            if (formatOptions.decimal == 0) {
                // do nothing
            } else if (formatOptions.decimal > 0) {
                formatString += ".";
                for (let i = 0; i < formatOptions.decimal; i++) {
                    formatString += "0";
                }
            } else {
                // auto decimals
                formatString += ".[00]";
            }

            if (formatOptions.abbreviated) {
                formatString += "a";
            }

            if (formatOptions.accountingStyle) {
                formatString = "(" + formatString + ")";
            }
        }

        if (format == FormatType.PERCENT) {
            formatString += "0";

            if (formatOptions.decimal != 0 && formatOptions.decimal !== "auto") {
                formatString += ".";
                for (let i = 0; i < formatOptions.decimal; i++) {
                    formatString += "0";
                }
            }
            if (formatOptions.decimal === "auto") {
                formatString += ".[00]";
            }
            formatString += "%";
        }

        if (format == FormatType.NUMBER || format == FormatType.CURRENCY || format == FormatType.PERCENT) {
            if (formatOptions.changeStyle == CellChangeStyle.PLUS_MINUS) {
                formatString = "+" + formatString;
            }
        }

        if (format == "date") {
            formatString = formatOptions.dateFormat || "ll";
        }

        return formatString;
    },

    getFormatType(format) {
        switch (format) {
            case FormatType.NUMBER:
            case FormatType.CURRENCY:
            case FormatType.PERCENT:
                return "numeric";
            case FormatType.DATE:
                return "date";
            case FormatType.ICON:
                return "icon";
            default:
        }
    }

};

export { formatter };
