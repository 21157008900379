import { $ } from "js/vendor";
import { app } from "js/namespaces";
import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { dialogTheme } from "./materialThemeOverrides";
import addReactNDevTools from "reactn-devtools";
import { UNSAFE_FirebaseAuthProvider } from "js/react/views/Auth/FirebaseAuthContext";
import { UNSAFE_FirebaseUserProvider } from "js/react/views/Auth/FirebaseUserContext";

const _rerouteUnmountables = [];
const pushMount = element => {
    let index = _rerouteUnmountables.findIndex(x => x === element);
    if (index < 0) {
        _rerouteUnmountables.push(element);
    }
};
const popMount = element => {
    let index = _rerouteUnmountables.findIndex(x => x === element);
    if (index > -1) {
        _rerouteUnmountables.splice(index, 1);
    }
};

addReactNDevTools();

export const reactUnmountAllForReroute = () => {
    while (_rerouteUnmountables.length) {
        let element = _rerouteUnmountables.pop();
        ReactDOM.unmountComponentAtNode(element);
    }
};

export const reactUnmount = element => {
    ReactDOM.unmountComponentAtNode(element);
    popMount(element);
};

export const reactMount = (dom, element, unmountOnReroute = false) => {
    if (unmountOnReroute) {
        pushMount(element);
    }
    ReactDOM.render(
        dom,
        element
    );
};

// React's Context is not available in ad-hoc ReactDOM.render roots, so we grab global auth,
// assuming that it was safely initalized elsewhere already
function provideAuth(children) {
    return (
        <UNSAFE_FirebaseAuthProvider>
            <UNSAFE_FirebaseUserProvider>
                {children}
            </UNSAFE_FirebaseUserProvider>
        </UNSAFE_FirebaseAuthProvider>
    );
}

export default function renderReactRoot(Component, props = {}, element, unmountOnReroute = false) {
    reactMount(
        provideAuth(
            <MuiThemeProvider theme={dialogTheme}>
                <Component {...props} />
            </MuiThemeProvider>
        ),
        element,
        unmountOnReroute
    );

    return () => {
        reactUnmount(element);
    };
}

export function renderReactRootJSX(jsx, element, unmountOnReroute = false) {
    reactMount(
        provideAuth(
            <MuiThemeProvider theme={dialogTheme}>
                {jsx}
            </MuiThemeProvider>
        ),
        element,
        unmountOnReroute
    );

    return () => {
        reactUnmount(element);
    };
}

// Dialogs by default do want to have their dom unmounted on reroute
export function renderReactDialog(Component, props = {}, zIndex = 1300) {
    let unmountOnReroute = true;
    if (props.unmountOnReroute !== undefined) {
        unmountOnReroute = props.unmountOnReroute;
        delete props.unmountOnReroute;
    }
    let registerDialog = true;
    if (props.registerDialog !== undefined) {
        registerDialog = props.registerDialog;
        delete props.registerDialog;
    }
    let $dialogContainer = $("body").addEl($.div("dialog-container"));
    $dialogContainer.attr("dialog-container");
    $dialogContainer.zIndex(zIndex);

    if (props.overlayIsUnblocking) {
        $dialogContainer.addClass("unblocking");
        delete props.overlayIsUnblocking;
    }
    let dialog = null;
    let unmounted = false;
    let unmount = result => {
        unmounted = true;
        if (
            dialog &&
            registerDialog &&
            app?.dialogManager
        ) {
            app.dialogManager.unregisterDialog(dialog);
        }
        reactUnmount($dialogContainer[0]);
        $dialogContainer.remove();
        const { onClose } = props;
        onClose && onClose(result);
    };
    props.closeDialog = unmount;
    let ref = React.createRef();
    reactMount(
        provideAuth(
            <MuiThemeProvider theme={dialogTheme}>
                <Component ref={ref} {...props} closeDialog={unmount} />
            </MuiThemeProvider>
        ),
        $dialogContainer[0],
        unmountOnReroute
    );
    dialog = ref.current;
    if (!unmounted) {
        // Using 'close' instead of 'closeDialog' because it
        //   clashes with various MaterialUI components
        if (dialog && !dialog.close) {
            dialog.close = unmount;
        }
        if (
            dialog &&
            registerDialog &&
            app?.dialogManager
        ) {
            app.dialogManager.registerDialog(dialog);
        }
    }
    return dialog;
}

export class DialogJSX extends Component {
    render() {
        return (
            <Fragment>{this.props.children}</Fragment>
        );
    }
}

// Dialogs by default do want to have their dom unmounted on reroute
export function renderReactDialogFromJSX(jsx, options) {
    options = {
        overlayIsUnblocking: false,
        unmountOnReroute: true,
        registerDialog: true,
        ...options,
    };
    let $dialogContainer = $("body").addEl($.div("dialog-container"));
    $dialogContainer.attr("dialog-container");

    let dialog = null;
    let unmounted = false;
    let unmount = () => {
        unmounted = true;
        if (
            dialog &&
            options.registerDialog &&
            app?.dialogManager
        ) {
            app.dialogManager.unregisterDialog(dialog);
        }
        reactUnmount($dialogContainer[0]);
        $dialogContainer.remove();
    };
    if (options.overlayIsUnblocking) {
        $dialogContainer.addClass("unblocking");
    }

    let ref = React.createRef();
    reactMount(
        provideAuth(
            <MuiThemeProvider theme={dialogTheme}>
                <DialogJSX ref={ref}>
                    {jsx}
                </DialogJSX>
            </MuiThemeProvider>
        ),
        $dialogContainer[0],
        options.unmountOnReroute
    );
    dialog = ref.current;
    if (!unmounted) {
        // Using 'close' instead of 'closeDialog' because it
        //   clashes with various MaterialUI components
        if (dialog && !dialog.close) {
            dialog.close = unmount;
        }
        if (
            dialog &&
            options.registerDialog &&
            app?.dialogManager
        ) {
            app.dialogManager.registerDialog(dialog);
        }
    }
    return dialog;
}

class DomExtractor extends React.Component {
    ref = React.createRef();

    componentDidMount() {
        const {
            onExtract,
        } = this.props;

        const htmlText = this.ref.current.innerHTML;
        const cssText = this.extractStyles();

        onExtract(htmlText, cssText);
    }

    extractStyles = () => {
        const styleSheets = document.styleSheets;
        let cssText = "";
        for (let indexSS = 0; indexSS < styleSheets.length; ++indexSS) {
            const ss = styleSheets[indexSS];
            // To avoid CORS issues, we only extract the CSS from the stylesheets that are locally sourced.
            //   NOTE: In the future we might want to pull the CSS from webpack and vendor bundles. In that
            //   case, we should implement a whitelist to pull from in addition to the local stylesheets
            if (!ss.href) {
                for (let indexCss = 0; indexCss < ss.cssRules.length; ++indexCss) {
                    const rule = ss.cssRules[indexCss];
                    cssText += rule.cssText;
                }
            }
        }
        return cssText;
    }

    render() {
        const {
            width,
            height,
            children,
        } = this.props;

        return (
            <div
                ref={this.ref}
                style={{ width, height }}
            >{children}</div>
        );
    }
}

export async function reactToSvg(width, height, element) {
    const elemMount = document.createElement("div");
    document.body.appendChild(elemMount);

    const result = await new Promise(resolve => {
        // Briefly render the element so we can extract the HTML and CSS data for it
        const elemExtractor = (
            <DomExtractor
                width={width}
                height={height}
                onExtract={(htmlText, cssText) => {
                    const index = htmlText.indexOf(">");
                    if (index > -1) {
                        // Insert the `xmlns` attribute on the root element so it
                        //   properly renders within a <foreignObject> element
                        const before = htmlText.substring(0, index);
                        const after = htmlText.substring(index, htmlText.length);
                        htmlText = `${before} xmlns="http://www.w3.org/1999/xhtml"${after}`;
                    } else {
                        throw new Error(`[reactToSvg] Unable to insert xmlns="http://www.w3.org/1999/xhtml" into htmlText. Likely incomplete HTML provided.`);
                    }

                    // Wrap the HTML and CSS in an SVG element
                    const result = `
<svg
    xmlns="http://www.w3.org/2000/svg"
    width="${width}"
    height="${height}"
>
    <foreignObject width="100%" height="100%">
        <style>${cssText}</style>
        ${htmlText}
    </foreignObject>
</svg>
`;
                    resolve(result);
                }}
            >{element}</DomExtractor>
        );
        ReactDOM.render(
            elemExtractor,
            elemMount,
        );
    });

    ReactDOM.unmountComponentAtNode(elemMount);

    return result;
}
