import React, { Component } from "react";

import { browserHistory } from "js/react/history";
import { Divider, UINavigation, UINavigationItem } from "js/react/components/UiComponents";
import ProBadge from "js/react/components/ProBadge";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { FeatureType } from "common/features";
import { $ } from "js/vendor";
import { Key } from "js/core/utilities/keys";
import { PresentationLibraryController } from "js/editor/PresentationLibrary/PresentationLibraryController";
import { trackActivity } from "js/core/utilities/utilities";
import ErrorHandler from "js/core/utilities/errorHandler";
import { Gap20 } from "js/react/components/Gap";
import AppController from "js/core/AppController";
import DesignerBotIcon from "js/react/components/DesignerBotIcon";
import GeneratePresentationPane from "js/react/views/AIGeneration/panes/GeneratePresentationPane/GeneratePresentationPane";
import { PresentationActivityType } from "common/interfaces";
import { presentations as presentationsApi } from "apis/callables";
import pusher from "js/core/services/pusher";
import { PusherEventType } from "common/constants";

import { CreateBlankPresentationPane } from "js/editor/CreatePresentation/panes/CreateBlankPresentationPane";
import { ChooseStarterTemplatePane } from "js/editor/CreatePresentation/panes/ChooseStarterTemplatePane";
import { ChooseTeamTemplatePane } from "js/editor/CreatePresentation/panes/ChooseTeamTemplate";
import { ImportPresentationFromPPTPane } from "js/editor/CreatePresentation/panes/ImportPresentationFromPPTPane";
import { AnimatedFullScreenContainer } from "js/editor/components/AnimatedFullScreenContainer";
import { CreatePresentationDialogPaneType } from "js/editor/CreatePresentation/CreatePresentationDialogTypes";
import { PresentationTemplateController } from "js/core/dataServices/PresentationTemplateDataService";

export class CreatePresentationDialog extends Component {
    constructor() {
        super();

        this.state = {
            isOpen: true,
            selectedPaneId: "blank",
        };
    }

    componentDidMount() {
        $(window).on("keydown.add-slide-container", event => {
            if (event.which == Key.ESCAPE) {
                this.handleBackButton();
            }
        });
    }

    componentWillUnmount() {
        $(window).off("keydown.add-slide-container");
    }

    handleSelectPane = pane => {
        const { isTeamTemplate } = this.props;
        AppController.showCreatePresentationDialog({
            pane,
            state: {
                isTeamTemplate
            }
        });
    }

    handleBackButton = () => {
        // We want to be able to return to the correct page when we close the dialog
        browserHistory.goBack();
    }

    handleComplete = () => {
        this.closeDialog();
    }

    closeDialog() {
        AppController.showLibrary();
    }

    handleCreatePresentation = async options => {
        const {
            documentType,
            libraryFilter,
            isTeamTemplate,
            callback
        } = this.props;

        const workspaceId = AppController.workspaceId;

        try {
            let createdPresentation;
            switch (options.type) {
                case "team-template":
                    createdPresentation = await PresentationLibraryController.createPresentationFromTemplate({
                        template: options.template,
                        libraryFilter,
                    });
                    break;
                case "theme":
                    createdPresentation = await PresentationLibraryController.createPresentation({
                        documentType,
                        libraryFilter,
                        theme: options.theme
                    });
                    break;
                case "starter-template":
                    const { presentationId } = await presentationsApi.copyPresentation({
                        id: options.templateId,
                        workspaceId,
                    });

                    const props = {
                        presentationId: options.templateId,
                        source: "template"
                    };
                    trackActivity("Presentation", "GetDeck", null, null, props, { audit: true });

                    createdPresentation = await ds.presentations.getPresentation(presentationId);
                    PresentationLibraryController.editPresentation({ presentation: createdPresentation });

                    break;
                case "ppt-import":
                    createdPresentation = await ds.presentations.getPresentation(options.presentationId);

                    // Ensure the slide references are updated
                    if (!createdPresentation.get("slideRefs")) {
                        const waitForSlideRefs = new Promise(resolve => createdPresentation.once("change:slideRefs", resolve));

                        // Ensure real time updates are enabled
                        await createdPresentation.setAutoSync(true);

                        await waitForSlideRefs;
                    }

                    break;
                case "generate-with-ai":
                    createdPresentation = await PresentationLibraryController.createPresentation({
                        name: options.name,
                        documentType,
                        libraryFilter,
                        theme: options.theme || ds.builtInThemes.sample(),
                        defaultSlides: options.defaultSlides,
                        metadata: options.metadata
                    });
                    await createdPresentation.prepareSlides(false);
                    createdPresentation.slides.map(async slide => {
                        await slide.getThumbnailUrl(createdPresentation.id, "small", 0, true);
                    });
                    trackActivity("Presentation", "Generate", null, null, {
                        presentationId: createdPresentation.id,
                        numSlides: options.defaultSlides.length,
                        fromTour: false,
                    });
                    break;
            }

            // record presentation creation as activity log
            await presentationsApi.recordActivity({
                id: createdPresentation.id,
                activity: PresentationActivityType.CREATED,
                activityData: { creationType: options.type }
            });

            // check if first presentation flag exists
            if (!app.user.get("firstPresentationId")) {
                app.user.update({ firstPresentationId: createdPresentation.id });
                await app.user.updatePromise;
            }

            // Ensure the new presentation template
            //   triggers the create dialog when edited
            isTeamTemplate && createdPresentation.update({
                isTemplateConfirmed: false,
            });

            if (isTeamTemplate) {
                let presentationTemplate = await PresentationTemplateController.convertToPresentationTemplate(createdPresentation);
                if (presentationTemplate) {
                    PresentationLibraryController.editPresentation({
                        presentation: presentationTemplate
                    });
                }
            } else {
                if (callback) {
                    callback(createdPresentation);
                } else {
                    PresentationLibraryController.editPresentation({ presentation: createdPresentation });
                }
            }
        } catch (err) {
            ErrorHandler.handleSlideLimitReached(err, { workspaceId });
        }
    }

    render() {
        const { pane } = this.props;

        const workspaceId = AppController.workspaceId;

        const designerBotDisabled = app.user.features.isFeatureEnabled(FeatureType.PROHIBIT_GENERATIVE_AI, workspaceId);
        const designerBotAccessible = app.user.features.isFeatureEnabled(FeatureType.DESIGNER_BOT, workspaceId);

        return (
            <AnimatedFullScreenContainer>
                <UINavigation
                    selected={pane}
                    onSelect={this.handleSelectPane}
                    onBack={this.closeDialog}
                >
                    <label>Start from...</label>
                    <Gap20 />
                    <UINavigationItem
                        key={CreatePresentationDialogPaneType.BLANK_PRESENTATION}
                        id={CreatePresentationDialogPaneType.BLANK_PRESENTATION}
                        title="Blank Presentation"
                        icon="add_circle"
                    />
                    {!designerBotDisabled && <Divider color="#444" margin={15} />}
                    {!designerBotDisabled &&
                        <UINavigationItem
                            key={CreatePresentationDialogPaneType.GENERATE_WITH_AI}
                            id={CreatePresentationDialogPaneType.GENERATE_WITH_AI}
                            title="Generate with AI..."
                            image={<DesignerBotIcon />}
                            // styles={{ background: themeColors.aiColor, color: "white" }}
                            proBadge={
                                !designerBotAccessible &&
                                <ProBadge
                                    upgradeType={UpgradePlanDialogType.UPGRADE_PLAN}
                                    show={!designerBotAccessible}
                                    analytics={{ cta: "PresentationBot", ...ds.selection?.presentation?.getAnalytics() }}
                                    workspaceId={AppController.workspaceId}
                                />
                            }
                        />
                    }

                    <Divider color="#444" margin={15} />
                    <UINavigationItem
                        key={CreatePresentationDialogPaneType.TEAM_TEMPLATES}
                        id={CreatePresentationDialogPaneType.TEAM_TEMPLATES}
                        title="Team Templates"
                        icon="video_library"
                        proBadge={
                            <ProBadge
                                upgradeType={UpgradePlanDialogType.TEAM_NOOP}
                                show={!app.user.features.isFeatureEnabled(FeatureType.VIEW_LIBRARY_ITEMS, workspaceId)}
                                analytics={{ cta: "TeamTemplates" }}
                                workspaceId={workspaceId}
                            />
                        }
                    />
                    {
                        app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_INSPIRATION_SLIDES, workspaceId) &&
                        <UINavigationItem
                            key={CreatePresentationDialogPaneType.STARTER_TEMPLATES}
                            id={CreatePresentationDialogPaneType.STARTER_TEMPLATES}
                            title="Starter Templates"
                            icon="lightbulb"
                        />
                    }

                    <Divider color="#444" margin={15} />
                    {
                        app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_IMPORT_PPT, workspaceId) &&
                        <UINavigationItem
                            key={CreatePresentationDialogPaneType.IMPORT_PPT}
                            id={CreatePresentationDialogPaneType.IMPORT_PPT}
                            title="Import PPT..."
                            icon="upload"
                        />
                    }
                </UINavigation>

                {this.renderSelectedPane()}
            </AnimatedFullScreenContainer>
        );
    }

    renderSelectedPane = () => {
        let { pane } = this.props;
        const requireTeamTheme = !app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_CUSTOM_THEMES, AppController.workspaceId);

        switch (pane) {
            case CreatePresentationDialogPaneType.BLANK_PRESENTATION:
                return (
                    <CreateBlankPresentationPane
                        onCreate={theme => this.handleCreatePresentation({ type: "theme", theme })}
                        requireTeamTheme={requireTeamTheme}
                    />);
            case CreatePresentationDialogPaneType.STARTER_TEMPLATES:
                return <ChooseStarterTemplatePane onCreate={this.handleCreatePresentation} />;
            case CreatePresentationDialogPaneType.TEAM_TEMPLATES:
                return <ChooseTeamTemplatePane onCreate={this.handleCreatePresentation} />;
            case CreatePresentationDialogPaneType.GENERATE_WITH_AI:
                return (<GeneratePresentationPane onCreate={this.handleCreatePresentation} requireTeamTheme={requireTeamTheme} />);
            case CreatePresentationDialogPaneType.IMPORT_PPT:
                return <ImportPresentationFromPPTPane onCreate={this.handleCreatePresentation} />;
        }
    }
}
