import Cookies from "js-cookie";

import firebase from "js/firebase";
import { openWindowAndWaitForItToBeClosed } from "js/core/utilities/selfClosingWindow";

export class DropboxAuthError extends Error { }
export class DropboxAuthFlowWasInterruptedError extends DropboxAuthError { }

interface DropboxAuthTokens {
    accessToken: string
}

/**
 * Grants our app offline access for the given scope, executes the redirect ouath flow in a separate window
 * and saves the tokens
 */
export async function grantOfflineAccess(): Promise<DropboxAuthTokens> {
    const userIdToken = await firebase.auth().currentUser.getIdToken();
    Cookies.set("user_id_token", userIdToken, { sameSite: "strict", expires: new Date(Date.now() + 60 * 1000) });

    const url = `${window.location.origin}/dropbox/auth` +
        `?continueUrl=${encodeURIComponent("/close")}` +
        "&requestOfflineAccess=true";

    const lastSearchParams = await openWindowAndWaitForItToBeClosed(url);

    const error = lastSearchParams.get("dropbox_auth_error");
    if (error) {
        throw new DropboxAuthError(error);
    }

    const accessToken = Cookies.get("dropbox_access_token");
    Cookies.remove("dropbox_access_token");

    if (!accessToken && !error) {
        throw new DropboxAuthFlowWasInterruptedError();
    }

    return { accessToken };
}
