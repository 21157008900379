import { EndpointCallableDefinition } from "./types/client";
import {
    BadRequestError,
    NotFoundError,
    InternalServerError,
    UnauthorizedError,
    ForbiddenError,
    TooManyRequestsError,
    ConflictError
} from "./types/errors";
import firebase from "../../js/firebase";
import getLogger, { LogGroup, sessionId } from "../../js/core/logger";

const logger = getLogger(LogGroup.API);

declare global {
    interface Window { overrideIdToken: string; }
}

/**
 * Composes the callable API function from EndpointCallableDefinition
 */
export function getCallable<Request, Response>(apiName: string, endpointName: string, endpointDefinition: EndpointCallableDefinition<Request>, useLogger: boolean = true) {
    /**
     * Callable, typed API function
     */
    const callable = async (request: Request) => {
        const { url, options } = endpointDefinition.composer(request);

        // @ts-ignore
        const baseUrl = window.apis[`api-${apiName.toLowerCase()}`];

        let idToken: string = null;
        if (window.overrideIdToken) {
            idToken = window.overrideIdToken;
        } else if (firebase.auth().currentUser) {
            idToken = await firebase.auth().currentUser.getIdToken();
        }

        const res = await fetch(`${baseUrl}${url}`, {
            ...options,
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "Authorization": idToken ? `Bearer ${idToken}` : null,
                "X-Client-Session-Id": sessionId,
                ...(options.headers ?? {}),
            }
        });

        if (res.status < 400) {
            return res.json() as Promise<Response>;
        }

        const requestId = res.headers.get("X-Request-Id");

        const responseBody = await res.json().catch(() => { });

        let error: Error;
        if (res.status === 400) {
            error = new BadRequestError("Bad Request", responseBody);
        } else if (res.status === 404) {
            error = new NotFoundError("Not Found", responseBody);
        } else if (res.status === 401) {
            error = new UnauthorizedError("Unauthorized", responseBody);
        } else if (res.status === 403) {
            error = new ForbiddenError("Forbidden", responseBody);
        } else if (res.status === 429) {
            error = new TooManyRequestsError("Too Many Requests", responseBody);
        } else if (res.status === 409) {
            error = new ConflictError("Conflict", responseBody);
        } else {
            error = new InternalServerError("Internal Server Error", responseBody);
        }

        const logData = {
            url,
            method: options.method,
            status: res.status,
            body: request,
            requestId,
            endpointName,
            apiName
        };

        if (useLogger) {
            logger.error(error, `[callable][${apiName}] request failed`, logData);
        } else {
            console.error(error, `[callable][${apiName}] request failed`, logData);
        }

        throw error;
    };

    return callable;
}
