import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { app } from "js/namespaces";
import Loadable from "js/react/components/Loadable";
import { UIPane } from "js/react/components/UiComponents";
import { FlexBox } from "js/react/components/LayoutGrid";
import { Gap5 } from "js/react/components/Gap";
import { Breadcrumb } from "js/editor/PresentationLibrary/PresentationLibrary";
import { SearchBarContainer, UIPaneResultsContainer } from "js/react/views/AddSlide/Panes/Components/SearchBox";
import DropDown from "js/react/components/DropDown";
import InfoToolTip from "js/react/components/InfoToolTip";
import { BrandingPermission, FeatureType } from "common/features";
import { trackActivity } from "js/core/utilities/utilities";
import AppController from "js/core/AppController";

const gridTemplateColumns = "1fr 250px";

const selectOptions = [
    {
        label: "All Members",
        value: BrandingPermission.ALL,
    },
    {
        label: "Librarians and Owners Only",
        value: BrandingPermission.OWNER_AND_LIB,
    },
    {
        label: "Nobody in this Workspace",
        value: BrandingPermission.NONE,
    },
];

const LargeDropDown = withStyles({
    button: {
        fontSize: "16px",
        width: "100%",
    },
    buttonLabel: {
        justifyContent: "space-between",
    },
})(DropDown);

const GuardrailRow = props => {
    let {
        handleChange,
        label,
        tooltip,
        field,
        value,
    } = props;

    let index = selectOptions.findIndex(x => x.value === value);

    const handleSelect = value => {
        if (value === props.value) {
            return false;
        }

        let trackProps = {
            guard: field,
            permission: value,
        };
        trackActivity("TeamGuardrails", "GuardChange", null, null, trackProps, { audit: false });
        return !!handleChange && handleChange({ [field]: value });
    };

    return (
        <div className="list-item no-highlight" style={{ gridTemplateColumns }}>
            <FlexBox middle left className="list-field bold">
                {label}
                <Gap5 />
                <InfoToolTip title={tooltip} />
            </FlexBox>
            <div className="list-field bold">
                <LargeDropDown
                    variant="text"
                    onSelect={handleSelect}
                    items={selectOptions}
                    selectedIndex={index}
                    type="transparent"
                    aboveAll
                />
            </div>
        </div>
    );
};

export default class TeamBrandingGuardrailsPane extends Component {
    state = {
        isLoading: true,
    }

    componentDidMount() {
        let brandingPermissions = app.user.features.getFeatureProps(FeatureType.WORKSPACE_BRANDING_PERMISSIONS, AppController.orgId);

        this.setState({
            isLoading: false,
            ...brandingPermissions,
        });
    }

    handleChange = stateDelta => {
        AppController.currentTeam.update({
            workspaceSettings: {
                brandingPermissions: stateDelta,
            }
        });
        this.setState(stateDelta);
        return true;
    }

    render() {
        const {
            isLoading,
            inspirationSlides,
            customThemes,
            stockLibraries,
            webImages,
            classicSlides,
            convertToClassic,
            importPPT,
        } = this.state;

        return (
            <UIPane>
                <SearchBarContainer className="search-container">
                    <Breadcrumb>Brand Guardrails</Breadcrumb>
                </SearchBarContainer>
                <UIPaneResultsContainer>
                    <Loadable isLoading={isLoading}>
                        <div className="beautiful-list">
                            <div className="list-header" style={{ gridTemplateColumns }}>
                                <div className="list-field-header">Feature</div>
                                <div className="list-field-header">Who has access?</div>
                            </div>
                            <GuardrailRow
                                label="Built-in Starter Templates and Inspiration Slides"
                                tooltip="Decide who on your team has access to our pre-designed starter materials."
                                field="inspirationSlides"
                                value={inspirationSlides}
                                handleChange={this.handleChange}
                            />
                            <GuardrailRow
                                label="Built-in and Custom Themes"
                                tooltip="Decide who on your team has access to themes outside of your Team Theme."
                                field="customThemes"
                                value={customThemes}
                                handleChange={this.handleChange}
                            />
                            <GuardrailRow
                                label="Stock Image and Video Libraries"
                                tooltip="Decide who on your team has access to our stock media library, in addition to Team Assets, icons, logos and media upload."
                                field="stockLibraries"
                                value={stockLibraries}
                                handleChange={this.handleChange}
                            />
                            <GuardrailRow
                                label="Web Images"
                                tooltip="Decide who on your team has access to our web image search, in addition to Team Assets, icons, logos, and media upload."
                                field="webImages"
                                value={webImages}
                                handleChange={this.handleChange}
                            />
                            <GuardrailRow
                                label="Classic Slides"
                                tooltip="Decide who on your team can design a slide from scratch with our blank, Classic slide."
                                field="classicSlides"
                                value={classicSlides}
                                handleChange={this.handleChange}
                            />
                            <GuardrailRow
                                label="Convert to Classic"
                                tooltip="Decide who on your team can remove design guardrails by converting Smart Slides to Classic."
                                field="convertToClassic"
                                value={convertToClassic}
                                handleChange={this.handleChange}
                            />
                            <GuardrailRow
                                label="PowerPoint Import"
                                tooltip="Decide who on your team can import PowerPoint slides into presentations."
                                field="importPPT"
                                value={importPPT}
                                handleChange={this.handleChange}
                            />
                        </div>
                    </Loadable>
                </UIPaneResultsContainer>
            </UIPane>
        );
    }
}
