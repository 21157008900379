import { GlobalStateController } from "bai-react-global-state";
import { v4 as uuid } from "uuid";

import {
    findElementsWithImages,
    generateImagesForElement,
    buildSlideModel
} from "js/core/services/slideModelBuilder";
import AiGenerationService, { GeneratePresentationOutlineRequestWithFiles, InsufficientCreditsError, TooManyRequestsError } from "js/core/services/aiGeneration";
import { getRandomTheme } from "common/themes";
import { Theme } from "js/core/models/theme";
import {
    AiGeneratedSlideType,
    AiGeneratedSlideTypeGroups,
    AiGeneratedSlideGroupType,
    GeneratePresentationOutlineResponse,
    GeneratedSlideModel
} from "common/aiConstants";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { withMaxConcurrency } from "common/utils/withMaxConcurrency";
import * as geom from "js/core/utilities/geom";
import getLogger, { LogGroup } from "js/core/logger";
import AppController from "js/core/AppController";
import { ds } from "js/core/models/dataService";
import { ShowInsufficientCreditsDialog, ShowTooManyRequestsDialog } from "js/react/components/Dialogs/CredtsErrorDialog";

const logger = getLogger(LogGroup.AI);

export enum ContextSourceType {
    FILE = "text-document",
    WEBPAGE = "webpage",
    TEXT = "text"
}

export interface ContextSource {
    type: ContextSourceType;
    file?: File;
    url?: string;
    text?: string;
}

export enum PresentationGenerationStep {
    CHOOSE_FLOW = "choose-flow",
    PROMPT = "prompt",
    DOC2DECK = "doc-to-deck",
    GENERATING_OUTLINE = "generating-outline",
    EDITING_OUTLINE = "editing-outline",
    GENERATING_SLIDES = "generating-slides",
    THEME = "theme",
    DONE = "done"
}

export interface StartGenerationParams {
    prompt: string;
    files: File[];
    contextUrls: string[];
    documentText: string;
}

export interface SlideOutline {
    id: string;
    title: string;
    summary: string;
    prompt?: string;
    type: AiGeneratedSlideType | "";

    bounds?: geom.Rect;
    dragBounds?: geom.Rect;
}

interface FauxBackboneTheme {
    id: string;
    get: (key: string) => any;
    loadTheme: () => Promise<void>;
}

export interface GeneratePresentationState {
    step: PresentationGenerationStep;
    startGenerationParams?: StartGenerationParams;
    theme: FauxBackboneTheme;
    generatePresentationOutlineResponse?: GeneratePresentationOutlineResponse;
    slideOutlines: SlideOutline[];
    slideModels: GeneratedSlideModel[];
    showChooseThemeDialog: boolean;
    aiCreditsBalance: number;
    workspaceId: string | null;
}

const initialState: GeneratePresentationState = {
    step: PresentationGenerationStep.PROMPT,
    theme: new Theme(getRandomTheme(), { disconnected: true, autoLoad: false }),
    slideOutlines: [],
    slideModels: [],
    startGenerationParams: undefined,
    generatePresentationOutlineResponse: undefined,
    showChooseThemeDialog: false,
    aiCreditsBalance: 0,
    workspaceId: "personal"
};

export interface OnPresentationGeneratedCallbackParams {
    type: string,
    defaultSlides: Object[],
    theme: Object,
    name: string,
    metadata
}

class GeneratePresentationController extends GlobalStateController<GeneratePresentationState> {
    onPresentataionGeneratedCallback?: (params: OnPresentationGeneratedCallbackParams) => void

    async reset(
        workspaceId: string,
        onPresentataionGeneratedCallback?: (params: OnPresentationGeneratedCallbackParams) => void
    ) {
        const state = { ...initialState, workspaceId };

        const teamThemes = ds.sharedThemes.getThemesInWorkspace(AppController.orgId)
            .sort((a, b) => a.id.localeCompare(b.id));
        const userThemes = ds.userThemes.getThemesInWorkspace(AppController.orgId);

        if (teamThemes?.length > 0) {
            state.theme = teamThemes[0];
        } else if (userThemes?.length > 0) {
            state.theme = userThemes[0];
        } else {
            state.theme = new Theme(getRandomTheme(), { disconnected: true, autoLoad: false })
        }

        await this._updateState(() => state);

        await this.loadAiCreditsBalance();

        if (onPresentataionGeneratedCallback) {
            this.onPresentataionGeneratedCallback = onPresentataionGeneratedCallback;
        }
    }

    async showChooseFlow() {
        await this._updateState({
            step: PresentationGenerationStep.CHOOSE_FLOW
        });
    }

    async showPrompt() {
        await this._updateState({
            step: PresentationGenerationStep.PROMPT
        });
    }

    async showDocToDeck() {
        await this._updateState({
            step: PresentationGenerationStep.DOC2DECK
        });
    }

    async generatePresentationOutline(startGenerationParams: StartGenerationParams) {
        await this._updateState({
            step: PresentationGenerationStep.GENERATING_OUTLINE,
            startGenerationParams
        });

        try {
            await this._generatePresentationOutline();
            await this._generateSlideOutlines();
            await this.loadAiCreditsBalance();

            await this.generatePresentationFromOutline();
        } catch (err) {
            logger.error(err, "[GeneratePresentationController] error generating presentation", { startGenerationParams });

            if (err instanceof InsufficientCreditsError) {
                ShowInsufficientCreditsDialog(() => this.loadAiCreditsBalance(true));
            } else if (err instanceof TooManyRequestsError) {
                ShowTooManyRequestsDialog();
            } else {
                ShowErrorDialog({
                    title: "Oops, DesignerBot AI wasn't able to generate a valid presentation for your prompt.",
                    message: "This can happen unexpectedly sometimes. You can either try again or try to rephrase your prompt."
                });
            }

            await this._updateState({ step: PresentationGenerationStep.PROMPT });
        }
    }

    async setSlideOutlines(slideOutlines: SlideOutline[]) {
        await this._updateState({ slideOutlines });
    }

    async setShowChooseThemeDialog(showChooseThemeDialog: boolean) {
        await this._updateState({ showChooseThemeDialog });
    }

    async setTheme(theme: FauxBackboneTheme) {
        await this._updateState({ theme });
    }

    async generatePresentationFromOutline() {
        try {
            const { generatePresentationOutlineResponse } = this._state;
            await this._updateState({ step: PresentationGenerationStep.GENERATING_SLIDES, slideModels: new Array(this._state.slideOutlines.length).fill(null) });

            await this._state.theme.loadTheme();

            await withMaxConcurrency(this._state.slideOutlines.map((slideOutline, idx) => async () => {
                const model = await this._generateSlideModel(slideOutline, idx);
                const slideModels = [...this._state.slideModels];
                slideModels[idx] = model;
                await this._updateState({ slideModels });
            }), 5);

            const defaultSlides = this._state.slideModels
                .filter(Boolean)
                .map(generatedModel => {
                    const model = { ...generatedModel } as any;
                    model.templateId = model.template_id;
                    model.data = model.states[0];
                    delete model.states;
                    return model;
                });

            if (defaultSlides.length === 0) {
                throw new Error("Couldn't generate any slides");
            }

            await this.loadAiCreditsBalance();

            if (this.onPresentataionGeneratedCallback) {
                this.onPresentataionGeneratedCallback({
                    type: "generate-with-ai",
                    defaultSlides,
                    theme: this._state.theme,
                    name: this._state.generatePresentationOutlineResponse.title.replace(/\*/g, ""),
                    metadata: generatePresentationOutlineResponse
                });
            }

            await this._updateState({ step: PresentationGenerationStep.DONE });
        } catch (err) {
            logger.error(err, "[GeneratePresentationController] error generating presentation from outline");

            if (err instanceof InsufficientCreditsError) {
                ShowInsufficientCreditsDialog(() => this.loadAiCreditsBalance(true));
            } else if (err instanceof TooManyRequestsError) {
                ShowTooManyRequestsDialog();
            } else {
                ShowErrorDialog({
                    title: "Oops, DesignerBot AI wasn't able to generate a valid presentation for your outline.",
                    message: "This can happen unexpectedly sometimes. You can either try again or try to rephrase your prompt."
                });
            }

            await this._updateState({ step: PresentationGenerationStep.PROMPT });
        }
    }

    async loadAiCreditsBalance(handleErrors = false) {
        try {
            const { workspaceId } = this._state;
            if (workspaceId === null) {
                await this._updateState({ aiCreditsBalance: 0 });
                return;
            }

            const aiCreditsBalance = await AiGenerationService.getCreditsBalance(workspaceId);
            await this._updateState({ aiCreditsBalance });
        } catch (err) {
            if (!handleErrors) {
                throw err;
            }

            logger.error(err, "[GeneratePresentationController] error loading AI credits balance");
        }
    }

    private async _generatePresentationOutline() {
        const { startGenerationParams: { prompt, files, contextUrls, documentText } } = this._state;

        const generatePresentationOutlineRequest: GeneratePresentationOutlineRequestWithFiles = {
            allowedSlideTypes: AiGeneratedSlideTypeGroups[AiGeneratedSlideGroupType.PRESENTATION],
            prompt,
            files: [...files],
            contextUrls,
        };

        if (documentText) {
            generatePresentationOutlineRequest.files.push(new File([documentText], "document.txt", { type: "text/plain" }));
        }

        const generatePresentationOutlineResponse = await AiGenerationService.generatePresentationOutline(generatePresentationOutlineRequest);
        await this._updateState({ generatePresentationOutlineResponse });
    }

    private async _generateSlideOutlines() {
        const { generatePresentationOutlineResponse } = this._state;

        const slideOutlines: SlideOutline[] = [
            // Predefined title slide
            {
                id: uuid(),
                title: generatePresentationOutlineResponse.title,
                summary: "Introductory slide",
                type: AiGeneratedSlideType.TITLE
            },
            // Ai generated slides
            ...generatePresentationOutlineResponse.slides.map(slide => ({
                id: uuid(),
                title: slide.title,
                summary: slide.summary,
                type: slide.type,
            }))
        ];

        await this._updateState({ slideOutlines });
    }

    private async _generateSlideModel(slideOutline: SlideOutline, idx, isRetry = false) {
        const { generatePresentationOutlineResponse } = this._state;

        try {
            const generateSlideResponse = await AiGenerationService.generateSlide({
                prompt: slideOutline.prompt,
                slideTitle: slideOutline.title,
                presentationTitle: generatePresentationOutlineResponse.title,
                // Empty string is auto
                allowedSlideTypes: slideOutline.type === "" ? Object.values(AiGeneratedSlideType) : [slideOutline.type],
                slideSummary: slideOutline.summary,
                indexFileName: generatePresentationOutlineResponse.indexFileName,
                traceData: {
                    parentRequest: generatePresentationOutlineResponse.traceData?.request
                }
            });

            const model = await buildSlideModel(generateSlideResponse);

            if (idx === 0) {
                const imagesToGenerate = findElementsWithImages(model);
                if (imagesToGenerate.length > 0) {
                    await withMaxConcurrency(imagesToGenerate.map(image => () => generateImagesForElement(image, generatePresentationOutlineResponse)), 3);
                }
            }

            return model;
        } catch (err) {
            if (isRetry) {
                logger.error(err, "[GenerateSlidesStep] error generating slide", { slideOutline });
            } else {
                logger.warn("[GenerateSlidesStep] error generating slide", { slideOutline, error: err });
            }

            if (!isRetry) {
                return this._generateSlideModel(slideOutline, true);
            }

            return null;
        }
    }
}

const generatePresentationController = new GeneratePresentationController(initialState);
export default generatePresentationController;
