import safeStringify from "fast-safe-stringify";

import isConnected from "js/core/utilities/isConnected";
import {
    LogGroup,
    LogLevel,
    LoggerTransportEntryData,
    ApiTransportLogEntry,
    LoggerTransportContext,
    LogEntryContext
} from "js/core/logger/types";
import LoggerTransport from "js/core/logger/transports/LoggerTransport";

class ApiTransport extends LoggerTransport {
    static MAX_ENTRIES_BEFORE_FLUSH = 100
    static MAX_ENTRIES_TO_SEND = 100
    static MAX_SERIALIZED_ENTRY_SIZE_CHARS = 10 * 1024 // approx 10kB

    private _entries: Array<ApiTransportLogEntry> = []

    private _flushLoop: Promise<void> | null

    constructor(context: LoggerTransportContext) {
        super(context);

        this._initializeFlushLoop();

        window.addEventListener("beforeunload", this._onBeforeUnload);
    }

    private _onBeforeUnload = () => {
        this.dispose();
    }

    private _initializeFlushLoop() {
        this._flushLoop = Promise.resolve();

        let flushAttemptedAt = Date.now();
        let flushFailedAt: number = null;
        let wasOffline = false;

        const onFlushLoopIteration = async () => {
            if (!this._flushLoop) {
                // _flushLoop was removed, transport was disposed, stop the loop
                return;
            }

            const now = Date.now();

            let shouldFlush = false;
            if (flushFailedAt) {
                if (wasOffline && isConnected.connected) {
                    // We were offline and now we're online, should flush asap
                    shouldFlush = true;
                } else {
                    // If we failed to flush before, then do exponential backoff and flush only when it passed
                    const waitMs = Math.min(Math.max(1000, now - flushFailedAt), 30 * 1000);
                    if (now - flushAttemptedAt >= waitMs) {
                        shouldFlush = true;
                    }
                }
            } else {
                if (this._entries.some(entry => entry.level === LogLevel.ERROR)) {
                    // Have errors, should flush asap
                    shouldFlush = true;
                } else if (this._entries.length >= ApiTransport.MAX_ENTRIES_BEFORE_FLUSH) {
                    // Reached max entries, should flush asap
                    shouldFlush = true;
                } else if (now - flushAttemptedAt >= 10 * 1000) {
                    // It's been 10 seconds since last flush, should flush
                    shouldFlush = true;
                }
            }

            if (!isConnected.connected) {
                // Don't flush if we're not connected
                shouldFlush = false;
                wasOffline = true;
            } else {
                wasOffline = false;
            }

            if (shouldFlush) {
                flushAttemptedAt = now;
                try {
                    await this._flush();
                    flushFailedAt = null;
                } catch {
                    if (!flushFailedAt) {
                        flushFailedAt = now;
                    }
                }
            }

            await new Promise(resolve => setTimeout(resolve, 500));

            this._flushLoop = this._flushLoop.then(() => onFlushLoopIteration());
        };

        // Start the loop
        onFlushLoopIteration();
    }

    private async _flush() {
        if (!isConnected.connected) {
            return;
        }

        if (this._entries.length === 0) {
            return;
        }

        const entriesToSend = this._entries.splice(0, ApiTransport.MAX_ENTRIES_TO_SEND);

        try {
            await this._sendEntriesToApi(entriesToSend);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error("[Logger][ApiTransport] _sendEntriesToApi failed, returning entries back to queue", err);
            this._entries.push(...entriesToSend);

            throw err;
        }
    }

    private async _sendEntriesToApi(entries: Array<ApiTransportLogEntry>) {
        // @ts-ignore // Safe to ignore because we use Webpack
        const { clientLogs: { writeLogs } } = await import("apis/callables");

        await writeLogs({
            entries: entries
                .map(entry => ({
                    ...entry,
                    context: {
                        // Combining the context of the transport and the context of the log entry
                        ...this._context,
                        ...entry.context
                    }
                }))
                .filter(entry => {
                    const serializedEntrySize = safeStringify(entry).length;
                    if (serializedEntrySize > ApiTransport.MAX_SERIALIZED_ENTRY_SIZE_CHARS) {
                        // eslint-disable-next-line no-console
                        console.error("[Logger][ApiTransport] entry size is too big, skipping", entry);
                        return false;
                    }
                    return true;
                })
        });
    }

    public dispose() {
        this._flushLoop = null;

        window.removeEventListener("beforeunload", this._onBeforeUnload);

        this._flush().catch(() => { }); // Fire and forget
    }

    public log(context: LogEntryContext, group: LogGroup, level: LogLevel, message: string, data: LoggerTransportEntryData, error?: Error) {
        this._entries.push({
            timestamp: Date.now(),
            level,
            group,
            context,
            message: `[${group}] ${message}`,
            data,
            error: error ? { name: error.name ?? error.constructor?.name ?? "anonymous", message: error.message ?? "", stack: error.stack ?? "" } : null
        });
    }
}

export default ApiTransport;
