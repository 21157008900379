import { _, Backbone } from "js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import Adapter from "./adapter";
import DummyAdapter from "./dummyAdapter";
import { mergeChanges, computeChangeSet } from "common/utils/changeset";
import { deepClone } from "../utilities/extensions";

const logger = getLogger(LogGroup.STORAGE);

function sanitizeAttrs(attrs, ignoredKeys = []) {
    if (!attrs) {
        return {};
    }
    attrs = Object.assign({}, attrs);
    delete attrs.id;

    let keys = Object.keys(attrs);

    // TODO fix escape logic to accomodate BA-6082 and related problems
    // keys.forEach(key => {
    //     if (_.isString(attrs[key]) && !isUrl(attrs[key])) {
    //         attrs[key] = _.escape(attrs[key]);
    //     }
    // });

    // if only remaining keys are the ignoredKeys, remove them
    // if other keys are passed, do not ignore
    if (keys.length === ignoredKeys.length) {
        ignoredKeys.map(key => {
            delete attrs[key];
        });
    }

    return attrs;
}

const StorageModel = Backbone.Model.extend({

    root: null,
    profiler: null,

    readonly: false,

    getRoot: function() {
        return this.root;
    },

    createAdapter(options) {
        return Adapter.create(options);
    },

    getDummyAdapterOptions() {
        return {};
    },

    constructor: function(model, options) {
        Backbone.Model.call(this, model, options);
        options = Object.assign({ autoLoad: true }, options);
        this.root = options.root || this.getRoot();
        this.userId = options.userId;
        this.autoSync = options.autoSync !== false;

        if (options.disconnected || window.isFirebaseDisabled) {
            this.adapter = new DummyAdapter(this.getDummyAdapterOptions());
        } else {
            this.adapter = this.createAdapter({
                autoSync: this.autoSync,
                readonly: this.readonly,
                userId: this.userId
            });
        }
        this.profiler = options.profiler || this.profiler;

        this._initialModel = model;
        this._defaultValue = options.defaultValue;
        this.ensurePersisted = options.ensurePersisted || this.ensurePersisted;

        this.loaded = false;

        this.instantiatedAt = Date.now();

        if (options.autoLoad) {
            this.load();
        }
    },

    async setAutoSync(autoSync) {
        if (this.autoSync === autoSync) {
            // Nothing to do, already set to the same value
            return;
        }

        if (this.disconnected || this.adapter instanceof DummyAdapter) {
            // Nothing to do, already disconnected
            return;
        }

        // Ensure loaded
        if (this.loadPromise) {
            await this.loadPromise;
        }

        // Ensure all updates have propagated
        if (this.updatePromise) {
            await this.updatePromise;
        }

        this.autoSync = autoSync;

        await this.adapter.setAutoSync(autoSync);
    },

    getIgnoredKeys() {
        return [];
    },

    generateLoadPromise(waitForPlainProperties) {
        const profilerId = this.profiler ? this.profiler.start(this.id) : null;
        const id = this._initialModel && this._initialModel.id;
        let model = sanitizeAttrs(this._initialModel, this.getIgnoredKeys());
        if (Object.keys(model).length === 0) {
            model = null;
        }

        const loadPromise = new Promise((resolve, reject) => {
            this.once("load", () => {
                this.loaded = true;
                this.off("loaderror");
                if (this.profiler) {
                    this.profiler.end(profilerId);
                }
                resolve(this);
            });
            this.once("loaderror", err => {
                this.loaded = true;
                this.off("load");
                if (this.profiler) {
                    this.profiler.end(profilerId);
                }
                reject(err);
            });
        });

        const connectId = this.adapter.connect({
            root: this.root,
            id: id,
            data: model,
            onRemoteChange: this.handleRemoteChange.bind(this),
            onRemoteError: this.handleRemoteError.bind(this),
            defaultValue: this._defaultValue,
            ensurePersisted: this.ensurePersisted,
            waitForPlainProperties
        });

        if (connectId !== id) {
            this.set("id", connectId, { silent: true });
        }

        if (model !== null && !this.ensurePersisted) {
            this.loaded = true;
            this.trigger("load", this);
        } else {
            this.loaded = false;
        }

        delete this._initialModel;
        delete this._defaultValue;

        return loadPromise;
    },

    load: function(waitForPlainProperties) {
        if (!this.loadPromise) {
            this.loadPromise = this.generateLoadPromise(waitForPlainProperties);
        }
        return this.loadPromise;
    },

    sync: function() {
        //VOID
    },

    handleRemoteError: function(err) {
        if (!this.loaded) {
            this.trigger("loaderror", err);
        } else {
            this.onUpdateError(err);
        }
    },

    onUpdateError: function(err) {
        logger.error(err, "[StorageModel] onUpdateError()");
    },

    handleRemoteChange: function(type, data) {
        let changeSet;
        switch (type) {
            case Adapter.TYPE.initialize:
                this.set(data, {
                    initialize: true
                });
                if (!this.isLoaded) {
                    this.isLoaded = true;
                    this.trigger("load", this);
                }
                changeSet = {
                    original: {},
                    update: data,
                    hasUpdates: true
                };
                break;
            case Adapter.TYPE.remove: {
                changeSet = {
                    original: Object.assign({}, this.attributes),
                    update: {},
                    hasUpdates: true
                };
                this.destroy({
                    remoteChange: true
                });
                break;
            }
            case Adapter.TYPE.replace:
                changeSet = this._setData(data);
                break;
            case Adapter.TYPE.update:
                data = mergeChanges(this.attributes, data);
                changeSet = this._setData(data);
                break;
            default:
                const err = new Error(`Unsupported type ${type} from adapter ${this.adapter.toString()}`);
                logger.error(err, "[StorageModel] handleRemoteChange()", { type });
                changeSet = {
                    original: {},
                    update: {},
                    hasUpdates: false
                };
        }

        return changeSet;
    },

    _setData: function(data) {
        const removedKeys = this.unsetMissingAttrs(data, { silent: true });
        const options = {
            remoteChange: true,
            removedKeys: removedKeys
        };
        const changeSet = computeChangeSet(this.attributes, data, true);
        this.set(data, options);
        // trigger a change event if keys were removed and data did not change.
        if (!this.hasChanged() && removedKeys.length > 0) {
            this.trigger("change", this, options);
        }
        return changeSet;
    },

    replace: function(attrs, options) {
        const attributes = sanitizeAttrs(this.attributes);
        attrs = sanitizeAttrs(attrs);
        this.adapter.update(Adapter.TYPE.replace, attrs, _.cloneDeep(attributes));
        this.set(attrs, Object.assign({
            removedKeys: this.unsetMissingAttrs(attrs, options)
        }, options));
    },

    update: function(attrs, options) {
        // sanity check
        if (Array.isArray(attrs)) {
            throw new Error("attrs should not be an array");
        }
        // sanity check
        for (const key of Object.keys(attrs)) {
            if (key.includes("/")) {
                throw new Error("key should not contain '/'");
            }
        }
        options = options || {};
        const attributes = sanitizeAttrs(this.attributes);
        attrs = sanitizeAttrs(attrs);
        let removeMissingKeys = options.removeMissingKeys || false;
        if (options.replaceKeys) {
            attrs = Object.assign({}, attributes, attrs);
            removeMissingKeys = true;
        }
        const changeSet = computeChangeSet(attributes, attrs, removeMissingKeys);
        if (!changeSet.hasUpdates || options.computeChangeSet) {
            this.updatePromise = Promise.resolve();
            return changeSet;
        }
        attrs = mergeChanges(attributes, changeSet.update);
        this.set(attrs, Object.assign({
            removedKeys: this.unsetMissingAttrs(attrs, { ...options })
        }, options));
        if (options.save !== false) {
            this.updatePromise = this.adapter.update(Adapter.TYPE.update, changeSet.update, changeSet.original);
        }
        return changeSet;
    },

    unsetMissingAttrs: function(attrs, options = {}) {
        const unsetKeys = [];
        for (let key in this.attributes) {
            if (this.attributes.hasOwnProperty(key) && !attrs.hasOwnProperty(key) && key !== "id") {
                unsetKeys.push(key);
                this.unset(key, { ...options, silent: options.unsetSilent !== undefined ? options.unsetSilent : options.silent });
            }
        }
        return unsetKeys;
    },

    // Exposed for testing.
    getSanitizedAttrs: function() {
        return sanitizeAttrs(this.attributes);
    },

    disconnect: function() {
        this.adapter.disconnect();
        this.off();
    },

    destroy: function(options) {
        if (!options || !options.remoteChange) {
            this.adapter.update(Adapter.TYPE.remove).then(() => {
                this.adapter.disconnect();
            });
        } else {
            this.adapter.disconnect();
        }
        Backbone.Model.prototype.destroy.apply(this, arguments);
    },

    async toObject() {
        return Promise.resolve(deepClone(this.attributes));
    },

    async fromObject(obj) {
        this.set(deepClone(obj));
    }

});

StorageModel.fetch = function(id, options) {
    const model = new StorageModel({ id: id }, Object.assign({}, options, { autoSync: false }));
    return model.load().catch(err => {
        model.disconnect();
        return Promise.reject(err);
    });
};

StorageModel.fetchData = function(id, options) {
    return StorageModel.fetch(id, options).then(model => {
        const attrs = Object.assign({}, model.attributes);
        model.disconnect();
        return attrs;
    });
};

export default StorageModel;
