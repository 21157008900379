import { tinycolor, _ } from "../../vendor";
import { ForeColorType, PaletteColorType, BackgroundStyleType } from "common/constants";
import { blendColors } from "js/core/utilities/utilities";

const BSS_COLOR_REGEX = /^\s*(rgba?\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*(,\s*\d+(\.\d+)?\s*)?\)|\S+)(\s+.*)?$/;

class Palette {
    constructor(theme) {
        this.theme = theme;

        // palette migration defaults - MJG 6/20
        if (!this.colors.hasOwnProperty("chart1")) {
            let colors = { ...this.colors };
            let index = 0;
            for (let color of Object.values(this.getSlideColors())) {
                colors["chart" + ++index] = color;
            }
            this.theme.set("colors", colors, { silent: true });
        }
        if (!this.colors.hasOwnProperty(PaletteColorType.BACKGROUND_ACCENT)) {
            let blendColor = tinycolor(this.colors[PaletteColorType.THEME]).setAlpha(0.1);
            let accentColor = blendColors(blendColor, tinycolor("white")).toRgbString();
            this.theme.set("colors", {
                ...this.colors,
                [PaletteColorType.BACKGROUND_ACCENT]: accentColor
            }, { silent: true });
        }

        if (!this.colors.hasOwnProperty(PaletteColorType.POSITIVE)) {
            this.theme.set("colors", { ...this.colors, [PaletteColorType.POSITIVE]: "#54C351" }, { silent: true });
        }
        if (!this.colors.hasOwnProperty(PaletteColorType.NEGATIVE)) {
            this.theme.set("colors", { ...this.colors, [PaletteColorType.NEGATIVE]: "#E04C2B" }, { silent: true });
        }

        if (!this.colors.hasOwnProperty(PaletteColorType.HYPERLINK)) {
            this.theme.set("colors", { ...this.colors, [PaletteColorType.HYPERLINK]: "#11a9e2" }, { silent: true });
        }
    }

    get colors() {
        return this.theme.get("colors") || {};
    }

    isRawColor(color) {
        return tinycolor(color).isValid();
    }

    getColor(name, colorMods = []) {
        if (!name || name == "none") {
            return {
                name: "none",
                toHexString: function() {
                    return "none";
                },
                toRgbString: function() {
                    return "none";
                },
                isDark: function() {
                    return false;
                },
                darken: function() {
                },
                lighten: function() {
                },
                clone: function() {
                    return this;
                },
                setAlpha: function() {
                    return this;
                },
                getAlpha: function() {
                    return 1;
                }

            };
        }

        if (name == ForeColorType.COLORFUL) {
            name = "theme";
        }

        let color;
        if (this.colors.hasOwnProperty(name)) {
            color = tinycolor(this.colors[name]);
            color.name = name;
        } else if (name.startsWith("accent")) {
            // if we are referring to a removed accent color, return theme
            color = tinycolor(this.colors["theme"]);
            color.name = "theme";
        } else if (name.startsWith("chart")) {
            // Roll the chart color over if we don't have enough colors
            let chartColors = Object.keys(this.colors)
                .filter(name => name.startsWith("chart"))
                .sort((nameA, nameB) => nameA < nameB ? -1 : 1)
                .map(name => this.colors[name]);
            let index = (parseInt(name.split("chart")[1]) - 1);
            index %= chartColors.length;
            let chartColor = chartColors[index];
            color = tinycolor(chartColor);
        } else if (name == "neutral") {
            color = tinycolor("white");
            color.name = "neutral";
        } else {
            color = tinycolor(name);
        }

        color.isColor = !name.equalsAnyOf(PaletteColorType.BACKGROUND_ACCENT, PaletteColorType.BACKGROUND_DARK, PaletteColorType.BACKGROUND_LIGHT, PaletteColorType.PRIMARY_DARK, PaletteColorType.PRIMARY_LIGHT, "white", "black");

        this.applyColorMods(color, colorMods);

        return color;
    }

    getColorMods(color) {
        const colorMods = [];

        if (typeof color === "string") {
            // strip and spaces after commas
            color = color.replace(/,\s+/g, ",");

            const results = BSS_COLOR_REGEX.exec(color);

            if (results) {
                color = results[1];
                let mods = results[results.length - 1];
                mods = mods ? mods.split(" ") : [];

                for (let i = 0; i < mods.length; i++) {
                    const colorMod = mods[i];

                    if (colorMod === "") {
                        continue;
                    }

                    if (colorMod.startsWith("darken")) {
                        colorMods.push({
                            type: "darken",
                            value: parseInt(colorMod.substring("darken".length))
                        });
                    } else if (colorMod.startsWith("lighten")) {
                        colorMods.push({
                            type: "lighten",
                            value: parseInt(colorMod.substring("lighten".length))
                        });
                    } else {
                        colorMods.push({
                            type: "alpha",
                            value: parseFloat(colorMod)
                        });
                    }
                }
            }
        }
        return colorMods;
    }

    applyColorMods(color, colorMods) {
        if (color.name != "none") {
            for (let colorMod of colorMods) {
                switch (colorMod.type) {
                    case "alpha":
                        color.setAlpha(colorMod.value);
                        break;
                    case "darken":
                        color.darken(colorMod.value);
                        break;
                    case "lighten":
                        color.lighten(colorMod.value);
                        break;
                }
            }
        }
        return color;
    }

    getBackgroundColorType(backgroundColor) {
        if (backgroundColor == BackgroundStyleType.IMAGE) {
            return BackgroundStyleType.IMAGE;
        } else if (backgroundColor._originalInput == "white") {
            return BackgroundStyleType.LIGHT;
        } else if (backgroundColor._originalInput == "black") {
            return BackgroundStyleType.DARK;
        } else if (tinycolor.equals(backgroundColor, this.getColor(PaletteColorType.BACKGROUND_DARK))) {
            return BackgroundStyleType.DARK;
        } else if (tinycolor.equals(backgroundColor, this.getColor(PaletteColorType.BACKGROUND_LIGHT))) {
            return BackgroundStyleType.LIGHT;
        } else if (tinycolor.equals(backgroundColor, this.getColor(PaletteColorType.BACKGROUND_ACCENT))) {
            return BackgroundStyleType.ACCENT;
        } else {
            return BackgroundStyleType.COLOR;
        }
    }

    getForeColor(color, slideColor, backgroundColor, options = {}) {
        let backgroundColorType;
        if (backgroundColor && backgroundColor.isDark) {
            backgroundColorType = this.getBackgroundColorType(backgroundColor);
            // if the background is color, automagically update the forecolor to white or black depending on the color brightness
            if (backgroundColorType == BackgroundStyleType.COLOR) {
                if (backgroundColor.isDark()) {
                    backgroundColorType = BackgroundStyleType.DARK;
                } else {
                    backgroundColorType = BackgroundStyleType.LIGHT;
                }
                if (color == ForeColorType.NEUTRAL) {
                    color = PaletteColorType.PRIMARY_LIGHT;
                } else if (color.contains(ForeColorType.SLIDE)) {
                    // this is the color-on-color check. We only allow color-on-color if the elemenet has a user defined color and is on an authoringCanvas or the allowColorOnColor option is set
                    if (options.userColor && options.userColor != "auto" && (options.allowColorOnColor || options.isOnAuthoringCanvas)) {
                        color = color.replace(ForeColorType.SLIDE, options.userColor);
                    } else {
                        // if we aren't allowing color-on-color we always resolve slide color to white (unless its style = slide!)
                        if (!color.contains("!")) {
                            // slide color on a background color should be white (unless slide color is transparent)
                            color = color.replace(ForeColorType.SLIDE, PaletteColorType.PRIMARY_LIGHT);
                        }
                    }
                } else if (color == "auto") {
                    // if the color is auto then set it to light/dark depending on the background
                    // this is different than slide which would always be Light even on a light background
                    switch (backgroundColorType) {
                        case BackgroundStyleType.DARK:
                            color = PaletteColorType.PRIMARY_LIGHT;
                            break;
                        case BackgroundStyleType.LIGHT:
                            color = PaletteColorType.PRIMARY_DARK;
                            break;
                        case BackgroundStyleType.ACCENT:
                            color = "slide";
                            break;
                    }
                } else if (color == "positive" || color == "negative") {
                    switch (backgroundColorType) {
                        case BackgroundStyleType.DARK:
                            color = PaletteColorType.PRIMARY_LIGHT;
                            break;
                        case BackgroundStyleType.LIGHT:
                            color = PaletteColorType.PRIMARY_DARK;
                            break;
                    }
                }
            }
        } else if (backgroundColor == BackgroundStyleType.IMAGE) {
            backgroundColorType = BackgroundStyleType.IMAGE;
        }

        // if the color is auto at this point, we can switch it use slide because we are on a light/dark bg
        color = color.replace("auto", "slide");

        // force the slide color
        color = color.replace("slide!", "slide");

        if (color == ForeColorType.SLIDE && (tinycolor.equals(slideColor, backgroundColor) || (slideColor.name && (slideColor.name.contains("primary") || slideColor.name == "neutral")))) {
            switch (backgroundColorType) {
                case BackgroundStyleType.DARK:
                    color = "primary_light";
                    break;
                case BackgroundStyleType.LIGHT:
                case BackgroundStyleType.ACCENT:
                    color = "primary_dark";
                    break;
            }
        }

        if (color == ForeColorType.SLIDE && slideColor.name && slideColor.name.contains("secondary")) {
            switch (backgroundColorType) {
                case BackgroundStyleType.DARK:
                    color = "secondary_light";
                    break;
                case BackgroundStyleType.LIGHT:
                case BackgroundStyleType.ACCENT:
                    color = "secondary_dark";
                    break;
            }
        }

        const colorMods = this.getColorMods(color);

        if (colorMods.length) {
            color = color.replace(/,\s+/g, ",");
            color = color.split(" ")[0]; // strip any mods
        }

        if (color == "backgroundColor" || color == "background") {
            return backgroundColor;
        }

        switch (backgroundColorType) {
            case BackgroundStyleType.DARK:
            case BackgroundStyleType.IMAGE:
                switch (color) {
                    case ForeColorType.TRAY:
                    case ForeColorType.SLIDE:
                        if (slideColor == BackgroundStyleType.IMAGE) {
                            return tinycolor("black");
                        } else {
                            // return this.getColor(slideColor.name, colorMods);
                            return this.applyColorMods(_.clone(slideColor), colorMods);
                        }
                    case ForeColorType.NEUTRAL:
                    case ForeColorType.PRIMARY:
                        return this.getColor(PaletteColorType.PRIMARY_LIGHT, colorMods);
                    case ForeColorType.SECONDARY:
                        return this.getColor(PaletteColorType.SECONDARY_LIGHT, colorMods);
                    default:
                        return this.getColor(color, colorMods);
                }
            case BackgroundStyleType.LIGHT:
                switch (color) {
                    case ForeColorType.TRAY:
                    case ForeColorType.SLIDE:
                        if (slideColor == BackgroundStyleType.IMAGE) {
                            return tinycolor("black");
                        } else {
                            // return this.getColor(slideColor.name, colorMods);
                            return this.applyColorMods(_.clone(slideColor), colorMods);
                        }
                    case ForeColorType.NEUTRAL:
                    case ForeColorType.PRIMARY:
                        return this.getColor(PaletteColorType.PRIMARY_DARK, colorMods);
                    case ForeColorType.SECONDARY:
                        return this.getColor(PaletteColorType.SECONDARY_DARK, colorMods);
                    default:
                        return this.getColor(color, colorMods);
                }
            case BackgroundStyleType.COLOR:
                return this.getColor(PaletteColorType.PRIMARY_LIGHT, colorMods);
            case BackgroundStyleType.ACCENT:
                switch (color) {
                    case ForeColorType.SLIDE:
                        return this.applyColorMods(_.clone(slideColor), colorMods);
                    case ForeColorType.PRIMARY:
                        if (backgroundColor.isDark()) {
                            return this.getColor(PaletteColorType.PRIMARY_LIGHT, colorMods);
                        } else {
                            return this.getColor(PaletteColorType.PRIMARY_DARK, colorMods);
                        }
                    case ForeColorType.SECONDARY:
                        if (backgroundColor.isDark()) {
                            return this.getColor(PaletteColorType.SECONDARY_LIGHT, colorMods);
                        } else {
                            return this.getColor(PaletteColorType.SECONDARY_DARK, colorMods);
                        }
                    default:
                        return this.getColor(color, colorMods);
                }
            default:
                // backgroundColor is undefined
                if (color === "slide") {
                    // log.error(`Err: color is ${color}, backgroundColor is ${backgroundColor}`);
                    return this.getColor(slideColor.name, colorMods);
                }
                return this.getColor(color, colorMods);
        }
    }

    getColorfulColor(index) {
        let colors = [PaletteColorType.THEME];
        for (let accentColor of Object.keys(this.getAccentColors())) {
            colors.push(accentColor);
        }

        return this.getColor(colors[Math.max(0, index) % colors.length]);
    }

    getShadedColor(baseColor, index, maxCount, toDark) {
        if (toDark) {
            return baseColor.clone().darken(30 * (index / maxCount));
        } else {
            return baseColor.clone().brighten(30 * (index / maxCount));
        }
    }

    getBlendedColor(baseColor, index, maxCount, toDark) {
        let color = baseColor.clone();
        color.setAlpha(index / maxCount);
        return "white " + (index / maxCount);
    }

    getSlideColors() {
        let colors = {
            [PaletteColorType.THEME]: this.getColor(PaletteColorType.THEME).toHexString()
        };
        _.extend(colors, this.getAccentColors());
        return colors;
    }

    getAccentColors() {
        let colors = {};
        for (let colorName of Object.keys(this.colors)) {
            if (colorName.startsWith("accent")) {
                colors[colorName] = this.colors[colorName];
            }
        }
        return colors;
    }

    getChartColors(includePositiveNegetiveColors = true) {
        let colors = {};
        for (let colorName of Object.keys(this.colors)) {
            if (colorName.startsWith("chart")) {
                colors[colorName] = this.colors[colorName];
            }
        }
        if (includePositiveNegetiveColors) {
            colors.positive = this.colors[PaletteColorType.POSITIVE];
            colors.negative = this.colors[PaletteColorType.NEGATIVE];
        }
        return colors;
    }

    getBackgroundColors(includeColors = true) {
        let colors = {
            [PaletteColorType.BACKGROUND_LIGHT]: this.getColor(PaletteColorType.BACKGROUND_LIGHT).toRgbString(),
            [PaletteColorType.BACKGROUND_DARK]: this.getColor(PaletteColorType.BACKGROUND_DARK).toRgbString(),
            [PaletteColorType.BACKGROUND_ACCENT]: this.getColor(PaletteColorType.BACKGROUND_ACCENT).toRgbString()
        };
        if (includeColors) {
            _.extend(colors, { [PaletteColorType.THEME]: this.getColor(PaletteColorType.THEME).toRgbString() }, this.getAccentColors());
        }
        return colors;
    }

    getColorfulColors() {
        let colors = [PaletteColorType.THEME];

        for (let accentColor of Object.keys(this.getAccentColors())) {
            colors.push(accentColor);
        }

        return colors;
    }

    getColors() {
        let colors = {
            primary_dark: this.getColor("primary_dark").toHexString(),
            primary_light: this.getColor("primary_light").toHexString(),
            [PaletteColorType.THEME]: this.getColor(PaletteColorType.THEME).toHexString()
        };
        _.extend(colors, this.getAccentColors());

        return colors;
    }

    // helper function for ui color palette pickers
    getColorList(options = {}) {
        var colors = [];

        if (options.includeNone) {
            colors.push({ type: "color", value: "none", color: "none" });
        }

        _.each(this.colors, (color, key) => {
            colors.push({ type: "color", value: key, color: color });
        });

        return colors;
    }

    getElementColors() {
        return Object.keys(this.getSlideColors())
            .map(value => ({
                type: "color",
                value,
                color: this.getColor(value).toRgbString()
            }));
    }
}

export { Palette };
