import { app } from "js/namespaces";
import firebase from "js/firebase";
import { serverApi } from "js/config";
import ApiError from "common/ApiError";
import getLogger, { LogGroup, sessionId } from "js/core/logger";
import fetchAndTrackOfflineStatus from "js/core/utilities/fetchAndTrackOfflineStatus";

const logger = getLogger(LogGroup.API);

declare global {
    interface Window { overrideIdToken: string; }
}

interface ServerApi {
    [routeName: string]: {
        get: (
            params?: Record<string, any>,
            reloadCache?: boolean,
            returnRawResponseObject?: boolean,
            trackOfflineStatus?: boolean,
            verbose?: boolean
        ) => Promise<any>,
        post: (
            params?: Record<string, any>,
            rawPayload?: Record<string, any>,
            reloadCache?: boolean,
            returnRawResponseObject?: boolean,
            trackOfflineStatus?: boolean,
            verbose?: boolean
        ) => Promise<any>,
        put: (
            params?: Record<string, any>,
            rawPayload?: Record<string, any>,
            reloadCache?: boolean,
            returnRawResponseObject?: boolean,
            trackOfflineStatus?: boolean,
            verbose?: boolean
        ) => Promise<any>,
        delete: (
            params?: Record<string, any>,
            rawPayload?: Record<string, any>,
            reloadCache?: boolean,
            returnRawResponseObject?: boolean,
            trackOfflineStatus?: boolean,
            verbose?: boolean
        ) => Promise<any>
    }
}

/**
 * Builds a request object containing "url" and "payload" fields,
 * parses the supplied url if it contains resource parameters (i.e. /:id)
 * and replaces them with values from the payload
 */
function buildRequest(url: string, payload: Record<string, any>) {
    // No payload passed, returning the plain url
    if (!payload) {
        return {
            url,
            payload: null
        };
    }

    let enrichedUrl = url;
    const filteredPayload: Record<string, any> = {};
    for (const payloadKey in payload) {
        const payloadValue = payload[payloadKey];

        const urlResourceParameterName = `:${payloadKey}`;
        // If the url contains a resource parameter then replace it with the corresponding payload's field
        if (enrichedUrl.includes(urlResourceParameterName)) {
            enrichedUrl = enrichedUrl.replace(urlResourceParameterName, encodeURIComponent(payloadValue));
        } else {
            filteredPayload[payloadKey] = payloadValue;
        }
    }

    return {
        url: enrichedUrl,
        payload: filteredPayload
    };
}

/**
 * Builds a query with the supplied url and query parameters
 */
function appendQueryParameters(url: string, queryParameters: Record<string, any>) {
    if (!queryParameters) {
        return url;
    }

    const queryString = Object.keys(queryParameters)
        .filter(queryParameterName => queryParameters[queryParameterName] != null)
        .map(queryParameterName => `${encodeURIComponent(queryParameterName)}=${encodeURIComponent(queryParameters[queryParameterName])}`)
        .join("&");

    if (!queryString) {
        return url;
    }

    return `${url}?${queryString}`;
}

/**
 * Submits a fetch with the supplied parameters
 * Parses the response into an object, logs out the user upon 401s
 */
async function request(
    routeName: string,
    url: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    payload: Record<string, any>,
    reloadCache: boolean = false,
    returnRawResponseObject: boolean = false,
    trackOfflineStatus: boolean = true,
    verbose: boolean = undefined,
    on5xxAttemptsLeft = 3
) {
    const fetchParams: any = {
        method,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-Client-Session-Id": sessionId
        },
        credentials: "same-origin"
    };

    if (payload) {
        fetchParams.body = JSON.stringify(payload);
    }

    if (window.overrideIdToken) {
        fetchParams.headers["Authorization"] = `Bearer ${window.overrideIdToken}`;
    } else if (firebase.auth().currentUser) {
        const idToken = await firebase.auth().currentUser.getIdToken();
        fetchParams.headers["Authorization"] = `Bearer ${idToken}`;
    }

    if (reloadCache) {
        fetchParams.cache = "reload";

        if (verbose === undefined) {
            // Force verbose mode if cache is requested to be reloaded
            // (Temporary solution to debug cache issues)
            verbose = true;
        }
    }

    if (app.appController?.workspaceId) {
        fetchParams.headers["X-Workspace-Id"] = app.appController.workspaceId;
    }

    const loggerTraceData = {
        routeName,
        url,
        method,
        payload: "<redacted>",
        reloadCache,
        returnRawResponseObject,
        trackOfflineStatus,
        on5xxAttemptsLeft,
        fetchParams: {
            ...fetchParams,
            body: "<redacted>",
            headers: {
                ...fetchParams.headers,
                "Authorization": "<redacted>"
            }
        }
    };
    if (verbose) {
        logger.info("Requesting...", loggerTraceData);
    }

    const response = await (trackOfflineStatus ? fetchAndTrackOfflineStatus(url, fetchParams) : fetch(url, fetchParams));

    // Successful response
    if (response.status < 400) {
        if (returnRawResponseObject) {
            return response;
        }
        return await response.json();
    }

    // Errorneous response

    // Reporing the error
    try {
        const responseBody = await response.clone().text().catch(() => null);
        logger.error(new Error(`API request to ${url} failed`), "Request failed", {
            ...loggerTraceData,
            requestId: response.headers.get("X-Request-ID") ?? response.headers.get("x-cloud-trace-context"),
            responseBody
        });
    } catch (err) {
        // ignore
    }

    if (response.status < 500) {
        // Status <500 means it's a caught/handled error, so we can parse the response with error details
        let errorData;
        try {
            errorData = await response.json();
        } catch (err) {
            // Note: we already logged all request details above
            logger.warn(err, "Couldn't parse erroneous response");
        }

        if (errorData) {
            throw new ApiError(errorData.message, errorData.status ?? response.status, errorData.code ?? undefined);
        }

        throw new ApiError(`API responded with ${response.status} status code`, response.status);
    }

    // Unexpected server error
    if (on5xxAttemptsLeft > 0) {
        // Note: we already logged all request details above
        logger.info(`Received ${response.status} status, sleeping for 1 second and retrying request..`);
        await new Promise(resolve => setTimeout(resolve, 1000));
        return request(routeName, url, method, payload, reloadCache, returnRawResponseObject, trackOfflineStatus, verbose, on5xxAttemptsLeft - 1);
    }

    throw new ApiError("Oops! Our server had an error. Try again later", response.status);
}

/**
 * The Api object allows us to call server api endpoint like they are function calls.
 * This reads data from the serverApi object passed in here from the server that has a list of all
 * api endpoints.
 */
const Api: ServerApi = Object.entries(serverApi ?? {}).reduce((api, [routeName, routeUrl]) => ({
    ...api,
    [routeName]: {
        get: (params, reloadCache, returnRawResponseObject, trackOfflineStatus, verbose) => {
            const { url, payload } = buildRequest(routeUrl as string, params);
            return request(
                routeName,
                appendQueryParameters(url, payload),
                "GET",
                null,
                reloadCache,
                returnRawResponseObject,
                trackOfflineStatus,
                verbose
            );
        },
        post: (params, rawPayload, reloadCache, returnRawResponseObject, trackOfflineStatus, verbose) => {
            const { url, payload } = buildRequest(routeUrl as string, params);
            return request(
                routeName,
                url,
                "POST",
                rawPayload ? rawPayload : payload,
                reloadCache,
                returnRawResponseObject,
                trackOfflineStatus,
                verbose
            );
        },
        put: (params, rawPayload, reloadCache, returnRawResponseObject, trackOfflineStatus, verbose) => {
            const { url, payload } = buildRequest(routeUrl as string, params);
            return request(
                routeName,
                url,
                "PUT",
                rawPayload ? rawPayload : payload,
                reloadCache,
                returnRawResponseObject,
                trackOfflineStatus,
                verbose
            );
        },
        delete: (params, rawPayload, reloadCache, returnRawResponseObject, trackOfflineStatus, verbose) => {
            const { url, payload } = buildRequest(routeUrl as string, params);
            return request(
                routeName,
                url,
                "DELETE",
                rawPayload ? rawPayload : payload,
                reloadCache,
                returnRawResponseObject,
                trackOfflineStatus,
                verbose
            );
        }
    }
}), {});

export default Api;
