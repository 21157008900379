import { getCodeList } from "country-list";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { TextField } from "@material-ui/core";

import { Dropdown } from "js/Components/Dropdown";
import { MenuItem } from "js/Components/Menu";
import { TaxIdForm } from "./TaxIdForm";
import { guessUserCountry } from "./utils";

const AddressContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`;

const InputContainer = styled.div<{ isSingle?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: ${({ isSingle }) => isSingle ? "100%" : "calc(50% - 6px)"};

    >label {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 14.88px;
        font-weight: 400;
        line-height: 17.112px;
        color: rgb(48, 49, 61);
        margin-bottom: 4px;
    }
`;

const StyledDropdown = styled(Dropdown)`
    &&& {
        width: 100%;

        .MuiSelect-select {
            padding: 10px 32px 10px 12px;
            font-size: 16px;
            font-weight: 400;
            color: rgb(48, 49, 61);
        }

        &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border-color: #11a9e2;
            }
        }
    }
`;

const StyledTextField = styled(TextField) <{ warning: boolean }>`
    &&& {
        >:not(.Mui-focused)>.MuiOutlinedInput-notchedOutline {
            border-color: ${({ warning }) => warning ? "red" : "rgba(0, 0, 0, 0.23)"};
            box-shadow: ${({ warning }) => warning ? "0 0 0px 1px #ff0000;" : "none"}; 
        }

        .Mui-focused>.MuiOutlinedInput-notchedOutline {
            border-width: 1px;
            border-color: #11a9e2;
            box-shadow: 0 0 0px 1px #11a9e2;
        }

        :hover>:not(.Mui-focused)>.MuiOutlinedInput-notchedOutline {
            border-color: #333;
        }

        input::placeholder {
            font-style: normal;
        }
    }
`;

const ErrorMessage = styled.div`
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.112px;
    color: red;
`;

function getPostalCodeProps(countryCode: string): {
    show: boolean;
    placeholder: string;
    validate: (value: string) => boolean;
    normalize: (value: string) => string;
    name: string;
} {
    if (countryCode === "US") {
        return {
            show: true,
            placeholder: "00000",
            validate: value => /^\d{5}$/.test(value),
            normalize: value => value.replace(/\D/g, ""),
            name: "Zip code"
        };
    }

    if (countryCode === "CA") {
        return {
            show: true,
            placeholder: "T5M 1A3",
            validate: value => /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(value),
            normalize: value => value.replace(/[^A-Z0-9 -]/gi, ""),
            name: "Postal code"
        };
    }

    if (countryCode === "GB") {
        return {
            show: true,
            placeholder: "RG6 1FL",
            validate: value => /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/.test(value),
            normalize: value => value.replace(/[^A-Z0-9 -]/gi, ""),
            name: "Postal code"
        };
    }

    return {
        show: false,
        placeholder: undefined,
        validate: () => true,
        normalize: value => value,
        name: "Postal code"
    };
}

/**
 * Stripe-compatible billing address
 */
interface MinimalBillingAddress {
    country: string;
    postal_code: string;
}

interface Props {
    initialAddress: MinimalBillingAddress;
    initialTaxId: string;
    showTaxIdForm: boolean;
    onChange: (values: { address: MinimalBillingAddress, isAddressValid: boolean, taxId: string | null, isTaxIdValid: boolean, taxIdType: string | null }) => void;
    disabled: boolean;
}

export function MinimalBillingAddressForm(props: Props) {
    const {
        initialAddress,
        initialTaxId,
        showTaxIdForm,
        onChange,
        disabled
    } = props;

    const [countryCode, setCountryCode] = useState(initialAddress.country || guessUserCountry());
    const [postalCode, setPostalCode] = useState(initialAddress.postal_code);
    const [taxId, setTaxId] = useState<string>(initialTaxId);
    const [taxIdType, setTaxIdType] = useState<string>(null);
    const [isTaxIdValid, setIsTaxIdValid] = useState(true);
    const [showError, setShowError] = useState(false);

    const {
        show: showPostalCode,
        placeholder: postalCodePlaceholder,
        validate: postalCodeValidate,
        normalize: postalCodeNormalize,
        name: postalCodeName
    } = getPostalCodeProps(countryCode);
    const isAddressValid = postalCodeValidate(postalCode);

    const handleCountryCodeChange = (countryCode: string) => {
        setCountryCode(countryCode);
        setPostalCode("");
        setTaxId("");
    };

    const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPostalCode(postalCodeNormalize(event.target.value));
    };

    const handleTaxIdChange = ({ taxId, isTaxIdValid, taxIdType }) => {
        setTaxId(taxId);
        setIsTaxIdValid(isTaxIdValid);
        setTaxIdType(taxIdType);
    };

    const handleBlur = () => {
        setShowError(true);
    };

    const handleFocus = () => {
        setShowError(false);
    };

    useEffect(() => {
        onChange({
            address: {
                country: countryCode,
                postal_code: showPostalCode ? postalCode : ""
            },
            isAddressValid,
            taxId,
            isTaxIdValid,
            taxIdType
        });
    }, [countryCode, postalCode, showPostalCode, taxId, isTaxIdValid, taxIdType]);

    const countryCodes = Object.entries(getCodeList())
        .map(([code, name]) => ({ code: code as string, name: name as string }))
        .sort((a, b) => a.name.localeCompare(b.name));

    return (<>
        <AddressContainer>
            <InputContainer isSingle={!showPostalCode}>
                <label>Country</label>
                <StyledDropdown
                    value={countryCode}
                    onChange={handleCountryCodeChange}
                    onBlur={handleBlur}
                    disabled={disabled}
                    data-testid="minimal-billing-address-form-country"
                >
                    {countryCodes.map(({ code, name }) => (
                        <MenuItem key={code} value={code.toUpperCase()}>{name}</MenuItem>
                    ))}
                </StyledDropdown>
            </InputContainer>
            {showPostalCode && <InputContainer isSingle={false}>
                <label>{postalCodeName}</label>
                <StyledTextField
                    variant="outlined"
                    placeholder={postalCodePlaceholder}
                    onChange={handlePostalCodeChange}
                    value={postalCode}
                    fullWidth
                    disabled={disabled}
                    autoFocus={false}
                    autoComplete="off"
                    warning={!isAddressValid && showError}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    data-testid="minimal-billing-address-form-postal-code"
                />
                {!isAddressValid && showError && <ErrorMessage>Invalid {postalCodeName}</ErrorMessage>}
            </InputContainer>}
        </AddressContainer>
        {showTaxIdForm && <TaxIdForm
            countryCode={countryCode}
            initialTaxId={""}
            onChange={handleTaxIdChange}
            disabled={disabled}
        />}
    </>);
}

export default MinimalBillingAddressForm;
